import { Tooltip, withStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const styles = {
  tooltip: {
    textAlign: 'center',
    background: Colors.white.default,
    boxShadow: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.2)',
    color: Colors.black.default,
    fontSize: '0.75rem',
    fontWeight: 400,
    borderRadius: '‭0.125rem'
  }
};

const WhiteTooltip = withStyles(styles)(Tooltip);

export default WhiteTooltip;
