import {
  SET_BULK_HCOGS_WARNING_SHOW,
  SET_FONT_READY,
  SET_NOT_FILLED_COMPANY_COUNTRY_WARNING_SHOW,
  SET_PPC_NEEDS_RECONNECT_WARNING_SHOW,
  SET_SUBHEADER_WARNINGS_HEIGHT,
  SET_VIEWPORT_HEIGHT
} from '../actions';

const defaultState = {
  fontReady: false,
  viewportHeight: '100vh',
  subheaderWarningsHeight: 0,
  ppcWarningShow: false,
  notFilledCompanyCountryWarningShow: false,
  bulkHcogsWarning: null
};

export const uiSelector = state => state.ui;

const ui = (state = defaultState, action) => {
  switch (action.type) {
    case SET_FONT_READY: {
      return { ...state, fontReady: true };
    }
    case SET_VIEWPORT_HEIGHT: {
      return { ...state, viewportHeight: action.payload };
    }
    case SET_SUBHEADER_WARNINGS_HEIGHT: {
      return { ...state, subheaderWarningsHeight: action.payload };
    }
    case SET_NOT_FILLED_COMPANY_COUNTRY_WARNING_SHOW: {
      return { ...state, notFilledCompanyCountryWarningShow: action.payload };
    }
    case SET_BULK_HCOGS_WARNING_SHOW: {
      return { ...state, bulkHcogsWarning: action.payload };
    }
    case SET_PPC_NEEDS_RECONNECT_WARNING_SHOW: {
      return { ...state, ppcWarningShow: action.payload };
    }
    default:
      return state;
  }
};

export default ui;
