import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const UserAvatar = ({ userName, className, avatarUrl }) => {
  const userNameList = userName ? userName.replace(/\s+/g, ' ').split(' ') : [];
  let avatar = '';
  if (userNameList.length > 0) {
    [[avatar]] = userNameList;
  }
  if (userNameList.length > 1) {
    avatar += userNameList[1][0];
  }
  if (avatarUrl) {
    avatar = (
      <div
        style={{ backgroundImage: `url(${avatarUrl})`, backgroundPosition: 'top center' }}
        className="w-full h-full bg-cover round"
      />
    );
  }
  const rootClassName = classNames(
    'round bg-half-transparent sm-line-height align-middle items-center justify-center text-center text-lg flex-shrink-0',
    className
  );

  return <div className={rootClassName}>{avatar}</div>;
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  userName: PropTypes.string.isRequired
};
UserAvatar.defaultProps = {
  className: '',
  avatarUrl: ''
};

export default UserAvatar;
