const TIMEZONES_LIST = [
  { id: 0, title: 'USA', regions: [1, 3], timezone: 'America/Los_Angeles' },
  { id: 1, title: 'MX', regions: [2], timezone: 'America/Mexico_City' },
  { id: 2, title: 'UK', regions: [8], timezone: 'Europe/London' },
  { id: 3, title: 'CET', regions: [4, 5, 6, 7, 18, 20, 22], timezone: 'Europe/Berlin' },
  { id: 4, title: 'IN', regions: [9], timezone: 'Asia/Kolkata' },
  { id: 5, title: 'JP', regions: [10], timezone: 'Asia/Tokyo' },
  { id: 6, title: 'AU', regions: [12], timezone: 'Australia/Sydney' },
  { id: 7, title: 'BR', regions: [13], timezone: 'Brazil/East' },
  { id: 8, title: 'SG', regions: [14], timezone: 'Asia/Singapore' },
  { id: 9, title: 'UAE', regions: [15], timezone: 'Asia/Dubai' },
  { id: 10, title: 'SA', regions: [19], timezone: 'Asia/Riyadh' },
  { id: 10, title: 'EG', regions: [17], timezone: 'Africa/Cairo' },
  { id: 11, title: 'TR', regions: [21], timezone: 'Europe/Istanbul' }
];

export default TIMEZONES_LIST;
