import React from 'react';
import PropTypes from 'prop-types';
import { isAfter, isBefore, startOfDay } from 'date-fns';
import { IconButton } from '@material-ui/core';
import Colors from 'styles/colors';
import { ChevronLeftIcon, ChevronRightIcon } from '../icons';
import { getSafeMkRange, getShiftedRange } from './snippets';

const IntervalControl = ({ lifeTime, range, mkToday, updateSelection }) => {
  const subbedRange = getShiftedRange(range, true);
  const shiftedRange = getShiftedRange(range);
  const addedRange = getSafeMkRange(shiftedRange, mkToday, lifeTime) || shiftedRange;

  const disableArrows = {
    left: isBefore(startOfDay(subbedRange.from), startOfDay(lifeTime)),
    right: isAfter(startOfDay(shiftedRange.from), startOfDay(mkToday))
  };

  return (
    <div className="h-4 xs:ml-4 flex items-center">
      <IconButton
        className="h-8 w-8 p-0"
        onClick={() => updateSelection(subbedRange)}
        disabled={disableArrows.left}
      >
        <ChevronLeftIcon
          className="object-contain sm-w-12px"
          fill={disableArrows.left ? Colors.gray.default : Colors.black.default}
          height="0.75rem"
        />
      </IconButton>
      <IconButton
        className="h-8 w-8 p-0"
        onClick={() => updateSelection(addedRange)}
        disabled={disableArrows.right}
      >
        <ChevronRightIcon
          className="object-contain sm-w-12px"
          height="0.75rem"
          fill={disableArrows.right ? Colors.gray.default : Colors.black.default}
        />
      </IconButton>
    </div>
  );
};

IntervalControl.propTypes = {
  range: PropTypes.instanceOf(Object).isRequired,
  updateSelection: PropTypes.func.isRequired,
  mkToday: PropTypes.instanceOf(Date).isRequired,
  lifeTime: PropTypes.instanceOf(Date).isRequired
};
export default IntervalControl;
