import axios from 'axios/index';
import API from '@sm/api';
import { buildParams } from 'seller/utils/buildParams';
import { dateToStr } from '@utils';
import { pullNotesAction } from '../saga/dashboard';

const pullNotesStep = (state, dispatch, callback) => {
  const params = buildParams({ state, dispatch }, true);
  dispatch(pullNotesAction({ params, callback }));
};

const print = reason => {
  console.log(reason);
};

export const addNoteAction = (description, date, marketplaceID, tag) => {
  return (dispatch, getState) => {
    const { userId } = getState().user;

    return axios
      .post(API.notes, { userId, description, date: dateToStr(date), marketplaceID, tag })
      .then(() => pullNotesStep(getState(), dispatch))
      .catch(print);
  };
};

export const editNoteAction = (id, description, date, marketplaceID, tag, callback) => {
  return (dispatch, getState) => {
    const { userId } = getState().user;

    return axios
      .put(API.notes, { id, userId, description, date: dateToStr(date), marketplaceID, tag })
      .then(() => {
        pullNotesStep(getState(), dispatch, callback);
      })
      .catch(print);
  };
};

export const deleteNoteAction = id => {
  return (dispatch, getState) => {
    const { userId } = getState().user;

    return axios
      .delete(API.notes, { params: { id, userId } })
      .then(() => pullNotesStep(getState(), dispatch))
      .catch(print);
  };
};
