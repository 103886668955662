import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyBookmarkAction, setBookmarksSidebarShowAction } from 'seller/store/saga/bookmarks';
import { productSearchSelector } from 'seller/store/reducers';
import { Button } from '@material-ui/core';
import { Sidebar } from '@components';
import BookmarksList from './BookmarksList';

const BookmarksSidebar = () => {
  const dispatch = useDispatch();
  const onCancel = () => dispatch(setBookmarksSidebarShowAction(false));
  const { bookmarksSidebarOpen } = useSelector(productSearchSelector);

  const onBookmarkSet = bookmark => {
    dispatch(applyBookmarkAction(bookmark));
    dispatch(setBookmarksSidebarShowAction(false));
  };

  return (
    <Sidebar isSearchDialogOpened={bookmarksSidebarOpen} handleClose={onCancel} size="370px">
      <div className="h-full w-full flex flex-col">
        <div className="flex-grow">
          <BookmarksList onBookmarkSet={onBookmarkSet} />
        </div>
        <div className="w-full bg-white px-4 py-3 flex justify-end box-shadow-dark">
          <Button
            onClick={onCancel}
            className="h-10 text-gray-dark ml-auto border-gray-default normal-case sm-w-140px"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default BookmarksSidebar;
