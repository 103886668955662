import React from 'react';
import PropTypes from 'prop-types';

import Colors from 'styles/colors';
import HorizontalProductList from './HorizontalProductList';

const horizontalProductListStyles = {
  background: Colors.white.dark
};

const HorizontalProductListContainer = props => {
  const { data, updateList, bookmarkButton } = props;

  if (data.length === 0) {
    return null;
  }

  function onCleared(index) {
    const result = [...data];
    result.splice(index, 1);
    updateList(result);
  }

  return (
    <div className="w-full mb-4" style={horizontalProductListStyles}>
      <div className="flex mb-2 items-center h-8">
        <p className="text-gray-dark text-base mr-2">{data.length} Selected</p>
        {bookmarkButton}
        <div
          role="button"
          tabIndex={0}
          className="ml-2 cursor-pointer text-gray-dark text-sm"
          onClick={() => updateList([])}
          onKeyPress={() => updateList([])}
        >
          Clear Selections
        </div>
      </div>
      <HorizontalProductList onCleared={onCleared} data={data} className="box-shadow-list mr-4" />
    </div>
  );
};

HorizontalProductListContainer.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  updateList: PropTypes.func.isRequired,
  bookmarkButton: PropTypes.node
};
HorizontalProductListContainer.defaultProps = {
  bookmarkButton: null
};

export default HorizontalProductListContainer;
