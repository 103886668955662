import { SUCCESS, WARNING } from '@components/notifications/NotificationView';

export const SET_NOTIFICATION_MSG = 'SET_NOTIFICATION_MSG';
export const SET_PRIMARY_NOTIFICATION_MSG = 'SET_PRIMARY_NOTIFICATION_MSG';

export const setNotificationMsgAction = (
  msg,
  msgType = 'error',
  nodeTypeMessage,
  autoHideMsg,
  closeOnBlur
) => {
  return { type: SET_NOTIFICATION_MSG, msg, msgType, nodeTypeMessage, autoHideMsg, closeOnBlur };
};

export const setSuccessMsgAction = (msg, autoHideMsg = true, closeOnBlur = true) => {
  return { type: SET_NOTIFICATION_MSG, msg, msgType: SUCCESS, autoHideMsg, closeOnBlur };
};

export const setWarningMsgAction = (msg, autoHideMsg = true, closeOnBlur = true) => {
  return { type: SET_NOTIFICATION_MSG, msg, msgType: WARNING, autoHideMsg, closeOnBlur };
};

export const setPrimaryNotificationMsgAction = (msg, msgType = 'error', nodeTypeMessage) => {
  return { type: SET_PRIMARY_NOTIFICATION_MSG, msg, msgType, nodeTypeMessage };
};
