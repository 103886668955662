import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import BlackTooltip from './BlackTooltip';

const DashedUnderlineWrapper = ({
  onClick,
  children,
  tooltipContent,
  className,
  placement,
  borderColorClassname
}) => {
  if (!tooltipContent) return children;
  return (
    <BlackTooltip title={tooltipContent} arrow placement={placement} enterTouchDelay={0}>
      <Box
        onClick={onClick}
        className={classNames(
          'cursor-pointer border-b border-dashed',
          borderColorClassname,
          className
        )}
      >
        {children}
      </Box>
    </BlackTooltip>
  );
};

DashedUnderlineWrapper.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  tooltipContent: PropTypes.instanceOf(Object),
  borderColorClassname: PropTypes.string
};

DashedUnderlineWrapper.defaultProps = {
  placement: 'top',
  className: '',
  onClick: () => {},
  tooltipContent: '',
  borderColorClassname: 'border-gray'
};
export default DashedUnderlineWrapper;
