import { put, select, takeLatest, takeLeading, call } from '@redux-saga/core/effects';
import { axiosCall, isArray } from '@utils';
import API from '@sm/api';
import {
  PULL_ADVERTISING_CAMPAIGN_DETAILS,
  PULL_ADVERTISING_CAMPAIGNS,
  setAdvertisingCampaignDetailsAction,
  setAdvertisingCampaignsAction,
  setAdvertisingFetchingAction,
  UPDATE_ADVERTISING_CAMPAIGN_DETAILS
} from './action';
import { setNotificationMsgAction, setSuccessMsgAction } from '../../actions';
import { selectorSelector } from '../../reducers';

function* pullAdvertisingCampaignsSaga(action) {
  try {
    yield put(setAdvertisingFetchingAction({ campaigns: true }));
    if (!action?.skipCleanup) yield put(setAdvertisingCampaignsAction([]));
    const { marketplaceID } = yield select(selectorSelector);
    const params = { marketplaceID };
    let { data } = yield axiosCall.get(API.advertising.campaigns, { params });
    if (!isArray(data)) data = [];
    yield put(setAdvertisingCampaignsAction(data));
  } catch (e) {
    console.log('pullAdvertisingCampaignsSaga', e);
    yield put(setNotificationMsgAction('Pull advertising campaigns failed'));
  } finally {
    yield put(setAdvertisingFetchingAction({ campaigns: false }));
  }
}

function* pullAdvertisingCampaignDetailsSaga(action) {
  try {
    yield put(setAdvertisingFetchingAction({ campaigns: true }));
    const { data } = yield axiosCall.get(`${API.advertising.campaigns}${action.payload}/`);
    yield put(setAdvertisingCampaignDetailsAction(data));
  } catch (e) {
    console.log('pullAdvertisingCampaignDetailsSaga', e);
    yield put(setNotificationMsgAction('Pull advertising campaign details failed'));
  } finally {
    yield put(setAdvertisingFetchingAction({ campaigns: false }));
  }
}

function* updateAdvertisingCampaignDetailsSaga(action) {
  try {
    const { campaignDetails, products } = action.payload;
    yield put(setAdvertisingFetchingAction({ campaigns: true }));
    const patchData = { products: products.map(item => item.id) };
    yield axiosCall.patch(`${API.advertising.campaigns}${campaignDetails.id}/`, patchData);
    yield put(setSuccessMsgAction('Campaign products updated successfully'));
  } catch (e) {
    console.log('updateAdvertisingCampaignDetailsSaga', e);
    yield put(setNotificationMsgAction('Update advertising campaign products failed'));
  } finally {
    yield call(pullAdvertisingCampaignsSaga, { skipCleanup: true });
  }
}

export function* advertisingSagaWatcher() {
  yield takeLeading(PULL_ADVERTISING_CAMPAIGNS, pullAdvertisingCampaignsSaga);
  yield takeLeading(PULL_ADVERTISING_CAMPAIGN_DETAILS, pullAdvertisingCampaignDetailsSaga);
  yield takeLatest(UPDATE_ADVERTISING_CAMPAIGN_DETAILS, updateAdvertisingCampaignDetailsSaga);
}
