import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { pullBookmarksAction } from 'seller/store/saga/bookmarks';
import { productSearchSelector } from 'seller/store/reducers';
import { AutoSizer } from 'react-virtualized';
import NoResultsIcon from '@components/products/products-search-results/il_searchstack.svg';
import { rowHeightMap } from '@components/products/products-search-results/ProductsList';
import { VideoButton, LoadingSpinner } from '@components';
import BookmarkRow from './BookmarkCell';

const noBookmarks = (
  <div className="flex-grow flex flex-col items-center justify-center h-full bg-violet-white">
    <img className="sm-w-80px" src={NoResultsIcon} alt="no-results" />
    <p className="text-sm text-gray-dark mt-6 mb-2">There are no bookmarks</p>
    <VideoButton src="https://www.youtube.com/embed/OaZG-thbVLw" firstLine="Learn more" />
  </div>
);

const BookmarksList = ({ onBookmarkSet, filterFunction, userId }) => {
  const dispatch = useDispatch();
  const { bookmarksLoading, bookmarks: _bookmarks } = useSelector(productSearchSelector);
  const bookmarks = _bookmarks?.filter(filterFunction);
  useEffect(() => {
    dispatch(pullBookmarksAction({ userId }));
  }, [dispatch, userId]);

  const getBookmarkList = () => {
    return (
      <div className="bg-white flex-grow flex flex-col">
        <LoadingSpinner loading={bookmarksLoading} />
        <div
          className="bg-violet-white w-full flex items-center pl-2 flex-shrink-0"
          style={{ height: rowHeightMap.title }}
        >
          <p className="text-black text-sm font-medium ">Your bookmarks</p>
        </div>
        <div className="flex-grow">
          <AutoSizer>
            {({ width, height }) => (
              <div className="overflow-auto scroll-container py-2" style={{ width, height }}>
                {bookmarks.map(item => (
                  <BookmarkRow
                    key={item.id}
                    productCount={item.productCount}
                    name={item.name}
                    marketplace={item.marketplace}
                    pictures={item.pictures}
                    onClick={() => onBookmarkSet(item)}
                  />
                ))}
              </div>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  };
  const getContent = () => {
    if (bookmarksLoading && !bookmarks)
      return <LoadingSpinner className="flex-grow flex items-center justify-center" override />;
    if (!bookmarks?.length) return noBookmarks;
    return getBookmarkList();
  };
  return <div className="h-full w-full bg-violet-white flex flex-col">{getContent()}</div>;
};

BookmarksList.defaultProps = {
  filterFunction: item => item.productCount,
  userId: null
};

BookmarksList.propTypes = {
  onBookmarkSet: PropTypes.func.isRequired,
  filterFunction: PropTypes.func,
  userId: PropTypes.number
};

export default BookmarksList;
