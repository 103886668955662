import React, { useState } from 'react';

import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';

import { TabSwitcher, WebImg } from '@components';
import featureWebImgs from './img/main-features';

const featuresList = [
  {
    tabName: 'Real-time',
    img: featureWebImgs.il_feature3,
    title: 'Track Your Amazon Sales & Profit - In Real Time',
    description:
      'Instantly see your Amazon sales and profit in your personal dashboard. Track key financial metrics like Sales, Profit, PPC Spend, Amazon fees, ROI, and Refunds all in one place.'
  },
  {
    tabName: 'P&L',
    img: featureWebImgs.il_feature3,
    title: 'Gain a Complete View of Your Profit & Loss',
    description:
      'Keep track of your P&L with detailed insights on profitability, costs, refunds, and ROI. Connect multiple marketplaces to get a global view of your financial performance.'
  },
  {
    tabName: 'Traffic',
    img: featureWebImgs.il_feature10,
    title: 'Discover What Keywords Drive Your Organic Sales',
    description:
      'Uncover the keywords bringing organic traffic to your listings with ease. View Search Query Performance Data and identify which keywords contribute the most to your sales.'
  },
  {
    tabName: 'Reviews & Email',
    img: featureWebImgs.il_feature8,
    title: 'Boost Reviews with Email Automation',
    description: `Automate your follow-up emails and "Request Review" processes, leveraging Amazon's internal systems. Fully ToS compliant, send unlimited emails with any Seller Metrix plan.`,
    imgClassName: 'w-1/2 mx-auto'
  },
  {
    tabName: 'Mobile',
    img: featureWebImgs.il_feature6,
    title: 'Your Amazon Analytics On The Go',
    description:
      'Stay updated with real-time sales data via the Seller Metrix mobile app. Available for both iOS and Android, the app updates every 3 minutes, ensuring you never miss a sale.'
  }
];

export const FeaturesCarousel = () => {
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
  const getSlideComponent = feature => {
    return (
      <div
        key={feature.title}
        className="md:sm-h-480px md:flex justify-between items-center md:pl-20 px-6"
        style={{ width: '100%' }}
      >
        <div className="inline-flex flex-col justify-start md:pr-4 shrink md:w-1/2 mb-4 md:mb-0">
          <h2 className="text-black text-2xl md:text-3xl lg:text-4xl font-bold tracking-tight">
            {feature.title}
          </h2>
          <p className="text-gray-dark text-lg md:text-lg lg:text-2xl mt-4 md:mt-6 tracking-tight">
            {feature.description}
          </p>
        </div>
        <WebImg
          className={classNames(
            'md:w-1/2 md:h-3/4 flex justify-center items-center',
            feature.imgClassName || ''
          )}
          imgClassName="max-h-full"
          alt="feature"
          {...feature.img}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center mt-4 md:mt-6">
      <TabSwitcher
        changeActiveTab={newIndex => setSelectedFeatureIndex(newIndex)}
        options={featuresList.map(({ tabName }, i) => ({ text: tabName, value: i }))}
        activeTab={selectedFeatureIndex}
        className="mb-4 md:mb-0"
      />
      <SwipeableViews
        index={selectedFeatureIndex}
        onChangeIndex={setSelectedFeatureIndex}
        springConfig={{
          duration: '0.8s',
          easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
          delay: '0s'
        }}
        containerStyle={{ width: '100vw' }}
      >
        {featuresList.map(slide => {
          return getSlideComponent(slide);
        })}
      </SwipeableViews>
    </div>
  );
};
