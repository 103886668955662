import React from 'react';
import PropTypes from 'prop-types';
import { ExpandIcon } from '../icons';

const RowExpandIcon = ({ selectedParents, recordId, ...other }) => {
  const selected = selectedParents.includes(recordId);
  return <ExpandIcon selected={selected} {...other} />;
};

RowExpandIcon.propTypes = {
  selectedParents: PropTypes.instanceOf(Array).isRequired,
  recordId: PropTypes.number.isRequired
};

export default RowExpandIcon;
