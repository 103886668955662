import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import { IconButton, withStyles } from '@material-ui/core';
import ROUTES from 'seller/routing';
import WhiteTooltip from '@components/common/WhiteTooltip';
import { UPGRADE_PLAN_EVENT } from 'seller/documentEvents';
import {
  isFullAccessForFree,
  isIncompleted,
  USER_INCOMPLETED_STATUS,
  USER_ONBOARDING_STATUS
} from '@sm/api';
import { FontButton, RoundButton } from '@components/buttons';
import UserAvatar from '@components/common/UserAvatar';
import GetStartedChecklist from '../get-started-checklist';
import ChevronDown from './ic_chevron_down.svg';
import TasksIcon from './ic_tasks-solid.svg';
import BarsIcon from './ic_bars.svg';
import UserMenu from './UserMenu';

const styles = {
  square: { width: '5px', height: '5px' },
  squaresIcon: { width: '12px', height: '12px', marginRight: '27px' },
  tasksIcon: { width: '19px', height: '22px', paddingTop: '5px' },
  showGetStartedCheckListButton: { marginRight: '23px' }
};

/*
  FIXME: not using now
  const getSquaresIcon = () => {
    return (
      <div style={styles.squaresIcon} className="hidden md:flex flex-col">
        <div style={{ marginBottom: '2px' }} className="flex flex-row items-center justify-between">
          <div style={styles.square} className="bg-white" />
          <div style={styles.square} className="border border-white border-solid" />
        </div>
        <div className="flex flex-row items-center justify-between">
          <div style={styles.square} className="bg-white" />
          <div style={styles.square} className="bg-white" />
        </div>
      </div>
    );
  };
*/

const getTasksIcon = hasFinishedTask => {
  return (
    <div style={styles.tasksIcon} className="relative">
      {hasFinishedTask && (
        <div className="absolute top-0 right-0 ml-2 rounded bg-red-lighter w-2 h-2" />
      )}
      <img src={TasksIcon} alt="tasks list" className="sm-h-14px sm-w-14px" />
    </div>
  );
};

const trialText =
  'During your Trial, you will only find the past 60 days of sales data in Seller Metrix. After becoming a subscriber, we will import your past 24 months of sales data.';

const TrialTooltip = withStyles({ tooltip: { maxWidth: '13rem !important' } })(WhiteTooltip);

const UserSmallCard = props => {
  const {
    user: { userName, getStartedChecklist, subscription, status, billing, avatar },
    logout,
    onboarding,
    showGetStartedCheckList,
    showTrialInfo,
    history
  } = props;
  const dueDate = subscription && subscription.dueDate;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const getShowGetStartedCheckListButton = () => {
    if (status === USER_INCOMPLETED_STATUS || status === USER_ONBOARDING_STATUS || !dueDate) {
      return null;
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : null;

    const foundNonFinishedPoint = !getStartedChecklist
      ? false
      : Object.keys(getStartedChecklist).find(i => !getStartedChecklist[i]);

    return (
      <div style={styles.showGetStartedCheckListButton}>
        <RoundButton
          aria-owns={anchorEl ? 'simple-popover' : undefined}
          aria-describedby={id}
          onClick={handleClick}
          className="hidden sm:block"
        >
          {getTasksIcon(foundNonFinishedPoint)}
        </RoundButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          className="mt-5"
        >
          <GetStartedChecklist keysAndValues={getStartedChecklist} />
        </Popover>
      </div>
    );
  };

  const preloadComponent = () => {
    // FIXME: find more optimal way for prefetching images or components without inserting elements into DOM.
    return (
      <React.Suspense fallback={null}>
        <div hidden>
          <GetStartedChecklist keysAndValues={getStartedChecklist} />
        </div>
      </React.Suspense>
    );
  };

  const maxLen = 15;
  const displayedUserName =
    userName && userName.length > maxLen ? `${userName.slice(0, maxLen)}...` : userName;

  const noSubscription =
    !subscription || (!subscription.cc && showTrialInfo && (billing || onboarding));

  const showUpgradeNowButton =
    !isFullAccessForFree({ status }) && !isIncompleted({ status }) && noSubscription;

  return (
    <div className="items-center justify-center flex flex-row text-white">
      {!onboarding && showGetStartedCheckList && getShowGetStartedCheckListButton()}
      {/* 
      FIXME: not using now
      getSquaresIcon()
      */}
      <UserAvatar
        userName={userName}
        avatarUrl={avatar}
        className="h-10 w-10 mr-2 hidden xs:block"
      />
      <div className="flex flex-col relative">
        <FontButton
          className="justify-center items-center hidden xs:flex flex-row text-sm self-end"
          onClick={event => setMenuAnchorEl(event.currentTarget)}
        >
          {displayedUserName}
          <img src={ChevronDown} alt="down" width="10px" height="10px" className="ml-2" />
        </FontButton>
        {showUpgradeNowButton && (
          <div className="hidden xs:flex flex-row items-center mt-1">
            <TrialTooltip title={trialText} placeholder="bottom">
              <div className="sm-w-36px sm-h-18px bg-gray flex justify-center items-center mr-1 rounded-sm text-xs">
                Trial
              </div>
            </TrialTooltip>
            {onboarding || history.location?.pathname === ROUTES.plans ? (
              <FontButton
                id="elem"
                className="flex items-center text-xs"
                onClick={() => document.dispatchEvent(new Event(UPGRADE_PLAN_EVENT))}
              >
                Upgrade now
              </FontButton>
            ) : (
              <Link to={ROUTES.plans}>
                <FontButton className="flex items-center text-xs">Upgrade now</FontButton>
              </Link>
            )}
          </div>
        )}
        <IconButton
          onClick={event => setMenuAnchorEl(event.currentTarget)}
          className="inline-flex xs:hidden"
        >
          <img src={BarsIcon} alt="bars" className="h-4 w-4" />
        </IconButton>
        <UserMenu
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          logout={logout}
          subscription={subscription}
          {...props}
        />
      </div>
      {preloadComponent()}
    </div>
  );
};

UserSmallCard.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  logout: PropTypes.func.isRequired,
  onboarding: PropTypes.bool
};

UserSmallCard.defaultProps = {
  onboarding: false
};

export default UserSmallCard;
