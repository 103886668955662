import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton } from '@material-ui/core';
import Colors from 'styles/colors';
import { CrossSolidIcon } from '../icons';
import { ModernCancelConfirmButtons } from '../buttons';

const ModernDialog = ({ open, title, content, onCancel, onConfirm, confirmActive }) => {
  return (
    <Dialog open={open} onBackdropClick={onCancel}>
      <div className="p-6">
        <div className="flex items-center justify-between mb-8">
          <div className="text-large font-medium text-gray-darkest">{title}</div>
          <IconButton className="p-1 ml-4 -mr-1" onClick={onCancel}>
            <CrossSolidIcon fill={Colors.gray.dark} />
          </IconButton>
        </div>
        {content}
      </div>
      <ModernCancelConfirmButtons
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelText="Cancel"
        confirmText="Confirm"
        confirmActive={confirmActive}
      />
    </Dialog>
  );
};

ModernDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ModernDialog;
