import StarterImage from './img/plan_starter@2x.png';
import GrowthImage from './img/plan_growth@2x.png';
import ProImage from './img/plan_pro@2x.png';
import EnterpriseImage from './img/plan_enterprise@2x.png';

const PLANS_LIST = [
  {
    title: 'Starter',
    description: '0 to 100 orders per month',
    src: StarterImage,
    price: 0,
    maxOrders: 100
  },
  {
    title: 'Growth',
    description: '101 to 2500 orders per month',
    percentage: 73,
    src: GrowthImage,
    price: 15,
    maxOrders: 2500
  },
  {
    title: 'Pro',
    description: '2501 to 5000 orders per month',
    src: ProImage,
    price: 35,
    maxOrders: 5000
  },
  {
    title: 'Enterprise',
    description: '5001 to 10000 orders per month',
    src: EnterpriseImage,
    price: 85
  }
];

export default PLANS_LIST;
