import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from '@material-ui/core';

import classNames from 'classnames';
import { getProgress } from '@utils';
import { getFormattedValue } from '@utils/format';
import LoadingSpinner from '@components/common/LoadingSpinner';

const TextOnlyTooltip = withStyles({
  tooltip: {
    fontSize: '0.875rem '
  }
})(Tooltip);

function Card({
  title,
  className,
  currency,
  value,
  compareValue,
  isCompareMode,
  subvalues,
  isSelected,
  infoIcon,
  minimumWidth,
  totalsLoading,
  tooltip,
  positiveDecrease,
  noDecimalScale
}) {
  const { value: progress, text: progressText } = isCompareMode
    ? getProgress(compareValue, value, positiveDecrease)
    : { value: null, text: null };
  const cardClassName = classNames(
    className,
    'flex-col flex-1 items-start rounded box-shadow-list pl-4 pt-3 relative sm-h-111px',
    {
      'bg-white': !isSelected,
      'bg-grad': isSelected
    }
  );

  const titleClassName = classNames('font-medium text-base flex items-center', {
    'text-gray-darkest': !isSelected,
    'text-white': isSelected
  });

  const colorProgress = positiveDecrease ? -progress : progress;
  const progressClassNames = classNames('font-medium ml-auto mr-2', {
    'text-green': colorProgress > 0,
    'text-red': colorProgress < 0
  });

  const valueClassName = classNames(
    'font-medium flex w-auto flex-1 text-size-2rem leading-none mt-2 items-center',
    {
      'text-gray-darkest': !isSelected,
      'text-white': isSelected
    }
  );

  const compareValueClassName = classNames(
    'font-medium flex w-auto flex-1 leading-none mt-2 items-center',
    {
      'text-gray-darkest': !isSelected,
      'text-white': isSelected
    }
  );

  const subvalueClassName = classNames('text-sm', {
    'align-middle flex flex-row leading-none mt-1': subvalues,
    'text-gray-dark': !isSelected,
    'text-white': isSelected
  });

  const underlineClassName = classNames('underline-sm', {
    'bg-grad-2': !isSelected,
    'bg-grad-5': isSelected
  });

  const subValuesView = [];
  if (subvalues) {
    Object.keys(subvalues)
      .filter(key => key !== 'measurementUnit')
      .forEach(key => {
        const measurementUnit = subvalues.measurementUnit || null;
        subValuesView.push(
          <span key={key} className="mr-1">
            {key}: {getFormattedValue(subvalues[key])}
            {measurementUnit}
          </span>
        );
      });
  }

  const content = !totalsLoading ? (
    <>
      <div className={valueClassName}>
        {getFormattedValue(value, noDecimalScale || isCompareMode ? null : currency)}
        {infoIcon}
      </div>
      {isCompareMode && (
        <div className={compareValueClassName}>{getFormattedValue(compareValue)}</div>
      )}
      <div className={subvalueClassName}>{subValuesView}</div>
    </>
  ) : (
    <LoadingSpinner
      className="absolute inset-0 flex items-center justify-center h-full w-full pointer-events-none"
      override
    />
  );

  const currencyView = currency ? `(${currency})` : '';
  const getTitle = () => {
    if (tooltip) {
      return (
        <TextOnlyTooltip title={tooltip} placement="bottom">
          <div className={titleClassName}>
            {title} {currencyView}
          </div>
        </TextOnlyTooltip>
      );
    }

    return (
      <>
        <div className={titleClassName}>
          {title} {currencyView}
          {isCompareMode && <span className={progressClassNames}>{progressText}</span>}
        </div>
      </>
    );
  };

  return (
    <div style={{ minWidth: minimumWidth }} className={cardClassName}>
      {getTitle()}
      <div className={underlineClassName} />
      {content}
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  currency: PropTypes.string,
  value: PropTypes.number,
  compareValue: PropTypes.number,
  subvalues: PropTypes.instanceOf(Object),
  isSelected: PropTypes.bool,
  isCompareMode: PropTypes.bool,
  noDecimalScale: PropTypes.bool,
  infoIcon: PropTypes.node,
  className: PropTypes.string,
  minimumWidth: PropTypes.string,
  totalsLoading: PropTypes.bool.isRequired,
  positiveDecrease: PropTypes.bool.isRequired,
  tooltip: PropTypes.string
};

Card.defaultProps = {
  currency: null,
  subvalues: null,
  isSelected: false,
  infoIcon: null,
  className: '',
  value: null,
  compareValue: null,
  minimumWidth: '',
  tooltip: '',
  isCompareMode: false,
  noDecimalScale: false
};

export default Card;
