import { useSelector } from 'react-redux';
import { getFlagsById, GLOBAL_MK } from '@sm/Flags';
import { selectorSelector, userSelector } from 'seller/store/reducers';

export const CURRENCY_MAP = {
  USD: { short: '$' },
  EUR: { short: '€' },
  GBP: { short: '£' },
  JPY: { short: '¥' },
  AUD: { short: 'A$' },
  CNY: { short: '元' },
  CAD: { short: 'C$' },
  MXN: { short: '₱' },
  INR: { short: '₹' },
  BRL: { short: 'R$' },
  AED: { short: 'د.' },
  SGD: { short: 'S$' },
  PLN: { short: 'zł' }
};

export const getCurrencyAbbreviationByMk = mk => {
  const marketplace = getFlagsById(mk);
  if (!marketplace) return null;
  return marketplace.currency;
};

export const getCurrencySymbolByCurrencyName = currencyName => {
  return CURRENCY_MAP[currencyName]?.short || null;
};

const GetCurrency = mk => {
  const { profile } = useSelector(userSelector);
  const { marketplaceID: _marketplaceID } = useSelector(selectorSelector);
  const marketplaceID = mk || _marketplaceID;
  let currencyName = null;
  if (marketplaceID === GLOBAL_MK) {
    const { defaultSettings } = profile;
    if (!defaultSettings) return null;
    currencyName = defaultSettings.currency;
  } else {
    currencyName = getCurrencyAbbreviationByMk(marketplaceID);
  }
  if (!currencyName) return null;
  return getCurrencySymbolByCurrencyName(currencyName);
};

export default GetCurrency;
