import { getToken } from '@sm/storage';
import { SET_TOKEN, SET_TOKEN_REFRESH_EXPIRED, SET_USER_PROFILE, SET_USERNAME } from '../actions';

const initialState = {
  username: null,
  token: getToken(),
  tokenDate: null,
  tokenRefreshExpired: false,
  id: null
};
export const loginSelector = state => state.login;
const login = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERNAME: {
      const { username } = action;
      return { ...state, username };
    }
    case SET_TOKEN: {
      const { token } = action;
      return { ...state, token, tokenDate: token ? Date.now() : null, id: token ? state.id : null };
    }
    case SET_TOKEN_REFRESH_EXPIRED: {
      return { ...state, tokenRefreshExpired: action.payload };
    }
    case SET_USER_PROFILE: {
      return { ...state, id: action.profile?.id || null };
    }
    default: {
      return state;
    }
  }
};

export default login;
