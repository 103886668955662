import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { GradButton } from '../buttons';

export const CAMPAIGN_SUBHEADER_HEIGHT = '4rem';

const SubHeader = ({
  title,
  leftBlock,
  videoButton,
  fixHeight,
  rightButtonData,
  className,
  alignCenter,
  downloadButton
}) => {
  return (
    <div
      className={classNames(
        'flex justify-start w-full',
        {
          'items-start': !fixHeight && !alignCenter,
          'items-center': fixHeight || alignCenter
        },
        className
      )}
      style={fixHeight ? { height: CAMPAIGN_SUBHEADER_HEIGHT } : {}}
    >
      {leftBlock}
      {title && (
        <div className="text-2xl font-medium text-gray-darkest truncate min-w-0 mr-2">{title}</div>
      )}
      <div
        className={classNames('ml-auto flex justify-start items-center', {
          'md:mt-2': fixHeight
        })}
      >
        {videoButton}
        {downloadButton}
        {!!rightButtonData.text && (
          <div className="flex items-center">
            <GradButton
              className="min-w-0 h-10 flex-shrink-0"
              grad="bg-grad-8"
              onClick={rightButtonData.onClick}
              active={rightButtonData.active}
            >
              <Typography
                className={classNames('font-medium text-sm', {
                  'hidden xs:block': rightButtonData.smallText
                })}
              >
                {rightButtonData.text}
              </Typography>
              <Typography
                className={classNames('font-medium text-sm', {
                  'xs:hidden block': rightButtonData.smallText
                })}
              >
                {rightButtonData.smallText}
              </Typography>
            </GradButton>
          </div>
        )}
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  videoButton: PropTypes.node,
  leftBlock: PropTypes.node,
  fixHeight: PropTypes.bool,
  alignCenter: PropTypes.bool,
  rightButtonData: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  downloadButton: PropTypes.node
};

SubHeader.defaultProps = {
  title: '',
  className: '',
  videoButton: null,
  fixHeight: false,
  alignCenter: false,
  leftBlock: null,
  downloadButton: null,
  rightButtonData: { text: '', smallText: '', onClick: () => {} }
};

export default SubHeader;
