import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function BackButton(props) {
  const { onClick, className } = props;
  return (
    <Button
      type="button"
      className={`rounded text-gray-dark font-medium cursor-pointer bg-white py-2 px-16 text-sm border-gray-default mr-auto normal-case ${className}`}
      onClick={onClick}
    >
      Back
    </Button>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

BackButton.defaultProps = {
  className: ''
};

export default BackButton;
