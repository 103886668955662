import PropTypes from 'prop-types';

const formatNumber = (value, suffix = '', prefix = '') => {
  // Example: 1234567 -> $1,234,567
  const valueStrSeparated = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${prefix}${valueStrSeparated}${suffix}`;
};

export const ShortCurrencyValueView = ({ value }) => {
  if (Math.abs(value) < 1000) return formatNumber(value, '', '$');
  const decimalPlaces = Math.abs(value) >= 10000 ? 0 : 1;
  return formatNumber((value / 1000).toFixed(decimalPlaces), 'K', '$');
};

ShortCurrencyValueView.propTypes = {
  value: PropTypes.number.isRequired
};
