import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton, withStyles, Tooltip } from '@material-ui/core';
import { BookmarkIcon } from '@components/icons';
import Colors from 'styles/colors';
import { setProductSearchKeyword } from 'seller/store/actions';
import { setBookmarksSidebarShowAction } from 'seller/store/saga/bookmarks';
import { SVG_GRAD_ONE } from '@components/gradients';
import { productSearchSelector } from 'seller/store/reducers';

export const BookmarkButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: Colors.yellow.transparent
    }
  }
})(IconButton);

const BookmarkButtonTooltip = withStyles({
  tooltip: {
    backgroundColor: Colors.black.default,
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem'
  },
  arrow: {
    color: Colors.black.default
  }
})(Tooltip);

const BookmarkIconButton = ({ className }) => {
  const dispatch = useDispatch();
  const { bookmarksSidebarOpen } = useSelector(productSearchSelector);
  const onClick = () => {
    dispatch(setProductSearchKeyword(''));
    dispatch(setBookmarksSidebarShowAction(!bookmarksSidebarOpen));
  };
  return (
    <BookmarkButtonTooltip title="Your bookmarks" placement="right" arrow>
      <BookmarkButton className={classNames('p-2 rounded', className)} onClick={onClick}>
        <BookmarkIcon
          height="1rem"
          fill={bookmarksSidebarOpen ? SVG_GRAD_ONE : Colors.gray.default}
        />
      </BookmarkButton>
    </BookmarkButtonTooltip>
  );
};

BookmarkIconButton.propTypes = { className: PropTypes.string };
BookmarkIconButton.defaultProps = {
  className: ''
};

export default BookmarkIconButton;
