const searchableFields = [
  'asin',
  'buyer',
  'sku',
  'name',
  'orderId',
  'orderItemId',
  'product__brand',
  'status',
  'variation_value_one',
  'variation_value_two',
  'variation_value_three',
  'variation_value_four',
  'variation_value_five',
  'customer_comments'
];

// eslint-disable-next-line import/prefer-default-export
export const commonTableFilter = (data, keyword) => {
  const check = (row, field) =>
    row[field] && row[field].toLowerCase().includes(keyword.toLowerCase());

  let result = [];
  data.forEach(row => {
    let parentRow = null;
    if (searchableFields.find(field => check(row, field))) {
      parentRow = row;
    }

    if (parentRow) {
      result.push(parentRow);
      return;
    }

    if (!row.children) {
      return;
    }

    if (parentRow) {
      result = [...result, ...parentRow.children];
      return;
    }

    const newChildren = [];
    row.children.forEach(child => {
      if (searchableFields.find(field => check(child, field))) {
        newChildren.push(child);
      }
    });

    if (newChildren) {
      result = [...result, ...newChildren];
    }
  });
  return result;
};
