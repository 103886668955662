import { areIntervalsOverlapping, endOfDay, startOfDay, compareDesc } from 'date-fns';
import { dateToStr } from '@utils';

export const getFullDaysRange = range => ({
  from: startOfDay(new Date(range.from)),
  to: endOfDay(new Date(range.to))
});

export const rangeToApiFormat = range => ({
  from: dateToStr(new Date(range.from)),
  to: dateToStr(new Date(range.to))
});

const rangeToDateFnsFormat = _range => {
  const range = getFullDaysRange(_range);
  return { start: range.from, end: range.to };
};

export const isOverlap = (existRanges, currentRange) => {
  const ranges = existRanges?.map(item => item.range);
  if (!ranges) return false;
  return ranges.find(range => {
    return areIntervalsOverlapping(rangeToDateFnsFormat(range), rangeToDateFnsFormat(currentRange));
  });
};

export const sortRanges = (a, b) => compareDesc(new Date(a.range?.from), new Date(b.range?.from));
