export const PNL_DASHBOARD_SET_MARKETPLACES = 'PNL_DASHBOARD_SET_MARKETPLACES';
export const PNL_DASHBOARD_SET_PLATFORMS = 'PNL_DASHBOARD_SET_PLATFORMS';
export const PNL_DASHBOARD_SET_SELECTED_BRANDS = 'PNL_DASHBOARD_SET_SELECTED_BRANDS';
export const PNL_DASHBOARD_SET_METRICS = 'PNL_DASHBOARD_SET_METRICS';
export const PNL_DASHBOARD_SET_TIME_GRAIN = 'PNL_DASHBOARD_SET_TIME_GRAIN';
export const PNL_DASHBOARD_SET_TARGETS_ENABLED = 'PNL_DASHBOARD_SET_TARGETS_ENABLED';
export const PNL_DASHBOARD_SET_DATE_RANGE = 'PNL_DASHBOARD_SET_DATE_RANGE';
export const PULL_PNL_DASHBOARD_TABLE_DATA = 'PULL_PNL_DASHBOARD_TABLE_DATA';
export const PULL_PNL_DASHBOARD_BRANDS = 'PULL_PNL_DASHBOARD_BRANDS';
export const SET_PNL_DASHBOARD_BRANDS = 'SET_PNL_DASHBOARD_BRANDS';
export const SET_PNL_DASHBOARD_TABLE_DATA = 'SET_PNL_DASHBOARD_TABLE_DATA';
export const SET_PNL_DASHBOARD_TABLE_LOADING = 'SET_PNL_DASHBOARD_TABLE_LOADING';
export const SET_PNL_DASHBOARD_SEGMENTATION = 'SET_PNL_DASHBOARD_SEGMENTATION';
export const SET_PNL_DASHBOARD_PERCENTAGE_ENABLED = 'SET_PNL_DASHBOARD_PERCENTAGE_ENABLED';
export const SET_PNL_DASHBOARD_GRANULARITY = 'SET_PNL_DASHBOARD_GRANULARITY';
export const SET_PNL_DASHBOARD_CHART_METRICS = 'SET_PNL_DASHBOARD_CHART_METRICS';
export const setPnlDashboardMarketplacesAction = marketplaces => ({
  type: PNL_DASHBOARD_SET_MARKETPLACES,
  payload: marketplaces
});
export const setPnlDashboardPlatformsAction = platforms => ({
  type: PNL_DASHBOARD_SET_PLATFORMS,
  payload: platforms
});
export const setPnlDashboardSelectedBrandsAction = brands => ({
  type: PNL_DASHBOARD_SET_SELECTED_BRANDS,
  payload: brands
});
export const setPnlDashboardMetricsAction = metrics => ({
  type: PNL_DASHBOARD_SET_METRICS,
  payload: metrics
});
export const setPnlDashboardTimeGrainAction = timeGrain => ({
  type: PNL_DASHBOARD_SET_TIME_GRAIN,
  payload: timeGrain
});
export const setPnlDashboardDateRangeAction = dateRange => ({
  type: PNL_DASHBOARD_SET_DATE_RANGE,
  payload: dateRange
});
export const setPnlDashboardTargetsEnabledAction = targetsEnabled => ({
  type: PNL_DASHBOARD_SET_TARGETS_ENABLED,
  payload: targetsEnabled
});
export const pullPnlDashboardTableDataAction = () => ({
  type: PULL_PNL_DASHBOARD_TABLE_DATA
});
export const setPnlDashboardTableDataAction = tableData => ({
  type: SET_PNL_DASHBOARD_TABLE_DATA,
  payload: tableData
});
export const setPnlDashboardTableLoadingAction = loading => ({
  type: SET_PNL_DASHBOARD_TABLE_LOADING,
  payload: loading
});
export const setPnlDashboardSegmentationAction = segmentation => ({
  type: SET_PNL_DASHBOARD_SEGMENTATION,
  payload: segmentation
});
export const setPnlDashboardPercentageEnabledAction = enabled => ({
  type: SET_PNL_DASHBOARD_PERCENTAGE_ENABLED,
  payload: enabled
});
export const pullPnlDashboardBrandsAction = () => ({
  type: PULL_PNL_DASHBOARD_BRANDS
});
export const setPnlDashboardBrandsAction = brands => ({
  type: SET_PNL_DASHBOARD_BRANDS,
  payload: brands
});
export const setPnlDashboardGranularityAction = level => ({
  type: SET_PNL_DASHBOARD_GRANULARITY,
  payload: level
});
export const setPnlDashboardChartMetricsAction = metrics => ({
  type: SET_PNL_DASHBOARD_CHART_METRICS,
  payload: metrics
});
