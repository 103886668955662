import { GLOBAL_MK } from '@sm/Flags';
import { getPercent, sumOfSafeValues } from 'seller/pages/dashboard/prepareModernBreakdownData';

export const getAvgValueByKey = (arr, key) =>
  arr && key && arr.reduce((acc, item) => acc + (item[key] || 0), 0) / arr.length;

export const compareFieldNameEnd = '_compare';

export const getAgencyCompareKey = key => `${key}${compareFieldNameEnd}`;

const getRoundedFieldsObject = obj => {
  if (!obj) return {};
  const keys = Object.keys(obj);
  const roundedFieldsObj = {};
  keys.forEach(key => {
    roundedFieldsObj[key] = typeof obj[key] === 'number' ? Math.round(obj[key]) : obj[key];
  });
  return roundedFieldsObj;
};

const getCommonUserData = (_user, _compareUser) => {
  const user = getRoundedFieldsObject(_user);
  const compareUser = getRoundedFieldsObject(_compareUser);
  const keys = compareUser ? Object.keys(compareUser) : [];
  const modCompareUser = {};
  keys.forEach(key => {
    modCompareUser[getAgencyCompareKey(key)] = compareUser[key];
  });
  if (compareUser) {
    modCompareUser[getAgencyCompareKey('organic_orders__sum')] =
      (compareUser.orders__sum || 0) - (compareUser.ppc_orders__sum || 0);
    modCompareUser[getAgencyCompareKey('full_quantity_ordered__sum')] =
      (compareUser.quantity_ordered__sum || 0) - (compareUser.promo_units_qty__sum || 0);
  }
  return {
    ...user,
    organic_orders__sum: (user.orders__sum || 0) - (user.ppc_orders__sum || 0),
    full_quantity_ordered__sum:
      (user.quantity_ordered__sum || 0) - (user.promo_units_qty__sum || 0),
    ...modCompareUser
  };
};

export const getAgencyMarketplaces = clients => {
  const buffer = [];
  clients.forEach(({ mk_list: mkList }) => {
    if (mkList) {
      buffer.push(...mkList);
    }
  });
  return [GLOBAL_MK, ...new Set(buffer)].sort();
};

export const getCommonAgencyMetrix = (agencyUsersMetrix, compareAgencyUsersMetrix) => {
  const idField = 'user';
  return agencyUsersMetrix.map(agencyUser => {
    const compareAgencyUser = compareAgencyUsersMetrix.find(
      compareUser => compareUser[idField] === agencyUser[idField]
    );
    return getCommonUserData(agencyUser, compareAgencyUser);
  });
};

export const getCommonAgencyTotals = (_agencyTotals, _compareAgencyTotals) => {
  const agencyTotals = getRoundedFieldsObject(_agencyTotals);
  const compareAgencyTotals = getRoundedFieldsObject(_compareAgencyTotals);
  const keys = agencyTotals ? Object.keys(agencyTotals) : [];
  const modAgencyTotals = {};
  keys.forEach(key => {
    if (key in compareAgencyTotals) {
      modAgencyTotals[getAgencyCompareKey(key)] = compareAgencyTotals[key];
    }
  });
  return {
    ...agencyTotals,
    ...modAgencyTotals
  };
};

export const reduceAgencyClientsData = (data, callback) => {
  const accumulator = {
    orders__sum: 0,
    sales__sum: 0,
    profit__sum: 0,
    ads_sales: 0,
    amazon_ads_sum: 0,
    ads_sum: 0,
    units__sum: 0,
    clients: 0
  };
  if (!data) return { data: [], totals: accumulator };
  data.reduce((acc, currentValue) => {
    if (!acc) {
      console.error('acc: ', acc, '; currentValue: ', currentValue);
      return accumulator;
    }
    acc.sales__sum += currentValue.sales__sum || 0;
    acc.orders__sum += currentValue.orders__sum || 0;
    acc.profit__sum += currentValue.profit__sum || 0;
    acc.ads_sales += currentValue.ads_sales__sum || 0;
    acc.amazon_ads_sum += currentValue.amazon_ads_sum || 0;
    acc.ads_sum += currentValue.ads_sum || 0;
    acc.units__sum += currentValue.units__sum || 0;
    acc.clients += 1;
    return acc;
  }, accumulator);
  accumulator.acos = getPercent(accumulator.amazon_ads_sum, accumulator.ads_sales);
  accumulator.tacos = getPercent(accumulator.ads_sum, accumulator.sales__sum);
  if (callback) {
    callback(accumulator.clients);
  }
  return { data, totals: accumulator };
};

export const prepareAgencyData = data => {
  if (!data) return data;
  const salesSum = sumOfSafeValues([
    data.product_sales__sum,
    data.discounts__sum,
    data.new_tax__sum,
    data.shipping__sum
  ]);
  const totalAmazonAdvertising = sumOfSafeValues([
    data.ads_brands__sum,
    data.ads_display__sum,
    data.ads_products__sum,
    data.ads_video__sum,
    data.ads_custom__sum
  ]);
  const totalAdvertising = sumOfSafeValues([totalAmazonAdvertising, data.ads_custom__sum]);
  const acos = getPercent(totalAmazonAdvertising, data.ads_sales__sum);
  const tacos = getPercent(totalAdvertising, data.product_sales__sum);
  const refunds = sumOfSafeValues([
    data.refunds__sum,
    data.referral_fee_reimbursement__sum,
    data.refund_commission__sum,
    data.reimbursement__sum,
    data.reimbursement_return__sum,
    data.reimbursed_inventory__sum,
    data.refunded_cogs__sum,
    data.other_refunds__sum
  ]);
  const cogs = sumOfSafeValues([
    data.production_cost__sum,
    data.shipping_cost__sum,
    data.fbm_shipping__sum
  ]);
  const grossProfit = sumOfSafeValues([
    salesSum,
    refunds,
    totalAdvertising,
    data.amazon_expenses__sum,
    data.new_tax__sum * -1
  ]);
  const customExpenses = sumOfSafeValues([
    data.warehouse_expenses__sum,
    data.employees_expenses__sum,
    data.branding_expenses__sum,
    data.other_expenses__sum
  ]);
  const netProfit = sumOfSafeValues([grossProfit, customExpenses, cogs]);
  const roi = getPercent(netProfit, cogs);
  return {
    sales__sum: salesSum,
    amazon_ads_sum: totalAmazonAdvertising,
    ads_sum: totalAdvertising,
    acos,
    tacos,
    refund_cashflow__sum: refunds,
    profit__sum: netProfit,
    roi_sum: roi,
    ...data
  };
};
