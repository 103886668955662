import React from 'react';
import PropTypes from 'prop-types';

import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@components/icons/ic_search.svg';

const ProductSearchInput = props => {
  const {
    onChange,
    onFocus,
    keyword,
    placeholder,
    className,
    onKeyDown,
    adornment,
    disabled
  } = props;
  const inputAdornment = <InputAdornment position="start">{adornment}</InputAdornment>;

  return (
    <InputBase
      classes={{
        input: `${
          keyword.length > 0 ? 'text-black' : 'text-gray-dark'
        } text-lg xs:text-sm sm-scale-075 xs:sm-scale-100 -ml-6 mt-1 xs:m-0`
      }}
      className={className}
      placeholder={placeholder}
      inputProps={{ 'aria-label': 'naked' }}
      startAdornment={inputAdornment}
      onChange={event => onChange(event.target.value)}
      fullWidth
      onFocus={onFocus}
      value={keyword}
      onKeyDown={onKeyDown}
      disabled={disabled}
    />
  );
};

ProductSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  keyword: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  adornment: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

ProductSearchInput.defaultProps = {
  disabled: false,
  className: '',
  onFocus: null,
  onKeyDown: null,
  adornment: <img src={SearchIcon} alt="search" />
};

export default ProductSearchInput;
