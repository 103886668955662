import { compareDesc } from 'date-fns';

export const sortStrAsc = sortField => {
  return (lhs, rhs) => {
    return `${lhs[sortField]}`.localeCompare(rhs[sortField]);
  };
};

export const sortIntAsc = sortField => {
  return (lhs, rhs) => {
    const a = lhs[sortField] || 0;
    const b = rhs[sortField] || 0;

    if (a > b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }

    return 0;
  };
};

export const sortDtAsc = sortField => {
  return (lhs, rhs) => {
    const lhsDt = new Date(lhs[sortField]);
    const rhsDt = new Date(rhs[sortField]);
    return compareDesc(lhsDt, rhsDt);
  };
};
