export const SET_CAMPAIGNS = 'EMAIL-TOOL/SET_CAMPAIGNS';
export const SET_MESSAGES = 'EMAIL-TOOL/SET_MESSAGES';
export const SET_SENT_MESSAGES = 'EMAIL-TOOL/SET_SENT_MESSAGES';
export const SET_SCHEDULED_MESSAGES = 'EMAIL-TOOL/SET_SCHEDULED_MESSAGES';
export const PULL_MESSAGE_TEMPLATES = 'EMAIL-TOOL/PULL_MESSAGE_TEMPLATES';
export const PULL_CAMPAIGNS = 'EMAIL-TOOL/PULL_CAMPAIGNS';
export const SET_FETCHING = 'EMAIL-TOOL/SET_FETCHING';
export const SET_ACTIVE_CAMPAIGN = 'EMAIL-TOOL/SET_ACTIVE_CAMPAIGN';
export const POST_NEW_CAMPAIGN = 'EMAIL-TOOL/POST_NEW_CAMPAIGN';
export const DELETE_CAMPAIGN = 'EMAIL-TOOL/DELETE_CAMPAIGN';
export const PAUSE_CAMPAIGN = 'EMAIL-TOOL/PAUSE_CAMPAIGN';
export const PAUSE_MESSAGE_TEMPLATE = 'EMAIL-TOOL/PAUSE_MESSAGE_TEMPLATE';
export const PUT_CAMPAIGN = 'EMAIL-TOOL/PUT_CAMPAIGN';
export const POST_NEW_MESSAGE_TEMPLATE = 'EMAIL-TOOL/POST_NEW_MESSAGE_TEMPLATE';
export const PUT_MESSAGE_TEMPLATE = 'EMAIL-TOOL/PUT_MESSAGE_TEMPLATE';
export const DELETE_MESSAGE_TEMPLATE = 'EMAIL-TOOL/DELETE_MESSAGE_TEMPLATE';
export const SET_NEW_MESSAGE_TYPE = 'EMAIL-TOOL/SET_NEW_MESSAGE_TYPE';
export const SET_EDITED_CAMPAIGN = 'EMAIL-TOOL/SET_EDITED_CAMPAIGN';
export const SET_EDITED_MESSAGE = 'EMAIL-TOOL/SET_EDITED_MESSAGE';
export const SET_IS_EDIT = 'EMAIL-TOOL/SET_IS_EDIT';
export const UNSUBSCRIBE_EMAIL_TOOL = 'EMAIL-TOOL/UNSUBSCRIBE';
export const CANCEL_SCHEDULED_MESSAGE = 'EMAIL-TOOL/CANCEL_SCHEDULED_MESSAGE';
export const PULL_EMAIL_TOOL_TABLE_DATA = 'EMAIL-TOOL/PULL_TABLE_DATA';
export const SEND_TEST_EMAIL = 'EMAIL-TOOL/SEND_TEST_EMAIL';
export const SET_DYNAMIC_VARIABLES = 'EMAIL-TOOL/SET_DYNAMIC_VARIABLES';
export const PULL_DYNAMIC_VARIABLES = 'EMAIL-TOOL/PULL_DYNAMIC_VARIABLES';
export const SET_EMAIL_PREVIEW = 'EMAIL-TOOL/SET_EMAIL_PREVIEW';
export const PULL_EMAIL_PREVIEW = 'EMAIL-TOOL/PULL_EMAIL_PREVIEW';
export const FILTER_SCHEDULED_MESSAGE = 'EMAIL-TOOL/FILTER_SCHEDULED_MESSAGE';

export const filterScheduledMessageAction = payload => ({
  type: FILTER_SCHEDULED_MESSAGE,
  payload
});

export const pullDynamicVariablesAction = () => ({
  type: PULL_DYNAMIC_VARIABLES
});

export const setEmailPreviewAction = payload => ({
  type: SET_EMAIL_PREVIEW,
  payload
});

export const pullEmailPreviewAction = payload => ({
  type: PULL_EMAIL_PREVIEW,
  payload
});

export const cancelScheduledMessageAction = payload => ({
  type: CANCEL_SCHEDULED_MESSAGE,
  payload
});

export const setDynamicVariablesAction = payload => ({
  type: SET_DYNAMIC_VARIABLES,
  payload
});

export const sendTestEmailAction = payload => ({
  type: SEND_TEST_EMAIL,
  payload
});

export const setCampaignsAction = payload => ({
  type: SET_CAMPAIGNS,
  payload
});

export const setMessagesAction = payload => ({
  type: SET_MESSAGES,
  payload
});

export const setSentMessagesAction = payload => ({
  type: SET_SENT_MESSAGES,
  payload
});

export const setScheduledMessagesAction = payload => ({
  type: SET_SCHEDULED_MESSAGES,
  payload
});

export const setEmailToolFetching = payload => ({
  type: SET_FETCHING,
  payload
});

export const pullEmailToolTableDataAction = () => ({
  type: PULL_EMAIL_TOOL_TABLE_DATA
});

export const pullMessageTemplatesAction = () => ({
  type: PULL_MESSAGE_TEMPLATES
});

export const deleteCampaignAction = payload => ({
  type: DELETE_CAMPAIGN,
  payload
});

export const pauseCampaignAction = payload => ({
  type: PAUSE_CAMPAIGN,
  payload
});

export const pauseMessageTemplateAction = payload => ({
  type: PAUSE_MESSAGE_TEMPLATE,
  payload
});

export const setActiveCampaignAction = payload => ({
  type: SET_ACTIVE_CAMPAIGN,
  payload
});

export const postNewCampaignAction = ({ campaign, history }) => ({
  type: POST_NEW_CAMPAIGN,
  campaign,
  history
});

export const putCampaignAction = payload => ({
  type: PUT_CAMPAIGN,
  payload
});

export const setNewMessageTypeAction = payload => ({
  type: SET_NEW_MESSAGE_TYPE,
  payload
});

export const postNewMessageTemplateAction = ({ payload, history }) => ({
  type: POST_NEW_MESSAGE_TEMPLATE,
  payload,
  history
});

export const putMessageTemplateAction = ({ payload, history }) => ({
  type: PUT_MESSAGE_TEMPLATE,
  payload,
  history
});

export const deleteMessageTemplateAction = payload => ({
  type: DELETE_MESSAGE_TEMPLATE,
  payload
});

export const setEditedCampaignAction = payload => ({
  type: SET_EDITED_CAMPAIGN,
  payload
});

export const setEditedMessageAction = payload => ({
  type: SET_EDITED_MESSAGE,
  payload
});

export const setIsEditAction = payload => ({
  type: SET_IS_EDIT,
  payload
});

export const unsubscribeEmailToolAction = payload => ({
  type: UNSUBSCRIBE_EMAIL_TOOL,
  payload
});
