import API, { API_V2_1 } from '@sm/api';
import mock from '../mock';

const summaryDetailedBreakdown = {
  product_sales: 39369.7,
  tax: 4627.76,
  gift_wrap: 3.99,
  discounts: 185.07,
  //
  refund_cashflow: 1161.55,
  fba_fees: 8330.36,
  referral_fees: 6550.78,
  tax_paid: 1.09,
  //
  new_gross_profit: 22079.46,
  production_cost: null,
  est_tax_unpaid: 4626.67,
  //
  profit: 17452.79,
  margin: 39.84,
  roi: null,
  sf_every_month: 123.45,
  ltsf_12_months: 123.45,
  ltsf_6_months: 123.45
};

const totals = {
  product_sales: 15757.81,
  discounts: -127.45,
  refunds: -379.53,
  referral_fee_reimbursement: 56.17,
  fba_fees: -2307.37,
  referral_fees: -2346.26,
  refund_commission: -11.24,
  other_refunds: 5.0,
  reimbursement: 0,
  ads_sales: 124.85,
  ads_products: -81.19,
  ads_products_spread: 0,
  ads_brands: 0,
  ads_video: 0,
  ads_display: 0,
  production_cost: -2814.6,
  shipping_cost: -136.69,
  fbm_shipping: 0,
  units: 579,
  promo_units: 51,
  orders: 504,
  ppc_orders: 5,
  quantity_refunded: 14,
  sessions: 2445,
  new_tax: 0,
  storage_fees_fin: -31.63,
  storage_fees_fin_spread: -28.5562,
  long_term_storage_fees_fin: 0,
  long_term_storage_fees_fin_spread: -0.536,
  not_retuned_units: 0,
  qualifyForReim: 0,
  marketplace: 3,
  reimbursement_return: 0,
  reimbursed_inventory: 0,
  refunded_cogs: 51.36,
  cin7_cogs: 0,
  shipping: 0,
  employees_expenses: 0,
  warehouse_expenses: 0,
  branding_expenses: 0,
  other_expenses: 0,
  replacement_units: 2,
  promo_sales: 1273.47,
  organic_orders: 499,
  full_price_units: 528,
  browser_sessions: 1041,
  mobile_sessions: 1404,
  conversion_rate: 965.0452,
  ads_custom: 0,
  clicks: 46,
  impressions: 23642,
  amazon_expenses: {
    FBADisposalFee: -9.36,
    FBAInboundConvenienceFee: 0.0,
    Subscription: -39.99,
    fba_sf: -28.5562,
    fba_ltsf: -0.536
  },
  coupon_payment_event: 0,
  subscription: 0,
  fba_inbound_convenience_fee: 0,
  fba_inbound_transportation_fee: 0,
  fba_inbound_transportation_program_fee: 0,
  fba_removal_fee: 0,
  fba_disposal_fee: 0,
  seller_deal_payment: 0,
  seller_review_enrollment_payment_event: 0,
  fba_overage_fee: 0,
  fba_international_inbound_freight_fee: 0,
  fba_international_inbound_freight_tax_and_duty: 0,
  storage_fees: -28.5562,
  long_term_storage_fees: -0.536,
  custom_expenses: []
};

const metricsByDt = {
  metrix: [
    [1727049600000, 838.97],
    [1727136000000, 574.3],
    [1727222400000, 437.46],
    [1727308800000, 804.02],
    [1727395200000, 511.88],
    [1727481600000, 851.45],
    [1727568000000, 586.79],
    [1727654400000, 756.57],
    [1727740800000, 449.94],
    [1727827200000, 641.18],
    [1727913600000, 384.53],
    [1728000000000, 360.05],
    [1728086400000, 294.64],
    [1728172800000, 581.79],
    [1728259200000, 502.38],
    [1728345600000, 604.26],
    [1728432000000, 669.18],
    [1728518400000, 299.64],
    [1728604800000, 289.65],
    [1728691200000, 501.89],
    [1728777600000, 591.78],
    [1728864000000, 489.41],
    [1728950400000, 589.28],
    [1729036800000, 479.42],
    [1729123200000, 509.87],
    [1729209600000, 531.85],
    [1729296000000, 404.51],
    [1729382400000, 614.25],
    [1729468800000, 479.42],
    [1729555200000, 0]
  ]
};

const getTotals = (sales, profit, orders, units, ads, refunds) => {
  return { sales, profit, orders, units, ads, refunds };
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

mock.onGet(API.metrix.breakdown).reply(async () => {
  await sleep(500);
  return [200, summaryDetailedBreakdown];
});

mock.onGet(API_V2_1.metrix.totals).reply(() => {
  return [200, totals];
});

mock.onGet(API.metrix.chart).reply(() => {
  return [200, metricsByDt];
});

mock
  .onGet(API.metrix.totals, {
    params: {
      startDate: '2019-03-01',
      endDate: '2019-03-01',
      marketplaceID: 2
    }
  })
  .reply(() => {
    return [200, getTotals(1200, 12, 2, 3, 14, 1)];
  });
