import { isArray, DECIMAL_SCALE } from '@utils';
import { isMediumSize, isXLargeSize } from '@sm/WindowSizes';
import { sortIntAsc } from './sortFuncs';

export const getFieldMaxValue = (arr, key) => {
  return arr.reduce((max, item) => {
    let value = item[key];
    if (typeof value !== 'number') return max;
    if (item.children) {
      const childrenValueLength = getFieldMaxValue(item.children, key);
      if (childrenValueLength > value) value = childrenValueLength;
    }
    return value > max ? value : max;
  }, 0);
};

export const getColumnsConfigWithMaxValue = (metrix, data) => {
  const keys = Object.keys(metrix);
  const newMetrix = {};
  keys.forEach(key => {
    newMetrix[key] = { ...metrix[key], maxValue: getFieldMaxValue(data, key) };
  });
  return newMetrix;
};

// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
export const getTextWidth = (textContent, className = '', fontFamily = 'Roboto') => {
  const textNodeId = 'text-width-picker-node';
  const classList = className?.split(' ');
  let textNode = document.getElementById(textNodeId);
  if (!textNode) {
    textNode = document.createElement('span');
    textNode.id = textNodeId;
  }
  document.body.appendChild(textNode);
  textNode.style.font = fontFamily;
  classList.forEach(item => textNode.classList.add(item));
  textNode.style.height = 'auto';
  textNode.style.width = 'auto';
  textNode.style.opacity = '0';
  textNode.style.zIndex = '-1';
  textNode.style.position = 'absolute';
  textNode.style.bottom = '110%';
  textNode.style.whiteSpace = 'no-wrap';
  textNode.innerHTML = textContent;
  return Math.ceil(textNode.clientWidth);
};

const getMeasuredWidthMetrix = (_metrix, defaultWidth) => {
  const MEASURED_TEXT_EXTRA_SPACE = 12;
  const metrix = { ..._metrix };
  const getMinMeasuredWidth = (width, maxValue) => {
    const minMeasuredWidth =
      getTextWidth(maxValue.toFixed(DECIMAL_SCALE).toString(), 'text-sm') +
      MEASURED_TEXT_EXTRA_SPACE;
    return Math.max(minMeasuredWidth, width);
  };
  Object.keys(metrix).forEach(key => {
    metrix[key] = {
      ...metrix[key],
      width: getMinMeasuredWidth(metrix[key].width || defaultWidth, metrix[key].maxValue)
    };
  });
  return metrix;
};

const initColumns = config => {
  const DEFAULT_WIDTH = 120;
  const SCROLLBAR_WIDTH = 17;
  const { columnsConfig: _columnsConfig, tableWidth, disableResponsiveColumns } = config;
  const columnsConfig = getMeasuredWidthMetrix(_columnsConfig, DEFAULT_WIDTH);
  const [fixedColumnKey] = Object.keys(_columnsConfig);

  const columnsTotalWidth = Object.keys(columnsConfig).reduce(
    (acc, key) => (key !== fixedColumnKey ? acc + columnsConfig[key].width : acc),
    0
  );
  const freeSpace =
    tableWidth - columnsTotalWidth - SCROLLBAR_WIDTH - columnsConfig[fixedColumnKey].width;

  const getColumnResponsiveWidth = width =>
    freeSpace <= 0 || disableResponsiveColumns
      ? width
      : width + freeSpace * (width / columnsTotalWidth);

  return Object.keys(columnsConfig).map(dataKey => {
    const { width } = columnsConfig[dataKey];
    return {
      label: columnsConfig[dataKey].title,
      dataKey,
      sort: {
        func: sortIntAsc
      },
      ...columnsConfig[dataKey],
      width: dataKey === fixedColumnKey ? width : getColumnResponsiveWidth(width)
    };
  });
};

const initMobileColumns = columnsConfig => {
  const DEFAULT_WIDTH = 95;
  const mobileColumns = getMeasuredWidthMetrix(columnsConfig, DEFAULT_WIDTH);
  if (mobileColumns.smallImage) mobileColumns.smallImage.width = 185;

  return Object.keys(mobileColumns).map(dataKey => {
    return {
      label: columnsConfig[dataKey].title,
      dataKey,
      sort: {
        func: sortIntAsc
      },
      ...mobileColumns[dataKey]
    };
  });
};

const getMobileSettings = columnsConfig => {
  return {
    productCardWidth: 185,
    productCardStyle: 'pl-2',
    columns: initMobileColumns(columnsConfig),
    rowHeight: 61.25,
    headerRowHeight: 42
  };
};

const getDesktopSmallScreenSettings = config => {
  return {
    productCardWidth: 349,
    productCardStyle: 'pl-6',
    columns: initColumns(config),
    rowHeight: 61.25,
    headerRowHeight: 42
  };
};

const getDesktopSettings = config => {
  return {
    productCardWidth: 349,
    productCardStyle: 'pl-6',
    columns: initColumns(config),
    rowHeight: 70,
    headerRowHeight: 42
  };
};

export const getSettings = config => {
  if (isMediumSize()) {
    return getMobileSettings(config.columnsConfig);
  }
  if (isXLargeSize()) {
    return getDesktopSmallScreenSettings(config);
  }
  return getDesktopSettings(config);
};

export const getFilteredColumnsConfig = metrix => {
  const filteredMetrix = { ...metrix };
  Object.keys(filteredMetrix).forEach(key => {
    if (filteredMetrix[key].disabled) delete filteredMetrix[key];
  });
  return filteredMetrix;
};

export const getColumnsListFromColumnsConfig = columnsConfig => Object.entries(columnsConfig);

export const getColumnsConfigFromColumnList = metrixList =>
  metrixList.reduce((acc, item) => {
    const [key, value] = item;
    return { ...acc, [key]: value };
  }, {});

export const getTableConfig = config => {
  return getColumnsListFromColumnsConfig(config).reduce((acc, item, index) => {
    const [key, itemData] = item;
    return { ...acc, [key]: { index, disabled: !!itemData.disabled } };
  }, {});
};

export const getSortedColumnsConfig = (metrixOrder, _metrix) => {
  if (!metrixOrder) return _metrix;
  const markedMetrix = { ..._metrix };
  Object.keys(markedMetrix).forEach(key => {
    markedMetrix[key].index = metrixOrder[key]?.index;
    markedMetrix[key].disabled = metrixOrder[key]?.disabled;
  });
  return getColumnsConfigFromColumnList(
    getColumnsListFromColumnsConfig(markedMetrix).sort((a, b) => a?.[1]?.index - b?.[1]?.index)
  );
};

export const getGroupJoinedColumnsList = _flatColumnsList => {
  let flatColumnsList = [..._flatColumnsList];
  const groupJoinedColumnsList = [];
  flatColumnsList.forEach(([key, item]) => {
    if ('groupId' in item) {
      const group = flatColumnsList.filter(([, data]) => data.groupId === item.groupId);
      flatColumnsList = flatColumnsList.filter(([, data]) => data.groupId !== item.groupId);
      if (group.length) groupJoinedColumnsList.push(['group', group]);
    } else {
      groupJoinedColumnsList.push([key, item]);
    }
  });
  return groupJoinedColumnsList;
};

export const getFlatColumnsList = groupJoinedColumnsList =>
  groupJoinedColumnsList.reduce((acc, item) => {
    const [, data] = item;
    return isArray(data) ? [...acc, ...data] : [...acc, item];
  }, []);
