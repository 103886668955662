import React from 'react';

import PropTypes from 'prop-types';

const progressBarStyle = {
  height: 5
};

export default function ProgressBar({ percentage, pathColor, className, pathClassName }) {
  const pathStyle = { width: `${percentage}%`, background: pathColor };

  return (
    <div className={`w-full bg-gray-lighter overflow-hidden ${className}`} style={progressBarStyle}>
      <div className={`h-full ${pathClassName}`} style={pathStyle} />
    </div>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  pathColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  pathClassName: PropTypes.string
};

ProgressBar.defaultProps = {
  className: '',
  pathClassName: ''
};
