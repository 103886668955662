import React from 'react';
import classNames from 'classnames';
import { Tooltip, withStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import BlackTooltip from '../common/BlackTooltip';
import { InfoIcon } from '../icons';

export const cellDefaultClassName =
  'inline-flex pt-3 pl-2 justify-end border-b border-gray-lighter-05 text-sm text-gray-darkest text-right';

export const defaultDataCellComponent = (key, rowIndex, newStyle, className, formattedValue) => {
  return (
    <div key={key} style={newStyle} className={className}>
      {formattedValue}
    </div>
  );
};

export const defaultRowRootComponent = (value, key, rowStyle, rowClassName, content) => {
  return (
    <div key={key} style={rowStyle} className={rowClassName}>
      {content}
    </div>
  );
};

export const defaultHeaderColumnClassName = (columnIndex, columnsCount, isSorted) => {
  return classNames(
    'inline-flex bg-white items-center border-b border-gray-lighter text-sm text-gray-darkest font-medium',
    {
      'pl-6': !columnIndex,
      'justify-end': columnIndex,
      'pr-6': columnIndex === columnsCount - 1,
      'pr-22px': !isSorted
    }
  );
};

const tableHeaderWhiteTooltipStyles = {
  tooltip: {
    maxWidth: '20rem', // FIXME: https://rainforest-life.atlassian.net/browse/SM-4120
    textAlign: 'center',
    background: Colors.white.default,
    boxShadow: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.2)',
    color: Colors.black.default,
    fontSize: '0.75rem',
    fontWeight: 400,
    borderRadius: '‭0.125rem',
    marginTop: '-1rem'
  }
};
const TableHeaderWhiteTooltip = withStyles(tableHeaderWhiteTooltipStyles)(Tooltip);

export const getHeaderButtonContent = (columnName, tooltip, infoIconTooltip) => {
  if (tooltip)
    return (
      <TableHeaderWhiteTooltip arrow title={tooltip} disableTouchListener placement="top">
        <div className="text-right">{columnName}</div>
      </TableHeaderWhiteTooltip>
    );
  if (infoIconTooltip) {
    return (
      <div className="flex items-center">
        <BlackTooltip
          arrow
          title={<div className={infoIconTooltip.className || ''}>{infoIconTooltip.content}</div>}
          disableTouchListener
          placement={infoIconTooltip.placement || 'top'}
        >
          <div className="cursor-pointer">
            <InfoIcon fill={Colors.gray.default} className="h-3 w-3" />
          </div>
        </BlackTooltip>
        <div className="ml-1 text-right">{columnName}</div>
      </div>
    );
  }
  return <div className="text-right">{columnName}</div>;
};

export const NONE_VIEW = <span className="text-gray-dark">none</span>;
