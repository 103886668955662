import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Chart, defaults, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format } from 'date-fns';
import colors from 'styles/colors';
import { getUTCDate } from '@utils';
import { ShortCurrencyValueView } from '@components/widgets/ShortCurrencyValueView';
import { formatMap, mainAxis, mainLineColor, secondAxis, secondLineColor } from './params';
import { getLeftYAxis, getRightYAxis, getXAxis } from './axes';
import { getTooltipParams } from './tooltip';

defaults.global.legend = null;
defaults.global.tooltips.mode = 'index';

const ChartComponent = ({
  interval,
  values,
  primaryMetricInfo,
  secondData,
  secondMetricInfo,
  end,
  currency,
  isBarView,
  startFromZero
}) => {
  const dtFormat = formatMap[interval];

  Chart.pluginService.register({
    /* eslint-disable no-underscore-dangle */
    afterDraw: chart => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.controller.tooltip._active[0];
        const { ctx } = chart;
        const { x } = activePoint.tooltipPosition();
        const topY = chart.scales[mainAxis].top;
        const bottomY = chart.scales[mainAxis].bottom;

        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.beginPath();
        ctx.moveTo(x, topY - 10);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = colors.gray.lighter;
        ctx.stroke();
        ctx.restore();
      }
    }
  });

  const getPointInfo = color => {
    if (values.length > 54) {
      return {
        pointRadius: 1,
        pointBorderColor: color
      };
    }
    return {
      pointBorderColor: '#ffffff',
      pointRadius: 4,
      pointBorderWidth: 1.5,
      pointBackgroundColor: color,
      pointShadowColor: '#c3c6d6',
      pointShadowOffsetX: 0,
      pointShadowOffsetY: 2,
      pointShadowBlur: 4
    };
  };

  const prepareLineData = (_values, label, color, yAxisID, prefix, suffix) => {
    if (!_values) {
      return null;
    }

    let metrics = [];
    let labels = [];

    _values.forEach(item => {
      metrics.push(item[1]);
      labels.push(format(getUTCDate(item[0]), dtFormat));
    });

    if (metrics.length === 1) {
      metrics = [null, ...metrics, null];
      labels = ['', ...labels, ''];
    }

    const data = {
      labels,
      legend: false,
      datasets: [
        {
          label,
          yAxisID,
          fill: false,
          lineTension: 0.1,
          borderColor: color,
          backgroundColor: color,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointHoverRadius: 5,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHitRadius: 10,
          pointShadowColor: '#c3c6d6',
          pointShadowOffsetX: 0,
          pointShadowOffsetY: 2,
          pointShadowBlur: 4,
          cubicInterpolationMode: 'monotone',
          data: metrics,
          srcValues: [..._values],
          ...getPointInfo(color),
          prefix,
          suffix
        }
      ]
    };

    return data;
  };

  const leftYAxisCurrency = primaryMetricInfo.currency ? currency : '';

  const options = {
    tooltips: getTooltipParams(interval, end, isBarView),
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [getXAxis()],
      yAxes: [
        getLeftYAxis(leftYAxisCurrency, values, primaryMetricInfo.suffix, isBarView, startFromZero)
      ]
    }
  };

  const data = prepareLineData(
    values,
    primaryMetricInfo.title,
    mainLineColor,
    mainAxis,
    leftYAxisCurrency,
    primaryMetricInfo.suffix
  );
  const rightYAxisCurrency = secondMetricInfo && secondMetricInfo.currency ? currency : '';
  const rightYAxisSuffix = secondMetricInfo ? secondMetricInfo.suffix : '';

  const secondChartData = prepareLineData(
    secondData,
    secondMetricInfo && secondMetricInfo.title,
    secondLineColor,
    secondAxis,
    rightYAxisCurrency,
    rightYAxisSuffix
  );

  if (secondMetricInfo && secondChartData) {
    data.datasets.push(secondChartData.datasets[0]);
    options.scales.yAxes.push(
      getRightYAxis(rightYAxisCurrency, secondData, rightYAxisSuffix, isBarView, startFromZero)
    );
  }
  const getContent = () => {
    if (isBarView)
      return (
        <Bar
          options={{
            ...options,
            plugins: {
              datalabels: {
                color: colors.white.default,
                align: 'bottom',
                anchor: 'end',
                clamp: true,
                formatter: (value, config) => {
                  if (config.dataset.suffix === '%') return `${value?.toFixed()}%`;
                  if (config.dataset.prefix) {
                    return ShortCurrencyValueView({
                      value,
                      prefix: config.dataset.prefix
                    });
                  }
                  return value;
                }
              }
            }
          }}
          data={data}
          plugins={[ChartDataLabels]}
        />
      );
    return <Line options={options} data={data} />;
  };
  return <div style={{ height: '22rem' }}>{getContent()}</div>;
};

ChartComponent.propTypes = {
  interval: PropTypes.string.isRequired,
  primaryMetricInfo: PropTypes.shape({
    title: PropTypes.string,
    currency: PropTypes.string,
    suffix: PropTypes.string
  }).isRequired,
  secondMetricInfo: PropTypes.shape({
    title: PropTypes.string,
    currency: PropTypes.string,
    suffix: PropTypes.string
  }),
  values: PropTypes.instanceOf(Array).isRequired,
  secondData: PropTypes.instanceOf(Array),
  end: PropTypes.instanceOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
  isBarView: PropTypes.bool,
  startFromZero: PropTypes.bool.isRequired
};

ChartComponent.defaultProps = {
  secondMetricInfo: null,
  secondData: null,
  isBarView: false
};

export default ChartComponent;
