import API from '@sm/api';
import mock from '../mock';

const billing = {
  cc: {
    exp_month: 12,
    exp_year: 2021,
    brand: 'Visa',
    last4: '1234'
  },
  accountInfo: {
    tier: 'Starter',
    orders: 80
  },
  nextBillingDate: '2019-07-10 02:50:47',
  history: [
    {
      issue_date: '2019-07-10 02:50:47',
      plan: 0,
      billingPeriod: {
        start: '2019-06-10 02:50:47',
        end: '2019-07-10 02:50:47'
      },
      status: 'Not Paid',
      paid: false,
      amount: 15,
      orders: 80,
      tier: 'Enterprise (+ 3 blocks of 1,000 orders at $7/block)'
    },
    {
      issue_date: '2019-07-10 02:50:47',
      plan: 0,
      billingPeriod: {
        start: '2019-06-10 02:50:47',
        end: '2019-07-10 02:50:47'
      },
      status: 'Payment Failed',
      paid: false,
      amount: 15,
      orders: 80,
      tier: 'Enterprise (+ 3 blocks of 1,000 orders at $7/block)'
    },
    {
      issue_date: '2019-07-10 02:50:47',
      plan: 1,
      billingPeriod: {
        start: '2019-06-10 02:50:47',
        end: '2019-07-10 02:50:47'
      },
      status: 'Paid',
      paid: true,
      paymentDate: '2019-07-03',
      amount: 35,
      orders: 5000,
      paidDate: '2019-06-17 02:50:47',
      tier: 'Enterprise (+ 3 blocks of 1,000 orders at $7/block)'
    }
  ]
};

mock.onGet(API.billing.history).reply(() => {
  return [200, billing];
});
