import axios from 'axios/index';
import { select } from '@redux-saga/core/effects';
import { cancelled, put, takeLatest, call } from 'redux-saga/effects';
import API from '@sm/api';
import { axiosCall } from '@utils';
import { getFlagsById, GLOBAL_MK } from '@sm/Flags';
import { prepareRefunds } from 'seller/utils/convertRawApiData';
import { buildParams } from 'seller/utils/buildParams';
import { pullTotalsAction } from '../saga/dashboard';
import { setNotificationMsgAction } from './index';
import { missedBookmarkHandler } from '../saga/utils';
import { selectorSelector, userSelector } from '../reducers';

export const SET_REFUNDS = 'SET_REFUNDS';
export const START_REFUNDS_LOADING = 'START_REFUNDS_LOADING';
export const ENABLE_VIDEO_DIALOG = 'ENABLE_VIDEO_DIALOG';
export const PULL_REFUNDS_DATA = 'PULL_REFUNDS_DATA';
export const PULL_REFUND_DETAILS = 'PULL_REFUND_DETAILS';

export const setRefundsAction = data => ({ type: SET_REFUNDS, data });
export const pullRefundsDataAction = payload => ({ type: PULL_REFUNDS_DATA, payload });
export const pullRefundDetailsAction = payload => ({ type: PULL_REFUND_DETAILS, payload });

const { CancelToken } = axios;
export function* pullRefundsDataSaga({ payload }) {
  const { params } = payload;
  const source = CancelToken.source();
  try {
    const { status, data } = yield axios.get(API.refunds.list, {
      params,
      cancelToken: source.token
    });
    if (status === 200) {
      yield put(setRefundsAction(prepareRefunds(data.orders)));
    } else {
      yield put(setNotificationMsgAction('Pull refunds failed'));
    }
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    yield put(setNotificationMsgAction('Pull refunds failed'));
    console.log('pullRefundsDataSaga error:', error);
  } finally {
    if (yield cancelled()) source.cancel();
  }
}

export function* pullRefundDetailsSaga({ payload }) {
  const { callback, apiParams } = payload;
  const { orderItemId, marketplace } = apiParams;
  const { defaultSettings } = yield select(userSelector);
  const { marketplaceID } = yield select(selectorSelector);
  const defaultCurrency = defaultSettings.currency;
  try {
    const params = {
      orderItemId,
      marketplaceID: marketplace,
      currency:
        marketplaceID === GLOBAL_MK ? defaultCurrency : getFlagsById(marketplaceID)?.currency,
      ...apiParams
    };
    const { data } = yield axiosCall.get(API.orders.realBreakdown, { params });
    callback(data);
  } catch (error) {
    console.log('pullRefundDetailsSaga error:', error);
    callback({});
  }
}

export const pullRefundsAction = () => {
  return (dispatch, getState) => {
    const params = buildParams({ state: getState(), dispatch });
    if (params.temp_bookmark_expired) return;
    dispatch({ type: START_REFUNDS_LOADING });
    dispatch(pullRefundsDataAction({ params }));
    dispatch(pullTotalsAction({ params, refunds: true, metrics: ['refunds'] }));
  };
};

export const showVideoDialogAction = enable => {
  return dispatch => {
    dispatch({ type: ENABLE_VIDEO_DIALOG, enable });
  };
};

export function* refundsSagaWatcher() {
  yield takeLatest(PULL_REFUNDS_DATA, pullRefundsDataSaga);
  yield takeLatest(PULL_REFUND_DETAILS, pullRefundDetailsSaga);
}
