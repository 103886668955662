import {
  EXCLUDE_ORDERS,
  FEEDBACK_EXCLUDE_TYPE,
  ORDER_INDEXES,
  REFUNDED_EXCLUDE_VALUE,
  SPECIAL_EXCLUDE_TYPE,
  WEEK_DAYS
} from '../consts';
import { convertTimeTo12Hr } from './time';

export const getTextFormattedIncludeOrders = orders => {
  if (orders.length === ORDER_INDEXES.length) return 'All orders';
  if (orders.length === 1) {
    const [singleOption] = orders;
    const singleOptionText = ORDER_INDEXES.find(item => item.value === singleOption);
    return `Only for ${singleOptionText ? singleOptionText.text : ''}`;
  }
  return `Only for: ${orders
    .map(order => {
      const option = ORDER_INDEXES.find(item => item.value === order);
      return option ? option.shortText : '';
    })
    .join(', ')} orders`;
};

export const getTextFormattedExcludeOrders = orders => {
  const sortedOptions = [...orders].sort((a, b) => a - b);

  const feedbackOptions = sortedOptions.filter(item => {
    const option = EXCLUDE_ORDERS.find(order => order.value === item);
    return option?.type === FEEDBACK_EXCLUDE_TYPE;
  });

  const specialOptions = sortedOptions.filter(item => {
    const option = EXCLUDE_ORDERS.find(order => order.value === item);
    return option?.type === SPECIAL_EXCLUDE_TYPE;
  });

  const getComposedFeedbackOptionsText = () => {
    const filteredOptions = orders
      .sort((a, b) => a - b)
      .filter(item => {
        const option = EXCLUDE_ORDERS.find(order => order.value === item);
        return option ? option.type === FEEDBACK_EXCLUDE_TYPE : false;
      });
    if (!filteredOptions.length) return '';
    let output;
    if (filteredOptions.length === 1) {
      const [singleValue] = filteredOptions;
      output = singleValue;
    } else if (filteredOptions.length > 2) {
      output = `${filteredOptions.slice(0, -1).join(', ')} or ${
        filteredOptions[filteredOptions.length - 1]
      }`;
    } else {
      output = filteredOptions.join(', ');
    }
    return `orders with ${output} star feedback`;
  };

  const getComposedSpecialOptionsText = () => {
    const filteredOption = orders.filter(item => {
      const option = EXCLUDE_ORDERS.find(order => order.value === item);
      return option ? option.type === SPECIAL_EXCLUDE_TYPE : false;
    });
    return filteredOption.length
      ? filteredOption
          .map(value => EXCLUDE_ORDERS.find(item => item.value === value).shortName)
          .join(', ')
      : '';
  };

  const isRefundedExclude = orders.includes(REFUNDED_EXCLUDE_VALUE);
  const feedbackOptionsText = getComposedFeedbackOptionsText(feedbackOptions)
    ? `${isRefundedExclude ? ' &' : ''} ${getComposedFeedbackOptionsText(feedbackOptions)}`
    : '';
  const specialOptionsText = getComposedSpecialOptionsText(specialOptions)
    ? `${isRefundedExclude || feedbackOptionsText ? ' &' : ''} ${getComposedSpecialOptionsText(
        specialOptions
      )}`
    : '';
  if (orders.length === 1) {
    if (isRefundedExclude) {
      return 'Excluding refunded orders';
    }
    return `Excluding ${feedbackOptionsText}${specialOptionsText}`;
  }
  return `Excluding ${
    isRefundedExclude ? 'refunded orders' : ''
  }${feedbackOptionsText}${specialOptionsText} `;
};

export const getTextFormattedTime = scheduleTime => {
  const time = convertTimeTo12Hr(scheduleTime);
  if (time === '12:00 am') return 'midnight';
  if (time === '12:00 pm') return 'noon';
  return time;
};

// process [1,2,4,5,6] to 'Mondays, Tuesdays, Thursdays - Saturdays'
export const getTextFormattedWeekdays = scheduleWeekday => {
  if (scheduleWeekday.length === WEEK_DAYS.length) return 'Any day';
  if (scheduleWeekday.length === 1) return WEEK_DAYS[scheduleWeekday[0] - 1].fullName;

  // process [1,2,4,5,6] to [{ start: 1, end: 2 }, { start: 4, end: 6 }]
  const periods = scheduleWeekday.reduce(
    (acc, day, i) => {
      if (i === 0) {
        acc.ranges.push({ start: day, end: day });
        acc.rangeStart = day;
      } else if (day === acc.last + 1) {
        acc.ranges[acc.ranges.length - 1].end = day;
      } else {
        acc.ranges.push({ start: day, end: day });
        acc.rangeStart = day;
      }
      acc.last = day;
      return acc;
    },
    { ranges: [] }
  );

  // process [{ start: 1, end: 2 }, { start: 4, end: 6 }] to [{ start: 1, end: 1 }, { start: 2, end: 2 }, { start: 4, end: 6 }]
  const filteredPeriods = periods.ranges.reduce((acc, period) => {
    if (period.end - period.start === 1) {
      acc.push({ start: period.start, end: period.start });
      acc.push({ start: period.end, end: period.end });
    } else {
      acc.push(period);
    }
    return acc;
  }, []);

  // process [{ start: 1, end: 1 }, { start: 2, end: 2 }, { start: 4, end: 6 }] to 'Mondays, Tuesdays, Thursdays - Saturdays'
  return filteredPeriods
    .map(period => {
      if (period.start === period.end) return WEEK_DAYS[period.start - 1].fullName;
      return `${WEEK_DAYS[period.start - 1].fullName} - ${WEEK_DAYS[period.end - 1].fullName}`;
    })
    .join(', ');
};
