import {
  addDays,
  compareAsc,
  compareDesc,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  getDate,
  getDaysInMonth,
  getMonth,
  isAfter,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameWeek,
  isSameYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears
} from 'date-fns';

import { DateUtils } from 'react-day-picker';
import { utcToZonedTime } from 'date-fns-tz';
import { createRange } from '@utils';
import { getFlagsById, GLOBAL_MK } from '@sm/Flags';

export const snippetsList = {
  today: 'today',
  yersterday: 'yersterday',
  Last7days: 'last_7_days',
  last30days: 'last_30_days',
  lastMonth: 'last_month',
  thisYear: 'this_year',
  lifeTime: 'life_time',
  last_4_weeks: 'last_4_weeks',
  last_3_months: 'last_3_months',
  last_6_months: 'last_6_months',
  last_12_months: 'last_12_months',
  last_24_months: 'last_24_months',
  last_4_quarters: 'last_4_quarters',
  last_8_quarters: 'last_8_quarters',
  last_16_quarters: 'last_16_quarters',
  last_2_years: 'last_2_years',
  last_4_years: 'last_4_years'
};

export const getRangeBySnippet = (snippetName, today, start, end) => {
  const getLastXMonthsConfig = months => ({
    getStart: () => startOfMonth(subMonths(today, months)),
    getEnd: () => endOfMonth(subMonths(today, 1))
  });

  const getLastXQuartersConfig = quarters => ({
    getStart: () => startOfMonth(subQuarters(today, quarters)),
    getEnd: () => endOfMonth(subQuarters(today, 1))
  });

  const getLastXYearsConfig = years => ({
    getStart: () => startOfYear(subYears(today, years)),
    getEnd: () => endOfYear(subYears(today, 1))
  });

  const snippetsConfigs = {
    [snippetsList.today]: { getStart: () => today, getEnd: () => today },
    [snippetsList.yersterday]: {
      getStart: () => subDays(today, 1),
      getEnd: () => subDays(today, 1)
    },
    [snippetsList.Last7days]: { getStart: () => subDays(today, 6), getEnd: () => today },
    [snippetsList.last30days]: { getStart: () => subDays(today, 29), getEnd: () => today },
    [snippetsList.lastMonth]: getLastXMonthsConfig(1),
    [snippetsList.last_3_months]: getLastXMonthsConfig(3),
    [snippetsList.last_6_months]: getLastXMonthsConfig(6),
    [snippetsList.last_12_months]: getLastXMonthsConfig(12),
    [snippetsList.last_24_months]: getLastXMonthsConfig(24),
    [snippetsList.thisYear]: { getStart: () => startOfYear(today), getEnd: () => today },
    [snippetsList.lifeTime]: { getStart: () => start, getEnd: () => today },
    [snippetsList.last_4_weeks]: { getStart: () => subWeeks(today, 3), getEnd: () => today },
    [snippetsList.last_4_quarters]: getLastXQuartersConfig(4),
    [snippetsList.last_8_quarters]: getLastXQuartersConfig(8),
    [snippetsList.last_16_quarters]: getLastXQuartersConfig(16),
    [snippetsList.last_2_years]: getLastXYearsConfig(2),
    [snippetsList.last_4_years]: getLastXYearsConfig(4)
  };

  const getSafeDateStart = date => (isAfter(date, start) ? date : start);
  const getSafeDateEnd = date => (isAfter(date, end) ? end : date);

  const { getStart, getEnd } = snippetsConfigs[snippetName];
  return createRange(getSafeDateStart(getStart()), getSafeDateEnd(getEnd()));
};

export const getNewSelection = (data, range, snippet, today, lifeTime, isSecondSelection) => {
  let result = {
    range,
    currentSnippet: snippet
  };
  if ('from' in data && 'to' in data) {
    let { from, to } = data;
    if (
      compareAsc(startOfDay(to), startOfDay(today)) > 0 ||
      compareAsc(startOfDay(from), startOfDay(lifeTime)) < 0
    ) {
      return { range };
    }
    if (compareAsc(startOfDay(to), startOfDay(today)) > 0) {
      to = today;
    }
    if (compareAsc(from, lifeTime) !== 1) {
      from = lifeTime;
    }
    if (compareAsc(startOfDay(from), startOfDay(today)) >= 0) {
      from = today;
    }
    result.range = createRange(from, to);

    return result;
  }
  let newRange;
  if (
    snippet !== '' ||
    (isSecondSelection && compareAsc(range.from, data) === 0) ||
    !isSecondSelection
  ) {
    newRange = createRange(data, data);
  } else {
    newRange = DateUtils.addDayToRange(data, range);
  }
  if (
    compareAsc(startOfDay(addDays(today, 1)), data) > 0 &&
    compareAsc(data, lifeTime) >= 0 &&
    newRange.from !== null
  ) {
    result = { range: newRange, currentSnippet: '' };
    return result;
  }
  return {};
};

export const isAllMonthSelected = range => {
  const { from, to } = range;

  const calendarDay = getDate(to);

  const isLastDayCorrect = calendarDay === getDaysInMonth(to);

  return (
    getDate(from) === 1 &&
    isLastDayCorrect &&
    getMonth(from) === getMonth(to) &&
    isSameYear(from, to)
  );
};

export const isAllYearSelected = range => {
  const { from, to } = range;
  const isFirstDay = isSameDay(startOfDay(from), startOfYear(from));
  const isLastDay =
    isSameDay(endOfDay(to), endOfYear(to)) || isSameDay(endOfDay(to), endOfDay(new Date()));
  return isFirstDay && isLastDay;
};

export const getLimitedRangeByLifetime = (_range, lifeTime) => {
  const newRange = { ..._range };
  if (compareAsc(startOfDay(newRange.from), startOfDay(lifeTime)) === -1) newRange.from = lifeTime;
  if (compareAsc(startOfDay(newRange.to), startOfDay(lifeTime)) === -1) newRange.to = lifeTime;
  return newRange;
};

export const getShiftedRange = (range, isSubtract = false) => {
  // Need to handle next cases:
  // 0. All past dates of the period (week, month, quarter, year) are selected - return previous week
  // 1. Various days selected - subtract range days
  // 2. Whole weeks selected - subtract range weeks
  // 3. Whole months selected - subtract range months
  // 4. Whole quarters selected - subtract range quarters
  // 5. Whole years selected - subtract range years

  const multiplier = isSubtract ? 1 : -1;
  const from = startOfDay(range.from);
  const to = endOfDay(range.to);
  const now = new Date();

  const rangesConfigs = [
    {
      getStart: startOfYear,
      getEnd: endOfYear,
      getDifference: differenceInYears,
      subtract: subYears,
      getIsSamePeriod: isSameYear
    },
    {
      getStart: startOfQuarter,
      getEnd: endOfQuarter,
      getDifference: differenceInQuarters,
      subtract: subQuarters,
      getIsSamePeriod: isSameQuarter
    },
    {
      getStart: startOfMonth,
      getEnd: endOfMonth,
      getDifference: differenceInMonths,
      subtract: subMonths,
      getIsSamePeriod: isSameMonth
    },
    {
      getStart: startOfWeek,
      getEnd: endOfWeek,
      getDifference: differenceInWeeks,
      subtract: subWeeks,
      getIsSamePeriod: isSameWeek
    },
    {
      getStart: startOfDay,
      getEnd: endOfDay,
      getDifference: differenceInDays,
      subtract: subDays,
      getIsSamePeriod: isSameDay
    }
  ];

  for (let i = 0; i < rangesConfigs.length; i += 1) {
    const { getStart, getEnd, getDifference, getIsSamePeriod, subtract } = rangesConfigs[i];
    const isIncompletePeriodSelected =
      isSubtract &&
      getIsSamePeriod(from, to) &&
      isSameDay(from, getStart(from)) &&
      isSameDay(to, now);

    if (isIncompletePeriodSelected || (isEqual(from, getStart(from)) && isEqual(to, getEnd(to)))) {
      const difference = getDifference(to, from) + 1;
      return {
        from: getStart(subtract(from, difference * multiplier)),
        to: getEnd(subtract(to, difference * multiplier))
      };
    }
  }

  return range;
};

const getMkLocalTime = mk => {
  const flagData = getFlagsById(mk);
  if (!flagData) return new Date();
  return utcToZonedTime(new Date(), flagData?.timezone);
};

export const getMkToday = (marketplaceID, marketplaces, today) => {
  if (!marketplaces || (!marketplaceID && marketplaceID !== GLOBAL_MK)) return today;
  if (marketplaceID === GLOBAL_MK) {
    const buffer = [];
    marketplaces
      .filter(item => item !== GLOBAL_MK)
      .forEach(item => buffer.push(getMkLocalTime(item)));
    const [fastestLocalTime] = buffer.sort(compareDesc);
    return fastestLocalTime;
  }
  return getMkLocalTime(marketplaceID);
};

export const getSafeMkRange = (range, mkToday) => {
  let needUpdate = false;
  const result = { ...range };
  if (isAfter(startOfDay(result.from), startOfDay(mkToday))) {
    result.from = mkToday;
    needUpdate = true;
  }
  if (isAfter(startOfDay(result.to), startOfDay(mkToday))) {
    result.to = mkToday;
    needUpdate = true;
  }
  if (needUpdate) return result;
  return null;
};
