/* eslint-disable no-param-reassign */
export const ENTER_CHAR_CODE = 13;
export const SPACE_CHAR_CODE = 32;

export const addInputFocusHandler = (inputList, onSubmit, inputRefs) => {
  const inputFocusHandler = e => {
    if (e.charCode !== ENTER_CHAR_CODE) return;
    const inputIndex = +e.currentTarget?.attributes?.inputId?.nodeValue;
    const emptyRequired = inputList.find(item => !item.props?.value && !item.optional);
    if (!emptyRequired) {
      onSubmit();
    } else if (inputIndex === inputList.length - 1) {
      const firstEmptyRequired = inputList.findIndex(item => !item.props?.value && !item.optional);
      const targetInput = inputRefs.current[firstEmptyRequired];
      if (targetInput) targetInput.focus();
    } else {
      const targetInput = inputRefs.current[inputIndex + 1];
      if (targetInput) targetInput.focus();
    }
  };

  inputList.forEach((inputConfig, index) => {
    if (!inputConfig.props.inputProps) inputConfig.props.inputProps = {};
    inputConfig.props.inputProps = {
      ...inputConfig.props.inputProps,
      onKeyPress: inputFocusHandler,
      inputId: index
    };
    inputConfig.props.inputRef = el => {
      inputRefs.current[index] = el;
    };
  });
};
