import { SET_REACT_BOUNDARY_ERROR_NOTIFICATION } from '../actions';

const initialState = {
  errorNotification: false
};

export const reactBoundarySelector = state => state.reactBoundary;

const reactBoundary = (state = initialState, action) => {
  switch (action.type) {
    case SET_REACT_BOUNDARY_ERROR_NOTIFICATION: {
      return { ...state, errorNotification: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reactBoundary;
