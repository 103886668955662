import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList as List } from 'react-window';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import ProductCard from '../product-card/ProductCard';
import CheckBox from '../../check-box';
import GradButton from '../../buttons/GradButton';

export const getCustomProperties = props => {
  const keysAndValues = {
    variation_type_one: 'variation_value_one',
    variation_type_two: 'variation_value_two',
    variation_type_three: 'variation_value_three',
    variation_type_four: 'variation_value_four',
    variation_type_five: 'variation_value_five'
  };
  const result = {};
  Object.keys(keysAndValues).map(value => {
    if (keysAndValues[value] !== '') {
      const key = props[keysAndValues[value]];
      if (key !== undefined && key !== undefined && key !== '') {
        result[props[value]] = key;
      }
    }
    return 0;
  });
  return result;
};

const Row = props => {
  const { style, index, data } = props;
  const { id, name, productCount, type } = data.list[index];
  const { onCheck } = data.config;

  // There is no id for brands, because we join brands from different marketplaces for global.
  const rowId = type === 'brand' ? name : id;

  if (type === 'title') {
    return (
      <p className="inline-flex items-center text-black text-sm font-medium pl-2" style={style}>
        {name}
      </p>
    );
  }
  const item = data.list[index];
  const onChecked = () => onCheck(!item.selected, item);

  const customProperties = getCustomProperties(item);
  const { isFirst, isLast } = item;

  const rootClass = classNames(
    'inline-flex items-center justify-between w-full overflow-hidden bg-white box-shadow-list pl-2 pr-5',
    {
      'pt-4': isFirst && !isLast,
      'pb-4': isLast && !isFirst,
      'pb-2 pt-2': !isFirst && !isLast
    }
  );
  return (
    <ProductCard
      onClick={onChecked}
      {...item}
      selected={null}
      childCount={productCount}
      productsCount={productCount}
      lastItem={<CheckBox value={item.selected} onCheckHandler={onChecked} />}
      key={rowId}
      customProperties={customProperties}
      style={style}
      {...data.config}
      {...props}
      className={rootClass}
      enableTooltips={false} // FIXME: https://rainforest-life.atlassian.net/browse/SM-694
    />
  );
};

export const rowHeightMap = {
  title: 28,
  default: 71,
  variation: 85
};

export const defaultRowHeight = 65;
const stickyFooterHeight = 54;

function ProductsList(props) {
  const { data, axis, height, onCancel, onAccept, hideCancelConfirm } = props;
  const listRef = useRef();

  const rowHeights = data.map(value => {
    return rowHeightMap[value.type] || defaultRowHeight;
  });

  function getItemSize(index) {
    return rowHeights[index];
  }

  useEffect(() => {
    listRef.current.resetAfterIndex(0, false);
  }, [data]);

  const heightReduce = hideCancelConfirm ? 0 : stickyFooterHeight;
  return (
    <div className="flex flex-col">
      <List
        itemData={{
          list: data,
          config: {
            ...props
          }
        }}
        {...props}
        ref={listRef}
        height={height - heightReduce}
        layout={axis}
        itemCount={data.length}
        itemSize={getItemSize}
        className="bg-white-blue scroll-container"
      >
        {Row}
      </List>
      {!hideCancelConfirm && (
        <div className="w-full bg-white px-2 py-3 flex justify-end fixed sm:absolute left-0 bottom-0 box-shadow-dark">
          <Button
            onClick={onCancel}
            className="text-gray-dark mr-4 border-gray-default normal-case sm-w-140px"
          >
            Cancel
          </Button>
          <GradButton onClick={onAccept} className="sm-w-140px">
            Accept
          </GradButton>
        </div>
      )}
    </div>
  );
}

ProductsList.defaultProps = {
  hideCancelConfirm: false
};

ProductsList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  hideCancelConfirm: PropTypes.bool
};

export default ProductsList;
