import React from 'react';
import PropTypes from 'prop-types';
import { ArrowsView } from './ArrowsView';

export const ChangeYearWidget = ({
  year,
  isLeftVIew,
  onDoubleLeftClick,
  onLeftClick,
  onDoubleRightClick,
  onRightClick
}) => {
  return (
    <div className="grid grid-cols-3 mb-2">
      <div className="flex">
        {isLeftVIew && (
          <ArrowsView onClick={onLeftClick} onDoubleClick={onDoubleLeftClick} rotated />
        )}
      </div>
      <div className="flex items-center justify-center">
        <div className="text-black font-medium text-lg">{year}</div>
      </div>
      <div className="w-full flex justify-end">
        {!isLeftVIew && <ArrowsView onClick={onRightClick} onDoubleClick={onDoubleRightClick} />}
      </div>
    </div>
  );
};

ChangeYearWidget.propTypes = {
  year: PropTypes.number.isRequired,
  isLeftVIew: PropTypes.bool.isRequired,
  onDoubleLeftClick: PropTypes.func,
  onLeftClick: PropTypes.func,
  onDoubleRightClick: PropTypes.func,
  onRightClick: PropTypes.func
};

ChangeYearWidget.defaultProps = {
  onDoubleLeftClick: null,
  onLeftClick: null,
  onDoubleRightClick: null,
  onRightClick: null
};
