import API from '@sm/api';
import mock from '../mock';

const messageTemplatesDB = [
  {
    id: 3,
    request_review: false,
    include_order: ['1', '2', '3', '4', '5'],
    exclude_order: [],
    include_countries: '',
    exclude_countries: 'US',
    schedule_order_status: 'Delivered',
    schedule_event: ['5'],
    schedule_after: 5,
    schedule_weekday: ['2'],
    schedule_time: '14:33',
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable',
    previous_email_opened: true,
    active: true,
    messages_sent: 20,
    emails_opened: 10,
    orders: 10,
    messages_pending: 5
  },
  {
    id: 5,
    request_review: true,
    include_order: ['1', '2'],
    exclude_order: ['3'],
    include_countries: 'RU US RU US RU US RU RU US RU US',
    exclude_countries: 'UK',
    schedule_order_status: 'Refunded',
    schedule_event: ['2'],
    schedule_after: 3,
    schedule_weekday: [],
    schedule_time: '11:33',
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable. Complimentary',
    previous_email_opened: false,
    active: false,
    messages_sent: 25,
    emails_opened: 10,
    orders: 10,
    messages_pending: 7
  },
  {
    id: 6,
    request_review: true,
    include_order: ['1', '2'],
    exclude_order: ['1', '2', '3'],
    include_countries: 'RU US',
    exclude_countries: 'UK',
    schedule_order_status: 'Refunded',
    schedule_event: ['2'],
    schedule_after: 0,
    schedule_time: '15:33',
    schedule_weekday: [],
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable. Complimentary',
    previous_email_opened: false,
    active: true,
    messages_sent: 20,
    emails_opened: 10,
    orders: 10,
    messages_pending: 5
  },
  {
    id: 8,
    request_review: true,
    include_order: ['1', '2'],
    exclude_order: ['1', '2', '3'],
    include_countries: 'RU US RU US RU US RU RU US RU US',
    exclude_countries: 'UK',
    schedule_order_status: 'Refunded',
    schedule_event: ['2'],
    schedule_after: 0,
    schedule_time: '15:33',
    schedule_weekday: [],
    name: 'Protein Shaker Bottle 20 oz. Vortex Mixer Cup. USB Rechargeable. Complimentary',
    previous_email_opened: false,
    active: true,
    messages_sent: 20,
    emails_opened: 10,
    orders: 10,
    messages_pending: 5
  }
];

const campaignsDB = [
  {
    id: 2679,
    name: 'Bed sheet straps',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://m.media-amazon.com/images/I/41TQsDd84aL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Hot-Designer-leather-briefcase-man-Office-Bag.jpg_350x350.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Premium-Kitchen-Utensil-Set-With-Bronze-Wire.jpg_350x350.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/71bKSij4fxL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2691,
    name: 'TestFromUSA',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [
      {
        id: 2691,
        name: 'ST Em1',
        active: true,
        request_review: false,
        deleted: false,
        schedule_event: 2,
        schedule_after: 7,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2692,
    name: 'TestFromUSA',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2693,
    name: 'Test6Alex',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2694,
    name: 'Test-5Alex',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2695,
    name: 'TestAlex-3',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2696,
    name: 'Alex test',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://m.media-amazon.com/images/I/41TQsDd84aL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/61fzkJE1b1L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/71YuZCCp+TL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Premium-Kitchen-Utensil-Set-With-Bronze-Wire.jpg_350x350.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/71YuZCCp+TL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2648,
    name: 'Activene PRC Main',
    active: true,
    target_scope: 'asins',
    target_values: 'B012A5Z89K',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg'
    ],
    messagetemplates: [
      {
        id: 2678,
        name: 'name',
        active: true,
        request_review: true,
        deleted: true,
        schedule_event: 3,
        schedule_after: 7,
        emails_sent: 0,
        request_reviews_sent: 0
      },
      {
        id: 2680,
        name: 'Std Email After Delivered',
        active: false,
        request_review: false,
        deleted: true,
        schedule_event: 3,
        schedule_after: 0,
        emails_sent: 0,
        request_reviews_sent: 0
      },
      {
        id: 2693,
        name: 'Req Rev for Activene PRC',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 5,
        emails_sent: 0,
        request_reviews_sent: 393
      }
    ],
    marketplace: 3,
    products_count: 1,
    deleted: false,
    emails_sent: 0,
    request_reviews_sent: 393
  },
  {
    id: 2652,
    name: 'InstaShaker USA',
    active: true,
    target_scope: 'asins',
    target_values: 'B01LVWG3WW',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg'
    ],
    messagetemplates: [
      {
        id: 2677,
        name: 'Request Review for IS',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 20,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 1,
    deleted: false,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2654,
    name: 'Nachteule',
    active: true,
    target_scope: 'asins',
    target_values: 'B014K05KAA',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg'
    ],
    messagetemplates: [
      {
        id: 2674,
        name: 'Nachteule Request Review',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 7,
        emails_sent: 0,
        request_reviews_sent: 103
      }
    ],
    marketplace: 3,
    products_count: 1,
    deleted: false,
    emails_sent: 0,
    request_reviews_sent: 103
  },
  {
    id: 2655,
    name: 'Activene DSC',
    active: true,
    target_scope: 'asins',
    target_values: 'B078RNS6BJ',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/travel%20pillow.jpg'
    ],
    messagetemplates: [
      {
        id: 2666,
        name: 'Activene DSC',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 7,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 1,
    deleted: false,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2656,
    name: 'Req Rev for all prodds',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/71YuZCCp+TL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg'
    ],
    messagetemplates: [
      {
        id: 2672,
        name: 'Request Review Message',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 7,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2658,
    name: 'tsest',
    active: false,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/travel%20pillow.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Premium-Kitchen-Utensil-Set-With-Bronze-Wire.jpg_350x350.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg'
    ],
    messagetemplates: [
      {
        id: 2676,
        name: 'test',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 10,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2662,
    name: 'Headphones Campaign',
    active: false,
    target_scope: 'asins',
    target_values: 'B01LVWG3WW',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg'
    ],
    messagetemplates: [
      {
        id: 2681,
        name: 'Request Review for Headphones',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 9,
        emails_sent: 0,
        request_reviews_sent: 0
      },
      {
        id: 2682,
        name: 'Headphones Standard Email Product Manual (English)',
        active: true,
        request_review: false,
        deleted: false,
        schedule_event: 3,
        schedule_after: 0,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 1,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2664,
    name: 'Headphones X Campaign',
    active: false,
    target_scope: 'asins',
    target_values: 'B01LVWG3WW',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg'
    ],
    messagetemplates: [
      {
        id: 2684,
        name: 'Headphones X Product Guide (English)',
        active: true,
        request_review: false,
        deleted: false,
        schedule_event: 3,
        schedule_after: 0,
        emails_sent: 0,
        request_reviews_sent: 0
      },
      {
        id: 2686,
        name: 'Request review HeadphonesX',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 7,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 1,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2665,
    name: 'Request review all products',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/travel%20pillow.jpg',
      'https://m.media-amazon.com/images/I/71YuZCCp+TL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg'
    ],
    messagetemplates: [
      {
        id: 2687,
        name: 'Request Review Message',
        active: true,
        request_review: true,
        deleted: false,
        schedule_event: 3,
        schedule_after: 7,
        emails_sent: 0,
        request_reviews_sent: 0
      }
    ],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2667,
    name: 'Test7',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2668,
    name: 'Test333',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2670,
    name: 'Jeff test',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://m.media-amazon.com/images/I/41TQsDd84aL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/61fzkJE1b1L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/71YuZCCp+TL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Premium-Kitchen-Utensil-Set-With-Bronze-Wire.jpg_350x350.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/71YuZCCp+TL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/41BRNqG96zL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2672,
    name: 'all products',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://m.media-amazon.com/images/I/71YuZCCp+TL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      'https://m.media-amazon.com/images/I/71bKSij4fxL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  },
  {
    id: 2677,
    name: 'asdsadasd',
    active: true,
    target_scope: 'all_products',
    target_values: '',
    products: [
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Amazon-hot-selling-Pink-Silicone-Kitchen-Utensils.jpg_350x350.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/headphones.jpg',
      'https://m.media-amazon.com/images/I/81-uvP2H4PL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg',
      'https://m.media-amazon.com/images/I/811ciegg+6L._SL75_.jpg',
      'https://m.media-amazon.com/images/I/91yzctvznlL._SL75_.jpg',
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/Hot-Designer-leather-briefcase-man-Office-Bag.jpg_350x350.jpg',
      'https://m.media-amazon.com/images/I/6105T62lTrL._SL75_.jpg'
    ],
    messagetemplates: [],
    marketplace: 3,
    products_count: 33,
    deleted: true,
    emails_sent: 0,
    request_reviews_sent: 0
  }
];

const messagesDB = [
  {
    id: 13177579,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '141-2341518-1269238',
    buyer: '2jp59yqkytxxrjk@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13186236,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-1300956-6538827',
    buyer: 'k3h5bt2j0x5xydl@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13186409,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-9714152-2610424',
    buyer: '4sk7zrp6f0sq9yn@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13186647,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-9116906-7748085',
    buyer: 'hj5twqxbs2jws4t@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13187839,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-2608903-1033546',
    buyer: 'dx5cm6bx8z5py1y@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13193103,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-5447052-2525922',
    buyer: 'dx5cm6bx8z5py1y@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13193106,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-3626108-1005119',
    buyer: '18s60x6qb75gdc6@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13194168,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-8515020-2806116',
    buyer: '18s60x6qb75gdc6@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195516,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-9308872-2094785',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195637,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-4468213-2499320',
    buyer: '18s60x6qb75gdc6@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195653,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-4659021-8774236',
    buyer: 'xbs186x6vd56ykx@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195874,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-9016260-7126431',
    buyer: 'mk76nk89sj9yh37@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195912,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-2320301-7647756',
    buyer: 'xzn8chmsdlw7w4h@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195913,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-6991487-8748005',
    buyer: 'hkcr306zfzsn0c1@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195946,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-0074559-7730802',
    buyer: '2bh96thw8dflgbr@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195968,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-0560175-9876634',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195972,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-1712719-6076763',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195983,
    sent: '2024-10-30T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-0511913-0668717',
    buyer: '0jtcjn84xhjh90y@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195989,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-1899500-2888028',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195994,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-3512810-0274679',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13195996,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-8774546-9246149',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196015,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-4012415-0077512',
    buyer: '7bmkrdp9swz0dnq@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196021,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-0271640-8713998',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196024,
    sent: '2024-10-28T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-9218131-8624445',
    buyer: 'qqvttmvjkhhfkz2@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196026,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-4575993-4254135',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196047,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-1263062-4071680',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196059,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-2492514-5709658',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196062,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-1234843-4063344',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196064,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-8910407-4538697',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196085,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-7668979-1661978',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196087,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-5677956-2225532',
    buyer: 'w5y1m7f8yq1nrq7@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196088,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-0566314-0580199',
    buyer: '9zs0y3w5j9xvh5d@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196089,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-2354844-3102342',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196090,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-7594698-0565822',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196091,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-7064804-2226385',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196093,
    sent: '2024-10-28T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-6475657-0600990',
    buyer: '09182rssszdb3kv@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196112,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-9503457-4439871',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196113,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-2027236-4284423',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196116,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-6917732-2759854',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196117,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-8969553-4059084',
    buyer: '9k33vz0r7xtpvqy@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196122,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-7566171-2854041',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196123,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-9049578-6881213',
    buyer: 'tct9dv5qs7l2mkd@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196127,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-1394691-5286886',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196129,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-0272269-5854124',
    buyer: 'cb15lq9zfv1nrrh@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196130,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '141-7206782-4261370',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196131,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-4604943-4212642',
    buyer: '4qdpk8jss72qxzs@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196132,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-5694486-6708276',
    buyer: 'gc3wk7qwgvwrpq6@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196138,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-3134313-7416376',
    buyer: 'gn1przz299gm4wk@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196145,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-5653429-7856130',
    buyer: 'l9bqclgb65mk7dz@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196146,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-0878121-2586449',
    buyer: '3q2rypzn2syjtqz@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196147,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-3627556-1875662',
    buyer: 'xj55s5x1zr0jkxq@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196149,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-4698679-7631464',
    buyer: 'hqvcnbdrfppn8jk@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196150,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-2769871-2129217',
    buyer: 'd1kn34gfv79y2vv@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196151,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-9088388-8841652',
    buyer: 'dgvydjld31rz3d8@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196153,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-1995975-3504701',
    buyer: '9jjz3yjcsxtj8k2@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196154,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-1777525-1042631',
    buyer: '3chk8zhprn8gnyy@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196156,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-1767242-5778721',
    buyer: 'g06kdx6ygn7lr4c@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196157,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-8691320-0752576',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196158,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-0031541-6368325',
    buyer: 'l4j1cfzxs0m025z@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196159,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-0395902-2692859',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196160,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-8622899-6344033',
    buyer: 'nll5l73fj0h3zmp@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196161,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-2936155-5050412',
    buyer: 'mczrt6cjwkqgl5w@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196162,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-1210781-2207581',
    buyer: 'qqkg27h6yhl728n@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196163,
    sent: '2024-10-28T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-9386274-6484626',
    buyer: 'wbs3vg3jm2scskp@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196173,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-8297885-3688591',
    buyer: 'hv51qf31zj10rhn@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196174,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-6892239-7658951',
    buyer: '9c5841cpc65n6y0@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196175,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-4663157-9071937',
    buyer: 'zffdrcfmbvlsyr2@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196176,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-9261798-3347398',
    buyer: 'bwqsd44mgk1bx0g@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196177,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-3509080-0013001',
    buyer: 'dcwgx99n36yqfpr@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196178,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-1167746-4323114',
    buyer: 't391c4nry9dqv4b@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196179,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-2478993-4018753',
    buyer: '4k3c9fw5440gclz@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196180,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-2019311-6866394',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196182,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-6346105-0662904',
    buyer: 'thxt5lthnp8xy2x@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196183,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-4078036-0485186',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196192,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-4083120-2229710',
    buyer: 'flpgqrsz8v7vz5n@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196193,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-3600805-1763226',
    buyer: 'l5qycphcb2l4t5c@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196194,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-3420177-2402472',
    buyer: 'f78w0j7c461wdv7@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196196,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-6066161-8213229',
    buyer: 'qztchrsbvtncdqf@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196197,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-7298426-9559430',
    buyer: 'jdbzcmwbk24y6zs@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196199,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-3464347-3308269',
    buyer: 'd20r3rd2dy4bzs1@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196201,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-3393287-5464153',
    buyer: '4t8fd9c6hjcw4px@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196203,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-1354030-3636684',
    buyer: 'pqqnmp4wl5nq0d3@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196204,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-0702164-6224528',
    buyer: 'zg1pnldcdry4f6z@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196206,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-4870721-4424274',
    buyer: 'gy86zntwz2ctnct@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196207,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-6856168-4824722',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196208,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-4636620-5672246',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196209,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-3983256-1491818',
    buyer: 'zqk4f1vp4nr9zng@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196212,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-0718757-0646275',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196213,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-0080824-2003132',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196214,
    sent: '2024-10-25T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '114-9736864-6541904',
    buyer: '0tcmxhn197x5z7y@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196220,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-4199218-2658711',
    buyer: 'pp56477v4rtlqh2@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196221,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-0195390-2442050',
    buyer: 'h8vn2lk38cyfmmn@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196222,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '141-6156963-1332098',
    buyer: 'rn1m59v4mc1spp2@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196223,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-2779907-7746460',
    buyer: '8st2s6w63bfc993@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196224,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-5031644-0140566',
    buyer: 'f9mwdp193f8bhtb@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196225,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-9746995-4081321',
    buyer: '3d2cpn0n88l938m@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196226,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-2922838-8116940',
    buyer: '9x7b23n856m8w3f@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196227,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-7909532-3057959',
    buyer: '3fjjcfz31kk1h8d@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196228,
    sent: '2024-10-30T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-8401937-9464228',
    buyer: '9jz0cp37h2x486f@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196229,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-0098723-1231542',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196230,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-5853375-4606386',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196231,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-6076143-4266936',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196233,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-3168069-0110980',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196234,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-4070684-8806359',
    buyer: 'hlgw2c3ghm064lc@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196235,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-5286723-6598062',
    buyer: 'tbnnc56j51ydyt6@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196241,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-7153005-2055314',
    buyer: 'n6qd8wmzznsxcdc@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196244,
    sent: '2024-10-26T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '141-1003364-7320546',
    buyer: 'smxw7n468twn0xh@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196245,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-0896472-0654700',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196246,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-3595753-4364072',
    buyer: 'tbnnc56j51ydyt6@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196247,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-1603031-1513425',
    buyer: 'rgpvthrn860rgx3@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196248,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-7584494-1131476',
    buyer: 'mrhxzh8l9pp8bxt@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196249,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-0623000-6564394',
    buyer: 'r92t47g7wqtdr4m@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196250,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-2312813-0818787',
    buyer: 'fzn6msy7s669mbg@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196251,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '113-5387045-1047660',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196252,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-5558959-8150445',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196253,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-6659787-5303828',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196254,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-1344106-2140142',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196255,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-7850266-6693672',
    buyer: '1y38wtw5bhjfhhy@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196256,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-7920743-1739401',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196257,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-7874743-0465216',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196258,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-0186884-7760126',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196264,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-9349435-6214132',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196265,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '141-7120745-6540276',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196266,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-4084036-6013819',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196267,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-1708308-9849471',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196268,
    sent: '2024-10-28T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '112-1528031-2084072',
    buyer: '5qyv9vl36g7y25h@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196269,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-9910986-1900554',
    buyer: 'kcm570qrzh8lb19@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196270,
    sent: '2024-10-28T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-7782054-4767481',
    buyer: 'wj85858lfyrvr9k@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196273,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-2419981-3389754',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196275,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-0779456-8554990',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196277,
    sent: '2024-10-28T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-6418116-4449469',
    buyer: 'w03pnj37cp63m13@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196278,
    sent: '2024-10-27T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-8715031-2902961',
    buyer: '0ym0cj24m6z6zmv@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196279,
    sent: '2024-10-28T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-0376970-4146792',
    buyer: 'm5xrwx2mtv82gkq@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196280,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-7662851-2050358',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196281,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-1770267-7730366',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196282,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-6612516-9831573',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196283,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '131-1971229-0721125',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196286,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-4688045-4363357',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196287,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-9526908-5898053',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196288,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-4076814-1402977',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196289,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '141-0810230-2895174',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196290,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '211-6523703-7009649',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196300,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-3432252-8626223',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196301,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '111-1511467-2856636',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196302,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '311-1960598-2687940',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196303,
    sent: {
      schedule_event: 3,
      schedule_after: 5
    },
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '121-3837699-5796347',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196304,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2648,
    paused: false,
    message: 2693,
    request_review: true,
    amazon_order_id: '411-2340598-8201236',
    buyer: 'f1n39fybts5qdb2@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    asin: 'H515DFT59R',
    product_name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage'
  },
  {
    id: 13196022,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '131-6384385-2852568',
    buyer: 'tb4l42ffh59gfyq@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196048,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '141-8045218-2095535',
    buyer: 'db1p5vj5rj0c441@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196077,
    sent: '2024-10-23T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '411-9801809-3568657',
    buyer: '5zcrwxmy30zm5xk@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196092,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '131-3496260-1436144',
    buyer: 'jvqfsw52rqwzc6p@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196105,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '111-2959884-6968422',
    buyer: 'rs2m0y7s6l3vzzb@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196111,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '141-3403177-0502590',
    buyer: '6pr1cp4crj8g0j7@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196124,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '114-8168852-1710445',
    buyer: 'w9d05fwj395j9yz@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196126,
    sent: '2024-10-24T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '111-1337965-8540285',
    buyer: '8fy0clymrpkty8z@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196136,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '141-6238276-1866836',
    buyer: 'pc6lf343tv26574@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196137,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '121-6259405-2080416',
    buyer: '5khpwfs57t3rps8@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196152,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '112-1763162-0643890',
    buyer: 'qykb6r6t0zqy9zg@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196181,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '114-2327966-2848601',
    buyer: 'lc4yxkdz9pl46td@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196195,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '131-7793242-3250883',
    buyer: 'mvg91jvgl30ncss@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196198,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '112-9817562-0600859',
    buyer: 'y75k0qgkbx95ryx@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196202,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '311-7502295-3570905',
    buyer: 'tw615k6lv82lcf5@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196205,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '131-0316522-4567274',
    buyer: 'w8vn3yzlbl2qtb7@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196210,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '311-3628414-6770885',
    buyer: '7w04s4s945b66lv@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196211,
    sent: '2024-10-29T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '112-9490018-0229600',
    buyer: '2wtcx38tkl0sbtv@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196219,
    sent: '2024-10-30T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '113-6663304-0395412',
    buyer: 'j66cxk4d61mv8pm@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196236,
    sent: '2024-10-30T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '311-8034184-0645101',
    buyer: '07dm52wlg09922g@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196271,
    sent: '2024-10-30T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '113-4492977-2760169',
    buyer: 'yh5bfhtm3qp6xth@marketplace.amazon.com',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196272,
    sent: '2024-10-30T00:00:00Z',
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '131-7142331-2541650',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196274,
    sent: {
      schedule_event: 3,
      schedule_after: 7
    },
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '111-7493374-8951422',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196284,
    sent: {
      schedule_event: 3,
      schedule_after: 7
    },
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '111-3489671-8146407',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  },
  {
    id: 13196285,
    sent: {
      schedule_event: 3,
      schedule_after: 7
    },
    campaign: 2654,
    paused: false,
    message: 2674,
    request_review: true,
    amazon_order_id: '121-9161479-9640919',
    buyer: '',
    product_image:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    asin: 'A554K46TJL',
    product_name:
      'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical'
  }
];

const dynamicVariablesDB = {
  '{{product-title}}': {
    desc: 'Will dynamically input the product title.',
    preview: 'product 7503682'
  },
  '{{order-id}}': {
    desc: 'Will dynamically input the order ID.',
    preview: '123-4567890-1234567'
  },
  '{{product-review-link: Leave a review by clicking here}}': {
    desc: 'Link it to the product review with editable text.',
    preview:
      'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682'
  },
  '{{product-title-review-link}}': {
    desc: 'This will dynamically input the title of the product and link it to the product review.',
    preview:
      'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682'
  },
  '{{feedback-link: Leave feedback by clicking here}}': {
    desc: 'This will create a link to leave feedback for the seller with a dynamic text.',
    preview: 'https://www.amazon.com/hz/feedback/?_encoding=UTF8&orderID=111-5685561-2010022'
  },
  '{{product-list}}': {
    desc: 'Will insert the product name as a list.',
    preview: ['product 7503682', 'product 7503683']
  },
  '{{product-title-review-link-list}}': {
    desc: 'Will insert a list of\u00a0the product titles with a review.',
    preview: [
      {
        link:
          'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682',
        title: 'product 7503682'
      },
      {
        link:
          'https://www.amazon.com/review/review-your-purchases/ref=?_encoding=UTF8&asins=asin_7503682',
        title: 'product 7503682'
      }
    ]
  }
};

mock.onGet(API.emailTool.dynamicVariables).reply(() => {
  return [200, dynamicVariablesDB];
});

mock.onGet(API.emailTool.messageTemplates).reply(() => {
  return [200, messageTemplatesDB];
});

mock.onGet(API.emailTool.campaigns).reply(() => {
  return [200, campaignsDB];
});

mock.onGet(API.emailTool.messages).reply(() => {
  return [200, messagesDB];
});
