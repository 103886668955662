import React from 'react';
import PropTypes from 'prop-types';
import { Popover as MuiPopover } from '@material-ui/core';

const Popover = ({ children, config, anchorOrigin, transformOrigin, ...other }) => {
  return (
    <MuiPopover
      open={!!config.target}
      onClose={config.clearTarget}
      anchorEl={config.target}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...other}
    >
      {children}
    </MuiPopover>
  );
};

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.instanceOf(Node), PropTypes.instanceOf(Object)])
    .isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  transformOrigin: PropTypes.instanceOf(Object),
  anchorOrigin: PropTypes.instanceOf(Object)
};

Popover.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

export default Popover;
