/* eslint-disable no-param-reassign */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Colors from 'styles/colors';
import ProductCard from '@components/products/product-card';
import SearchIcon from '@components/icons/ic_search.svg';
import { isEmpty } from '@utils';
import { searchProductsAction } from 'seller/store/actions';

const minKeywordLength = 3;

const hideElement = () => ({
  display: 'none'
});

const customStyles = {
  option: provided => ({
    ...provided,
    padding: '1rem',
    background: Colors.white.default,
    color: Colors.gray.default
  }),
  control: () => ({
    display: 'flex',
    width: '100%',
    height: '3rem'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  indicatorSeparator: hideElement,
  loadingIndicator: hideElement,
  dropdownIndicator: hideElement
};

function NoteDialogInput({ value, onChange, initTag, marketplaceID }) {
  const dispatch = useDispatch();
  function convertToOptions(products) {
    if (isEmpty(products)) {
      return [];
    }

    products.brands.forEach(object => {
      object.type = 'brand';
    });
    products.parent_products.forEach(object => {
      object.type = 'parent';
    });
    products.products.forEach(object => {
      object.type = object.variation_type_one ? 'variation' : 'default';
    });
    return (
      products.brands && [...products.brands, ...products.parent_products, ...products.products]
    );
  }

  function loadOptions(keyword, callback) {
    if (keyword.length < minKeywordLength) {
      callback([]);
      return;
    }
    dispatch(
      searchProductsAction(
        { keyword, marketplaceID },
        result => callback(convertToOptions(result), () => callback([])),
        () => callback([], () => callback([]))
      )
    );
  }

  return (
    <div className="flex items-center w-full justify-center border-gray-default my-2 rounded">
      <img src={SearchIcon} alt="search" />
      <AsyncSelect
        defaultOptions
        className="z-9999 text-sm font-normal w-11/12"
        styles={customStyles}
        placeholder="Product/Brand"
        formatOptionLabel={props => <ProductCard {...props} />}
        loadOptions={loadOptions}
        onChange={onChange}
        isClearable
        value={value}
        noOptionsMessage={() => null}
        defaultValue={initTag}
      />
    </div>
  );
}

NoteDialogInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  initTag: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool])
};

NoteDialogInput.defaultProps = {
  initTag: null
};

export default NoteDialogInput;
