/* eslint-disable no-param-reassign */
export const getMenuItem = (title, callback) => ({ title, callback });

export const toggleValueInArray = (array, value) => {
  if (!array) return array;
  if (array.includes(value)) {
    return array.filter(item => item !== value);
  }
  return [...array, value];
};

// FIXME: find another way to disable autocomplete
export const disableInputsAutoFill = () => {
  const tagArray = [...document.getElementsByTagName('input')];
  tagArray.forEach(tag => {
    const fakeAutofillTag = Date.now();
    tag.autocomplete = fakeAutofillTag;
    tag.name = fakeAutofillTag;
  });
};
