import API from '@sm/api';
import mock from '../mock';

const data = {
  orders: [
    {
      asin: 'A554K46TJL',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-19T21:56:41Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      sku: 'BU-4HE3-PV122',
      orderItemId: '108722123864041',
      orderId: '114-1028113-1686219',
      status: null,
      reimbursement_return: null,
      return_quantity: 0,
      return_date: null,
      customer_comments: null,
      reason: null,
      returned_to_inventory: 'No',
      disposition: null,
      recordId: '114-1028113-1686219',
      refund_value: 35.16
    },
    {
      asin: 'H515DFT59R',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-19T18:18:00Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      sku: 'GG-51B4-DTG2',
      orderItemId: '112026954415841',
      orderId: '211-0607220-3424285',
      status: null,
      reimbursement_return: null,
      return_quantity: 0,
      return_date: null,
      customer_comments: null,
      reason: null,
      returned_to_inventory: 'No',
      disposition: null,
      recordId: '211-0607220-3424285',
      refund_value: 0.0
    },
    {
      asin: 'A554K46TJL',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-18T01:44:33Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      sku: 'BU-4HE3-PV122',
      orderItemId: '109747546815561',
      orderId: '113-5951146-2546910',
      status: null,
      reimbursement_return: null,
      return_quantity: 0,
      return_date: null,
      customer_comments: null,
      reason: null,
      returned_to_inventory: 'No',
      disposition: null,
      recordId: '113-5951146-2546910',
      refund_value: 35.16
    },
    {
      asin: 'H515DFT59R',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-16T19:19:31Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      sku: 'GG-51B4-DTG2',
      orderItemId: '110027629208201',
      orderId: '411-2216699-2318749',
      status: null,
      reimbursement_return: null,
      return_quantity: 0,
      return_date: null,
      customer_comments: null,
      reason: null,
      returned_to_inventory: 'No',
      disposition: null,
      recordId: '411-2216699-2318749',
      refund_value: 19.77
    },
    {
      asin: 'H515DFT59R',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-15T11:15:52Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      sku: 'GG-51B4-DTG2',
      orderItemId: '111497022766801',
      orderId: '411-9925868-4438217',
      status: null,
      reimbursement_return: null,
      return_quantity: 0,
      return_date: null,
      customer_comments: null,
      reason: null,
      returned_to_inventory: 'No',
      disposition: null,
      recordId: '411-9925868-4438217',
      refund_value: 21.97
    },
    {
      asin: 'H515DFT59R',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-14T17:35:05Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      sku: 'GG-51B4-DTG2',
      orderItemId: '111254677754281',
      orderId: '111-8351761-4567272',
      status: null,
      reimbursement_return: null,
      return_quantity: 0,
      return_date: null,
      customer_comments: null,
      reason: null,
      returned_to_inventory: 'No',
      disposition: null,
      recordId: '111-8351761-4567272',
      refund_value: 0.0
    },
    {
      asin: 'H515DFT59R',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-09T23:41:35Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      sku: 'GG-51B4-DTG2',
      orderItemId: '109412989290681',
      orderId: '114-6827443-5204579',
      status: 'Unit returned to inventory',
      reimbursement_return: null,
      return_quantity: 1,
      return_date: '2024-10-09T20:08:47Z',
      customer_comments: '',
      reason: 'UNDELIVERABLE_UNKNOWN',
      returned_to_inventory: 'Yes',
      disposition: 'SELLABLE',
      recordId: '114-6827443-5204579',
      refund_value: 21.97
    },
    {
      asin: 'A554K46TJL',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-07T19:08:42Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      sku: 'BU-4HE3-PV122',
      orderItemId: '110540667679041',
      orderId: '114-1595184-4248801',
      status: 'Unit returned to inventory',
      reimbursement_return: null,
      return_quantity: 1,
      return_date: '2024-10-13T07:50:21Z',
      customer_comments: 'Too dark useless',
      reason: 'UNWANTED_ITEM',
      returned_to_inventory: 'Yes',
      disposition: 'SELLABLE',
      recordId: '114-1595184-4248801',
      refund_value: 35.16
    },
    {
      asin: 'H515DFT59R',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-10-04T18:34:03Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      sku: 'GG-51B4-DTG2',
      orderItemId: '108735870857961',
      orderId: '113-2702058-7150085',
      status: 'Unit returned to inventory',
      reimbursement_return: null,
      return_quantity: 1,
      return_date: '2024-10-11T20:19:27Z',
      customer_comments: '',
      reason: 'UNDELIVERABLE_UNKNOWN',
      returned_to_inventory: 'Yes',
      disposition: 'SELLABLE',
      recordId: '113-2702058-7150085',
      refund_value: 19.77
    },
    {
      asin: 'A554K46TJL',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-09-30T19:15:06Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      sku: 'BU-4HE3-PV122',
      orderItemId: '109913681207641',
      orderId: '114-0016495-0538842',
      status: 'IMMEDIATE_DONATION',
      reimbursement_return: null,
      return_quantity: 1,
      return_date: '2024-10-03T13:30:08Z',
      customer_comments: '',
      reason: 'DEFECTIVE',
      returned_to_inventory: 'No',
      disposition: 'CUSTOMER_DAMAGED',
      recordId: '114-0016495-0538842',
      refund_value: 35.16
    },
    {
      asin: 'A554K46TJL',
      quantity_refunded: 2,
      marketplace: 3,
      latest_refund_timestamp: '2024-09-30T01:15:27Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      sku: 'BU-4HE3-PV122',
      orderItemId: '109301381278401',
      orderId: '111-8295071-4635403',
      status: 'Unit returned to inventory',
      reimbursement_return: null,
      return_quantity: 2,
      return_date: '2024-10-07T06:22:59Z',
      customer_comments: 'Don’t need',
      reason: 'UNWANTED_ITEM',
      returned_to_inventory: 'Yes',
      disposition: 'SELLABLE',
      recordId: '111-8295071-4635403',
      refund_value: 70.32,
      is_parent: true,
      type: 'parent',
      children: [
        {
          asin: 'A554K46TJL',
          quantity_refunded: 1,
          marketplace: 3,
          latest_refund_timestamp: '2024-09-30T01:15:27Z',
          smallImage:
            'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
          name:
            'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
          sku: 'BU-4HE3-PV122',
          orderItemId: '109301381278401',
          orderId: '111-8295071-4635403',
          status: 'Unit returned to inventory',
          reimbursement_return: null,
          return_quantity: 1,
          return_date: '2024-10-07T06:22:59Z',
          customer_comments: 'Don’t need',
          reason: 'UNWANTED_ITEM',
          returned_to_inventory: 'Yes',
          disposition: 'SELLABLE',
          recordId: '111-8295071-4635403',
          refund_value: null,
          type: 'child'
        },
        {
          asin: 'A554K46TJL',
          quantity_refunded: 1,
          marketplace: 3,
          latest_refund_timestamp: '2024-09-30T01:15:27Z',
          smallImage:
            'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
          name:
            'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
          sku: 'BU-4HE3-PV122',
          orderItemId: '109301381278401',
          orderId: '111-8295071-4635403',
          status: 'Unit returned to inventory',
          reimbursement_return: null,
          return_quantity: 1,
          return_date: '2024-10-07T06:22:59Z',
          customer_comments: 'Don’t need',
          reason: 'UNWANTED_ITEM',
          returned_to_inventory: 'Yes',
          disposition: 'SELLABLE',
          recordId: '111-8295071-4635403',
          refund_value: null,
          type: 'child'
        }
      ]
    },
    {
      asin: 'A554K46TJL',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-09-26T01:45:53Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      sku: 'BU-4HE3-PV122',
      orderItemId: '108113830975441',
      orderId: '411-2657970-5534607',
      status: 'Unit returned to inventory',
      reimbursement_return: null,
      return_quantity: 1,
      return_date: '2024-09-30T09:18:04Z',
      customer_comments: 'Doesn’t connect as easily to glasses and I wanted',
      reason: 'UNWANTED_ITEM',
      returned_to_inventory: 'Yes',
      disposition: 'SELLABLE',
      recordId: '411-2657970-5534607',
      refund_value: 35.16
    },
    {
      asin: 'H515DFT59R',
      quantity_refunded: 1,
      marketplace: 3,
      latest_refund_timestamp: '2024-09-25T18:49:58Z',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      sku: 'GG-51B4-DTG2',
      orderItemId: '109964096857801',
      orderId: '211-2290264-5079485',
      status: null,
      reimbursement_return: null,
      return_quantity: 0,
      return_date: null,
      customer_comments: null,
      reason: null,
      returned_to_inventory: 'No',
      disposition: null,
      recordId: '211-2290264-5079485',
      refund_value: 0.0
    }
  ]
};

mock.onGet(API.refunds.list).reply(() => {
  return [200, data];
});
