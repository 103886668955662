import React from 'react';
import PropTypes from 'prop-types';
import { endOfYear, format, getDayOfYear, getYear, startOfDay, subDays, isEqual } from 'date-fns';
import { isAllMonthSelected } from './snippets';

const TOP_ROW_DATE_FORMAT = 'MMM d, yyyy';
const TOP_ROW_DATE_NO_YEAR = 'MMM d';
const TOP_ROW_MONTH_FORMAT = 'MMMM, yyyy';
const TOP_ROW_YEAR_FORMAT = 'yyyy';

const DatePickerWidgetContent = ({ range, today, separator, skipWrapper }) => {
  const getResult = () => {
    const { from, to } = range;
    if (
      isEqual(startOfDay(from), startOfDay(today)) &&
      isEqual(startOfDay(to), startOfDay(today))
    ) {
      return `Today, ${format(from, TOP_ROW_DATE_NO_YEAR)}`;
    }
    if (
      isEqual(startOfDay(from), startOfDay(subDays(today, 1))) &&
      isEqual(startOfDay(to), startOfDay(subDays(today, 1)))
    ) {
      return `Yesterday, ${format(from, TOP_ROW_DATE_NO_YEAR)}`;
    }
    if (
      getDayOfYear(from) === 1 &&
      getYear(from) === getYear(to) &&
      (getDayOfYear(to) === getDayOfYear(today) || isEqual(endOfYear(to), to))
    ) {
      return format(from, TOP_ROW_YEAR_FORMAT);
    }
    if (isAllMonthSelected(range)) return format(to, TOP_ROW_MONTH_FORMAT);
    if (isEqual(startOfDay(from), startOfDay(to))) return format(to, TOP_ROW_DATE_FORMAT);

    return (
      <>
        <div className="inline-flex items-center justify-start">
          <div>
            {format(
              from,
              getYear(from) === getYear(to) ? TOP_ROW_DATE_NO_YEAR : TOP_ROW_DATE_FORMAT
            )}
          </div>
          <div>{separator}</div>
        </div>
        <br className="inline xxs:hidden" />
        <span>{format(to, TOP_ROW_DATE_FORMAT)}</span>
      </>
    );
  };
  if (skipWrapper) return getResult();
  return <span>{getResult()}</span>; // Wrapper applied to workaround the issue https://github.com/facebook/react/issues/11538#issuecomment-390386520
};

DatePickerWidgetContent.propTypes = {
  range: PropTypes.instanceOf(Object).isRequired,
  today: PropTypes.instanceOf(Date).isRequired,
  separator: PropTypes.node,
  skipWrapper: PropTypes.bool
};

DatePickerWidgetContent.defaultProps = {
  separator: <span className="mx-1">-</span>,
  skipWrapper: false
};

export default DatePickerWidgetContent;
