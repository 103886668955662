import React from 'react';
import NewTabReference from '@components/common/NewTabReference';
import { WebImg } from '@components';
import { appStoreWebImg, playMarketWebImg } from './img/markets';
import platformsWebImg from './img/platforms';

export const APPSTORE_LINK = 'https://apps.apple.com/sg/app/seller-metrix/id1435738191';
export const PLAYMARKET_LINK = 'https://play.google.com/store/apps/details?id=com.sellermetrix';
export const CHROME_EXT_LINK =
  'https://chrome.google.com/webstore/detail/seller-metrix/onhmpeijafobjmdfjlgjocnopaepmodl';

function Platforms() {
  return (
    <div className="w-11/12 flex flex-col md:flex-row items-center justify-between md:my-24 xl:my-32">
      <WebImg
        {...platformsWebImg}
        className="w-full md:w-7/12 md:sm-h-400px mb-8 md:mb-0 mt-16 flex justify-center items-center flex-shrink-0"
        imgClassName="max-h-full"
        mobileMediaQuery="(max-width:500px)"
      />
      <div className="flex flex-col md:pl-16">
        <h2 className="text-black font-bold text-2xl md:text-3xl lg:sm-text-32px">
          For Desktop, Tablet & Mobile
        </h2>
        <p className="text-gray-dark text-lg md:text-xl tracking-tight mt-4 md:mt-6 leading-snug">
          Seller Metrix is accessible on all devices so you have access to your Amazon sales data
          wherever you are. Find our Amazon Seller App for mobile phones, available on both Android
          and iOS.
        </p>
        <div className="flex mt-8 flex-wrap">
          <NewTabReference href={APPSTORE_LINK}>
            <WebImg {...appStoreWebImg} className="w-40 mr-4 block mt-2" alt="app-store" />
          </NewTabReference>
          <NewTabReference href={PLAYMARKET_LINK}>
            <WebImg {...playMarketWebImg} className="w-40 block mt-2" alt="play-market" />
          </NewTabReference>
        </div>
      </div>
    </div>
  );
}

export default Platforms;
