import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

function GradButton(props) {
  const { children, className, onClick, active, paddingY, grad, ...otherProps } = props;
  const fixHeight = className.match(/h-\d+/);
  return (
    <Button
      className={classNames(
        'text-white normal-case rounded font-medium px-4 cursor-pointer',
        {
          'sm-button-yellow-shadow hover:shadow-grad-one': active,
          [grad]: active,
          'bg-gray': !active,
          'leading-none py-0': fixHeight,
          [paddingY]: !fixHeight
        },
        className
      )}
      onClick={onClick}
      disabled={!active}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

GradButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  grad: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  paddingY: PropTypes.string
};

GradButton.defaultProps = {
  grad: 'bg-grad-2',
  className: '',
  onClick: () => {},
  active: true,
  paddingY: 'py-2'
};

export default GradButton;
