export const SET_NOTES = 'SET_NOTES';
export const PULL_NOTES = 'PULL_NOTES';
export const PULL_SALES = 'PULL_SALES';
export const SET_SALES = 'SET_SALES';
export const PULL_PRIMARY_CHART_DATA = 'PULL_PRIMARY_CHART_DATA';
export const PULL_SECONDARY_CHART_DATA = 'PULL_SECONDARY_CHART_DATA';
export const SET_TOTALS = 'SET_TOTALS';
export const SET_REFUNDS_TOTALS = 'SET_REFUNDS_TOTALS';
export const PULL_TOTALS = 'PULL_TOTALS';
export const SET_DASHBOARD_ORDER_EVENT_TYPE = 'SET_DASHBOARD_ORDER_EVENT_TYPE';

export const setNotesAction = data => ({
  type: SET_NOTES,
  data
});
export const pullNotesAction = payload => ({
  type: PULL_NOTES,
  payload
});

export const pullSalesAction = params => ({
  type: PULL_SALES,
  params
});

export const setSalesAction = sales => ({
  type: SET_SALES,
  sales
});

export const pullPrimaryChartDataAction = payload => ({
  type: PULL_PRIMARY_CHART_DATA,
  payload
});

export const pullSecondaryChartDataAction = payload => ({
  type: PULL_SECONDARY_CHART_DATA,
  payload
});

export const setRefundsTotalsAction = totals => ({
  type: SET_REFUNDS_TOTALS,
  totals
});

export const setTotalsAction = totals => ({
  type: SET_TOTALS,
  totals
});

export const pullTotalsAction = payload => ({
  type: PULL_TOTALS,
  payload
});

export const setDashboardOrderEventTypeAction = payload => ({
  type: SET_DASHBOARD_ORDER_EVENT_TYPE,
  payload
});
