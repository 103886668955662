import { isBefore, isAfter, subMonths, addMonths, startOfMonth, endOfMonth } from 'date-fns';

const getArrowDisabled = (compareDate, month, shift, isLeftArrow) =>
  isLeftArrow
    ? isAfter(startOfMonth(compareDate), startOfMonth(subMonths(month, shift)))
    : isBefore(endOfMonth(compareDate), startOfMonth(addMonths(month, shift)));

const arrowsDisableCheck = (lifeTime, today, month, twoCalendarsView) => ({
  singleLeft: getArrowDisabled(lifeTime, month, 1, true),
  doubleLeft: getArrowDisabled(lifeTime, month, 2, true),
  singleRight: getArrowDisabled(today, month, twoCalendarsView ? 2 : 1, false),
  doubleRight: getArrowDisabled(today, month, twoCalendarsView ? 3 : 2, false)
});

export default arrowsDisableCheck;
