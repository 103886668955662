import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import IconsMenu from '@components/common/IconsMenu';
import ROUTES from 'seller/routing';

const UserMenu = withRouter(({ history, anchorEl, onClose, logout, subscription, menuItems }) => {
  if (!subscription) {
    return null;
  }

  function onOptionClick(title) {
    onClose();

    const result = menuItems.find(item => item.title === title);
    if (!result) {
      return;
    }
    if (result.link) {
      history.push(result.link);
    }
    if (result.logout) {
      logout();
      history.push(ROUTES.root);
    }
  }

  const selected = menuItems.find(item => item.link === history.location.pathname);

  return (
    <IconsMenu
      className="mt-6"
      selected={selected && selected.title}
      menuList={menuItems}
      onOptionClick={onOptionClick}
      onClose={onClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    />
  );
});

UserMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.instanceOf(Object)
};

UserMenu.defaultProps = {
  open: false,
  subscription: {}
};

export default UserMenu;
