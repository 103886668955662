import API from '@sm/api';
import mock from '../mock';
import { getRandomArbitrary } from '../utils';

let reports = [
  {
    id: 1,
    name: 'report123123',
    status: 'completed',
    request_date: Date.now() - 1000000,
    type: 'profit-and-loss',
    start: '2021-05-01',
    end: '2021-09-24',
    marketplace_id: 3
  },
  {
    id: 11,
    name: 'report333333',
    status: 'failed',
    request_date: Date.now() - 5000000,
    type: 'profit-and-loss',
    start: '2020-08-12',
    end: '2020-10-20',
    marketplace_id: 5
  },
  {
    id: 12,
    name: 'report333333',
    status: 'completed',
    request_date: Date.now() - 5000000,
    type: 'daily-sales',
    start: '2020-08-12',
    end: '2020-10-20',
    marketplace_id: 5
  },
  {
    id: 1,
    name: 'report333321333',
    status: 'in_progress',
    request_date: Date.now() - 5000000,
    type: 'daily-sales',
    start: '2020-08-12',
    end: '2020-10-20',
    marketplace_id: 5
  }
];
const columns = [
  '2020-11-30',
  '2020-12-31',
  '2021-01-31',
  '2021-02-28',
  '2021-03-31',
  '2021-04-30',
  '2021-05-31',
  '2021-06-30',
  '2021-07-31',
  '2021-08-31',
  '2021-09-24'
];
const getFakeColumns = (decimalScale = 2) =>
  columns.reduce(
    (acc, month) => ({
      ...acc,
      [month]: +getRandomArbitrary(!decimalScale ? 0 : -100, 100).toFixed(decimalScale)
    }),
    {}
  );

const getMkPnLData = mk => ({
  id: mk,
  data: {
    'Units Sold': {
      'product_id-1': {
        ...getFakeColumns(0),
        'meta-data': {
          url: 'https://www.amazon.com/dp/RO2LASG3QC',
          asin: 'RO2LASG3QC',
          sku: 'product_SKU-1'
        }
      },
      'product_id-2': {
        ...getFakeColumns(0),
        'meta-data': {
          url: 'https://www.amazon.com/dp/RO2LASG32C',
          asin: 'RO2LASG32C',
          sku: 'product_SKU-2'
        }
      },
      'product_id-3': {
        ...getFakeColumns(0),
        'meta-data': {
          url: 'https://www.amazon.com/dp/RO2LASG33C',
          asin: 'RO2LASG33C',
          sku: 'product_SKU-3'
        }
      }
    },
    Income: {
      'Product sales (non-FBA)': getFakeColumns(),
      'Product sale refunds (non-FBA)': getFakeColumns(),
      'FBA product sales': getFakeColumns(),
      'FBA product sale refunds': getFakeColumns(),
      'FBA inventory credit': getFakeColumns(),
      'FBA liquidation proceeds': getFakeColumns(),
      'FBA Liquidations proceeds adjustments': getFakeColumns(),
      'Shipping credits': getFakeColumns(),
      'Shipping credit refunds': getFakeColumns(),
      'Gift wrap credits': getFakeColumns(),
      'Gift wrap credit refunds': getFakeColumns(),
      'Promotional rebates': getFakeColumns(),
      'Promotional rebate refunds': getFakeColumns(),
      'A-to-z Guarantee claims': getFakeColumns(),
      Chargebacks: getFakeColumns(),
      'Amazon Shipping Reimbursement': getFakeColumns(),
      'SAFE-T reimbursement': getFakeColumns()
    },
    'Amazon Expenses': {
      'Seller fulfilled selling fees': getFakeColumns(),
      'FBA selling fees': getFakeColumns(),
      'Selling fee refunds': getFakeColumns(),
      'FBA transaction fees': getFakeColumns(),
      'FBA transaction fee refunds': getFakeColumns(),
      'Other transaction fees': getFakeColumns(),
      'Other transaction fee refunds': getFakeColumns(),
      'FBA inventory and inbound services fees': getFakeColumns(),
      'Shipping label purchases': getFakeColumns(),
      'Shipping label refunds': getFakeColumns(),
      'Carrier shipping label adjustments': getFakeColumns(),
      'Service fees': getFakeColumns(),
      'Refund administration fees': getFakeColumns(),
      Adjustments: getFakeColumns(),
      'Cost of Advertising': getFakeColumns(),
      'Refund of Advertising': getFakeColumns(),
      'Liquidation fees': getFakeColumns()
    },
    'Other Expenses': {
      'Custom expanse first': getFakeColumns(),
      'Custom expanse second': getFakeColumns()
    }
  },
  product_costs: [
    {
      url: 'https://www.amazon.com/dp/RO2LASG3QC',
      asin: 'RO2LASG3QC',
      seller_sku: 'product_SKU-1',
      unit_cost: 5,
      unit_shipping_cost: 2,
      fbm_shipping_cost: 80
    },
    {
      url: 'https://www.amazon.com/dp/RO2LASG32C',
      asin: 'RO2LASG32C',
      seller_sku: 'product_SKU-2',
      unit_cost: 5,
      unit_shipping_cost: 10,
      fbm_shipping_cost: 50
    },
    {
      url: 'https://www.amazon.com/dp/RO2LASG33C',
      asin: 'RO2LASG33C',
      seller_sku: 'product_SKU-3',
      unit_cost: 3,
      unit_shipping_cost: 10,
      fbm_shipping_cost: 2
    }
  ]
});

const profitAndLossReport = {
  name: 'Profit & Loss Profit & Loss Profit & Loss 2021',
  type: 'profit-and-loss',
  columns,
  marketplaces: [1, 2, 3, 4, 5].map(mk => getMkPnLData(mk))
};

const dailySalesReport = {
  type: 'daily-sales',
  name: 'report123123',
  marketplace_id: 3,
  data: [
    {
      date: '2020-06-06',
      tags: ['Bookmark-1', 'Bookmark-3'],
      marketplace: 3,
      product__brand: 'brand_8058168',
      product: 8058168,
      smallImage: 'https://m.media-amazon.com/images/I/51G4XTjRpZL._SL75_.jpg',
      name: 'product 8058168',
      sku: 'seller_sku_8058168',
      asin: 'asin_8058168',
      fba: 169,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 9758.54,
      units: 163,
      gp: 9758.54,
      order_item_count: 163,
      orders: 162,
      promo_units_qty: 8,
      full_units_qty: 155,
      promo_sales: 639,
      other_fees: -729.52,
      fba_fees: -829.26,
      commission_fees: -2321.2,
      tax: 817.02,
      other_revenue: -989.11,
      total_promo: -214.21,
      cost_of_goods_sold: 0,
      margin: 74.91586058651927,
      sales: 13726.02,
      est_tax_unpaid: 0,
      fbm_shipping_cost: null,
      recordId: 8058168,
      new_tax: 0,
      gift_wrap: 0,
      discounts: -117,
      referral_fees: -2321.2,
      tax_paid: -817.02,
      new_mpft: 817.02,
      seller_account: 1927,
      product_id: 8058168,
      ad_sales: 4215.04,
      ppc_orders: 51,
      ads_display: 30.24,
      ads_products: 2122.04,
      product_ad__product_id: 8058168,
      qty_refunded: 25,
      refunds: -1596.54,
      refunded_cogs: 0,
      fbm_return_cost: null,
      reimbursement: 0,
      reimbursement_return: 0,
      reimbursed_inventory: 0,
      profit: 6009.72,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      acos: 51.061911630731856,
      tacos: 15.680291883590437
    },
    {
      date: '2020-06-06',
      tags: ['Bookmark-2', 'Bookmark-4'],
      marketplace: 3,
      product__brand: 'brand_8058174',
      product: 8058174,
      smallImage: 'https://m.media-amazon.com/images/I/518d86nUbmL._SL75_.jpg',
      name: 'product 8058174',
      sku: 'seller_sku_8058174',
      asin: 'asin_8058174',
      fba: 108,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 8224.77,
      units: 90,
      gp: 8237.77,
      order_item_count: 68,
      orders: 67,
      promo_units_qty: null,
      full_units_qty: 90,
      promo_sales: null,
      other_fees: -642.12,
      fba_fees: -693,
      commission_fees: -1957.83,
      tax: 531.44,
      other_revenue: -796.77,
      total_promo: -17.96,
      cost_of_goods_sold: 0,
      margin: 75.62589650226194,
      sales: 11407.04,
      est_tax_unpaid: 13,
      fbm_shipping_cost: null,
      recordId: 8058174,
      new_tax: 13,
      gift_wrap: 0,
      discounts: 0,
      referral_fees: -1957.83,
      tax_paid: -518.44,
      new_mpft: 518.44,
      seller_account: 1927,
      product_id: 8058174,
      ad_sales: 4493.69,
      ppc_orders: 34,
      ads_display: 35.71,
      ads_products: 1233.85,
      product_ad__product_id: 8058174,
      qty_refunded: 15,
      refunds: -1480.14,
      refunded_cogs: 0,
      fbm_return_cost: null,
      reimbursement: 0,
      reimbursement_return: 0,
      reimbursed_inventory: 0,
      profit: 5475.07,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      estimated_monthly_storage_fee: 111.4123,
      acos: 28.252060110955583,
      tacos: 11.1296182006901
    },
    {
      date: '2020-06-06',
      tags: ['Bookmark-1', 'Bookmark-3'],
      marketplace: 3,
      product__brand: 'brand_8058169',
      product: 8058169,
      smallImage: 'https://m.media-amazon.com/images/I/51lMLYgUsSL._SL75_.jpg',
      name: 'product 8058169',
      sku: 'seller_sku_8058169',
      asin: 'asin_8058169',
      fba: 295,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 3783.54,
      units: 123,
      gp: 3783.54,
      order_item_count: 119,
      orders: 119,
      promo_units_qty: 3,
      full_units_qty: 120,
      promo_sales: 163,
      other_fees: -286.54,
      fba_fees: -597.8,
      commission_fees: -955.44,
      tax: 313.86,
      other_revenue: -345.53,
      total_promo: -69.73,
      cost_of_goods_sold: 0,
      margin: 70.57816213312242,
      sales: 5650.64,
      est_tax_unpaid: 0,
      fbm_shipping_cost: null,
      recordId: 8058169,
      new_tax: 0,
      gift_wrap: 0,
      discounts: -24,
      referral_fees: -955.44,
      tax_paid: -313.86,
      new_mpft: 313.86,
      seller_account: 1927,
      ad_sales: 955.77,
      ppc_orders: 23,
      ads_products: 1218.98,
      product_ad__product_id: 8058169,
      qty_refunded: 13,
      refunds: -466.15,
      refunded_cogs: 0,
      fbm_return_cost: null,
      reimbursement: 0,
      reimbursement_return: 0,
      reimbursed_inventory: 0,
      profit: 2098.41,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      estimated_monthly_storage_fee: 50.3269,
      acos: 127.53905228245289,
      tacos: 21.572423654665666
    },
    {
      date: '2020-06-06',
      tags: [],
      marketplace: 3,
      product__brand: 'brand_8058170',
      product: 8058170,
      smallImage: 'https://m.media-amazon.com/images/I/41Yr5vt5ZjL._SL75_.jpg',
      name: 'product 8058170',
      sku: 'seller_sku_8058170',
      asin: 'asin_8058170',
      fba: 185,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 2405.72,
      units: 115,
      gp: 2413.72,
      order_item_count: 114,
      orders: 114,
      promo_units_qty: 2,
      full_units_qty: 113,
      promo_sales: 67,
      other_fees: -235.08,
      fba_fees: -563.5,
      commission_fees: -649.88,
      tax: 214.07,
      other_revenue: -301.66,
      total_promo: -51.38,
      cost_of_goods_sold: 0,
      margin: 66.18393903546178,
      sales: 3833.17,
      est_tax_unpaid: 8,
      fbm_shipping_cost: null,
      recordId: 8058170,
      new_tax: 8,
      gift_wrap: 0,
      discounts: -15.8,
      referral_fees: -649.88,
      tax_paid: -206.07,
      new_mpft: 206.07,
      seller_account: 1927,
      ad_sales: 1263.65,
      ppc_orders: 39,
      ads_products: 1271.43,
      product_ad__product_id: 8058170,
      qty_refunded: 17,
      refunds: -444.39,
      refunded_cogs: 0,
      fbm_return_cost: null,
      reimbursement: 0,
      reimbursement_return: 0,
      reimbursed_inventory: 0,
      profit: 689.9,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      estimated_monthly_storage_fee: 47.7905,
      acos: 100.61567680924307,
      tacos: 33.16915242475549
    },
    {
      date: '2020-06-06',
      tags: ['Bookmark-4', 'Bookmark-2'],
      marketplace: 3,
      product__brand: 'brand_8058172',
      product: 8058172,
      smallImage: 'https://m.media-amazon.com/images/I/41nP-Ff5l8L._SL75_.jpg',
      name: 'product 8058172',
      sku: 'seller_sku_8058172',
      asin: 'asin_8058172',
      fba: 174,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 2105.42,
      units: 107,
      gp: 2105.42,
      order_item_count: 82,
      orders: 82,
      promo_units_qty: 6,
      full_units_qty: 101,
      promo_sales: 163,
      other_fees: -194.41,
      fba_fees: -368.88,
      commission_fees: -543.25,
      tax: 149.2,
      other_revenue: -299.35,
      total_promo: -38.03,
      cost_of_goods_sold: 0,
      margin: 69.15373372091113,
      sales: 3166.75,
      est_tax_unpaid: 0,
      fbm_shipping_cost: null,
      recordId: 8058172,
      new_tax: 0,
      gift_wrap: 0,
      discounts: -27,
      referral_fees: -543.25,
      tax_paid: -149.2,
      new_mpft: 149.2,
      seller_account: 1927,
      ad_sales: 359.88,
      ppc_orders: 12,
      ads_products: 406.77,
      product_ad__product_id: 8058172,
      qty_refunded: 18,
      refunds: -425.34,
      refunded_cogs: 0,
      fbm_return_cost: null,
      reimbursement: 0,
      reimbursement_return: 0,
      reimbursed_inventory: 0,
      profit: 1273.31,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      estimated_monthly_storage_fee: 28.1188,
      acos: 113.02934311437146,
      tacos: 12.845030393937002
    },
    {
      date: '2020-06-06',
      tags: ['Bookmark-1', 'Bookmark-3', 'Bookmark-5'],
      marketplace: 3,
      product__brand: 'brand_8058177',
      product: 8058177,
      smallImage: 'https://m.media-amazon.com/images/I/41JImBh6sDL._SL75_.jpg',
      name: 'product 8058177',
      sku: 'seller_sku_8058177',
      asin: 'asin_8058177',
      fba: 2,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 1138.02,
      units: 27,
      gp: 1138.02,
      order_item_count: 27,
      orders: 27,
      promo_units_qty: null,
      full_units_qty: 27,
      promo_sales: null,
      other_fees: -100.93,
      fba_fees: -140.92,
      commission_fees: -280.8,
      tax: 91.19,
      other_revenue: -271.02,
      total_promo: 0,
      cost_of_goods_sold: 0,
      margin: 72.96216036006001,
      sales: 1650.93,
      est_tax_unpaid: 0,
      fbm_shipping_cost: null,
      recordId: 8058177,
      new_tax: 0,
      gift_wrap: 0,
      discounts: 0,
      referral_fees: -280.8,
      tax_paid: -91.19,
      new_mpft: 91.19,
      seller_account: 1927,
      ad_sales: 839.86,
      ppc_orders: 14,
      ads_products: 483.57,
      product_ad__product_id: 8058177,
      qty_refunded: 10,
      refunds: -409.34,
      refunded_cogs: 0,
      fbm_return_cost: null,
      reimbursement: 0,
      reimbursement_return: 0,
      reimbursed_inventory: 0,
      profit: 245.11,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      estimated_monthly_storage_fee: 2.5342,
      acos: 57.57745338508799,
      tacos: 29.290763387908633
    },
    {
      date: '2020-06-06',
      marketplace: 3,
      product__brand: 'brand_8058171',
      product: 8058171,
      smallImage: 'https://m.media-amazon.com/images/I/51WY+taYleL._SL75_.jpg',
      name: 'product 8058171',
      sku: 'seller_sku_8058171',
      asin: 'asin_8058171',
      fba: 131,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 1011.47,
      units: 28,
      gp: 1011.47,
      order_item_count: 28,
      orders: 28,
      promo_units_qty: null,
      full_units_qty: 28,
      promo_sales: null,
      other_fees: -62.38,
      fba_fees: -137.2,
      commission_fees: -248.56,
      tax: 84.58,
      other_revenue: 4.29,
      total_promo: -4.29,
      cost_of_goods_sold: 0,
      margin: 72.39108808141823,
      sales: 1481.81,
      est_tax_unpaid: 0,
      fbm_shipping_cost: null,
      recordId: 8058171,
      new_tax: 0,
      gift_wrap: 0,
      discounts: 0,
      referral_fees: -248.56,
      tax_paid: -84.58,
      new_mpft: 84.58,
      seller_account: 1927,
      ad_sales: 599.88,
      ppc_orders: 12,
      ads_products: 1003.8,
      product_ad__product_id: 8058171,
      qty_refunded: 4,
      refunds: -75.34,
      refunded_cogs: 0,
      fbm_return_cost: null,
      reimbursement: 0,
      reimbursement_return: 0,
      reimbursed_inventory: 0,
      profit: -67.67,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      estimated_monthly_storage_fee: 14.9779,
      acos: 167.33346669333866,
      tacos: 67.74147832718094
    },
    {
      date: '2020-06-06',
      marketplace: 3,
      product__brand: 'brand_8058176',
      product: 8058176,
      smallImage: 'https://m.media-amazon.com/images/I/51zrdj2lykL._SL75_.jpg',
      name: 'product 8058176',
      sku: 'seller_sku_8058176',
      asin: 'asin_8058176',
      fba: 0,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 490.2,
      units: 3,
      gp: 490.2,
      order_item_count: 3,
      orders: 3,
      promo_units_qty: null,
      full_units_qty: 3,
      promo_sales: null,
      other_fees: -29.85,
      fba_fees: -20.82,
      commission_fees: -85.98,
      tax: 34.08,
      other_revenue: 0,
      total_promo: 0,
      cost_of_goods_sold: 0,
      margin: 82.1105527638191,
      sales: 631.08,
      est_tax_unpaid: 0,
      fbm_shipping_cost: null,
      recordId: 8058176,
      new_tax: 0,
      gift_wrap: 0,
      discounts: 0,
      referral_fees: -85.98,
      tax_paid: -34.08,
      new_mpft: 34.08,
      seller_account: 1927,
      profit: 490.2,
      return_on_investment: 0,
      production_cost: 0,
      roi: 0,
      estimated_monthly_storage_fee: 1.0734,
      acos: 0,
      tacos: 0
    }
  ]
};

mock.onPost(API.reports).reply(async request => {
  console.log(`mock.onPost`, JSON.parse(request.data));
  await new Promise(resolve => setTimeout(() => resolve(), 10000));
  return [200];
});

mock.onGet(`${API.reports}1`).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 350));
  return [200, profitAndLossReport];
});

mock.onDelete(`${API.reports}1`).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  reports = reports.filter(item => item.id !== 1);
  return [200];
});

mock.onGet(`${API.reports}12`).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 350));
  return [200, dailySalesReport];
});

mock.onGet(API.reports).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 350));
  return [200, reports];
});
