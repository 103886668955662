import axios from 'axios/index';
import API from '@sm/api';
import { sendError } from '@utils';
import { setNotificationMsgAction, setSuccessMsgAction } from './index';
import { SET_NETWORK_ACTIVITY } from './network.actions';
import { SET_USER_PROFILE } from './user.actions';

export const SET_BILLING_INFO = 'ADD_BILLING_INFO';

export const CC_ADDED = 'You successfully added a payment option!';
export const CC_UPDATED = 'You successfully updated a payment option!';

export const addCCAction = (tokenID, handleSuccess, successMsg) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
    const { userId } = getState().user;

    axios
      .post(API.billing.addCC, { tokenID, userId })
      .then(res => {
        const { profile } = getState().user;
        const newProfile = { ...profile };
        newProfile.subscription.cc = true;
        dispatch({ type: SET_USER_PROFILE, profile: newProfile });

        handleSuccess(res);
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        if (successMsg) {
          dispatch(setSuccessMsgAction(successMsg));
        }
        if (window.dataLayer) {
          try {
            window.dataLayer.push({ event: 'credit_card_added', email: profile.email });
          } catch (e) {
            sendError(e);
          }
        }
      })
      .catch(err => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(setNotificationMsgAction(err.response ? err.response.data : err.response));
      });
  };
};

export const getBillingInfoAction = () => {
  return (dispatch, getState) => {
    const { userId } = getState().user;

    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
    axios
      .get(API.billing.history, { params: { userId } })
      .then(res => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch({ type: SET_BILLING_INFO, billingInfo: res.data });
      })
      .catch(err => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(setNotificationMsgAction(err.response ? err.response.data : err.response));
      });
  };
};
