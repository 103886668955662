import API from '@sm/api';
import mock from '../mock';

const activeneProductSearchResult = {
  brands: [
    {
      id: 21,
      name: 'Activene',
      products: 10
    }
  ],
  parent_products: [
    {
      id: 22,
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 1,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC',
      seller_sku: 'BO1LVWG3WW'
    },
    {
      id: 23,
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 2,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC'
    },
    {
      id: 24,
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 3,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC'
    }
  ],
  products: [
    {
      id: 25,
      type: 'child',
      name:
        'ACTIVENE Arnica Gel Cream - with Menthol and MSM | Pain Relief for Joint, Tendon & Muscle Pains | Chosen by sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Ache 3oz',
      marketplace: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'B012A5Z89K',
      sku: 'UF-KPKM-TMSZ'
    }
  ]
};

const zegaroProductSearch = {
  brands: [
    {
      id: 1,
      name: 'Zegaro',
      products: 4
    }
  ],
  parent_products: [
    {
      id: 1,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers,",
      marketplace: 1,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg'
    }
  ],
  products: [
    {
      id: 1,
      brand: 'Zegaro',
      marketplace: 3,
      asin: 'B07JHYD2PL',
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | Black",
      smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
      sku: 'EBC02-MB',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 2,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JHYT5VH',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | Black",
      variation_value_one: 'Midnight Black',
      smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
      color: 'Midnight Black',
      sku: 'EBC02-MB',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 3,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      variation_value_one: 'Cloud White',
      variation_type_two: 'Size',
      variation_value_two: 'Large',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    },
    {
      id: 4,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 5,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 6,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 7,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 8,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    },
    {
      id: 9,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    },
    {
      id: 10,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    },
    {
      id: 11,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    },
    {
      id: 12,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      variation_value_one: 'Cloud White',
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    }
  ]
};

/* function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
} */

// mock.onGet(API.products.search).reply(async () => {
//   // console.log('-------------- delayed API for testing loading components --------------');
//   // await sleep(5000);
//   return [200, activeneProductSearchResult];
// });

const bookmarksDb = [
  {
    id: 1,
    marketplace: 3,
    name: 'Zegaro all',
    productCount: 1,
    pictures: [
      'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg'
    ],
    products: {
      brands: [
        {
          id: 1,
          name: 'Zegaro',
          products: 4
        }
      ],
      parent_products: [
        {
          id: 1,
          name:
            "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers,",
          marketplace: 1,
          variations: 3,
          smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg'
        },
        {
          id: 2,
          name:
            "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers,",
          marketplace: 2,
          variations: 3,
          sku: 'MX-1991',
          smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg'
        }
      ],
      products: [
        {
          id: 1,
          brand: 'Zegaro',
          marketplace: 3,
          asin: 'B07JHYD2PL',
          name:
            "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | Black",
          smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
          sku: 'EBC02-MB3',
          variation_type_two: 'Size',
          variation_value_two: 'Large'
        },
        {
          id: 2,
          parent_asin: 'B07JHYD2PL',
          variation_type_one: 'Color',
          brand: 'Zegaro',
          asin: 'B07JHYT5VH',
          marketplace: 3,
          name:
            "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | Black",
          variation_value_one: 'Midnight Black',
          smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
          color: 'Midnight Black',
          sku: 'EBC02-MB2',
          variation_type_two: 'Size',
          variation_value_two: 'Large'
        }
      ]
    }
  },
  {
    id: 2,
    marketplace: 4,
    name: 'Test all',
    productCount: 10,
    pictures: ['http://ecx.images-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg']
  },
  {
    name: null
  }
];

mock.onGet(API.products.bookmarks).reply(() => {
  return [200, bookmarksDb];
});

mock.onPost(API.products.bookmarks).reply(() => {
  return [201, bookmarksDb[0]];
});

mock.onPut(new RegExp(`${API.products.bookmarks}/.+`)).reply(() => {
  return [200, bookmarksDb[0]];
});

mock.onGet(API.products.search, { params: { searchText: 'acti' } }).reply(() => {
  return [200, activeneProductSearchResult];
});

mock.onGet(API.products.search, { params: { searchText: 'activ' } }).reply(() => {
  return [200, activeneProductSearchResult];
});

mock.onGet(API.products.search, { params: { searchText: 'active' } }).reply(() => {
  return [200, activeneProductSearchResult];
});

mock.onGet(API.products.search, { params: { searchText: 'activen' } }).reply(() => {
  return [200, activeneProductSearchResult];
});

mock.onGet(API.products.search, { params: { searchText: 'activene' } }).reply(() => {
  return [200, activeneProductSearchResult];
});

mock.onGet(API.products.search, { params: { searchText: 'zeg' } }).reply(() => {
  return [200, zegaroProductSearch];
});

mock.onGet(API.products.search, { params: { searchText: 'zega' } }).reply(() => {
  return [200, zegaroProductSearch];
});

mock.onGet(API.products.search).reply(() => {
  return [200, zegaroProductSearch];
});

mock.onGet(API.products.search, { params: { searchText: 'zegaro' } }).reply(() => {
  return [200, zegaroProductSearch];
});
