import API from '@sm/api';
import { getRandomArbitrary } from '../utils';
import mock from '../mock';

const getAgencyClientSummary = (name, id) => ({
  user: id,
  products: getRandomArbitrary(10, 1000).toFixed(0),
  username: name,
  avatar: 'https://staging-amza-210707.storage.googleapis.com/users/user_3562/profile/avatar.jpeg',
  mk_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
  refunded__sum: getRandomArbitrary(10, 1000),
  acos: getRandomArbitrary(10, 100),
  tacos: getRandomArbitrary(30, 100),
  orders__sum: getRandomArbitrary(500, 1000),
  quantity_ordered__sum: getRandomArbitrary(500, 1000),
  ppc_orders__sum: getRandomArbitrary(10, 500),
  promo_units_qty__sum: getRandomArbitrary(10, 500),
  product_sales__sum: getRandomArbitrary(10, 1000),
  sales__sum: getRandomArbitrary(10, 1000),
  shipping__sum: getRandomArbitrary(10, 1000),
  new_tax__sum: getRandomArbitrary(10, 1000),
  gift_wrap__sum: getRandomArbitrary(10, 1000),
  discounts__sum: getRandomArbitrary(10, 1000),
  profit__sum: getRandomArbitrary(10, 1000),
  new_gross_profit__sum: getRandomArbitrary(10, 1000),
  production_cost__sum: getRandomArbitrary(10, 1000),
  referral_fees__sum: getRandomArbitrary(10, 1000),
  fba_fees__sum: getRandomArbitrary(10, 1000),
  closing_fees__sum: getRandomArbitrary(10, 1000),
  tax_paid__sum: getRandomArbitrary(10, 1000),
  est_tax_unpaid__sum: getRandomArbitrary(10, 1000),
  new_mpft__sum: getRandomArbitrary(10, 1000),
  fbm_shipping_cost__sum: getRandomArbitrary(10, 1000),
  ad_spend__sum: getRandomArbitrary(10, 1000),
  ads_brands__sum: getRandomArbitrary(10, 1000),
  refund_cashflow__sum: getRandomArbitrary(10, 1000),
  refunded_cogs__sum: getRandomArbitrary(10, 1000),
  margin__sum: getRandomArbitrary(10, 1000),
  roi__sum: getRandomArbitrary(10, 1000),
  fbm_return_cost__sum: getRandomArbitrary(10, 1000),
  reimbursement__sum: getRandomArbitrary(10, 1000),
  reimbursement_return__sum: getRandomArbitrary(10, 1000),
  reimbursed_inventory__sum: getRandomArbitrary(10, 1000),
  ads_sum: getRandomArbitrary(10, 1000),
  roi_sum: getRandomArbitrary(0, 100)
});

const getAgencyClientsSummary = () =>
  new Array(6).fill('').map((item, index) => getAgencyClientSummary(`Client ${index}`, index));

mock.onGet(API.agency_clients_summary).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return [200, getAgencyClientsSummary()];
});
