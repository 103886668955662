import {
  SET_SQP,
  SET_SQP_ACCOUNTS_STATUSES,
  SET_SQP_LOADING,
  SET_SQP_STATUS
} from 'seller/store/saga/amazonTraffic';

const initialState = {
  mainSqpList: [],
  compareSqpList: [],
  sqpLoading: false,
  status: {},
  accounts: []
};
export const amazonTrafficSelector = state => state.amazonTraffic;

const amazonTraffic = (state = initialState, action) => {
  switch (action.type) {
    case SET_SQP: {
      return { ...state, ...action.payload };
    }
    case SET_SQP_LOADING:
      return { ...state, sqpLoading: action.payload };

    case SET_SQP_STATUS:
      return { ...state, status: action.payload };

    case SET_SQP_ACCOUNTS_STATUSES:
      return { ...state, accounts: action.payload };

    default: {
      return state;
    }
  }
};

export default amazonTraffic;
