import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FLAGS, { getFlagsById, GLOBAL_MK } from '@sm/Flags';
import { CaretDownIcon } from '@components/icons';
import CheckBox from '@components/check-box';
import { SafeImage } from '@components/images';

const MarketplaceSelector = props => {
  const {
    marketplaceID: selectedMarketplace,
    marketplaces,
    className,
    showRegionTitle,
    showCaretDown,
    onHover,
    onMarketplaceChanged,
    multiSelect,
    selectedMarketplaces: _selectedMarketplaces,
    onSelectedMarketplacesChanged
  } = props;
  const [selectedMarketplaces, setSelectedMarketplaces] = useState(_selectedMarketplaces);

  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuClose = () => {
    setAnchorEl(null);
    onSelectedMarketplacesChanged(selectedMarketplaces);
  };

  const onFlagClick = index => {
    if (multiSelect) {
      onSelectedMarketplacesChanged([index]);
      setSelectedMarketplaces([index]);
    } else {
      onMarketplaceChanged(index);
    }
    setAnchorEl(null);
  };

  const onCheck = marketplace => {
    if (!selectedMarketplaces.includes(marketplace)) {
      const result = selectedMarketplaces.filter(item => item !== GLOBAL_MK);
      setSelectedMarketplaces([...result, marketplace].sort());
      return;
    }
    const result = selectedMarketplaces.filter(item => item !== marketplace && item !== GLOBAL_MK);
    if (!result.length) return;
    setSelectedMarketplaces(result);
  };

  const caredDown = showCaretDown ? (
    <CaretDownIcon fill="#c3c6d6" width="16px" height="14px" className="hidden xs:block" />
  ) : null;

  const rootStyle = onHover ? {} : { backgroundColor: 'transparent' };

  const getButtonContent = () => {
    if (!multiSelect || selectedMarketplaces.length === 1) {
      const selectedFlag = getFlagsById(
        multiSelect ? selectedMarketplaces[0] : selectedMarketplace
      );
      return (
        <div className="flex flex-row items-center text-base w-full justify-between">
          <img
            src={selectedFlag?.src}
            alt="flag"
            className="xs:mr-1 rounded-sm"
            width="35"
            height="18"
          />
          {showRegionTitle && <p className="text-sm mx-2 text-black">{selectedFlag?.name}</p>}
          {caredDown}
        </div>
      );
    }
    return (
      <div className="flex items-center justify-between">
        <div
          className={classNames('h-full flex flex-wrap items-start justify-center mr-1', {
            'w-12': selectedMarketplaces.length <= 4,
            'w-20': selectedMarketplaces.length > 4
          })}
        >
          {selectedMarketplaces.slice(0, 6).map(mk => {
            const flag = getFlagsById(mk);
            return (
              <SafeImage
                key={mk}
                src={flag?.src}
                className="w-5 rounded-sm"
                style={{ margin: '0.125rem' }}
              />
            );
          })}
        </div>
        {caredDown}
      </div>
    );
  };

  return (
    <div>
      <Button
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
        className={classNames('pl-0 py-0', className)}
        style={rootStyle}
      >
        <div className="app-menu rounded-2xl flex flex-row items-center justify-center text-white normal-case py-2">
          {getButtonContent()}
        </div>
      </Button>
      <Menu
        MenuListProps={{ style: { padding: 0 } }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {marketplaces.map(marketplace => {
          const flag = FLAGS.find(flagInfo => flagInfo.id === marketplace);
          if (!flag) return null;
          const isSelected = multiSelect
            ? selectedMarketplaces.includes(marketplace)
            : flag.id === selectedMarketplace;
          return (
            <MenuItem
              key={flag.id}
              onClick={() => onFlagClick(flag.id)}
              className="sm-min-height-48px"
            >
              <img src={flag.src} alt="flag" width="30" height="16" className="rounded-sm" />
              <p
                className={classNames('text-sm ml-4 mr-4', {
                  'text-yellow': isSelected,
                  'text-black': !isSelected
                })}
              >
                {flag.name}
              </p>
              {multiSelect && marketplace !== GLOBAL_MK && (
                <CheckBox
                  value={isSelected}
                  classNameContainer="ml-auto"
                  onCheckHandler={() => onCheck(flag.id)}
                />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

MarketplaceSelector.propTypes = {
  marketplaces: PropTypes.instanceOf(Object).isRequired,
  selectedMarketplaces: PropTypes.instanceOf(Array),
  marketplaceID: PropTypes.number,
  onMarketplaceChanged: PropTypes.func,
  onSelectedMarketplacesChanged: PropTypes.func,
  className: PropTypes.string,
  showRegionTitle: PropTypes.bool,
  showCaretDown: PropTypes.bool,
  onHover: PropTypes.bool,
  multiSelect: PropTypes.bool
};

MarketplaceSelector.defaultProps = {
  className: '',
  showRegionTitle: false,
  showCaretDown: true,
  onHover: false,
  marketplaceID: 0,
  multiSelect: false,
  selectedMarketplaces: [],
  onSelectedMarketplacesChanged: () => {},
  onMarketplaceChanged: () => {}
};

export default MarketplaceSelector;
