import { Tooltip, withStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const styles = {
  tooltip: {
    textAlign: 'left',
    background: Colors.black.light,
    color: Colors.white.default,
    borderRadius: '0.25rem',
    paddingTop: '0.75rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '0.875rem',
    fontSize: '0.875rem'
  },
  tooltipPlacementLeft: {
    right: '0.5rem'
  },
  arrow: {
    color: Colors.black.light
  }
};

const BlackTooltip = withStyles(styles)(Tooltip);

export default BlackTooltip;
