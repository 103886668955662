import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Select } from '@material-ui/core';
import Colors from 'styles/colors';
import classNames from 'classnames';
import { ExpandIcon } from '@components/icons';
import { OutlinedWrapper } from '@components/inputs';
import { HEADER_HEIGHT_IN_PX } from '@sm/layout';

export const selectFieldStyles = makeStyles({
  root: {
    '& .MuiInputBase-input': {
      paddingBottom: '6px',
      paddingTop: '6px',
      fontSize: '0.875rem',
      color: Colors.gray.darkest
    },
    '&:after': {
      borderBottomStyle: 'none !important'
    },
    '&:hover:before': {
      borderBottomStyle: 'none !important'
    },
    '&:before': {
      borderBottomStyle: 'none !important'
    },
    '& .MuiSelect-select:focus': {
      background: 'none !important'
    }
  }
});

const SELECT_POPOVER_MARGIN = 8; // px

const OutlinedSelect = props => {
  const selectFieldClasses = selectFieldStyles();
  const { title, wrapperClassName, disabled, ...childProps } = props;
  const [popoverOpened, setPopoverOpened] = useState(false);
  const wrapperClassname = `outlined-selector-${title}`;
  // TODO: SMF-608
  useEffect(() => {
    setTimeout(() => {
      const selector = `.${wrapperClassname}>.MuiPopover-paper`;
      const paper = document.querySelector(selector);
      if (!paper) return;
      const [top] = paper.style.top.split('px');
      const safeTop =
        top > HEADER_HEIGHT_IN_PX + SELECT_POPOVER_MARGIN
          ? top
          : HEADER_HEIGHT_IN_PX + SELECT_POPOVER_MARGIN;
      if (paper?.style) paper.style.top = `${safeTop}px`;
    });
  }, [popoverOpened, wrapperClassname]);

  return (
    <OutlinedWrapper title={title} className={wrapperClassName} disabled={disabled}>
      <Select
        {...childProps}
        disabled={disabled}
        inputProps={{ autocomplete: 'off' }}
        className={selectFieldClasses.root}
        onOpen={() => setPopoverOpened(!popoverOpened)}
        MenuProps={{ className: wrapperClassname }}
        IconComponent={({ className }) =>
          disabled ? null : (
            <ExpandIcon
              height="10px"
              className={classNames('absolute right-0 my-2 mr-1 pointer-events-none', className)}
            />
          )
        }
      />
    </OutlinedWrapper>
  );
};

OutlinedSelect.propTypes = {
  title: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string
};

OutlinedSelect.defaultProps = {
  wrapperClassName: ''
};

export default OutlinedSelect;
