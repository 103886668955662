import { isEmpty } from '@utils';

const getCardInfo = (start, end, style) => {
  return { start, end, style };
};

const cardSizeMap = [
  getCardInfo(0, 100000, '11rem'),
  getCardInfo(100000, 1000000, '13.5rem'),
  getCardInfo(1000000, 10000000, '15rem'),
  getCardInfo(10000000, 100000000, '16rem'),
  getCardInfo(100000000, 1000000000, '17rem'),
  getCardInfo(1000000000, 10000000000, '18.5rem'),
  getCardInfo(10000000000, 100000000000, '19.5rem')
];

const findCardInfo = _value => {
  const value = Math.abs(_value);
  return cardSizeMap.findIndex(info => info.start <= value && info.end > value);
};

const getMaxCardsValue = values => {
  if (isEmpty(values)) return null;
  return Object.keys(values).reduce((max, key) => {
    let value = values[key];
    if (!parseFloat(value)) return max;
    value = Math.abs(value?.value ? value?.value : value);
    return max > value ? max : value;
  }, 0);
};

const getCardStyle = values => {
  let biggestValueInfoIndex = findCardInfo(getMaxCardsValue(values));
  const profitIndex = findCardInfo(values.profit);
  if (biggestValueInfoIndex < 0) return {};
  if (profitIndex === biggestValueInfoIndex) biggestValueInfoIndex += values.profit < 0 ? 2 : 1;
  return cardSizeMap[biggestValueInfoIndex];
};

export default getCardStyle;
