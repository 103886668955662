import API from '@sm/api';
import mock from '../mock';

let REPORT_DATA = {
  id: 123,
  created_on: '2024-03-19T07:29:21.692229Z',
  updated_on: '2024-03-19T07:29:21.692407Z',
  user_id: 3975,
  month: '2024-01-01',
  summary: null,
  comment: '',
  actual_ns: 263374.41,
  ytd_actual_ns: 263374.41,
  budget_ns: null,
  ytd_budget_ns: 323803.99,
  actual_vs_budget_ns: 0.0,
  ytd_actual_vs_budget_ns: 0.0,
  prev_year_ns: 263374.41,
  ytd_prev_year_ns: 263374.41,
  actual_yoy_ns: 10.0,
  ytd_actual_yoy_ns: 0.0,
  outlook_eoq_ns: 0.0,
  actual_cm3: 12194.3812,
  ytd_actual_cm3: 12194.3812,
  budget_cm3: 23806.14,
  ytd_budget_cm3: 23806.14,
  actual_vs_budget_cm3: 0.0,
  ytd_actual_vs_budget_cm3: 0.0,
  prev_year_cm3: 12194.3812,
  ytd_prev_year_cm3: 23806.14,
  actual_yoy_cm3: 0.0,
  ytd_actual_yoy_cm3: 0.0,
  outlook_eoq_cm3: 0.0,
  actual_cm3_margin: 0.0,
  ytd_actual_cm3_margin: 0.0,
  budget_cm3_margin: 0.0,
  ytd_budget_cm3_margin: 0.0,
  actual_vs_budget_cm3_margin: 0.0,
  ytd_actual_vs_budget_cm3_margin: 0.0,
  prev_year_cm3_margin: 0.0,
  ytd_prev_year_cm3_margin: 0.0,
  actual_yoy_cm3_margin: 0.0,
  ytd_actual_yoy_cm3_margin: 0.0,
  outlook_eoq_cm3_margin: 0.0,
  actual_npd: 1346.36,
  ytd_actual_npd: 1346.36,
  budget_npd: 0.0,
  ytd_budget_npd: 0.0,
  actual_vs_budget_npd: 0.0,
  ytd_actual_vs_budget_npd: 0.0,
  prev_year_npd: 1346.36,
  ytd_prev_year_npd: 0.0,
  actual_yoy_npd: 0.0,
  ytd_actual_yoy_npd: 0.0,
  outlook_eoq_npd: 0.0,
  actual_cvr: 0.0,
  ytd_actual_cvr: 0.0,
  budget_cvr: 0.0,
  ytd_budget_cvr: 0.0,
  actual_vs_budget_cvr: 0.0,
  ytd_actual_vs_budget_cvr: 0.0,
  prev_year_cvr: 0.0,
  ytd_prev_year_cvr: 0.0,
  actual_yoy_cvr: 0.0,
  ytd_actual_yoy_cvr: 0.0,
  outlook_eoq_cvr: 0.0,
  actual_mape: 0.0,
  ytd_actual_mape: 0.0,
  budget_mape: 0.0,
  ytd_budget_mape: 0.0,
  actual_vs_budget_mape: 0.0,
  ytd_actual_vs_budget_mape: 0.0,
  prev_year_mape: 0.0,
  ytd_prev_year_mape: 0.0,
  actual_yoy_mape: null,
  ytd_actual_yoy_mape: 0.0,
  outlook_eoq_mape: null,
  is_locked: false
};

const CHART_DATA = [
  [1672531200000, 105411],
  [1675209600000, 68312],
  [1677628800000, 26272],
  [1680307200000, 93311],
  [1682899200000, 20132],
  [1685577600000, 70511]
];

mock.onGet(API.monthly_brand_review.root).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 500));
  // return [404, null];
  return [200, REPORT_DATA];
});

mock.onPatch(`${API.monthly_brand_review.root}123/`).reply(async config => {
  const data = JSON.parse(config.data);
  console.log('data', data);
  await new Promise(resolve => setTimeout(resolve, 2500));
  REPORT_DATA = { ...REPORT_DATA, ...data };
  return [200, REPORT_DATA];
});

mock.onGet(API.monthly_brand_review.chart).reply(async config => {
  console.log('config', config);
  await new Promise(resolve => setTimeout(resolve, 2500));
  return [200, CHART_DATA];
});
