import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Link } from '@material-ui/core';
import Colors from 'styles/colors';
import { CheckIcon, ChevronRightIcon, ExclamationIcon } from '../icons';

function CheckItem(props) {
  const {
    label,
    subLabel,
    className,
    buttonClassName,
    done,
    style,
    onClick,
    link,
    disabled,
    error
  } = props;

  let LinkComponent = ({ children, ...other }) => {
    return <div {...other}>{children}</div>;
  };

  let LinkProps = null;
  if (link) {
    const isLocalLink = link.slice(0, 4) !== 'http';
    LinkComponent = isLocalLink ? RouterLink : Link;
    LinkProps = isLocalLink ? { to: { pathname: link } } : { href: link, target: '_blank' };
  }
  const getIcon = () => {
    const iconClassNames = 'mr-4 ml-2 sm-h-14px';
    if (error) return <ExclamationIcon className={iconClassNames} fill={Colors.yellow.default} />;
    return (
      <CheckIcon
        className={iconClassNames}
        fill={done ? Colors.green.lighter : Colors.gray.default}
      />
    );
  };
  return (
    <LinkComponent
      {...LinkProps}
      className={classNames('block no-underline', className, { 'cursor-default': disabled })}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick(label);
      }}
    >
      <Button
        className={classNames(
          'full-border-gray-lighter tracking-normal leading-normal rounded w-full py-0',
          buttonClassName
        )}
        style={style}
        disabled={disabled}
      >
        <div className="flex items-center w-full normal-case">
          {getIcon()}
          <div className="inline-flex flex-col items-start">
            <p
              className={`text-base font-medium text-left no-underline ${
                done ? 'text-black' : ' text-yellow'
              }`}
            >
              {label}
            </p>
            <p className="-mt-1 text-sm text-gray-dark text-left font-normal no-underline">
              {subLabel}
            </p>
          </div>
          {!done && (
            <ChevronRightIcon
              className="block ml-auto ml-4 mr-2 sm-h-14px"
              fill={Colors.yellow.default}
            />
          )}
        </div>
      </Button>
    </LinkComponent>
  );
}

CheckItem.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  done: PropTypes.bool,
  error: PropTypes.bool,
  subLabel: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  onClick: PropTypes.func.isRequired,
  link: PropTypes.string,
  disabled: PropTypes.bool
};

CheckItem.defaultProps = {
  className: '',
  buttonClassName: '',
  done: false,
  subLabel: '',
  style: {},
  link: '',
  disabled: false,
  error: false
};

export default CheckItem;
