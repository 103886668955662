import React from 'react';
import Colors from 'styles/colors';
import { SVG_GRAD_ID_ONE, SvgGradInitializer } from '@components/gradients';

const AddSvgGrads = () => {
  return (
    <SvgGradInitializer
      id={SVG_GRAD_ID_ONE}
      start={Colors.yellow.dark}
      end={Colors.yellow.default}
      gradientProps={{ gradientTransform: 'rotate(45)' }}
    />
  );
};

export default AddSvgGrads;
