import React from 'react';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import { IconButton } from '@material-ui/core';
import Colors from 'styles/colors';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isArray, usePopover } from '@utils';
import { ReactComponent as SlidersIcon } from '../icons/sliders.svg';
import { ReactComponent as GripLinesIcon } from '../icons/griplines.svg';
import { Popover } from '../poppers';
import CheckBox from '../check-box';
import {
  getColumnsConfigFromColumnList,
  getColumnsListFromColumnsConfig,
  getFlatColumnsList,
  getGroupJoinedColumnsList,
  getTableConfig
} from './utils';

const AdjustPopover = ({ columnsConfig, tableId, setTableConfig }) => {
  const columnsList = getColumnsListFromColumnsConfig(columnsConfig);
  // Hide second column if it doesn't have a title, if it used for place some button
  const isSecondColumnNoTitle = !columnsList[1].title;
  const fixedColumns = columnsList.slice(0, isSecondColumnNoTitle ? 2 : 1);
  const slicedColumnsList = columnsList.slice(isSecondColumnNoTitle ? 2 : 1);
  const groupJoinedColumnsList = getGroupJoinedColumnsList(slicedColumnsList);

  const applyTableConfig = newConfig => setTableConfig(tableId, getTableConfig(newConfig));

  const onSortEnd = ({ source, destination }) => {
    if (!destination) return;
    const sortedGroupedColumnsList = [
      ...fixedColumns,
      ...arrayMove(groupJoinedColumnsList, source.index, destination.index)
    ];
    const sortedFlatColumnsList = getFlatColumnsList(sortedGroupedColumnsList);
    applyTableConfig(getColumnsConfigFromColumnList(sortedFlatColumnsList));
  };

  const onCheck = (value, input) => {
    const enabledColumnsCounter = slicedColumnsList.filter(item => !item[1].disabled).length;
    if (!input && enabledColumnsCounter === 1) return;
    const [, columnData] = value;
    columnData.disabled = !input;
    applyTableConfig(getColumnsConfigFromColumnList([...fixedColumns, ...slicedColumnsList]));
  };

  const getOption = (value, provided) => {
    const [key, data] = value;
    return (
      <div className="flex items-center justify-start h-8" key={key}>
        <div
          className="flex items-center justify-start flex-grow"
          {...(provided ? provided.dragHandleProps : {})}
        >
          <GripLinesIcon fill={Colors.gray.default} className="mr-2 h-4 w-4" />
          <p className="mr-auto text-sm text-gray-darkest">{data.title}</p>
        </div>
        <CheckBox
          value={!data.disabled}
          classNameContainer="ml-2"
          onCheckHandler={input => onCheck(value, input)}
        />
      </div>
    );
  };

  const getOptionGroup = (value, provided) => {
    const [, data] = value;
    return data.map(item => getOption(item, provided));
  };

  const SortableItem = ({ value, index }) => {
    const [, data] = value;
    return (
      <Draggable draggableId={`${index}`} index={index}>
        {provided => {
          const content = isArray(data)
            ? getOptionGroup(value, provided)
            : getOption(value, provided);
          return (
            <div
              className="bg-white rounded px-4"
              {...provided.draggableProps}
              ref={provided.innerRef}
            >
              {content}
            </div>
          );
        }}
      </Draggable>
    );
  };

  const popover = usePopover();

  return (
    <div className="hidden md:block">
      <IconButton onClick={popover.targetClickHandler} className="p-2">
        <SlidersIcon fill={Colors.yellow.default} className="h-4 w-4" />
      </IconButton>
      <Popover
        config={popover}
        PaperProps={{ className: 'max-h-3/4 mt-2 scroll-container overflow-auto select-none' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className="py-2">
          <DragDropContext onDragEnd={onSortEnd}>
            <Droppable droppableId="main">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {groupJoinedColumnsList.map((value, index) => (
                    <SortableItem key={`item-${value[0]}`} index={index} value={value} />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Popover>
    </div>
  );
};

AdjustPopover.propTypes = {
  columnsConfig: PropTypes.instanceOf(Object).isRequired,
  tableId: PropTypes.string.isRequired,
  setTableConfig: PropTypes.func.isRequired
};

export default AdjustPopover;
