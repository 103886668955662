import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChangeYearWidget } from './ChangeYearWidget';
import { YearView } from './YearView';

const MonthPicker = props => {
  const {
    selectedDateRange,
    onDayClick,
    lastAvailableDate,
    firstAvailableDate,
    isBigScreen
  } = props;
  const [selectedEndYear, setSelectedEndYear] = useState(selectedDateRange.end.getFullYear());
  const yearsToShow = isBigScreen ? [selectedEndYear - 1, selectedEndYear] : [selectedEndYear];

  const onDoubleLeftClick =
    selectedEndYear - 2 >= firstAvailableDate.getFullYear()
      ? () => setSelectedEndYear(selectedEndYear - 2)
      : null;

  const onLeftClick =
    selectedEndYear - 1 >= firstAvailableDate.getFullYear()
      ? () => setSelectedEndYear(selectedEndYear - 1)
      : null;

  const onDoubleRightClick =
    selectedEndYear + 2 <= lastAvailableDate.getFullYear()
      ? () => setSelectedEndYear(selectedEndYear + 2)
      : null;

  const onRightClick =
    selectedEndYear + 1 <= lastAvailableDate.getFullYear()
      ? () => setSelectedEndYear(selectedEndYear + 1)
      : null;

  return (
    <div className="p-4 flex gap-6">
      {yearsToShow.map((year, index) => (
        <div className="w-72">
          <ChangeYearWidget
            year={year}
            onClick={onDayClick}
            isLeftVIew={!index}
            onDoubleLeftClick={onDoubleLeftClick}
            onLeftClick={onLeftClick}
            onDoubleRightClick={onDoubleRightClick}
            onRightClick={onRightClick}
          />
          <YearView
            year={year}
            onClick={onDayClick}
            selectedDateRange={selectedDateRange}
            firstAvailableDate={firstAvailableDate}
            lastAvailableDate={lastAvailableDate}
          />
        </div>
      ))}
    </div>
  );
};

MonthPicker.propTypes = {
  selectedDateRange: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  onDayClick: PropTypes.func.isRequired,
  firstAvailableDate: PropTypes.instanceOf(Date).isRequired,
  lastAvailableDate: PropTypes.instanceOf(Date).isRequired,
  isBigScreen: PropTypes.bool.isRequired
};

export default MonthPicker;
