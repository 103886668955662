export const toBase64 = file =>
  new Promise(resolve => {
    if (!file.path) resolve(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({ name: file.name, content: reader.result, size: file.size });
  });

// eslint-disable-next-line import/prefer-default-export
export const getApiFormatFiles = files => {
  if (!files) return [];
  return new Promise(resolve => {
    const filePromises = files.reduce((acc, file) => [...acc, toBase64(file)], []);
    Promise.all(filePromises).then(res => resolve(res));
  });
};
