import { compareDesc } from 'date-fns';
import { isObject } from '@utils';
import { ORDER_STATUSES } from '../consts';

export const sortMessageTemplates = (a, b) => {
  const aSortIndex = ORDER_STATUSES.find(status => status.value === a.schedule_event)?.sortIndex;
  const bSortIndex = ORDER_STATUSES.find(status => status.value === b.schedule_event)?.sortIndex;
  if (aSortIndex === bSortIndex) return a.schedule_after - b.schedule_after;
  return aSortIndex - bSortIndex;
};

export const sortMessages = sortField => (lhs, rhs) => {
  const lhsDt = lhs[sortField];
  const rhsDt = rhs[sortField];
  if (isObject(lhsDt) && !isObject(rhsDt)) return 1;
  if (!isObject(lhsDt) && isObject(rhsDt)) return -1;
  if (isObject(lhsDt) && isObject(rhsDt)) {
    return sortMessageTemplates(lhsDt, rhsDt);
  }
  return compareDesc(new Date(rhsDt), new Date(lhsDt));
};
