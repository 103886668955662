import React from 'react';
import Colors from 'styles/colors';
import { InfinityIcon } from '../icons';

const InfinityProgress = isPlus => {
  return (
    <div className="flex justify-center items-center">
      <div className="align-middle" style={{ marginRight: '2px' }}>
        {isPlus ? '+' : '-'}
      </div>
      <InfinityIcon
        fill={isPlus ? Colors.green.default : Colors.red.default}
        width="18px"
        height="18px"
        style={{ marginLeft: '2px', marginRight: '2px' }}
      />
      <div className="align-middle" style={{ marginLeft: '2px' }}>
        %
      </div>
    </div>
  );
};

export default InfinityProgress;
