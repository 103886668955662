import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputBase, IconButton } from '@material-ui/core';
import Colors from 'styles/colors';
import SearchIcon from '../icons/ic_search.svg';
import { CrossIcon } from '../icons';
import { getTextWidth } from './utils';

function DateTableInput(props) {
  const { onChange, keyword, placeholder, ui, ...others } = props;
  const { fontReady } = ui;
  const isKeywordEmpty = keyword.length === 0;

  const inputWidth = useMemo(
    () => `${getTextWidth(!isKeywordEmpty ? keyword : placeholder, 'tracking-normal text-sm')}px`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isKeywordEmpty, keyword, placeholder, fontReady]
  );

  return (
    <div className="max-w-full flex items-center h-6">
      {isKeywordEmpty && <img src={SearchIcon} alt="search" className="mr-2" />}
      <InputBase
        classes={{
          root: 'min-w-0',
          input: `${
            keyword.length > 0 ? 'text-black' : 'text-gray-dark'
          } tracking-normal text-sm text-right py-0`
        }}
        onChange={event => onChange(event.target.value)}
        value={keyword}
        inputProps={{
          'aria-label': 'naked',
          style: {
            width: inputWidth
          }
        }}
        placeholder={placeholder}
        {...others}
      />
      {!isKeywordEmpty && (
        <IconButton className="h-6 w-6 p-1 ml-1" onClick={() => onChange('')}>
          <CrossIcon className="h-3 w-3" fill={Colors.gray.default} />
        </IconButton>
      )}
    </div>
  );
}

DateTableInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default DateTableInput;
