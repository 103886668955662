import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropZone from 'react-dropzone';
import FileIcon from '../icons/FileIcon';
import Colors from '../../styles/colors';

const getOnDropHandler = (maxSize, onAbort, addSelectedFiles) => selectedFiles => {
  const abortedFiles = [];
  const acceptedFiles = [];
  selectedFiles.forEach(item => {
    if (maxSize && item.size > maxSize) {
      abortedFiles.push(item);
      return;
    }
    acceptedFiles.push(item);
  });
  if (abortedFiles.length) onAbort(abortedFiles);
  addSelectedFiles(acceptedFiles);
};

function FileInput({ onAbort, mask, maxSize, selectedFiles, addSelectedFiles, multiple }) {
  const [isHovered, setIsHovered] = useState(false);
  const selected = !!selectedFiles.length;
  const getDropzoneContent = (getRootProps, getInputProps) => {
    const getContent = () => {
      if (!selected) {
        return (
          <div className="mt-6 h-12">Click to select or drag and drop your CSV file here.</div>
        );
      }
      const getSelectedFileNames = () => selectedFiles?.map(x => x.name)?.join(', ');
      return (
        <div className=" h-12 mt-6 flex flex-col items-center  text-green-dark text-xs">
          <div className="mb-2">File attached.</div>
          <div>({getSelectedFileNames()})</div>
        </div>
      );
    };
    return (
      <div
        {...getRootProps()}
        className={classNames(
          'cursor-pointer flex justify-center border items-center self-center overflow-hidden rounded-lg w-128 p-9',
          {
            'border-orange-dashed text-yellow': isHovered && !selected,
            'border-gray-dashed': !isHovered,
            'border-green-dashed': selected,
            'bg-white-green': selected,
            'bg-white-gray': !selected
          }
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ borderColor: Colors.grad.first }}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center">
          <FileIcon hovered={isHovered && !selected} active={selected} />
          {getContent()}
        </div>
      </div>
    );
  };
  const onDrop = getOnDropHandler(maxSize, onAbort, addSelectedFiles);
  return (
    <DropZone onDrop={onDrop} accept={mask} onDropRejected={onAbort} multiple={multiple}>
      {({ getRootProps, getInputProps }) => getDropzoneContent(getRootProps, getInputProps)}
    </DropZone>
  );
}

FileInput.propTypes = {
  onAbort: PropTypes.func,
  mask: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  selectedFiles: PropTypes.instanceOf(Array).isRequired,
  addSelectedFiles: PropTypes.func.isRequired
};

FileInput.defaultProps = {
  onAbort: () => {},
  maxSize: null,
  multiple: false
};
export default FileInput;
