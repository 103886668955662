import API from '@sm/api';
import mock from '../mock';

const products = [
  {
    id: 14490709,
    asin: 'H515DFT59R',
    marketplace: 3,
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    listing_price: 24.97,
    shipping_price: 0.0,
    unit_shipping_cost: 0.08,
    unit_cost: 3.9,
    estimated_fba_fees: 4.16,
    estimated_commission: 3.75,
    estimated_other_fees: 0.0,
    fbm_shipping_cost: 0.0,
    fbm_return_cost: 0.0,
    smallImage:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    sku: 'GG-51B4-DTG2',
    fba: 2138,
    fbm: 0,
    status: 'Active',
    vat: 0.0,
    total_costs: 11.89,
    profit: 13.08,
    net_sales: 24.97,
    margin: 52.38,
    roi: 328.64
  },
  {
    id: 14490716,
    asin: 'A554K46TJL',
    marketplace: 3,
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    listing_price: 39.95,
    shipping_price: 0.0,
    unit_shipping_cost: 1.05,
    unit_cost: 9.8,
    estimated_fba_fees: 4.27,
    estimated_commission: 5.99,
    estimated_other_fees: 0.0,
    fbm_shipping_cost: 0.0,
    fbm_return_cost: 0.0,
    smallImage:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    sku: 'BU-4HE3-PV122',
    fba: 148,
    fbm: 0,
    status: 'Active',
    vat: 0.0,
    total_costs: 21.11,
    profit: 18.84,
    net_sales: 39.95,
    margin: 47.16,
    roi: 173.64
  }
];

const productHcog = [
  {
    id: 123,
    range: { from: '2020-02-13', to: '2020-10-22' },
    unit_cost: 1,
    unit_shipping_cost: 1,
    fbm_shipping_cost: 1,
    fbm_return_cost: 1
  },
  {
    id: 125,
    range: { from: null, to: '2020-01-20' },
    unit_cost: 3,
    unit_shipping_cost: 3,
    fbm_shipping_cost: 3,
    fbm_return_cost: 3
  },
  {
    id: 126,
    range: { from: '2020-01-21', to: '2020-02-12' },
    unit_cost: 2,
    unit_shipping_cost: 2,
    fbm_shipping_cost: 2,
    fbm_return_cost: 2
  }
];

mock.onGet(API.products.list).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  return [200, products];
});

let replyIndex = 0;

mock.onPost(API.products.list).reply(async () => {
  replyIndex += 1;
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  if (replyIndex % 2)
    return [
      400,
      {
        error: {
          message: '112 products had their product costs updated. We have detected 5 problems.',
          details: [
            '112 products had their product costs updated. We have detected 5 problems.',
            '112 products had their product costs updated. We have detected 5 problems.'
          ]
        }
      }
    ];
  return [
    200,
    { details: '112 products had their product costs updated. We have detected 5 problems.' }
  ];
});

mock.onPost(API.products.hCog).reply(async request => {
  console.log(`onPost: ${request.url}`, JSON.parse(request.data));
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  return [200, { id: 111 }];
});

mock.onPut(new RegExp(`${API.products.hCog}/.+`)).reply(async request => {
  console.log(`onPut: ${request.url}`, JSON.parse(request.data));
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  replyIndex += 1;
  return [replyIndex % 2 ? 200 : 409];
});

mock.onDelete(new RegExp(`${API.products.hCog}/.+`)).reply(async request => {
  console.log(`onDelete: ${request.url}`);
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  replyIndex += 1;
  return [replyIndex % 2 ? 200 : 409];
});

mock.onGet(API.products.hCog).reply(async request => {
  console.log('onGet:', request.params);
  await new Promise(resolve => setTimeout(() => resolve(), 1000));
  replyIndex += 1;
  return [200, replyIndex % 2 ? productHcog : null];
});
