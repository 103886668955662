export const PULL_MBR_REPORT = 'PULL_MBR_REPORT';
export const SET_MBR_REPORT = 'SET_MBR_REPORT';
export const SET_MBR_REPORT_LOADING = 'SET_MBR_REPORT_LOADING';
export const SET_MBR_PATCH_LOADING = 'SET_MBR_PATCH_LOADING';
export const PATCH_MBR_REPORT_DATA = 'PATCH_MBR_REPORT_DATA';
export const PULL_PRIMARY_MBR_CHART_DATA = 'PULL_PRIMARY_MBR_CHART_DATA';
export const SET_PRIMARY_MBR_CHART_DATA = 'SET_PRIMARY_MBR_CHART_DATA';
export const SET_PRIMARY_MBR_CHART_LOADING = 'SET_PRIMARY_MBR_CHART_LOADING';
export const PULL_SECONDARY_MBR_CHART_DATA = 'PULL_SECONDARY_MBR_CHART_DATA';
export const SET_SECONDARY_MBR_CHART_DATA = 'SET_SECONDARY_MBR_CHART_DATA';
export const SET_SECONDARY_MBR_CHART_LOADING = 'SET_SECONDARY_MBR_CHART_LOADING';

export const pullMbrReportAction = payload => ({ type: PULL_MBR_REPORT, payload });
export const setMbrReportAction = payload => ({ type: SET_MBR_REPORT, payload });
export const setMbrReportLoadingAction = payload => ({ type: SET_MBR_REPORT_LOADING, payload });
export const patchMbrReportDataAction = (payload, callback) => ({
  type: PATCH_MBR_REPORT_DATA,
  payload,
  callback
});
export const setMbrPatchLoadingAction = payload => ({ type: SET_MBR_PATCH_LOADING, payload });
export const pullPrimaryMbrChartDataAction = payload => ({
  type: PULL_PRIMARY_MBR_CHART_DATA,
  payload
});
export const setPrimaryMbrChartDataAction = payload => ({
  type: SET_PRIMARY_MBR_CHART_DATA,
  payload
});
export const setPrimaryMbrChartLoadingAction = payload => ({
  type: SET_PRIMARY_MBR_CHART_LOADING,
  payload
});

export const pullSecondaryMbrChartDataAction = payload => ({
  type: PULL_SECONDARY_MBR_CHART_DATA,
  payload
});
export const setSecondaryMbrChartDataAction = payload => ({
  type: SET_SECONDARY_MBR_CHART_DATA,
  payload
});
export const setSecondaryMbrChartLoadingAction = payload => ({
  type: SET_SECONDARY_MBR_CHART_LOADING,
  payload
});
