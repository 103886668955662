import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { withStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import TIMEZONES_LIST from '@sm/timezonesList';
import WhiteTooltip from '../common/WhiteTooltip';

const Tooltip = withStyles({
  tooltip: { maxWidth: '12rem !important', background: Colors.black.default }
})(WhiteTooltip);

const timeFormat = 'hh:mma';
const dateFormat = 'MMM d';

function CalendarTooltip(props) {
  const { children, hideTimezone, marketplaces } = props;
  if (!marketplaces) {
    return null;
  }

  const time = new Date();
  const bigMarketplaces = [];

  /* eslint-disable array-callback-return */
  marketplaces.map(marketplace => {
    TIMEZONES_LIST.map(timezone =>
      timezone.regions.find(id => {
        if (id === marketplace && !bigMarketplaces.includes(timezone.id)) {
          bigMarketplaces.push(timezone.id);
        }
      })
    );
  });

  const TooltipContent = (
    <div className="flex flex-col items-start text-sm py-1">
      <p className="text-gray-dark font-medium mb-1">Current Time</p>
      {bigMarketplaces.map(id => {
        const region = TIMEZONES_LIST[id];

        if (region) {
          const marketplaceTime = utcToZonedTime(time, region.timezone);
          const formattedTime = format(marketplaceTime, timeFormat);
          return (
            <p className="text-white mb-1" key={region.title}>
              {formattedTime}
              <span className="ml-1">{region.title}</span>
              <span className="text-gray-dark ml-1">({format(marketplaceTime, dateFormat)})</span>
            </p>
          );
        }
        return null;
      })}
    </div>
  );
  return (
    <Tooltip
      title={!hideTimezone && TooltipContent}
      placement="bottom"
      disableHoverListener={!bigMarketplaces.length}
    >
      <div className="flex">{children}</div>
    </Tooltip>
  );
}

CalendarTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  marketplaces: PropTypes.instanceOf(Array),
  hideTimezone: PropTypes.bool
};

CalendarTooltip.defaultProps = {
  marketplaces: [],
  hideTimezone: false
};

export default CalendarTooltip;
