import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { isDemoBuild } from '@utils';
import ROUTES from 'seller/routing';
import { ImageObserver } from 'seller/containers/observer';
import { loginSelector } from 'seller/store/reducers';
import HomepageHeader, { ALLOWED_SCROLL_HASHES, SCROLL_LINK_OFFSET } from './HomepageHeader';
import HomepagePricing from './HomepagePricing';
import HomepageFooter from './HomepageFooter';
import MoreFeatures from './MoreFeatures';
import Platforms from './Platforms';
import HomepageMarketplaces from './HomepageMarketplaces';
import HomepageChat from './HomepageChat';
import HomepageMainBlock from './HomepageMainBlock';
import MainFeatures from './MainFeatures';
import BlobLeftImage from './img/il_blob-left.svg';
import BlobRightImage from './img/il_blob-right.svg';
import { FeaturesCarousel } from './FeaturesCarousel';

const menuHeight = 1;

const offsetsList = ['70rem', '135rem', '200rem'];

const bubblesContent = offsetsList.map((offset, index) => {
  const side = index % 2 === 0;

  const bubbleStyle = {
    top: offset,
    zIndex: -1,
    ...(side ? { left: 0 } : { right: 0 })
  };

  return (
    <img
      key={offset}
      src={side ? BlobLeftImage : BlobRightImage}
      alt="bubble"
      className="absolute hidden lg:block sm-w-640px"
      style={bubbleStyle}
    />
  );
});

function Homepage({ history, location }) {
  console.log('home page');
  const { token, username } = useSelector(loginSelector);
  if ((token && username) || isDemoBuild()) {
    history.push(ROUTES.home);
  }

  const [menuScroll, setMenuScroll] = useState(false);

  function onScroll() {
    const { scrollY } = window;
    setMenuScroll(scrollY > menuHeight);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    const clearedHash = location.hash?.replace('#', '');
    if (!ALLOWED_SCROLL_HASHES.includes(clearedHash)) return;
    scroller.scrollTo(clearedHash, { smooth: true, offset: SCROLL_LINK_OFFSET });
    history.replace(location.pathname);
  });

  return (
    <ImageObserver
      className="w-full flex flex-col items-center"
      loadingClassname="overflow-hidden absolute w-screen h-screen "
    >
      <HomepageHeader scrollMode={menuScroll} location={location} history={history} />
      <HomepageMainBlock />
      <FeaturesCarousel />
      <MainFeatures />
      <MoreFeatures />
      <Platforms />
      <HomepageMarketplaces />
      <HomepagePricing />
      <HomepageChat />
      <HomepageFooter />
      {bubblesContent}
    </ImageObserver>
  );
}

Homepage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(Homepage);
