import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/index';

export const RoundButton = withStyles({
  root: {
    'border-radius': '50% !important;',
    'min-width': '0px !important;'
  }
})(Button);

export const RoundButton2 = withStyles({
  root: {
    'border-radius': '50% !important;',
    'min-width': '0px !important;',
    padding: '0px !important'
  }
})(Button);
