import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { uiSelector, userPreferencesSelector } from 'seller/store/reducers';
import Colors from 'styles/colors';
import {
  skipAccessDeniedWarningAction,
  skipPpcNeedsReconnectWarningAction,
  skipSpApiExpiredWarningAction
} from 'seller/store/saga/userPreferences';
import { Link } from 'react-router-dom';
import { isLargeSize } from '@sm/WindowSizes';
import { RoundExclamationIcon } from '@components';
import {
  DashboardBannerView,
  BulkHcogsWarningView,
  DASHBOARD_BANNER_HEIGHT,
  BULK_HCOGS_WARNING_HEIGHT_MULTIPLIER
} from '@components/notifications';

import ROUTES from 'seller/routing';
import {
  setBulkHcogsWarningShowAction,
  setSubheaderWarningsHeightAction
} from 'seller/store/actions';

const accountsShouldBeRenewedViewContent = (
  <p className="text-sm sm:text-base text-white">
    Access token rotation is required to continue importing data from Amazon. Please visit the{' '}
    <Link to={ROUTES.accounts}>
      <span className="underline">Seller Accounts</span>
    </Link>{' '}
    page for re-link your Seller Central account(s).
  </p>
);
const spApiProblemWarningContent = (
  <p className="text-sm sm:text-base text-white">
    There are problems with SP-API connection. Visit the{' '}
    <Link to={ROUTES.accounts}>
      <span className="underline">Seller Accounts</span>
    </Link>{' '}
    page to reconnect it.
    <span className="hidden md:inline">
      {' '}
      Please contact support@sellermetrix.com if problem persists.
    </span>
  </p>
);
const ppcWarningContent = (
  <p className="text-sm sm:text-base text-white">
    There is an issue with the PPC data connection. Visit the{' '}
    <Link to={ROUTES.accounts}>
      <span className="underline">Seller Accounts</span>
    </Link>{' '}
    page to reconnect it.
    <span className="hidden md:inline">
      {' '}
      Please contact support@sellermetrix.com if problem persists.
    </span>
  </p>
);
const notFilledCompanyCountryWarningContent = (
  <p className="text-sm sm:text-base text-white">
    Please complete your address by visiting the{' '}
    <Link to={ROUTES.profile.general}>
      <span className="underline">Profile</span>
    </Link>{' '}
    page to fill it.
    <span className="hidden md:inline">
      {' '}
      Please contact support@sellermetrix.com if problem persists.
    </span>
  </p>
);

const SystemNotificationView = ({ className, extraNotifications }) => {
  const dispatch = useDispatch();
  const { accessDeniedBannerShow, spApiExpiredWarningShow } = useSelector(userPreferencesSelector);
  const { bulkHcogsWarning, notFilledCompanyCountryWarningShow, ppcWarningShow } = useSelector(
    uiSelector
  );
  const onSkipAccountsWarning = () => dispatch(skipAccessDeniedWarningAction());
  const onSkipSpApiExpiredWarning = () => dispatch(skipSpApiExpiredWarningAction());
  const onSkipPpcWarning = () => dispatch(skipPpcNeedsReconnectWarningAction());
  const onSkipBulkHcogsWarning = () => dispatch(setBulkHcogsWarningShowAction(null));
  const [bulkHcogsWarningExpanded, setBulkHcogsWarningExpanded] = useState(false);

  const bulkHcogsWarningHeight =
    DASHBOARD_BANNER_HEIGHT * (bulkHcogsWarningExpanded ? BULK_HCOGS_WARNING_HEIGHT_MULTIPLIER : 1);
  const warningViews = [
    {
      view: (
        <DashboardBannerView onClose={onSkipAccountsWarning} content={spApiProblemWarningContent} />
      ),
      show: accessDeniedBannerShow,
      height: DASHBOARD_BANNER_HEIGHT
    },
    {
      view: (
        <DashboardBannerView
          onClose={onSkipSpApiExpiredWarning}
          content={accountsShouldBeRenewedViewContent}
          className="bg-yellow-dark"
          startIcon={<RoundExclamationIcon fill={Colors.white.default} />}
        />
      ),
      show: spApiExpiredWarningShow,
      height: DASHBOARD_BANNER_HEIGHT
    },
    {
      view: <DashboardBannerView onClose={onSkipPpcWarning} content={ppcWarningContent} />,
      show: ppcWarningShow,
      height: DASHBOARD_BANNER_HEIGHT
    },
    {
      view: (
        <DashboardBannerView
          content={notFilledCompanyCountryWarningContent}
          className="bg-yellow-dark"
          startIcon={<RoundExclamationIcon fill={Colors.white.default} />}
        />
      ),
      show: notFilledCompanyCountryWarningShow,
      height: DASHBOARD_BANNER_HEIGHT
    },
    {
      view: (
        <BulkHcogsWarningView
          expanded={bulkHcogsWarningExpanded}
          setExpanded={setBulkHcogsWarningExpanded}
          onClose={onSkipBulkHcogsWarning}
          content={bulkHcogsWarning}
          height={bulkHcogsWarningHeight}
        />
      ),
      show: bulkHcogsWarning,
      height: bulkHcogsWarningHeight
    },
    ...extraNotifications
  ];

  const warningsToShow = warningViews.filter(item => item.show);
  const viewsHeight = warningsToShow.reduce((acc, item) => acc + +item.height, 0);
  const paddingHeight = isLargeSize() ? 0.5 : 1;
  const spacingHeight = (warningsToShow.length - 1) * (isLargeSize() ? 0.5 : 1);
  const subheaderWarningsHeight = viewsHeight + spacingHeight + paddingHeight;

  useEffect(() => {
    dispatch(setSubheaderWarningsHeightAction(subheaderWarningsHeight));
  }, [dispatch, subheaderWarningsHeight]);

  if (!warningsToShow.length) return null;
  return (
    <div className={classNames('pb-2 lg:pb-4', className)}>
      {warningsToShow.map((item, i) => {
        return (
          <div key={item.view} className={classNames({ 'mt-2 lg:mt-4': i !== 0 })}>
            {item.view}
          </div>
        );
      })}
    </div>
  );
};

SystemNotificationView.propTypes = {
  className: PropTypes.string,
  extraNotifications: PropTypes.arrayOf(PropTypes.object)
};

SystemNotificationView.defaultProps = {
  className: '',
  extraNotifications: []
};

export default SystemNotificationView;
