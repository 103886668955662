import { cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { axiosCall, getCancelToken } from '@utils';
import { API_V2_1, rangeToAPI } from '@sm/api';
import { selectorSelector } from 'seller/store/reducers';
import { setNotificationMsgAction } from 'seller/store/actions';
import {
  PULL_SQP,
  PULL_SQP_STATUS,
  setSqpAccountsStatusesAction,
  setSqpAction,
  setSqpLoadingAction,
  setSqpStatusAction
} from './index';

const pullSqpForDateRange = async (cancelToken, marketplaceID, dateRange) => {
  if (!dateRange) return [];
  const params = {
    marketplaceID,
    ...rangeToAPI(dateRange)
  };
  const { data } = await axiosCall.get(API_V2_1.searchQueryPerformance.products, {
    params,
    cancelToken
  });
  data.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    if (!item.marketplace) item.marketplace = marketplaceID;
    // eslint-disable-next-line no-param-reassign
    if (!item.name) item.name = 'Unknown product';
  });
  return data;
};

function* pullSqpSaga() {
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    yield put(setSqpLoadingAction(true));
    const { dateRange, compareDateRange, marketplaceID } = yield select(selectorSelector);
    const [mainSqpList, compareSqpList] = yield Promise.all([
      pullSqpForDateRange(cancelToken, marketplaceID, dateRange),
      pullSqpForDateRange(cancelToken, marketplaceID, compareDateRange)
    ]);
    yield put(setSqpAction({ mainSqpList, compareSqpList }));
  } catch (error) {
    yield put(setSqpAction({ mainSqpList: [], compareSqpList: [] }));
    yield put(
      setNotificationMsgAction(
        'Pull data failed. Please contact support@sellermetrix.com if problem persists.'
      )
    );
  } finally {
    if (yield cancelled()) cancelRequest();
    yield put(setSqpLoadingAction(false));
  }
}

function* pullSqpStatusSaga() {
  try {
    const { data: status } = yield axiosCall.get(API_V2_1.searchQueryPerformance.status);
    yield put(setSqpStatusAction(status));
    const { data: accounts } = yield axiosCall.get(API_V2_1.searchQueryPerformance.accounts);
    yield put(setSqpAccountsStatusesAction(accounts));
  } catch (error) {
    yield put(
      setNotificationMsgAction(
        'Pull data failed. Please contact support@sellermetrix.com if problem persists.'
      )
    );
  }
}

export function* amazonTrafficSagaWatcher() {
  yield takeLatest(PULL_SQP, pullSqpSaga);
  yield takeLatest(PULL_SQP_STATUS, pullSqpStatusSaga);
}
