import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'styles/colors';
import { BanIcon } from '../icons';
import { GradButton } from '../buttons';

function AccountCardNoAccess(props) {
  const { onConnectSPA, mainMarketplace } = props;
  return (
    <div className="flex flex-col items-center mt-1">
      <div className="bg-red flex justify-between items-center px-2 py-4 rounded mt-2 w-full">
        <BanIcon fill={Colors.white.default} className="sm-w-14px sm-h-14px" />
        <p className="text-white text-sm">No Access to Seller Central Account</p>
      </div>
      <p className="text-xs mt-2 text-gray-dark">
        <span className="mr-1">
          Re-establishing access just takes 5 minutes. You can read our step-by-step guide
        </span>
        <a
          className="text-yellow hover:underline"
          href="https://intercom.help/sellermetrix/en/articles/2850575-how-to-generate-a-new-mws-auth-token-in-seller-central"
        >
          here.
        </a>
      </p>
      <GradButton
        onClick={() => onConnectSPA(mainMarketplace)}
        className="mt-4 box-shadow-default text-xs"
      >
        Connect to Seller Central Account
      </GradButton>
    </div>
  );
}

AccountCardNoAccess.propTypes = {
  onConnectSPA: PropTypes.func.isRequired,
  mainMarketplace: PropTypes.string.isRequired
};

export default AccountCardNoAccess;
