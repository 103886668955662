const csvColumns = {
  name: 'Product Name',
  asin: 'ASIN',
  sku: 'SKU',
  parent_asin: 'Parent ASIN',
  variation_type_one: 'Variation Theme1',
  variation_value_one: 'Variation Value1',
  variation_type_two: 'Variation Theme2',
  variation_value_two: 'Variation Value2',
  marketplace: 'Marketplace',
  currency: 'Currency',
  orders: 'Orders',
  organic_orders: 'Organic Orders',
  ppc_orders: 'PPC Orders',
  units: 'Units',
  full_units_qty: 'Full Price Units',
  promo_units: 'Promo Units',
  sales: 'Sales',
  discounts: 'Discounts',
  new_tax: 'Tax Collected',
  new_mpft: 'MPFT',
  gift_wrap: 'Gift Wrap',
  quantity_refunded: 'Quantity Refunded',
  total_refunds: 'Refunds',
  total_ads: 'AdSpend',
  fba_fees: 'FBA Fees',
  referral_fees: 'Referral Fees',
  tax_paid: 'Tax Paid',
  // gp: 'Gross Profit ',
  est_tax_unpaid: 'Estimated Tax Unpaid',
  fbm_shipping: 'FBM Shipping',
  shipping_cost: 'Inbound shipping',
  production_cost: 'Product Cost',
  profit: 'Net Profit',
  margin: 'Margin',
  roi: 'ROI',
  fba: 'FBA Inventory',
  fbm: 'FBM Inventory',
  sessions: 'Sessions',
  mobile_sessions: 'Sessions - Mobile App',
  browser_sessions: 'Sessions - Browser',
  conv_rate: 'Conv. Rate'
};

export default csvColumns;
