export const PULL_EXPENSES_LIST = 'PULL_EXPENSES_LIST';
export const SET_EXPENSES_LIST = 'SET_EXPENSES_LIST';
export const PULL_EXPENSE_DETAILS = 'PULL_EXPENSE_DETAILS';
export const UPDATE_EXPENSE_DETAILS = 'UPDATE_EXPENSE_DETAILS';
export const SET_EXPENSE_DETAILS = 'SET_EXPENSE_DETAILS';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const CREATE_EXPENSE = 'CREATE_EXPENSE';
export const SET_EXPENSES_FETCHING = 'SET_EXPENSES_FETCHING';
export const SET_EXPENSE_PROCESSING = 'SET_EXPENSE_PROCESSING';
export const ADD_PROCESSING_EXPENSE = 'ADD_PROCESSING_EXPENSE';
export const REMOVE_PROCESSING_EXPENSE = 'REMOVE_PROCESSING_EXPENSE';
export const SET_EXPENSES_PAGE = 'SET_EXPENSES_PAGE';
export const SET_SELECTED_EXPENSES_CATEGORY = 'SET_SELECTED_EXPENSES_CATEGORY';
export const SET_SHOW_ALL_EXPENSES = 'SET_SHOW_ALL_EXPENSES';

export const pullExpenseDetailsAction = payload => ({ type: PULL_EXPENSE_DETAILS, payload });
export const setExpensesListAction = payload => ({ type: SET_EXPENSES_LIST, payload });
export const pullExpensesListAction = (params, callback) => ({
  type: PULL_EXPENSES_LIST,
  params,
  callback
});
export const updateExpenseDetailsAction = payload => ({ type: UPDATE_EXPENSE_DETAILS, payload });
export const setExpenseDetailsAction = payload => ({ type: SET_EXPENSE_DETAILS, payload });

export const deleteExpenseAction = payload => ({ type: DELETE_EXPENSE, payload });
export const createExpenseAction = payload => ({ type: CREATE_EXPENSE, payload });
export const setExpensesFetchingAction = payload => ({ type: SET_EXPENSES_FETCHING, payload });
export const setExpenseProcessingAction = payload => ({ type: SET_EXPENSE_PROCESSING, payload });
export const addProcessingExpenseAction = payload => ({ type: ADD_PROCESSING_EXPENSE, payload });
export const removeProcessingExpenseAction = payload => ({
  type: REMOVE_PROCESSING_EXPENSE,
  payload
});
export const setExpensesPageAction = payload => ({ type: SET_EXPENSES_PAGE, payload });
export const setSelectedExpensesCategoryAction = payload => ({
  type: SET_SELECTED_EXPENSES_CATEGORY,
  payload
});
export const setShowAllExpensesAction = payload => ({ type: SET_SHOW_ALL_EXPENSES, payload });
