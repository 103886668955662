import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, createMigrate } from 'redux-persist';
import productSearch, { productSearchSelector } from './productSearch.reducer';
import user, { userSelector } from './user.reducer';
import selector, { selectorSelector } from './selector.reducer';
import dashboard, { dashboardSelector } from './dashboard.reducer';
import orders, { ordersSelector } from './orders.reducer';
import app from './app.reducer';
import products, { productsSelector } from './products.reducer';
import login, { loginSelector } from './login.reducer';
import network, { networkSelector } from './network.reducer';
import billing from './billing.reducer';
import notes from './notes.reducer';
import refunds from './refunds.reducer';
import agency, { agencySelector } from './agency.reducer';
import ui, { uiSelector } from './ui.reducer';
import dataTable, { dataTableSelector } from './dataTable.reducer';
import emailTool, { emailToolStateSelector } from './emailTool.reducer';
import inventory, { inventorySelector } from './inventory.reducer';
import notification, { notificationSelector } from './notification.reducer';
import userPreferences, { userPreferencesSelector } from './userPreferences.reducer';
import sentry, { sentrySelector } from './sentry.reducer';
import reactBoundary, { reactBoundarySelector } from './reactBoundary.reducer';
import globalSystemNotification, {
  globalSystemNotificationSelector
} from './globalSystemNotification.reducer';
import reports, { reportsSelector } from './reports.reducer';
import expenses, { expensesSelector } from './expenses.reducer';
import amazonTraffic, { amazonTrafficSelector } from './amazonTraffic.reducer';
import monthlyBrandReview, { monthlyBrandReviewSelector } from './monthlyBrandReview.reducer';
import pnlDashboard, { pnlDashboardSelector } from './pnlDashboard.reducer';
import advertising, { advertisingSelector } from './advertising.reducer';
import { SET_NOTIFICATION_MSG, setTokenAction, USER_LOGOUT, userLogoutAction } from '../actions';
import { loginReducerMigration } from '../migration';

export const LOGIN_REDUCER_KEY = 'login';
export const REHYDRATE_ACTION_TYPE = 'persist/REHYDRATE';

const createReducer = asyncReducers =>
  combineReducers({
    ui,
    notification,
    globalSystemNotification,
    reactBoundary,
    userPreferences,
    dataTable,
    emailTool,
    agency,
    app,
    billing,
    dashboard,
    login: persistReducer(
      {
        key: LOGIN_REDUCER_KEY,
        storage,
        blacklist: ['token'],
        version: 1,
        migrate: createMigrate(loginReducerMigration)
      },
      login
    ),
    network,
    notes,
    orders,
    products,
    productSearch,
    refunds,
    selector,
    user,
    inventory,
    reports,
    expenses,
    amazonTraffic,
    monthlyBrandReview,
    pnlDashboard,
    advertising,
    sentry,
    ...asyncReducers
  });

const nonPersistReducers = {
  ui,
  notification,
  dataTable,
  emailTool,
  agency,
  app,
  billing,
  dashboard,
  network,
  notes,
  orders,
  products,
  productSearch,
  refunds,
  selector,
  user,
  inventory,
  reports,
  expenses,
  amazonTraffic,
  monthlyBrandReview,
  pnlDashboard,
  advertising,
  sentry
};

const getInitialState = () =>
  Object.keys(nonPersistReducers).reduce(
    (acc, key) => ({ ...acc, [key]: nonPersistReducers[key](undefined, {}) }),
    {}
  );

const persistReducersLogoutActions = [() => setTokenAction(null), () => userLogoutAction()]; // FIXME: move token from local-storage to redux-persist

const appReducer = createReducer();

const rootReducer = (state, action) => {
  if (action.type === SET_NOTIFICATION_MSG && state.network.serverUnavailable && action.msg) {
    return state;
  }
  if (action.type === USER_LOGOUT) {
    const initialState = getInitialState();
    initialState.ui = {
      ...initialState.ui,
      fontReady: true,
      viewportHeight: state.ui.viewportHeight
    };
    const persistReducersInitialState = persistReducersLogoutActions.reduce(
      (accState, logoutAction) => appReducer(accState, logoutAction()),
      state
    );
    return { ...persistReducersInitialState, ...initialState };
  }
  return appReducer(state, action);
};

export {
  dashboardSelector,
  emailToolStateSelector,
  userSelector,
  uiSelector,
  dataTableSelector,
  productSearchSelector,
  ordersSelector,
  selectorSelector,
  productsSelector,
  networkSelector,
  agencySelector,
  loginSelector,
  inventorySelector,
  notificationSelector,
  globalSystemNotificationSelector,
  userPreferencesSelector,
  reactBoundarySelector,
  reportsSelector,
  expensesSelector,
  amazonTrafficSelector,
  monthlyBrandReviewSelector,
  advertisingSelector,
  sentrySelector,
  pnlDashboardSelector
};

export default rootReducer;
