import API from '@sm/api';
import mock from '../mock';

const dataResponse = {
  stats: {
    used_model: 'BrandDateSummaryByMonthsMirrorFirst',
    to_determine_proxy: 0.010666624642908573,
    to_proxy_blocking_lock: 0.0006518922746181488,
    to_table_to_select: 0.003091607242822647,
    to_data_pull: 0.011931397020816803,
    to_denormalize_metrics: 0.0008088452741503716,
    to_merge_by_intervals: 0.01864502113312483,
    to_merge_target_metrics: 0.0006129313260316849,
    to_get_children_structure: 0.00041896477341651917,
    to_fill_children_structure_items: 0.0030897166579961777,
    to_processing_total: 0.02357547916471958
  },
  config: {
    levels: ['metric'],
    time_grain: 'month',
    dates: [
      '2024-09-01',
      '2024-08-01',
      '2024-07-01',
      '2024-06-01',
      '2024-05-01',
      '2024-04-01',
      '2024-03-01'
    ]
  },
  data: [
    {
      type: 'metric',
      metadata: {
        name: 'gross_sales',
        type: 'metric'
      },
      name: 'gross_sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'refunds',
            type: 'metric'
          },
          name: 'refunds',
          children: [],
          values: [-6073.37, -1097.12, -680.93, -830.22, -700.87, -927.64, -910.15, -926.44],
          targets: [
            -13584.96,
            -1938.22,
            -1951.17,
            -2032.73,
            -1946.41,
            -1919.55,
            -1892.33,
            -1904.55
          ],
          values_percentages: [1.08, 1.49, 0.91, 1.03, 0.92, 1.07, 1.11, 1.05],
          targets_percentages: [2.12, 2.12, 2.12, 2.1, 2.12, 2.12, 2.12, 2.12]
        },
        {
          type: 'metric',
          metadata: {
            name: 'discounts',
            type: 'metric'
          },
          name: 'discounts',
          children: [],
          values: [-16265.85, -2732.32, -2534.77, -3226.73, -2560.2, -2278.83, -1548.39, -1384.61],
          targets: [-13754.32, -1723.2, -1837.99, -2306.09, -1839.0, -2102.11, -1778.1, -2167.83],
          values_percentages: [2.9, 3.71, 3.39, 4.01, 3.38, 2.64, 1.89, 1.56],
          targets_percentages: [2.14, 1.88, 1.99, 2.39, 2.0, 2.32, 1.99, 2.42]
        },
        {
          type: 'metric',
          metadata: {
            name: 'other_credits_and_refunds',
            type: 'metric'
          },
          name: 'other_credits_and_refunds',
          children: [],
          values: [9646.64, 1265.72, 1057.46, 1138.43, 1623.17, 1075.77, 2109.68, 1376.41],
          targets: [12494.92, 1785.0, 1784.98, 1784.98, 1785.0, 1784.98, 1785.0, 1784.98],
          values_percentages: [1.72, 1.72, 1.41, 1.41, 2.14, 1.25, 2.58, 1.56],
          targets_percentages: [1.95, 1.95, 1.94, 1.85, 1.94, 1.97, 2.0, 1.99]
        }
      ],
      values: [561440.08, 73703.27, 74872.12, 80465.01, 75791.36, 86301.01, 81807.23, 88500.08],
      targets: [641408.5, 91432.82, 92162.09, 96572.86, 91812.0, 90537.84, 89127.9, 89762.99],
      values_percentages: [100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0],
      targets_percentages: [100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0]
    },
    {
      type: 'metric',
      metadata: {
        name: 'net_sales',
        type: 'metric'
      },
      name: 'net_sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'net_cogs',
            type: 'metric'
          },
          name: 'net_cogs',
          children: [],
          values: [
            -192094.22,
            -23141.56,
            -25498.88,
            -28111.92,
            -24935.07,
            -30002.15,
            -28853.9,
            -31550.74
          ],
          targets: [
            -209239.55,
            -29625.92,
            -29732.13,
            -32184.24,
            -29577.9,
            -29219.31,
            -29307.9,
            -29592.15
          ],
          values_percentages: [35.01, 32.53, 35.07, 36.25, 33.63, 35.64, 35.42, 36.03],
          targets_percentages: [33.39, 33.08, 32.98, 34.23, 32.93, 33.09, 33.59, 33.83]
        }
      ],
      values: [548747.5, 71139.55, 72713.88, 77546.49, 74153.46, 84170.31, 81458.37, 87565.44],
      targets: [626568.3, 89557.83, 90157.67, 94020.76, 89811.89, 88302.69, 87242.77, 87474.69],
      values_percentages: [97.74, 96.52, 97.12, 96.37, 97.84, 97.53, 99.57, 98.94],
      targets_percentages: [97.69, 97.95, 97.83, 97.36, 97.82, 97.53, 97.88, 97.45]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_1',
        type: 'metric'
      },
      name: 'cm_1',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'marketplace_costs',
            type: 'metric'
          },
          name: 'marketplace_costs',
          children: [],
          values: [
            -260011.99,
            -31165.45,
            -37875.74,
            -43973.92,
            -33757.09,
            -37092.72,
            -37128.84,
            -39018.23
          ],
          targets: [
            -268629.54,
            -38071.1,
            -38405.93,
            -40977.08,
            -38279.15,
            -37700.02,
            -37507.22,
            -37689.04
          ],
          values_percentages: [47.38, 43.81, 52.09, 56.71, 45.52, 44.07, 45.58, 44.56],
          targets_percentages: [42.87, 42.51, 42.6, 43.58, 42.62, 42.69, 42.99, 43.09]
        },
        {
          type: 'metric',
          metadata: {
            name: 'non_marketplace_costs',
            type: 'metric'
          },
          name: 'non_marketplace_costs',
          children: [],
          values: [
            -168406.72,
            -69840.0,
            -13677.51,
            -1316.88,
            -34077.3,
            -26654.73,
            -15294.9,
            -7545.4
          ],
          targets: [-65589.17, -6024.0, -6018.03, -15212.01, -7325.1, -7307.01, -16494.9, -7208.12],
          values_percentages: [30.69, 98.17, 18.81, 1.7, 45.96, 31.67, 18.78, 8.62],
          targets_percentages: [10.47, 6.73, 6.68, 16.18, 8.16, 8.27, 18.91, 8.24]
        }
      ],
      values: [356653.1, 47998.04, 47214.91, 49434.44, 49218.42, 54168.26, 52604.35, 56014.68],
      targets: [417330.17, 59931.98, 60425.83, 61837.04, 60233.69, 59084.09, 57934.87, 57882.67],
      values_percentages: [64.99, 67.47, 64.93, 63.75, 66.37, 64.36, 64.58, 63.97],
      targets_percentages: [66.61, 66.92, 67.02, 65.77, 67.07, 66.91, 66.41, 66.17]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_2',
        type: 'metric'
      },
      name: 'cm_2',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'marketing_and_advertising',
            type: 'metric'
          },
          name: 'marketing_and_advertising',
          children: [],
          values: [-46835.68, -7232.31, -7415.49, -7398.21, -6732.43, -6219.52, -5841.98, -5995.74],
          targets: [
            -79648.41,
            -13506.9,
            -9048.28,
            -10716.08,
            -8670.0,
            -13074.25,
            -16665.9,
            -7967.0
          ],
          values_percentages: [8.54, 10.17, 10.2, 9.54, 9.08, 7.39, 7.17, 6.85],
          targets_percentages: [12.71, 15.08, 10.04, 11.4, 9.65, 14.81, 19.1, 9.11]
        }
      ],
      values: [-71765.31, -53007.38, -4338.24, 4143.73, -18615.9, -9579.13, 180.65, 9450.96],
      targets: [83103.36, 15834.56, 16001.31, 5646.52, 14627.22, 14075.64, 3931.45, 12986.66],
      values_percentages: [13.08, 74.51, 5.97, 5.34, 25.1, 11.38, 0.22, 10.79],
      targets_percentages: [13.26, 17.68, 17.75, 6.01, 16.29, 15.94, 4.51, 14.85]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_3',
        type: 'metric'
      },
      name: 'cm_3',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'operating_expenses',
            type: 'metric'
          },
          name: 'operating_expenses',
          children: [],
          values: [-73252.47, -1511.4, -14854.27, 17578.24, 17421.6, -13868.78, -71158.8, -6859.06],
          targets: [-10483.03, -1446.9, -1447.08, -1487.07, -1446.9, -1567.98, -1568.1, -1519.0],
          values_percentages: [13.35, 2.12, 20.43, 22.67, 23.49, 16.48, 87.36, 7.83],
          targets_percentages: [1.67, 1.62, 1.61, 1.58, 1.61, 1.78, 1.8, 1.74]
        },
        {
          type: 'metric',
          metadata: {
            name: 'other_income',
            type: 'metric'
          },
          name: 'other_income',
          children: [],
          values: [2250.9, 2250.9, 0, 0, 0, 0, 0, 0],
          targets: [0, 0, 0, 0, 0, 0, 0, 0],
          values_percentages: [0.41, 3.16, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
          targets_percentages: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
        }
      ],
      values: [-118601.09, -60239.8, -11753.73, -3254.49, -25348.33, -15798.62, -5661.33, 3455.21],
      targets: [3457.71, 2328.56, 6953.65, -5069.25, 5958.42, 1002.63, -12735.65, 5019.35],
      values_percentages: [21.61, 84.68, 16.16, 4.2, 34.18, 18.77, 6.95, 3.95],
      targets_percentages: [0.55, 2.6, 7.71, 5.39, 6.63, 1.14, 14.6, 5.74]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_4',
        type: 'metric'
      },
      name: 'cm_4',
      children: [],
      values: [-189602.66, -59500.3, -26608.0, 14323.75, -7926.73, -29667.4, -76820.13, -3403.85],
      targets: [-7029.29, 880.46, 5505.64, -6556.32, 4510.62, -566.59, -14303.45, 3500.35],
      values_percentages: [34.55, 83.64, 36.59, 18.47, 10.69, 35.25, 94.31, 3.89],
      targets_percentages: [1.12, 0.98, 6.11, 6.97, 5.02, 0.64, 16.39, 4.0]
    }
  ]
};

mock.onGet(API.pnl_dashboard.metrics).reply(async config => {
  console.log('config', config);
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200, dataResponse];
});

const brandsResponse = [
  {
    client_id: 4672,
    name: 'BABR'
  },
  {
    client_id: 4685,
    name: '#4685'
  },
  {
    client_id: 5807,
    name: '#5807'
  },
  {
    client_id: 5820,
    name: '#5820'
  }
];
mock.onGet(API.pnl_dashboard.brands).reply(() => {
  return [200, brandsResponse];
});
