import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ROUTES from 'seller/routing';
import VideoDialog from '@components/dialogs/VideoDialog';
import { WebImg } from '@components';
import mainBlockWebImg from './img/main-block';

function HomepageMainBlock() {
  const [dialog, setDialog] = useState(false);

  return (
    <div className="w-full flex flex-col md:h-3/4vh items-center">
      <div className="flex flex-col w-11/12 mt-24 md:mt-40 z-10">
        <h2 className="text-gray-dark text-lg md:text-xl mr-2">
          Sales & profit analytics, generate product reviews & automate email follow-up
        </h2>
        <h1 className="font-bold mt-4 leading-tight sm-text-40px lg:text-6xl">
          Manage & Grow your
          <br /> Amazon Business -<br /> For Sellers & Agencies
        </h1>
        <div className="flex mt-4 md:mt-6">
          <Link to={ROUTES.registration}>
            <Button className="text-white bg-grad-3 rounded-none py-3 text-base font-medium normal-case rounded mr-6 sm-w-144px sm-h-52px sm-button-green-shadow">
              Start Free Trial
            </Button>
          </Link>
          <Button
            onClick={() => setDialog(true)}
            className="text-white bg-grad-8 rounded-none py-3 text-base font-medium normal-case rounded mr-6 sm-w-144px sm-h-52px sm-button-green-shadow"
          >
            Watch Video
          </Button>
        </div>
      </div>
      <WebImg
        {...mainBlockWebImg}
        mobileMediaQuery="(max-width:768px)"
        imgClassName="w-full md:w-auto static md:absolute top-0 right-0 md:h-3/4vh z-0"
        alt="main-block"
      />
      <VideoDialog
        src="https://www.youtube.com/embed/a1t8olQHC7w"
        open={dialog}
        onClose={() => setDialog(null)}
      />
    </div>
  );
}

export default HomepageMainBlock;
