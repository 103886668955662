/* eslint-disable global-require */
import { getWebpImg } from '@utils';

const images = {
  'il_feature1-mobile.png': require('./il_feature1-mobile.png'),
  'il_feature1-mobile.webp': require('./il_feature1-mobile.webp'),
  'il_feature1.png': require('./il_feature1.png'),
  'il_feature1.webp': require('./il_feature1.webp'),
  'il_feature2-mobile.png': require('./il_feature2-mobile.png'),
  'il_feature2-mobile.webp': require('./il_feature2-mobile.webp'),
  'il_feature2.png': require('./il_feature2.png'),
  'il_feature2.webp': require('./il_feature2.webp'),
  'il_feature3-mobile.png': require('./il_feature3-mobile.png'),
  'il_feature3-mobile.webp': require('./il_feature3-mobile.webp'),
  'il_feature3.png': require('./il_feature3.png'),
  'il_feature3.webp': require('./il_feature3.webp'),
  'il_feature4-mobile.png': require('./il_feature4-mobile.png'),
  'il_feature4-mobile.webp': require('./il_feature4-mobile.webp'),
  'il_feature4.png': require('./il_feature4.png'),
  'il_feature4.webp': require('./il_feature4.webp'),
  'il_feature5-mobile.png': require('./il_feature5-mobile.png'),
  'il_feature5-mobile.webp': require('./il_feature5-mobile.webp'),
  'il_feature5.png': require('./il_feature5.png'),
  'il_feature5.webp': require('./il_feature5.webp'),
  'il_feature6-mobile.png': require('./il_feature6-mobile.png'),
  'il_feature6-mobile.webp': require('./il_feature6-mobile.webp'),
  'il_feature6.png': require('./il_feature6.png'),
  'il_feature6.webp': require('./il_feature6.webp'),
  'il_feature7-mobile.png': require('./il_feature7-mobile.png'),
  'il_feature7-mobile.webp': require('./il_feature7-mobile.webp'),
  'il_feature7.png': require('./il_feature7.png'),
  'il_feature7.webp': require('./il_feature7.webp'),
  'il_feature8-mobile.png': require('./il_feature8-mobile.png'),
  'il_feature8-mobile.webp': require('./il_feature8-mobile.webp'),
  'il_feature8.png': require('./il_feature8.png'),
  'il_feature8.webp': require('./il_feature8.webp'),
  'il_feature9-mobile.png': require('./il_feature9-mobile.png'),
  'il_feature9-mobile.webp': require('./il_feature9-mobile.webp'),
  'il_feature9.png': require('./il_feature9.png'),
  'il_feature9.webp': require('./il_feature9.webp'),
  'il_feature10-mobile.png': require('./il_feature10-mobile.png'),
  'il_feature10-mobile.webp': require('./il_feature10-mobile.webp'),
  'il_feature10.png': require('./il_feature10.png'),
  'il_feature10.webp': require('./il_feature10.webp')
};
const featureWebImgs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce(
  (acc, id) => ({ ...acc, [`il_feature${id}`]: getWebpImg(images, `il_feature${id}`) }),
  {}
);
export default featureWebImgs;
