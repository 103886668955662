import { utcToZonedTime } from 'date-fns-tz';

import flagAustralia from './img/flag_australia.png';
import flagCanada from './img/flag_canada.png';
import flagFrance from './img/flag_france.png';
import flagGermany from './img/flag_germany.png';
import flagGlobe from './img/flag_globe.png';
import flagIndia from './img/flag_india.png';
import flagItaly from './img/flag_italy.png';
import flagJapan from './img/flag_japan.png';
import flagMexico from './img/flag_mexico.png';
import flagSpain from './img/flag_spain.png';
import flagUk from './img/flag_uk.png';
import flagUsa from './img/flag_usa.png';
import flagBrazil from './img/flag_brazil.png';
import flagSingapore from './img/flag_singapore.png';
import flagUAE from './img/flag_uae.png';
import flagTurkey from './img/flag_turkey.png';
import flagSweden from './img/flag_sweden.png';
import flagNetherlands from './img/flag_netherlands.png';
import flagSaudiArabia from './img/flag_saudi-arabia.png';
import flagPoland from './img/flag_poland.png';
import flagEgypt from './img/flag_egypt.png';
import flagBelgium from './img/flag_belgium.png';

export const EU_REGION = 'Europe & Middle East';
export const NA_REGION = 'North America';
export const FE_REGION = 'Asia-Pacific';
export const INDIA_REGION = 'India';

export const GLOBAL_MK = 0;

const FLAGS = [
  {
    src: flagGlobe,
    id: GLOBAL_MK,
    regionName: 'Global',
    name: 'Global',
    sort: 20
  },
  {
    src: flagCanada,
    id: 1,
    marketplaceid: 'A2EUQ1WTGCTBG2',
    regionName: NA_REGION,
    name: 'Canada',
    timezone: 'America/Los_Angeles',
    currency: 'CAD',
    abbreviation: 'CA',
    sort: 2,
    domain: 'ca',
    languages: ['English', 'French']
  },
  {
    src: flagMexico,
    id: 2,
    marketplaceid: 'A1AM78C64UM0Y8',
    regionName: NA_REGION,
    name: 'Mexico',
    timezone: 'America/Mexico_City',
    currency: 'MXN',
    abbreviation: 'MX',
    sort: 3,
    domain: 'com.mx',
    languages: ['Spanish', 'English']
  },
  {
    src: flagUsa,
    id: 3,
    marketplaceid: 'ATVPDKIKX0DER',
    regionName: NA_REGION,
    name: 'USA',
    timezone: 'America/Los_Angeles',
    currency: 'USD',
    abbreviation: 'USA',
    sort: 1,
    domain: 'com',
    languages: [
      'English',
      'Spanish',
      'German',
      'Portuguese',
      'Chinese(Simplified)',
      'Chinese(Traditional)',
      'Korean',
      'Hebrew'
    ]
  },
  {
    src: flagGermany,
    id: 4,
    marketplaceid: 'A1PA6795UKMFR9',
    regionName: EU_REGION,
    name: 'Germany',
    timezone: 'Europe/Berlin',
    currency: 'EUR',
    abbreviation: 'DE',
    sort: 6,
    domain: 'de',
    languages: ['German', 'Czech', 'Dutch', 'Turkish']
  },
  {
    src: flagSpain,
    id: 5,
    marketplaceid: 'A1RKKUPIHCS9HS',
    regionName: EU_REGION,
    name: 'Spain',
    timezone: 'Europe/Madrid',
    currency: 'EUR',
    abbreviation: 'ES',
    sort: 8,
    domain: 'es',
    languages: ['Spanish', 'Portuguese']
  },
  {
    src: flagFrance,
    id: 6,
    marketplaceid: 'A13V1IB3VIYZZH',
    regionName: EU_REGION,
    name: 'France',
    timezone: 'Europe/Paris',
    currency: 'EUR',
    abbreviation: 'FR',
    sort: 7,
    domain: 'fr',
    languages: ['French']
  },
  {
    src: flagItaly,
    id: 7,
    marketplaceid: 'APJ6JRA9NG5V4',
    regionName: EU_REGION,
    name: 'Italy',
    timezone: 'Europe/Rome',
    currency: 'EUR',
    abbreviation: 'IT',
    sort: 9,
    domain: 'it',
    languages: ['Italian']
  },
  {
    src: flagUk,
    id: 8,
    marketplaceid: 'A1F83G8C2ARO7P',
    regionName: EU_REGION,
    name: 'UK',
    timezone: 'Europe/London',
    currency: 'GBP',
    abbreviation: 'UK',
    sort: 5,
    domain: 'co.uk',
    languages: ['English']
  },
  {
    src: flagIndia,
    id: 9,
    marketplaceid: 'A21TJRUUN4KGV',
    regionName: INDIA_REGION,
    name: 'India',
    timezone: 'Asia/Kolkata',
    currency: 'INR',
    abbreviation: 'IN',
    sort: 11,
    domain: 'in',
    languages: ['English', 'Hindi', 'Tamil', 'Telugu', 'Kannada', 'Malayalam']
  },
  {
    src: flagJapan,
    id: 10,
    marketplaceid: 'A1VC38T7YXB528',
    regionName: FE_REGION,
    name: 'Japan',
    timezone: 'Asia/Tokyo',
    currency: 'JPY',
    abbreviation: 'JP',
    sort: 18,
    domain: 'co.jp',
    languages: ['Japanese', 'English', 'Simplified Chinese']
  },
  {
    src: flagAustralia,
    id: 12,
    marketplaceid: 'A39IBJ37TRP1C6',
    regionName: FE_REGION,
    name: 'Australia',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    abbreviation: 'AU',
    sort: 19,
    domain: 'com.au',
    languages: ['English']
  },
  {
    src: flagBrazil,
    id: 13,
    marketplaceid: 'A2Q3Y263D00KWC',
    regionName: NA_REGION,
    name: 'Brazil',
    timezone: 'Brazil/East',
    currency: 'BRL',
    abbreviation: 'BR',
    sort: 4,
    domain: 'com.br',
    languages: ['Portuguese']
  },
  {
    src: flagSingapore,
    id: 14,
    marketplaceid: 'A19VAU5U5O7RUS',
    regionName: FE_REGION,
    name: 'Singapore',
    timezone: 'Asia/Singapore',
    currency: 'SGD',
    abbreviation: 'SG',
    sort: 20,
    domain: 'sg',
    languages: ['English']
  },
  {
    src: flagUAE,
    id: 15,
    marketplaceid: 'A2VIGQ35RCS4UG',
    regionName: EU_REGION,
    name: 'UAE',
    timezone: 'Asia/Dubai',
    currency: 'AED',
    abbreviation: 'UAE',
    sort: 10,
    domain: 'sa',
    languages: ['Arabic']
  },
  {
    src: flagEgypt,
    id: 17,
    marketplaceid: 'ARBP9OOSHTCHU',
    regionName: EU_REGION,
    name: 'Egypt',
    timezone: 'Africa/Cairo',
    currency: 'EGP',
    abbreviation: 'EG',
    sort: 12,
    domain: 'eg'
  },
  {
    src: flagNetherlands,
    id: 18,
    marketplaceid: 'A1805IZSGTT6HS',
    regionName: EU_REGION,
    name: 'Netherlands',
    timezone: 'Europe/Amsterdam',
    currency: 'EUR',
    abbreviation: 'NL',
    sort: 13,
    domain: 'nl',
    languages: ['Dutch']
  },
  {
    src: flagSaudiArabia,
    id: 19,
    marketplaceid: 'A17E79C6D8DWNP',
    regionName: EU_REGION,
    name: 'Saudi Arabia',
    timezone: 'Asia/Riyadh',
    currency: 'SAR',
    abbreviation: 'SA',
    sort: 14,
    domain: 'sa',
    languages: ['Arabic']
  },
  {
    src: flagSweden,
    id: 20,
    marketplaceid: 'A2NODRKZP88ZB9',
    regionName: EU_REGION,
    name: 'Sweden',
    timezone: 'Europe/Stockholm',
    currency: 'SEK',
    abbreviation: 'SE',
    sort: 15,
    domain: 'se',
    languages: ['Swedish', 'English']
  },
  {
    src: flagTurkey,
    id: 21,
    marketplaceid: 'A33AVAJ2PDY3EV',
    regionName: EU_REGION,
    name: 'Turkey',
    timezone: 'Europe/Istanbul',
    currency: 'TRY',
    abbreviation: 'TR',
    sort: 17,
    domain: 'com.tr',
    languages: ['Turkish']
  },
  {
    src: flagPoland,
    id: 22,
    marketplaceid: 'A1C3SOZRARQ6R3',
    regionName: EU_REGION,
    name: 'Poland',
    timezone: 'Europe/Warsaw',
    currency: 'PLN',
    abbreviation: 'PL',
    sort: 16,
    domain: 'pl',
    languages: ['Polish']
  },
  {
    src: flagBelgium,
    id: 23,
    marketplaceid: 'A1RKKUPIHCS9HS',
    regionName: EU_REGION,
    name: 'Belgium',
    timezone: 'Europe/Brussels',
    currency: 'EUR',
    abbreviation: 'BE',
    sort: 17,
    domain: 'com.be',
    languages: ['Dutch', 'French', 'German']
  }
];

export const getFlagsByRegion = name => {
  const result = [];
  FLAGS.forEach(obj => {
    if (obj.regionName === name) {
      result.push(obj);
    }
  });
  return result;
};

export const EU_MARKETPLACES = getFlagsByRegion(EU_REGION);

export const getFlagsById = flagId => {
  return FLAGS.find(flag => flag.id === flagId);
};

export const getMarketTime = (marketplaceID, dt, sendTextError) => {
  const flag = getFlagsById(marketplaceID);
  if (!flag) return null;
  try {
    return utcToZonedTime(dt, flag.timezone);
  } catch (e) {
    if (sendTextError) sendTextError('getMarketTime utcToZonedTime error', dt);
    return null;
  }
};

export const getRegionByFLagId = id => FLAGS.find(item => item.id === id)?.regionName;

export default FLAGS;
