import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Colors from 'styles/colors';
import { RoundExclamationIcon } from '../icons';
import { DASHBOARD_BANNER_HEIGHT } from './DashboardBannerView';
import { FlatButton } from '../buttons';

export const BULK_HCOGS_WARNING_HEIGHT_MULTIPLIER = 5;

const BulkHcogsWarningView = ({ onClose, className, content, height, expanded, setExpanded }) => {
  const { message, details } = content;
  const getExpandedView = () => {
    if (!expanded) return null;
    return (
      <div className="w-full relative flex-grow min-h-0 bg-yellow-transparent-new rounded-lg">
        <div className="scroll-container h-full max-h-full min-h-0 overflow-auto px-6 py-5">
          {details.map(item => {
            return <div className="py-2 text-sm max-w-full truncate">{item}</div>;
          })}
        </div>
      </div>
    );
  };
  return (
    <div
      className={classNames(
        'rounded w-full flex flex-col justify-start px-4 bg-yellow-dark pb-6 text-gray-darkest',
        className
      )}
      style={{ height: `${height}rem` }}
    >
      <div
        className="w-full flex items-center justify-between flex-shrink-0"
        style={{ height: `${DASHBOARD_BANNER_HEIGHT}rem` }}
      >
        <RoundExclamationIcon fill={Colors.gray.darkest} className="mr-6 flex-shrink-0" />
        <div className="max-w-3/4 truncate">{message}</div>
        <FlatButton className="ml-auto p-1" onClick={onClose}>
          Dismiss
        </FlatButton>
        {details && details.length && (
          <FlatButton className="ml-1 p-1" onClick={() => setExpanded(!expanded)}>
            Show More
          </FlatButton>
        )}
      </div>
      {getExpandedView()}
    </div>
  );
};

BulkHcogsWarningView.propTypes = {
  onClose: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

BulkHcogsWarningView.defaultProps = {
  className: ''
};

export default BulkHcogsWarningView;
