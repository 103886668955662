import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setApp as setAppAction, userLogoutAction } from 'seller/store/actions';
import ROUTES from 'seller/routing';
import { HeaderView } from '@components';
import UserSmallCard from './UserSmallCard';
import AppMenu from './AppMenu';

const Header = withRouter(
  ({
    history,
    app,
    onboarding,
    appMenu,
    userMenu,
    showGetStartedCheckList,
    showTrialInfo,
    profile
  }) => {
    const dispatch = useDispatch();
    const onAppSelect = value => dispatch(setAppAction(value));
    const onLogout = () => dispatch(userLogoutAction());
    const rightButton = (
      <UserSmallCard
        user={profile}
        logout={onLogout}
        onboarding={onboarding}
        menuItems={userMenu}
        showGetStartedCheckList={showGetStartedCheckList}
        showTrialInfo={showTrialInfo}
        history={history}
      />
    );
    return (
      <HeaderView
        appMenu={<AppMenu onSelect={onAppSelect} selected={app} menuItems={appMenu} />}
        onLogoClick={() => history.push(ROUTES.home)}
        logo={profile?.logo}
        rightButton={rightButton}
        hideAppMenu={onboarding}
      />
    );
  }
);

Header.propTypes = {
  onboarding: PropTypes.bool
};

Header.defaultProps = {
  onboarding: false
};

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    app: state.app.app,
    appMenu: state.app.appMenu,
    userMenu: state.app.userMenu,
    showTrialInfo: state.app.showTrialInfo,
    showGetStartedCheckList: state.app.showGetStartedCheckList
  };
}

export default connect(mapStateToProps)(Header);
