import { ADD_SENT_SENTRY_ERROR_TYPE } from '../actions';

const initialState = {
  sentErrorsTypes: []
};

export const sentrySelector = state => state.login;

const sentry = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SENT_SENTRY_ERROR_TYPE: {
      return { sentErrorsTypes: [...state.sentErrorsTypes, action.payload] };
    }
    default: {
      return state;
    }
  }
};

export default sentry;
