import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const HorizontalScrollContainer = ({ className, style, children }) => {
  const [hovered, toggleHover] = useState(false);

  return (
    <div
      onMouseOver={() => toggleHover(true)}
      onFocus={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      className={classNames(
        className,
        'xxs:invisible-scroll-container lg:scroll-container scrolling-touch overflow-auto',
        {
          'lg:visible': hovered,
          'lg:invisible': !hovered
        }
      )}
      style={style}
    >
      <div className="flex w-full visible">{children}</div>
    </div>
  );
};

HorizontalScrollContainer.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object)
};

HorizontalScrollContainer.defaultProps = {
  style: {}
};

export default HorizontalScrollContainer;
