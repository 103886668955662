import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Auth from 'seller/Auth';
import { USER_INCOMPLETED_STATUS } from '@sm/api';
import { LoadingSpinner } from '@components';
import Header from '../containers/header/Header';
import DashBoard from '../pages/dashboard/DashBoard';
import SelectorView from '../containers/selector-view/SelectorView';
import LayoutView from './LayoutView';

const blurFilter = 'blur(4px) opacity(1) brightness(60%)';

export const RouteView = withRouter(
  React.memo(({ path, component, exact, ...other }) => {
    return (
      <Route
        path={path}
        exact={exact}
        component={() => {
          return <LayoutView>{React.createElement(component, { ...other })}</LayoutView>;
        }}
      />
    );
  })
);

RouteView.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  exact: PropTypes.bool
};

RouteView.defaultProps = {
  exact: false
};

export const DashboardBackgroundRouteView = ({ path, component }) => (
  <AuthRouteView
    key={path}
    path={path}
    authProps={{ permittedState: USER_INCOMPLETED_STATUS }}
    headerProps={{ onboarding: true }}
    component={
      <>
        <div style={{ filter: blurFilter, transform: 'scale(1.005)' }}>
          <SelectorView />
          <DashBoard disableDataFetching />
        </div>
        {component}
      </>
    }
  />
);

DashboardBackgroundRouteView.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired
};

export const AuthRouteView = ({ path, selector, component, config, authProps, headerProps }) => (
  <RouteView
    key={path}
    path={path}
    component={({ match }) => {
      return (
        <Suspense fallback={<LoadingSpinner />}>
          <Auth match={match} checkForNoOrders={config?.checkForNoOrders} {...authProps}>
            <Header {...headerProps} />
            {selector}
            {component}
          </Auth>
        </Suspense>
      );
    }}
  />
);

AuthRouteView.propTypes = {
  path: PropTypes.string.isRequired,
  selector: PropTypes.node,
  component: PropTypes.node.isRequired,
  authProps: PropTypes.instanceOf(Object),
  headerProps: PropTypes.instanceOf(Object)
};
AuthRouteView.defaultProps = {
  authProps: {},
  headerProps: {},
  selector: null
};

export const PublicRouteView = ({ path, component }) => (
  <Route key={path} path={path} component={component} />
);

PublicRouteView.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired
};
