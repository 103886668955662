export const SET_APP = 'SET_APP';
export const APPLY_CONFIG = 'APPLY_CONFIG';
export const SET_ONBOARDING_PAGE_SCREEN = 'SET_ONBOARDING_PAGE_SCREEN';
export const SET_APP_VERSION = 'SET_APP_VERSION';

export const setAppVersionActon = payload => ({ type: SET_APP_VERSION, payload });

export const setApp = app => {
  return dispatch => {
    dispatch({ type: SET_APP, app });
  };
};

export const setOnboardingPageScreen = onboardingPageScreen => {
  return dispatch => {
    dispatch({ type: SET_ONBOARDING_PAGE_SCREEN, onboardingPageScreen });
  };
};

export const configureApps = (appMenu, userMenu, showGetStartedCheckList, showTrialInfo) => {
  return dispatch => {
    dispatch({
      type: APPLY_CONFIG,
      config: { appMenu, userMenu, showGetStartedCheckList, showTrialInfo }
    });
  };
};
