const VAR_PRODUCT_TITLE = '{{product-title}}';
export const VAR_ORDER_ID = '{{order-id}}';
const VAR_PRODUCT_REVIEW_LINK_REGEXP = /{{product-review-link: (.*?)\}{2}/g;
const VAR_PRODUCT_TITLE_REVIEW_LINK = '{{product-title-review-link}}';
const VAR_FEEDBACK_LINK_REGEXP = /{{feedback-link: (.*?)\}{2}/g;
export const VAR_PRODUCT_LIST = '{{product-list}}';
export const VAR_TITLE_REVIEW_LINK_LIST = '{{product-title-review-link-list}}';

const getReplacedDirectVars = (_content, dictionary, key) => {
  let content = _content;
  content = content?.replaceAll(key, dictionary[key]?.preview);
  return content;
};

const getReplacedSimpleList = (_content, dictionary, key) => {
  let content = _content;
  content = content?.replaceAll(key, () => {
    const items = dictionary[key]?.preview || [];
    return `<ul>${items?.reduce((acc, item) => `${acc}<li>${item}</li>`, '')}</ul>`;
  });
  return content;
};

const getReplacedLinksWithCustomText = (_content, dictionary, regExp) => {
  let content = _content;
  const keys = Object.keys(dictionary);
  const targetKey = keys?.find(currentKey => currentKey.match(regExp));
  content = content?.replaceAll(regExp, (match, text) => {
    return `<a href="${dictionary[targetKey]?.preview}">${text}</a>`;
  });
  return content;
};

const getReplacedProductTitleReviewLink = (_content, dictionary) => {
  let content = _content;
  content = content?.replaceAll(
    VAR_PRODUCT_TITLE_REVIEW_LINK,
    `<a href="${dictionary[VAR_PRODUCT_TITLE_REVIEW_LINK]?.preview}">${dictionary[VAR_PRODUCT_TITLE]?.preview}</a>`
  );
  return content;
};

const getReplacedProductTitleReviewLinkList = (_content, dictionary) => {
  let content = _content;
  content = content?.replaceAll(VAR_TITLE_REVIEW_LINK_LIST, () => {
    const items = dictionary[VAR_TITLE_REVIEW_LINK_LIST]?.preview;
    const list = items?.reduce(
      (acc, item) => `${acc}<li><a href="${item.link}">Leave a review</a> for ${item.title}</li>`,
      ''
    );
    return `<ul>${list}</ul>`;
  });
  return content;
};

const VARIABLES_MAP = [
  { key: VAR_PRODUCT_TITLE, handler: getReplacedDirectVars },
  { key: VAR_ORDER_ID, handler: getReplacedDirectVars },
  { key: VAR_PRODUCT_LIST, handler: getReplacedSimpleList },
  { key: VAR_PRODUCT_REVIEW_LINK_REGEXP, handler: getReplacedLinksWithCustomText },
  { key: VAR_FEEDBACK_LINK_REGEXP, handler: getReplacedLinksWithCustomText },
  { key: VAR_PRODUCT_TITLE_REVIEW_LINK, handler: getReplacedProductTitleReviewLink },
  { key: VAR_TITLE_REVIEW_LINK_LIST, handler: getReplacedProductTitleReviewLinkList }
];

export const getPreviewContent = (content, dictionary) => {
  let previewContent = content;
  VARIABLES_MAP.forEach(({ key, handler }) => {
    previewContent = handler(previewContent, dictionary, key);
  });
  return previewContent;
};

const requiredVariables = [VAR_ORDER_ID];

export const getMissedVars = content => {
  if (!content) return [];
  return requiredVariables.reduce(
    (list, variable) => (!content.match(variable) ? [...list, variable] : list),
    []
  );
};
