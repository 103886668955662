import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isLargeSize } from '@sm/WindowSizes';
import { SafeImage } from '../../images';

const ProductsPicBox = ({ pictures, fixedSize }) => {
  const maxPicNumber = 4;
  const showPics = pictures.filter(url => url).slice(0, maxPicNumber);
  const size = isLargeSize() ? fixedSize.small : fixedSize.large;
  return (
    <div
      className="flex flex-wrap justify-center items-center flex-shrink-0"
      style={{ width: size, height: size }}
    >
      {showPics.map((src, i) => {
        const isSingle = showPics.length <= 1;
        return (
          <div
            key={`${src + i}`}
            className={classNames('p-px flex justify-center items-center', {
              'w-full h-full': isSingle,
              'w-1/2 h-1/2': !isSingle
            })}
          >
            <SafeImage className="h-full w-auto object-fit" src={src} />
          </div>
        );
      })}
    </div>
  );
};

ProductsPicBox.propTypes = {
  pictures: PropTypes.instanceOf(Array),
  fixedSize: PropTypes.instanceOf(Object)
};
ProductsPicBox.defaultProps = {
  pictures: [],
  fixedSize: { large: '46px', small: '37px' }
};

export default ProductsPicBox;
