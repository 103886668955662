import React from 'react';
import classNames from 'classnames';
import FLAGS, { GLOBAL_MK } from '@sm/Flags';
import { WebImg } from '@components';
import { sortIntAsc } from '@components/data-table/sortFuncs';
import marketplacesWebImg from './img/marketplaces';

const reversedFlags = FLAGS.sort(sortIntAsc('sort')).reverse();
const mkNames = reversedFlags
  .filter(flag => flag.id !== GLOBAL_MK)
  .map(flag => flag.name)
  .join(', ');

const marketplaceImg = (
  <WebImg
    {...marketplacesWebImg}
    mobileMediaQuery="(max-width:500px)"
    alt="marketplaces"
    className="w-full"
  />
);

function HomepageMarketplaces() {
  return (
    <div className="w-11/12 flex justify-between my-12 md:my-24">
      <div className="flex flex-col">
        <h2 className="text-black text-2xl md:text-4xl tracking-tight font-bold xl:whitespace-no-wrap">
          Works with all Marketplaces
        </h2>
        <div className="flex flex-col mt-4 md:mt-6">
          <div className="flex flex-col-reverse md:flex-col">
            <p className="text-gray-dark text-lg md:text-xl lg:text-2xl mt-4 md:mt-0 tracking-normal md:tracking-tight leading-snug">
              Use Seller Metrix with any Amazon marketplace. Currently, you can connect your
              accounts for Amazon {mkNames}. If you sell on multiple marketplaces, we have Global
              Dashboard, where you can see all your worldwide Amazon sales data in one place.
            </p>
            <div className="inline-flex w-full justify-between items-center">
              <div className="flex flex-wrap mt-6 w-20 justify-between md:justify-start md:w-auto">
                {reversedFlags.map(region => (
                  <div key={region.name} className="w-auto md:w-1/4 lg:w-1/5">
                    <img
                      src={region.src}
                      alt="flag"
                      className={classNames(
                        'h-5 md:h-10 lg:h-12 rounded-sm md:rounded mt-4 md:mr-2',
                        {
                          'sm-box-shadow-violet-light md:sm-box-shadow-violet':
                            region.name !== 'Global'
                        }
                      )}
                    />
                  </div>
                ))}
              </div>
              <div className="w-3/4 block md:hidden">{marketplaceImg}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block sm-min-w-1/2">{marketplaceImg}</div>
    </div>
  );
}

export default HomepageMarketplaces;
