import { put } from 'redux-saga/effects';
import { setNotificationMsgAction } from 'seller/store/actions';

export function* pullDataBaseSaga(
  request,
  setDataAction,
  defaultData,
  setPullingAction,
  errorNotificationMsg = 'Pull data failed.'
) {
  try {
    yield put(setPullingAction(true));
    const { data } = yield request();
    yield put(setDataAction(data));
  } catch (error) {
    yield put(setDataAction(defaultData));
    yield put(
      setNotificationMsgAction(
        `${errorNotificationMsg} Please contact support@sellermetrix.com if problem persists.`
      )
    );
  } finally {
    yield put(setPullingAction(false));
  }
}
