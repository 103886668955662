import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

const HEADER_DATE_FORMAT = 'MMMM yyyy';

function CalendarHeader({ classNames, date }) {
  return (
    <div className={`justify-center w-full ${classNames.caption}`}>
      <p className="w-full text-center text-black font-medium text-lg mt-0">
        {format(date, HEADER_DATE_FORMAT)}
      </p>
    </div>
  );
}

CalendarHeader.propTypes = {
  classNames: PropTypes.shape({
    caption: PropTypes.string
  }).isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

export default CalendarHeader;
