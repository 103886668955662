import {
  call,
  cancelled,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from '@redux-saga/core/effects';
import API, { selectedProductsToAPI } from '@sm/api';
import { prepareSelectedProducts } from 'seller/containers/bookmark-search/utils';
import {
  setMarketplaceAction,
  setNotificationMsgAction,
  setSelectedProducts,
  setSuccessMsgAction
} from 'seller/store/actions';
import { productSearchSelector, selectorSelector, userSelector } from 'seller/store/reducers';
import { axiosCall, getCancelToken } from '@utils';
import {
  setSelectedBookmarkAction,
  setBookmarksLoadingAction,
  setBookmarksAction,
  cleanupBookmarkAction,
  PULL_BOOKMARKS,
  CREATE_BOOKMARK,
  DELETE_BOOKMARK,
  UPDATE_BOOKMARK,
  APPLY_BOOKMARK
} from './action';

export function* applyBookmarkSaga({ payload }) {
  const { marketplaceID } = yield select(selectorSelector);
  if (marketplaceID !== payload.marketplace) {
    yield put(setMarketplaceAction(payload.marketplace, true));
  }
  const preparedBookmarkProducts = prepareSelectedProducts(payload);
  yield put(setSelectedBookmarkAction({ ...payload, preparedBookmarkProducts }));
  yield put(setSelectedProducts(preparedBookmarkProducts));
}

export function* pullSearchBookmarksSaga({ payload }) {
  const { cancelToken, cancelRequest } = getCancelToken();
  yield put(setBookmarksLoadingAction(true));
  const { userId } = yield select(userSelector);
  const params = {};
  if (userId) params.userId = userId;
  if (payload?.userId) params.userId = payload.userId;
  try {
    const { data } = yield axiosCall.get(API.products.bookmarks, { params, cancelToken });
    yield put(setBookmarksAction(data));
  } catch (e) {
    console.log('pullSearchBookmarksSaga', e);
    yield put(setNotificationMsgAction('Pull search bookmarks failed'));
  } finally {
    if (yield cancelled()) cancelRequest();
    yield put(setBookmarksLoadingAction(false));
  }
}

export function* deleteBookmarkSaga({ payload }) {
  try {
    yield axiosCall.delete(`${API.products.bookmarks}/${payload.id}/`);
    yield put(setSuccessMsgAction('Bookmark deleted successfully'));
    yield put(setSelectedBookmarkAction(null));
    yield put(cleanupBookmarkAction(payload.id));
  } catch (e) {
    console.log('deleteBookmarkSaga Error', e);
    yield put(setNotificationMsgAction('Delete bookmark failed'));
  } finally {
    payload.onFinally();
  }
}

export function* createBookmarkSaga({ payload }) {
  const { name } = payload;
  const { marketplaceID } = yield select(selectorSelector);
  const { selectedProducts } = yield select(productSearchSelector);
  const productsList = selectedProductsToAPI(selectedProducts);
  try {
    const { data } = yield axiosCall.post(API.products.bookmarks, {
      name,
      marketplaceID,
      ...productsList
    });
    yield put(setSuccessMsgAction('Bookmark created successfully'));
    yield call(applyBookmarkSaga, { payload: data });
    yield fork(pullSearchBookmarksSaga);
  } catch (e) {
    console.log('createBookmarkSaga Error', e);
    yield put(setNotificationMsgAction('Failed to create a bookmark'));
  } finally {
    payload.onFinally();
  }
}

export function* updateBookmarkSaga({ payload }) {
  const { selectedProducts } = yield select(productSearchSelector);
  const productsList = selectedProductsToAPI(selectedProducts);
  try {
    const { data } = yield axiosCall.put(`${API.products.bookmarks}/${payload.id}/`, {
      ...productsList,
      name: payload.name
    });
    yield put(setSuccessMsgAction('Bookmark updated successfully'));
    yield call(applyBookmarkSaga, { payload: data });
    yield fork(pullSearchBookmarksSaga);
  } catch (e) {
    console.log('updateBookmarkSaga Error', e);
    yield put(setNotificationMsgAction('Failed to update a bookmark'));
  } finally {
    payload.onFinally();
  }
}

export function* bookmarksSagaWatcher() {
  yield takeLatest(PULL_BOOKMARKS, pullSearchBookmarksSaga);
  yield takeEvery(CREATE_BOOKMARK, createBookmarkSaga);
  yield takeEvery(DELETE_BOOKMARK, deleteBookmarkSaga);
  yield takeEvery(UPDATE_BOOKMARK, updateBookmarkSaga);
  yield takeEvery(APPLY_BOOKMARK, applyBookmarkSaga);
}
