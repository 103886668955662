const getOrdersSubValues = (mainValue, values) => {
  if (!values) {
    return null;
  }

  const ppcOrders = values ? values.ppc_orders : 0;
  const organic = mainValue - ppcOrders;
  return { Organic: organic, PPC: ppcOrders };
};

const getUnitsSubValues = (mainValue, values) => {
  if (!values) {
    return null;
  }

  const promoUnits = values?.promo_units || 0;
  const full = mainValue - promoUnits;
  return { Full: full, Promo: promoUnits };
};

const getAdSpendSubValues = (mainValue, values) => {
  const { acos, tacos } = values;
  if (!acos || !tacos) return null;
  return { ACoS: values.acos, TACoS: values.tacos, measurementUnit: '%' };
};

export const metrixInfo = {
  sales: {
    title: 'Sales',
    currency: true,
    totals: true
  },
  product_sales: {
    title: 'Product Sales',
    currency: true
  },
  profit: {
    title: 'Net Profit',
    currency: true,
    hasInfoIcon: true,
    totals: true
  },
  orders: {
    title: 'Orders',
    subvalues: getOrdersSubValues,
    totals: true
  },
  units: {
    title: 'Units',
    subvalues: getUnitsSubValues,
    totals: true
  },
  total_ads: {
    title: 'Ad Spend',
    subvalues: getAdSpendSubValues,
    currency: true,
    totals: true
  },
  // impressions: {
  //   title: 'Impressions'
  // },
  refunds: {
    title: 'Refunds',
    currency: true,
    totals: true
  },
  refundedUnits: {
    title: 'Refunded Units'
  },
  promoSales: {
    title: 'Promo Sales',
    currency: true
  },
  organicOrders: {
    title: 'Organic Orders'
  },
  ppcOrders: {
    title: 'PPC Orders'
  },
  sessions: {
    title: 'Sessions'
  },
  conversionRate: {
    title: 'Conversion Rate',
    suffix: '%'
  },
  tacos: {
    title: 'TACOS',
    suffix: '%'
  },
  acos: {
    title: 'ACOS',
    suffix: '%'
  }
};

export const getMetricByTitle = title => {
  return Object.keys(metrixInfo).find(metricKey => metrixInfo[metricKey].title === title);
};

export const getMetricInfoByTitle = title => {
  const key = Object.keys(metrixInfo).find(metricKey => metrixInfo[metricKey].title === title);
  return metrixInfo[key];
};
