import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  pullProductsAction,
  setProductStatusAction,
  setMissingInfoFlagAction,
  updateProductsAction,
  bulkUpdateHcogsAction
} from 'seller/store/saga/products';
import { SELECTOR_VIEW_HEIGHT } from '@sm/layout';
import { GLOBAL_MK } from '@sm/Flags';
import { GradButton, DownloadButton, MenuButton } from '@components/buttons';
import CheckBox from '@components/check-box/CheckBox';
import { setMarketplaceAction } from 'seller/store/actions';
import MarketplaceSelector from '../selector-view/MarketplaceSelector';
import BulkUploadIcon from './ic_cloud-upload-alt-solid.svg';
import UploadPhotoDialog from '../../pages/profile/UploadPhotoDialog';
import getProductsFromCSV, { exportCSV, getCsvFileName } from './csvHelpers';
import HistoricalCogsBulkUpdate from './HistoricalCogsBulkUpdate';

const csvColumns = {
  name: 'Product',
  asin: 'ASIN',
  sku: 'SKU',
  unit_cost: 'Product CoGS',
  unit_shipping_cost: 'Inbound Shipping',
  fbm_shipping_cost: 'FBM Shipping',
  fbm_return_cost: 'Cost per FBM Return',
  currency: 'Currency',
  marketplace: 'Marketplace',
  inventory: 'Current Inventory',
  profit: 'Profit'
};

export const PRODUCT_STATUS_MAP = {
  active: 'Active',
  inactive: 'Inactive',
  deleted: 'Deleted',
  all: 'All'
};

const LeftView = props => {
  const {
    marketplaces,
    marketplaceID,
    onMarketplaceChanged,
    showOnlyWithMissingInfo,
    dispatch,
    setStatus,
    status
  } = props;

  return (
    <div className="flex flex-1 justify-start items-center pl-2 md:pl-0">
      <MarketplaceSelector
        marketplaces={marketplaces}
        marketplaceID={marketplaceID}
        onMarketplaceChanged={onMarketplaceChanged}
      />

      <MenuButton
        name={PRODUCT_STATUS_MAP[status]}
        data={Object.values(PRODUCT_STATUS_MAP)}
        bullet={null}
        className="ml-4"
        onSelected={item => {
          const itemKey = Object.keys(PRODUCT_STATUS_MAP).find(
            key => PRODUCT_STATUS_MAP[key] === item
          );
          // console.log(itemKey);
          setStatus(itemKey);
          // setFirstMetric(item);
          // onPrimaryMetricChanged(getMetricByTitle(item));
        }}
      />

      <CheckBox
        height={16}
        width={16}
        label="Show only with missing info."
        classNameContainer="ml-2"
        value={showOnlyWithMissingInfo}
        onCheckHandler={check => dispatch(setMissingInfoFlagAction(check))}
      />
    </div>
  );
};

LeftView.propTypes = {
  onMarketplaceChanged: PropTypes.func.isRequired,
  marketplaces: PropTypes.instanceOf(Array).isRequired,
  marketplaceID: PropTypes.number.isRequired,
  showOnlyWithMissingInfo: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

class ProductSelectorView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBulkUploadDialog: false
    };
  }

  render() {
    const {
      setMarketplace,
      profile,
      marketplaceID,
      showOnlyWithMissingInfo,
      productsList,
      status,
      dispatch
    } = this.props;
    const { showBulkUploadDialog } = this.state;
    const visibleMarketplaces = profile.marketplaces.filter(
      i => i !== GLOBAL_MK || profile.historical_cogs_bulk_upload
    );
    const csvFileName = getCsvFileName({ dashboardName: 'Products', marketplaceId: marketplaceID });
    const setStatus = newStatus => dispatch(setProductStatusAction(newStatus));
    const onDownloadTemplate = () => exportCSV(productsList, csvFileName, csvColumns);

    const onFileUploaded = (result, date = null) => {
      const products = getProductsFromCSV(result);
      const action = date ? bulkUpdateHcogsAction : updateProductsAction;
      dispatch(
        action({
          products,
          onRequestFinish: success => {
            if (success) dispatch(pullProductsAction());
          },
          isBulkUpload: true,
          date
        })
      );
    };
    return (
      <div
        style={{ height: SELECTOR_VIEW_HEIGHT }}
        className="flex flex-row flex-shrink-0 relative 10 box-shadow-light overflow-visible bg-white lg:px-6 xs:px-2 justify-between items-center 10 z-10"
      >
        <LeftView
          onChange={this.onSearchInputChange}
          openSearchDialog={() => this.setState(prevState => ({ ...prevState }))}
          onSearchFocus={this.onSearchFocus}
          marketplaces={visibleMarketplaces}
          marketplaceID={marketplaceID}
          onMarketplaceChanged={setMarketplace}
          showOnlyWithMissingInfo={showOnlyWithMissingInfo}
          dispatch={dispatch}
          status={status}
          setStatus={setStatus}
        />
        <DownloadButton
          className="mr-4 hidden md:flex"
          paddingY="py-0"
          fileName={csvFileName}
          columns={csvColumns}
          data={productsList}
          onClick={(data, fileName, columns) => exportCSV(data, fileName, columns)}
        >
          Your Template
        </DownloadButton>
        {profile.historical_cogs_bulk_upload && (
          <HistoricalCogsBulkUpdate
            className="h-8 bg-grad-8 mr-4 hidden md:flex"
            lifetime={profile.start}
            onDownloadTemplate={onDownloadTemplate}
            onFileUploaded={onFileUploaded}
          />
        )}
        <GradButton
          className="h-8 bg-grad-8 hidden md:flex"
          onClick={() =>
            this.setState(prevState => {
              return { ...prevState, showBulkUploadDialog: true };
            })
          }
        >
          <div className="flex flex-row items-center">
            <img src={BulkUploadIcon} alt="arrow-left" className="h-4 w-4 mr-2" />
            Bulk Upload
          </div>
        </GradButton>

        <UploadPhotoDialog
          onUploadHandler={onFileUploaded}
          open={showBulkUploadDialog}
          onClose={() =>
            this.setState(prevState => {
              return { ...prevState, showBulkUploadDialog: false };
            })
          }
          title="Bulk Upload Product Costs"
          placeholder="Click to select, or drag and drop your CSV file here"
          mask=".csv"
          loadFileFunc={(file, reader) => reader.readAsText(file)}
          loadedView={() => <div className="text-gray-dark">Attached</div>}
        />
      </div>
    );
  }
}

ProductSelectorView.propTypes = {
  setMarketplace: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  marketplaceID: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  showOnlyWithMissingInfo: PropTypes.bool.isRequired,
  productsList: PropTypes.instanceOf(Array).isRequired,
  status: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    productsList: state.products.productsList,
    profile: state.user.profile,
    marketplaceID: state.selector.marketplaceID,
    showOnlyWithMissingInfo: state.products.showOnlyWithMissingInfo,
    status: state.selector.productStatus
  };
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  setMarketplace: marketplace => dispatch(setMarketplaceAction(marketplace))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelectorView);
