/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { put, select, cancelled, call, takeLatest } from '@redux-saga/core/effects';
import API from '@sm/api';
import { axiosCall } from '@utils';
import { setNotificationMsgAction, setSelectedProducts } from 'seller/store/actions';
import { productSearchSelector, selectorSelector, userSelector } from 'seller/store/reducers';
import { prepareOrders } from 'seller/utils/convertRawApiData';
import { buildParams } from 'seller/utils/buildParams';
import { getFlagsById, GLOBAL_MK } from '@sm/Flags';
import {
  PULL_ORDER_DETAILS,
  PULL_ORDERS,
  setOrdersAction,
  startOrdersLoadingAction
} from './action';
import { missedBookmarkHandler } from '../utils';

const { CancelToken } = axios;

export function* pullOrdersSaga() {
  function* failHandler() {
    yield put(setOrdersAction([]));
    yield put(setNotificationMsgAction('Loading orders failed'));
  }
  const source = CancelToken.source();
  yield put(startOrdersLoadingAction());
  try {
    const store = yield select();
    const { orderEventType } = store.orders;
    const params = buildParams({ state: store });
    if (params.temp_bookmark_expired) {
      const { selectedProducts } = yield select(productSearchSelector);
      yield put(setSelectedProducts([...selectedProducts]));
      return;
    }
    if (orderEventType) params.order_event_type = orderEventType;
    const { data, status } = yield axios.get(API.orders.list, {
      params,
      cancelToken: source.token
    });
    if (status === 200) {
      yield put(setOrdersAction(prepareOrders(data.orders)));
    } else {
      yield call(failHandler);
    }
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    yield call(failHandler);
    console.log('pullOrdersSaga error:', error);
  } finally {
    if (yield cancelled()) source.cancel();
  }
}

function* pullOrderDetailsSaga({ payload }) {
  const { callback, order } = payload;
  const { orderItemId, marketplace } = order;
  const { profile } = yield select(userSelector);
  const { marketplaceID } = yield select(selectorSelector);
  const defaultCurrency = profile?.defaultSettings?.currency;
  try {
    const params = {
      orderItemId,
      marketplaceID: marketplace,
      currency:
        marketplaceID === GLOBAL_MK ? defaultCurrency : getFlagsById(marketplaceID)?.currency
    };
    const { data } = yield axiosCall.get(API.orders.realBreakdown, { params });
    callback(data);
  } catch (error) {
    console.log('pullOrderDetailsSaga error:', error);
    callback({});
  }
}

export function* ordersSagaWatcher() {
  yield takeLatest(PULL_ORDERS, pullOrdersSaga);
  yield takeLatest(PULL_ORDER_DETAILS, pullOrderDetailsSaga);
}
