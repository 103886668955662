import React from 'react';
import PropTypes from 'prop-types';

const colorsMap = {
  active: ['#DCEFE8', '#DDF7EE', '#1BCB89', '#D6DAEB', '#128E60'],
  inactive: ['#E7E8EF', '#D6DAEB', '#C3C6D6', '#D6DAEB', '#878994'],
  hovered: ['#FFF1D6', '#FDE6BA', '#F9AD19', '#DA9713', '#FDE6BA']
};

const FileIcon = ({ active, hovered }) => {
  const getColors = () => {
    if (hovered) return colorsMap.hovered;
    if (active) return colorsMap.active;
    return colorsMap.inactive;
  };
  const colors = getColors();

  return (
    <svg width="50" height="83" viewBox="0 0 50 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.5 69.9916V28.1026C39.5018 27.6122 39.2287 27.1585 38.7842 26.9133C38.3457 26.6666 37.8016 26.6666 37.3631 26.9133L35.2884 28.053C34.8274 28.325 34.2452 28.325 33.7842 28.053L30.9212 26.2295C30.4905 25.9592 29.9428 25.9253 29.4793 26.1403L25.1432 28.2215C24.737 28.4098 24.263 28.4098 23.8569 28.2215L19.5208 26.1502C19.0604 25.9261 18.5093 25.9564 18.0789 26.2295L15.2158 28.053C14.7548 28.325 14.1726 28.325 13.7116 28.053L11.6369 26.9133C11.1984 26.6666 10.6543 26.6666 10.2158 26.9133C9.7713 27.1585 9.4982 27.6122 9.50001 28.1026V70.3385C9.50001 71.788 10.675 72.963 12.1244 72.963C12.1367 72.963 12.1489 72.9629 12.1612 72.9627L36.9124 72.6157C38.3473 72.5956 39.5 71.4266 39.5 69.9916Z"
        fill={colors[0]}
      />
      <path
        d="M43.5 66.2849V14.5947C43.5023 13.9896 43.1564 13.4296 42.5934 13.1271C42.0379 12.8226 41.3487 12.8226 40.7932 13.1271L38.1653 14.5335C37.5814 14.8691 36.844 14.8691 36.26 14.5335L32.6335 12.2832C32.088 11.9497 31.3942 11.9078 30.8071 12.1731L25.3147 14.7414C24.8003 14.9738 24.1998 14.9738 23.6854 14.7414L18.193 12.1854C17.6098 11.9088 16.9118 11.9462 16.3665 12.2832L12.74 14.5335C12.1561 14.8691 11.4187 14.8691 10.8347 14.5335L8.2068 13.1271C7.65133 12.8226 6.96213 12.8226 6.40666 13.1271C5.84364 13.4296 5.49772 13.9896 5.50001 14.5947V66.7154C5.50001 68.5048 6.95061 69.9554 8.74001 69.9554C8.75476 69.9554 8.76951 69.9553 8.78426 69.9551L40.3043 69.5246C42.0762 69.5004 43.5 68.0571 43.5 66.2849Z"
        fill={colors[1]}
      />
      <mask
        id="mask0_1_51032"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="50"
        height="67"
      >
        <path
          d="M49.5 62.4244V14.3699C49.5 14.1312 49.4146 13.9004 49.2592 13.7191L37.7993 0.349209C37.6093 0.127563 37.332 0 37.0401 0H4.5C2.29086 0 0.5 1.79086 0.5 4V62.9485C0.5 65.1577 2.29086 66.9485 4.5 66.9485C4.51704 66.9485 4.53409 66.9484 4.55113 66.9482L45.5511 66.4241C47.7401 66.3961 49.5 64.6136 49.5 62.4244Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_51032)">
        <path
          d="M49.5 62.4244V14.3699C49.5 14.1312 49.4146 13.9004 49.2592 13.7191L37.7993 0.349209C37.6093 0.127563 37.332 0 37.0401 0H4.5C2.29086 0 0.5 1.79086 0.5 4V62.9485C0.5 65.1577 2.29086 66.9485 4.5 66.9485C4.51704 66.9485 4.53409 66.9484 4.55113 66.9482L45.5511 66.4241C47.7401 66.3961 49.5 64.6136 49.5 62.4244Z"
          fill={colors[2]}
        />
        <path
          d="M24.5 49C33.3366 49 40.5 41.8366 40.5 33C40.5 24.1634 33.3366 17 24.5 17C15.6634 17 8.5 24.1634 8.5 33C8.5 41.8366 15.6634 49 24.5 49Z"
          fill={colors[1]}
        />
        <path
          d="M16.8951 32.82H20.2333C20.4515 32.82 20.6284 32.9991 20.6284 33.22V39.64H16.5V33.22C16.5 32.9991 16.6769 32.82 16.8951 32.82Z"
          fill={colors[2]}
        />
        <path
          d="M22.7618 26H26.1C26.3182 26 26.4951 26.1791 26.4951 26.4V39.64H22.3667V26.4C22.3667 26.1791 22.5436 26 22.7618 26Z"
          fill={colors[2]}
        />
        <path
          d="M28.6481 28.2H31.9666C32.1848 28.2 32.3617 28.3791 32.3617 28.6V39.64H28.2531V28.6C28.2531 28.3791 28.4299 28.2 28.6481 28.2Z"
          fill={colors[2]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.5 0C45.9183 0 49.5 3.58172 49.5 8H43.5C42.3954 8 41.5 7.10457 41.5 6V0Z"
          fill={colors[3]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5 -9V10C37.5 12.2091 39.2909 14 41.5 14H58.5"
          fill={colors[4]}
        />
      </g>
      <path
        d="M25 83C37.4264 83 47.5 82.5523 47.5 82C47.5 81.4477 37.4264 81 25 81C12.5736 81 2.5 81.4477 2.5 82C2.5 82.5523 12.5736 83 25 83Z"
        fill={colors[1]}
      />
    </svg>
  );
};

FileIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  hovered: PropTypes.bool.isRequired
};

export default FileIcon;
