import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { animateScroll as scroll, Link as ScrollLink } from 'react-scroll';
import FontButton from '@components/buttons/FontButton';
import ROUTES from 'seller/routing';
import NewTabReference from '@components/common/NewTabReference';
import { isMediumSize } from '@sm/WindowSizes';
import { ExpandIcon, WebImg } from '@components';
import MarkerIcon from './img/ic_marker.svg';
import FacebookIcon from './img/ic_facebook.svg';
import LinkedinIcon from './img/ic_linkedin.svg';
import FooterBlob from './img/il_blob_footer.svg';
import { APPSTORE_LINK, PLAYMARKET_LINK } from './Platforms';
import { appStoreWebImg, playMarketWebImg } from './img/markets';
import logoWebImg from './img/logo';

const FooterExpansionPanel = withStyles({
  root: {
    background: 'none',
    boxShadow: 'none',
    borderBottom: '1px solid #c3c6d6',
    borderBottomRightRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    '&::before': {
      height: '0 !important'
    }
  }
})(ExpansionPanel);

const FooterExpansionPanelSummary = withStyles({
  root: {
    padding: '0 !important'
  }
})(ExpansionPanelSummary);

const FooterExpansionPanelDetails = withStyles({ root: { padding: '0 !important' } })(
  ExpansionPanelDetails
);

const companyAddress = 'Seller Metrix, 51 Goldhill Plaza, #07-10/11, 308900 Singapore';

const facebookLink = 'https://www.facebook.com/SellerMetrix/';
const linkedinLink = 'https://www.linkedin.com/company/sellermetrix/';
const supportLink = 'https://intercom.help/sellermetrix/en/collections/630363-support';
const termsConditionLink = 'https://sellermetrix.com/terms-of-service/';
const privacyPolicyLink = 'https://sellermetrix.com/privacy-policy/';

const pagesList = [
  <FontButton onClick={() => scroll.scrollToTop()}>Home</FontButton>,
  <ScrollLink to="features" smooth>
    Features
  </ScrollLink>,
  <ScrollLink to="pricing" smooth>
    Pricing
  </ScrollLink>
];
const resourcesList = [
  <a href={termsConditionLink}>Terms & Conditions</a>,
  <a href={privacyPolicyLink}>Privacy Policy</a>,
  <a href={supportLink}>Support</a>
];

const pagesListContent = pagesList.map((page, index) => {
  return (
    <FontButton key={`${index + 1}`} className="text-black text-base font-medium mb-4">
      {page}
    </FontButton>
  );
});

const resourcesListContent = resourcesList.map((page, index) => {
  return (
    <FontButton key={`${index + 1}`} className="text-gray-dark text-base font-medium mb-4">
      {page}
    </FontButton>
  );
});

function HomepageFooter() {
  return (
    <footer className="w-full flex flex-col items-center mt-12 md:mt-16 relative">
      <div className="w-11/12 flex flex-col md:flex-row justify-between pb-4">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col items-center md:items-start">
            <WebImg {...logoWebImg} alt="logo" imgClassName="sm-w-220px" />
            <div className="flex md:flex-col mt-8 md:mt-4 md:mx-0 md:mb-0">
              <NewTabReference href={APPSTORE_LINK}>
                <WebImg
                  {...appStoreWebImg}
                  className="w-32 sm-h-39px md:mt-4 mr-3 md:mr-0 block"
                  alt="app-store"
                />
              </NewTabReference>
              <NewTabReference href={PLAYMARKET_LINK}>
                <WebImg
                  {...playMarketWebImg}
                  className="w-32 sm-h-39px md:mt-4 block"
                  alt="play-market"
                />
              </NewTabReference>
            </div>
          </div>
          <div className="flex flex-col md:ml-20">
            <Link to={ROUTES.registration} className="my-12 md:mb-4 md:mt-0">
              <FontButton className="text-yellow text-base font-medium text-center md:text-left">
                Start Free Trial
              </FontButton>
            </Link>
            <div className="flex-col hidden md:flex">{pagesListContent}</div>
            <FooterExpansionPanel className="md:hidden">
              <FooterExpansionPanelSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandIcon className="h-4 w-4" />}
              >
                <p className="text-black text-base font-medium">Pages</p>
              </FooterExpansionPanelSummary>
              <FooterExpansionPanelDetails>
                <div className="flex flex-col">{pagesListContent}</div>
              </FooterExpansionPanelDetails>
            </FooterExpansionPanel>
          </div>
          <div className="hidden flex-col md:flex md:ml-20">{resourcesListContent}</div>
          <FooterExpansionPanel className="md:hidden">
            <FooterExpansionPanelSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={<ExpandIcon className="h-4 w-4" />}
            >
              <p className="text-black text-base font-medium">Resources</p>
            </FooterExpansionPanelSummary>
            <FooterExpansionPanelDetails>
              <div className="flex flex-col">{resourcesListContent}</div>
            </FooterExpansionPanelDetails>
          </FooterExpansionPanel>
        </div>
        <div className="flex flex-col-reverse items-center md:items-start md:flex-row">
          <div className="flex flex-col items-center md:items-end md:mr-5">
            <p className="text-center md:text-right text-base text-black md:text-gray-dark font-medium w-64">
              {companyAddress}
            </p>
            <div className="inline-flex">
              <NewTabReference href={facebookLink}>
                <div
                  className="bg-socialnetwork-facebook opacity-50 hover:sm-opacity-100 h-8 w-8 round flex justify-center items-center cursor-pointer mb-12 mt-6 md:mt-2 md:mb-0 mr-3 sm-transition-03s"
                  alt="facebook"
                >
                  <img className="w-4 h-4" src={FacebookIcon} alt="facebook" />
                </div>
              </NewTabReference>
              <NewTabReference href={linkedinLink}>
                <div
                  className="bg-socialnetwork-linkedin opacity-50 hover:sm-opacity-100 h-8 w-8 round flex justify-center items-center cursor-pointer mb-12 mt-6 md:mt-2 md:mb-0 sm-transition-03s"
                  alt="linkedin"
                >
                  <img className="w-4 h-4" src={LinkedinIcon} alt="linkedin" />
                </div>
              </NewTabReference>
            </div>
          </div>
          <img src={MarkerIcon} alt="marker-icon" className="h-5 w-5 mb-6 mt-12 md:mb-0 md:mt-1" />
        </div>
      </div>
      <div className="w-full bg-white-blue flex justify-center">
        <p className="text-sm text-gray-dark font-medium py-4">
          2017 - {new Date().getFullYear()} Copyrights All right reserved
        </p>
      </div>
      <div
        className="absolute left-0 bottom-0 w-full bg-no-repeat bg-top"
        style={{
          zIndex: '-1',
          height: isMediumSize() ? '112%' : '135%',
          backgroundImage: `url(${FooterBlob})`,
          backgroundSize: isMediumSize() ? 'auto 100%' : '160% auto'
        }}
      />
    </footer>
  );
}

export default HomepageFooter;
