import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const StatusIcon = ({
  isActive,
  className,
  isFilled,
  activeText,
  inactiveText,
  activeClasses,
  inactiveClasses,
  fontSizeClass
}) => {
  return isActive ? (
    <Typography
      className={classNames('px-1 rounded font-medium tracking-normal', fontSizeClass, className, {
        [activeClasses.unfilled]: !isFilled,
        [activeClasses.filled]: isFilled
      })}
    >
      {activeText}
    </Typography>
  ) : (
    <Typography
      className={classNames('px-1 rounded font-medium tracking-normal', fontSizeClass, className, {
        [inactiveClasses.unfilled]: !isFilled,
        [inactiveClasses.filled]: isFilled
      })}
    >
      {inactiveText}
    </Typography>
  );
};

StatusIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  fontSizeClass: PropTypes.string,
  activeClasses: PropTypes.instanceOf(Object),
  inactiveClasses: PropTypes.instanceOf(Object),
  isFilled: PropTypes.bool,
  activeText: PropTypes.string,
  inactiveText: PropTypes.string
};

StatusIcon.defaultProps = {
  className: '',
  isFilled: false,
  activeText: 'Active',
  inactiveText: 'Paused',
  fontSizeClass: 'text-xs',
  activeClasses: {
    filled: 'bg-green-lighter text-white',
    unfilled: 'bg-green-transparent text-green-lighter'
  },
  inactiveClasses: {
    filled: 'bg-yellow-lighter text-white',
    unfilled: 'bg-yellow-transparent text-yellow-light'
  }
};

export default StatusIcon;
