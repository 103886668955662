import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Colors from 'styles/colors';
import { CheckIcon } from '../icons';

const CheckBox = React.forwardRef((props, ref) => {
  const {
    label,
    labelClassName,
    classNameContainer,
    classNameCheckBox,
    onCheckHandler,
    value,
    onKeyPress,
    disabled
  } = props;

  function onCheckBoxClick(event) {
    event.stopPropagation();
    if (disabled) return;
    onCheckHandler(!value);
  }

  return (
    <div className={`flex items-center cursor-pointer ${classNameContainer}`}>
      <div
        ref={ref}
        role="checkbox"
        onClick={onCheckBoxClick}
        onKeyPress={onKeyPress || onCheckBoxClick}
        aria-checked={value}
        tabIndex={0}
        className={classNames(
          'flex justify-center items-center cursor-pointer rounded',
          classNameCheckBox,
          {
            'bg-grad-2 border-white': value && !disabled,
            'border-gray border': !value && disabled,
            'border-gray-dark border': !value && !disabled,
            'focus:border-yellow hover focus:shadow focus:outline-none border': !disabled,
            'bg-white-dark': disabled
          }
        )}
      >
        {value && (
          <CheckIcon
            fill={disabled ? Colors.gray.dark : Colors.white.default}
            width="50%"
            height="auto"
          />
        )}
      </div>
      {label && (
        <div
          role="button"
          className={classNames(labelClassName)}
          onClick={onCheckBoxClick}
          onKeyPress={onCheckBoxClick}
          tabIndex="0"
        >
          {label}
        </div>
      )}
    </div>
  );
});

CheckBox.propTypes = {
  label: PropTypes.node,
  classNameContainer: PropTypes.string,
  classNameCheckBox: PropTypes.string,
  onCheckHandler: PropTypes.func,
  value: PropTypes.bool.isRequired,
  labelClassName: PropTypes.string,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool
};

CheckBox.defaultProps = {
  label: null,
  classNameContainer: '',
  classNameCheckBox: 'h-4 w-4',
  onCheckHandler: () => {},
  labelClassName: 'text-sm text-black ml-2 font-medium select-none',
  onKeyPress: null,
  disabled: false
};

export default CheckBox;
