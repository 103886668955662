import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DotCircleIcon } from '../icons';
import { SVG_GRAD_ONE } from '../gradients';

const RadioIcon = ({ checked, className, size }) => {
  if (checked)
    return (
      <DotCircleIcon
        className={className}
        height={`${size}px`}
        width={`${size}px`}
        fill={SVG_GRAD_ONE}
      />
    );
  return (
    <div
      className={classNames('flex-shrink-0 bg-white round border border-gray', className)}
      style={{
        height: `${size}px`,
        width: `${size}px`
      }}
    />
  );
};

RadioIcon.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  size: PropTypes.number
};

RadioIcon.defaultProps = {
  className: '',
  size: 16
};

export default RadioIcon;
