import { rangeToAPI, selectedProductsToAPI } from '@sm/api';
import { GLOBAL_MK } from '@sm/Flags';
import { setSelectedProducts } from '../store/actions';

// eslint-disable-next-line import/prefer-default-export
export const buildParams = ({ state, dispatch }, skipSelectedProducts) => {
  const { dateRange, marketplaceID } = state.selector;
  const dateRangeToAPI = rangeToAPI(dateRange);
  let result = { ...dateRangeToAPI, marketplaceID };
  if (marketplaceID === GLOBAL_MK) result.currency = state.user.profile.defaultSettings?.currency;
  const addSelectedProductsParams = () => {
    const {
      selectedProducts,
      selectedBookmark,
      tempBookmark,
      isPersistBookmark
    } = state.productSearch;
    if (!selectedProducts.length) return;
    if (isPersistBookmark) {
      result.bookmark_id = selectedBookmark?.id;
    } else if (tempBookmark?.id) {
      const tempBookmarkExpired = tempBookmark?.expiry_date < Date.now();
      if (tempBookmarkExpired) {
        if (dispatch) dispatch(setSelectedProducts([...selectedProducts]));
        result.temp_bookmark_expired = true;
        return;
      }
      result.bookmark_id = tempBookmark?.id;
    } else {
      result = { ...result, ...selectedProductsToAPI(selectedProducts) };
    }
  };
  if (!skipSelectedProducts) addSelectedProductsParams();
  return result;
};
