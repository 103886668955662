import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

import { dateFromStr } from '@utils';
import Colors from 'styles/colors';

import DatePickerNoOverlapDialog from './no-overlap-dialog';
import { CalendarIcon } from '../icons';
import DatePickerWidgetContent from './DatePickerWidgetContent';
import { OutlinedWrapper } from '../inputs';

const DateRangeInput = ({ dateRange, start, onConfirm, title, className, disabled }) => {
  const [dateRangeDialogOpened, setDateRangeDialogOpened] = useState(false);
  return (
    <>
      <OutlinedWrapper
        title={title}
        className={classNames('cursor-pointer', className)}
        onClick={() => {
          if (disabled) return;
          setDateRangeDialogOpened(true);
        }}
        disabled={disabled}
      >
        <Box className="w-full">
          <div className="flex h-8 items-center w-full h-full">
            <CalendarIcon className="h-4 w-4 hidden xs:inline-block" fill={Colors.gray.default} />
            <p className={classNames('xs:ml-4 text-sm text-gray-darkest')}>
              <DatePickerWidgetContent
                today={new Date()}
                range={{ from: dateFromStr(dateRange.from), to: dateFromStr(dateRange.to) }}
              />
            </p>
          </div>
        </Box>
      </OutlinedWrapper>
      <DatePickerNoOverlapDialog
        lifeTime={new Date(start)}
        onClose={() => setDateRangeDialogOpened(false)}
        open={dateRangeDialogOpened}
        onConfirm={value => {
          setDateRangeDialogOpened(false);
          onConfirm(value);
        }}
        editedRange={dateRange}
      />
    </>
  );
};

DateRangeInput.propTypes = {
  dateRange: PropTypes.instanceOf(Object).isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

DateRangeInput.defaultProps = {
  className: ''
};

export default DateRangeInput;
