import React from 'react';
import NumberFormat from 'react-number-format';
import { DECIMAL_SCALE } from './consts';

export const getFormattedValue = (
  value,
  fixedDecimalScale = null,
  suffix = null,
  prefix = null
) => {
  if (Number.isNaN(value)) {
    return null;
  }

  return (
    <NumberFormat
      displayType="text"
      value={value}
      thousandSeparator=","
      decimalScale={DECIMAL_SCALE}
      fixedDecimalScale={fixedDecimalScale}
      suffix={suffix}
      prefix={prefix}
    />
  );
};

export const mock = () => {};

const htmlParser = new DOMParser();

const removeScriptTagFromString = str =>
  str && str.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

export const parseHtmlString = str => {
  if (!str) return str;
  return htmlParser.parseFromString(
    `<!doctype html><body>${removeScriptTagFromString(str)}`,
    'text/html'
  ).body.textContent;
};

export const normalizeCasedString = string => {
  try {
    if (!string || string.match(' ')) return string;
    if (string.match('_'))
      return string
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    return string.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2').trim();
  } catch {
    return string;
  }
};
