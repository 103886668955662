import API from '@sm/api';
import mock from '../mock';

const notesList = [
  {
    id: 1593,
    datetime: '2024-10-15',
    description: 'New Product launch',
    marketplace: null
  },
  {
    id: 1592,
    datetime: '2024-10-09',
    description: 'Started promotion',
    marketplace: null
  }
];

mock.onGet(API.notes).reply(() => {
  return [200, notesList];
});
