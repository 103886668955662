const bytesInMb = 1024 * 1024;
const bytesInKb = 1024;
const attachedFileMaxSize = 9 * bytesInMb;
const allowedAttachFormats = ['jpg', 'png', 'gif', 'txt', 'doc', 'docx', 'pdf'];

const getSizeOfFiles = files => {
  if (!files) return 0;
  return files.reduce((sum, item) => sum + item.size, 0);
};
const findFileByName = (name, list) => list.find(file => file.name === name);

const errorsTextMap = {
  nonUniqueName: ' The attached files must have unique names.',
  notAllowedExt: ' Not allowed file extension.',
  sizeLimit: ' Attachment size exceeds the allowable limit.'
};

export const getValidFiles = (stateFilesList, newFiles) => {
  if (!newFiles) return [];
  const sortedFiles = newFiles.reduce(
    (acc, file) => {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const extValid = allowedAttachFormats.includes(ext.toLowerCase());
      const attachedFilesSize = getSizeOfFiles(stateFilesList);
      const newAcceptedFilesSize = getSizeOfFiles(acc.accepted);
      const uniqueName =
        !findFileByName(file.name, stateFilesList) && !findFileByName(file.name, acc.accepted);
      const sizeValid = attachedFilesSize + newAcceptedFilesSize + file.size < attachedFileMaxSize;
      const errors = {
        nonUniqueName: acc.errors.nonUniqueName || !uniqueName,
        notAllowedExt: acc.errors.notAllowedExt || !extValid,
        sizeLimit: acc.errors.sizeLimit || !sizeValid
      };
      if (extValid && sizeValid && uniqueName) {
        return { ...acc, accepted: [...acc.accepted, file], errors };
      }
      return { ...acc, rejected: [...acc.rejected, file], errors };
    },
    {
      accepted: [],
      rejected: [],
      errors: { nonUniqueName: false, notAllowedExt: false, sizeLimit: false }
    }
  );
  let errorMsg = '';
  if (sortedFiles.rejected?.length) {
    errorMsg = Object.keys(errorsTextMap).reduce((msg, key) => {
      if (sortedFiles.errors[key]) {
        return msg + errorsTextMap[key];
      }
      return msg;
    }, `Files: ${sortedFiles.rejected.map(file => file.name).join(', ')} - not uploaded!`);
  }
  return { validFiles: sortedFiles.accepted, errorMsg };
};

export const getFormattedFileSize = size =>
  size < bytesInMb ? `${Math.ceil(size / bytesInKb)} Kb` : `${(size / bytesInMb).toFixed(2)} Mb`;
