import API from '@sm/api';
import mock from '../mock';
import { getTempId } from '../../@utils';

export const expensesList = {
  custom_expenses: [],
  amazon_expenses: [
    {
      id: 'amazon_9955353',
      allocation: 'all',
      category: 'FBA inventory and inbound services fees',
      name: 'FBADisposalFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -1.04,
      range_amount: -1.04,
      daily_amount: -1.04,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955355',
      allocation: 'profit_and_loss',
      category: 'Cost of Advertising',
      name: 'Charge',
      start: '2024-10-02T12:47:24Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -306.18,
      range_amount: -306.18,
      daily_amount: -306.18,
      product_count: 0,
      locked: true
    },
    {
      id: 'amazon_9955562',
      allocation: 'profit_and_loss',
      category: 'FBA inventory credit',
      name: 'REVERSAL_REIMBURSEMENT',
      start: '2024-09-29T08:13:38Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: 17.32,
      range_amount: 17.32,
      daily_amount: 17.32,
      product_count: 1,
      locked: false
    },
    {
      id: 'amazon_9955563',
      allocation: 'profit_and_loss',
      category: 'Adjustments',
      name: 'COMPENSATED_CLAWBACK',
      start: '2024-09-28T18:29:56Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -17.32,
      range_amount: -17.32,
      daily_amount: -17.32,
      product_count: 1,
      locked: false
    },
    {
      id: 'amazon_9955573',
      allocation: 'all',
      category: 'FBA inventory and inbound services fees',
      name: 'FBADisposalFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -1.04,
      range_amount: -1.04,
      daily_amount: -1.04,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955577',
      allocation: 'all',
      category: 'Service fees',
      name: 'FBAInboundConvenienceFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: 0.0,
      range_amount: 0.0,
      daily_amount: 0.0,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955581',
      allocation: 'all',
      category: 'Service fees',
      name: 'Subscription',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -39.99,
      range_amount: -39.99,
      daily_amount: -39.99,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955583',
      allocation: 'all',
      category: 'Service fees',
      name: 'FBAInboundConvenienceFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: 0.0,
      range_amount: 0.0,
      daily_amount: 0.0,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955593',
      allocation: 'all',
      category: 'Service fees',
      name: 'FBAInboundConvenienceFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: 0.0,
      range_amount: 0.0,
      daily_amount: 0.0,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955882',
      allocation: 'profit_and_loss',
      category: 'FBA inventory credit',
      name: 'REVERSAL_REIMBURSEMENT',
      start: '2024-10-10T00:45:51Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: 14.14,
      range_amount: 14.14,
      daily_amount: 14.14,
      product_count: 1,
      locked: false
    },
    {
      id: 'amazon_9955885',
      allocation: 'all',
      category: 'FBA inventory and inbound services fees',
      name: 'FBADisposalFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -3.12,
      range_amount: -3.12,
      daily_amount: -3.12,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955888',
      allocation: 'all',
      category: 'FBA inventory and inbound services fees',
      name: 'FBADisposalFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -1.04,
      range_amount: -1.04,
      daily_amount: -1.04,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955891',
      allocation: 'all',
      category: 'FBA inventory and inbound services fees',
      name: 'FBADisposalFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -1.04,
      range_amount: -1.04,
      daily_amount: -1.04,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955894',
      allocation: 'all',
      category: 'FBA inventory and inbound services fees',
      name: 'FBADisposalFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -1.04,
      range_amount: -1.04,
      daily_amount: -1.04,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955897',
      allocation: 'profit_and_loss',
      category: 'FBA inventory credit',
      name: 'REVERSAL_REIMBURSEMENT',
      start: '2024-10-15T01:38:38Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: 14.14,
      range_amount: 14.14,
      daily_amount: 14.14,
      product_count: 1,
      locked: false
    },
    {
      id: 'amazon_9955899',
      allocation: 'all',
      category: 'FBA inventory and inbound services fees',
      name: 'FBADisposalFee',
      start: '2024-10-02T00:00:00Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -1.04,
      range_amount: -1.04,
      daily_amount: -1.04,
      product_count: 0,
      locked: false
    },
    {
      id: 'amazon_9955902',
      allocation: 'profit_and_loss',
      category: 'Cost of Advertising',
      name: 'Charge',
      start: '2024-10-18T07:23:09Z',
      coupon_id: null,
      marketplace: null,
      redemption_count: 0,
      total_amount: -506.87,
      range_amount: -506.87,
      daily_amount: -506.87,
      product_count: 0,
      locked: true
    }
  ]
};

mock.onGet(new RegExp(`${API.expenses.root}.+/`)).reply(async config => {
  console.log('config', config);
  const { url } = config;
  const [, expenseId] = url.match(/expenses\/(\d+)\//);
  const expenseDetails = [...expensesList.custom_expenses, ...expensesList.amazon_expenses].find(
    item => item.id === +expenseId
  );
  await new Promise(resolve => setTimeout(() => resolve(), 200));
  console.log('expenseDetails', expenseDetails);
  return [200, expenseDetails];
});

mock.onGet(API.expenses.root).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  return [200, expensesList];
});

mock.onDelete(new RegExp(`${API.expenses.root}.+/`)).reply(async config => {
  console.log('config', config);
  const { url } = config;
  const [, expenseId] = url.match(/expenses\/(\d+)\//);
  expensesList.custom_expenses = expensesList.custom_expenses.filter(
    item => item.id !== +expenseId
  );
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  return [200];
});

mock.onPost(API.expenses.root).reply(async config => {
  console.log('config', config);
  const data = JSON.parse(config.data);
  expensesList.push({ ...data, id: getTempId() });
  await new Promise(resolve => setTimeout(() => resolve(), 5000));
  return [200];
});

mock.onPut(new RegExp(`${API.expenses.root}.+/`)).reply(async config => {
  const data = JSON.parse(config.data);
  console.log('config', config);
  console.log('onPut data', data);
  await new Promise(resolve => setTimeout(() => resolve(), 5000));
  return [200];
});
