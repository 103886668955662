import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { NotificationView } from '@components/notifications';
import LoadingSpinner from '@components/common/LoadingSpinner';
import { setNotificationMsgAction, setPrimaryNotificationMsgAction } from '../store/actions';

const LayoutView = ({
  children,
  setNotificationMsg,
  isFetching,
  msg,
  msgType,
  nodeTypeMessage,
  autoHideMsg,
  closeOnBlur,
  primaryNotification,
  primaryNotificationType,
  nodeTypePrimaryNotification,
  setPrimaryNotificationMsg
}) => {
  return (
    <>
      <NotificationView
        message={msg}
        messageType={msgType}
        nodeTypeMessage={nodeTypeMessage}
        autoHideMsg={autoHideMsg}
        closeOnBlur={closeOnBlur}
        onClose={() => setNotificationMsg('')}
        primaryNotification={primaryNotification}
        primaryNotificationType={primaryNotificationType}
        nodeTypePrimaryNotification={nodeTypePrimaryNotification}
        onPrimaryClose={() => setPrimaryNotificationMsg('')}
      />
      <LoadingSpinner loading={isFetching} />
      {children}
    </>
  );
};

LayoutView.propTypes = {
  children: PropTypes.node.isRequired,
  setNotificationMsg: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  msg: PropTypes.string
};

LayoutView.defaultProps = {
  msg: null
};

function mapStateToProps(state) {
  return {
    isFetching: state.network.isFetching,
    msg: state.notification.msg,
    msgType: state.notification.msgType,
    nodeTypeMessage: state.notification.nodeTypeMessage,
    autoHideMsg: state.notification.autoHideMsg,
    closeOnBlur: state.notification.closeOnBlur,
    primaryNotification: state.notification.primaryNotification,
    primaryNotificationType: state.notification.primaryNotificationType,
    nodeTypePrimaryNotification: state.notification.nodeTypePrimaryNotification
  };
}

export default connect(mapStateToProps, {
  setNotificationMsg: setNotificationMsgAction,
  setPrimaryNotificationMsg: setPrimaryNotificationMsgAction
})(LayoutView);
