import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import RadioIcon from './RadioIcon';

const RadioCard = ({ header, imgSrc, description, state, value, onClick, disabled }) => {
  return (
    <Box
      className={classNames(
        'w-full flex justify-start items-center px-4 py-3 border-solid border rounded mb-4',
        {
          'cursor-not-allowed border-gray-lighter': disabled,
          'cursor-pointer border-gray': !disabled
        }
      )}
      onClick={() => {
        if (!disabled) onClick(value);
      }}
    >
      <div className="h-full mr-4 flex justify-center items-center flex-shrink-0 flex-grow-0">
        {imgSrc}
      </div>
      <div className="mr-4 flex-grow flex flex-col justify-center items-start box-border">
        <Typography className="text-gray-darkest">{header}</Typography>
        <Typography className="text-left text-sm text-gray-black">{description}</Typography>
      </div>
      <RadioIcon checked={value === state} className="ml-auto flex-shrink-0" size={24} />
    </Box>
  );
};

RadioCard.propTypes = {
  header: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  state: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.instanceOf(Object).isRequired
};

RadioCard.defaultProps = {
  disabled: false
};

export default RadioCard;
