export const PING_SERVER = 'PING_SERVER';
export const CLEANUP_SYSTEM_NOTIFICATIONS = 'CLEANUP_SYSTEM_NOTIFICATIONS';
export const HANDLE_SYSTEM_ERRORS = 'HANDLE_SYSTEM_ERRORS';

export const pingServerAction = () => ({ type: PING_SERVER });

export const cleanupSystemNotificationsAction = payload => ({
  type: CLEANUP_SYSTEM_NOTIFICATIONS,
  payload
});
export const handleSystemErrorsAction = payload => ({ type: HANDLE_SYSTEM_ERRORS, payload });
