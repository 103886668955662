const WindowSizes = {
  xxs: 350,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};

export const isXLargeSize = () => window.innerWidth <= WindowSizes.xl;
export const isLargeSize = () => window.innerWidth <= WindowSizes.lg;
export const isMediumSize = () => window.innerWidth <= WindowSizes.md;
export const isSmallSize = () => window.innerWidth <= WindowSizes.sm;

export const isDesktopSize = () => WindowSizes.lg <= window.innerWidth;

export default WindowSizes;
