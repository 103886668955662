export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SETUP_NEW_PASSWORD = 'SETUP_NEW_PASSWORD';

export const refreshTokenAction = pingMode => ({ type: REFRESH_TOKEN, pingMode });

export const resetPasswordAction = (email, callback) => ({
  type: RESET_PASSWORD,
  payload: { email, callback }
});

export const setupNewPasswordAction = payload => ({ type: SETUP_NEW_PASSWORD, payload });
