import React from 'react';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import PLANS_LIST from '@sm/PlansList';
import { Button } from '@material-ui/core';
import ROUTES from 'seller/routing';
import { PRICING_ANCHOR } from './HomepageHeader';

function HomepagePricing() {
  return (
    <Element name={PRICING_ANCHOR} className="w-11/12 flex flex-col items-center">
      <h2 className="text-black text-2xl text-4xl font-bold">Pricing Plans</h2>
      <p className="text-gray-dark text-lg w-full sm:w-2/3 lg:w-1/2 text-center mt-6">
        Transparent and affordable pricing. Try 30 days for free by signing up today. No credit card
        required. No hidden fees.
      </p>
      <div className="flex flex-wrap justify-center sm:justify-between w-full lg:mt-10">
        {PLANS_LIST.map(value => {
          return (
            <div
              key={value.title}
              className="flex flex-col items-center justify-between box-shadow-default rounded py-4 mt-8 lg:mt-0 w-3/4 sm:sm-w-48/100 lg:sm-w-22/100 bg-white"
            >
              <div className="flex flex-col items-center">
                <h3 className="text-2xl font-medium">{value.title}</h3>
                <p className="text-base text-gray-dark mt-2">{value.description}</p>
                <img src={value.src} alt="plan" className="mt-5 w-8/12" />
              </div>
              <div className="flex flex-col items-center w-full">
                <p className="text-gray-darkest sm-text-32px">${value.price}</p>
                {value.title === 'Enterprise' && (
                  <p className="text-xs text-gray-darkest">+$7 per block of 1000 orders</p>
                )}
                <p className="text-gray-dark text-sm">
                  {value.title !== 'Starter' ? 'paid monthly' : 'forever free'}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <Link to={ROUTES.registration}>
        <Button className="text-white bg-grad-3 rounded-none py-3 px-12 text-base font-medium normal-case rounded mt-10 sm-button-green-shadow">
          Start Free Trial Now
        </Button>
      </Link>
    </Element>
  );
}

export default HomepagePricing;
