// eslint-disable-next-line import/prefer-default-export
export const retryImport = (fn, sendError, delay = 1000, attemptLimit = 5) => {
  return new Promise(resolve => {
    fn()
      .then(resolve)
      .catch(async error => {
        if (attemptLimit <= 0) {
          sendError(error);
          document.location.reload();
        }
        setTimeout(() => {
          console.log(`Chunk load retrying. Attempts left ${attemptLimit}`);
          retryImport(fn, sendError, delay, attemptLimit - 1).then(resolve);
        }, delay);
      });
  });
};
