import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import GradButton from '@components/buttons/GradButton';
import DropZone from 'react-dropzone';
import { Typography } from '@material-ui/core';

const uploadPhotoDialogStyle = {
  button: {
    width: '7rem'
  },
  circle: {
    width: '11.5rem',
    height: '11.5rem'
  }
};

function UploadPhotoDialog(props) {
  const {
    open,
    onClose,
    onUploadHandler,
    onAbort,
    title,
    placeholder,
    mask,
    loadFileFunc,
    loadedView,
    maxSize,
    widget
  } = props;
  const [path, setPath] = useState(null);

  function onUpload() {
    if (path) {
      onUploadHandler(path);
      setPath(null);
    }
    onClose();
  }

  function onDrop(selectedFiles) {
    const reader = new FileReader();
    const abortedFiles = [];
    const acceptedFiles = [];
    selectedFiles.forEach(item => {
      if (maxSize && item.size > maxSize) {
        abortedFiles.push(item);
        return;
      }
      acceptedFiles.push(item);
    });
    if (abortedFiles.length) onAbort(abortedFiles);
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      setPath(reader.result);
    };
    acceptedFiles.forEach(file => loadFileFunc(file, reader));
  }

  function getDropZoneContent(getInputProps) {
    return path ? (
      loadedView(path)
    ) : (
      <>
        <input {...getInputProps()} />
        <p className="text-center w-40 text-gray-dark text-base">{placeholder}</p>
      </>
    );
  }

  const ImageDropZone = (
    <DropZone onDrop={onDrop} accept={mask} onDropRejected={onAbort}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps()}
            className="border-gray-dashed-md flex justify-center items-center mt-6 self-center overflow-hidden rounded-full mx-24"
            style={uploadPhotoDialogStyle.circle}
          >
            {getDropZoneContent(getInputProps)}
          </div>
        </section>
      )}
    </DropZone>
  );

  const handleClose = () => {
    onClose();
    setPath(null);
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onEscapeKeyDown={handleClose}
      onClose={handleClose}
      PaperProps={{ className: 'p-4 max-w-480px' }}
    >
      <Typography className="font-medium text-gray-darkest">{title}</Typography>
      <div className="underline-sm bg-grad-8" />
      {widget}
      {ImageDropZone}
      <div className="flex justify-end mt-12">
        <Button
          variant="outlined"
          className="text-gray-dark mr-4 normal-case text-xs mt-2 h-8 leading-none"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <GradButton grad="bg-grad-8" className="text-xs mt-2 h-8" onClick={onUpload}>
          Upload
        </GradButton>
      </div>
    </Dialog>
  );
}

UploadPhotoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadHandler: PropTypes.func.isRequired,
  onAbort: PropTypes.func,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  loadFileFunc: PropTypes.func.isRequired,
  loadedView: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  widget: PropTypes.node
};

UploadPhotoDialog.defaultProps = {
  onAbort: () => {},
  maxSize: null,
  widget: null
};
export default UploadPhotoDialog;
