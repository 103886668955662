import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core';

import { ExpandIcon } from '@components/icons';
import Colors from 'styles/colors';

export const accordionClasses = makeStyles({
  root: {
    background: 'transparent !important',
    boxShadow: 'none',
    '&::before': { display: 'none' },
    margin: '0',
    '&.Mui-expanded': {
      margin: '0'
    }
  },
  summary: {
    flexDirection: 'row-reverse',
    padding: '0px',
    '& .MuiIconButton-edgeEnd': {
      marginRight: '0px',
      marginLeft: '-0.5rem',
      width: '1.5rem',
      height: '1.5rem',
      padding: '0px'
    },
    minHeight: '0px !important',
    '&.Mui-expanded': {
      minHeight: '0 !important'
    },
    '& .MuiAccordionSummary-content': {
      margin: '0 !important'
    }
  },
  details: {
    paddingTop: '0 !important',
    paddingLeft: '0.75rem !important',
    paddingRight: '0 !important',
    paddingBottom: '0 !important'
  }
});

const AccordionWrapper = ({ children, summary, expanded, onChange, alwaysExpanded }) => {
  const accordionStyles = accordionClasses();
  const expandIcon = (
    <ExpandIcon
      className={classNames('w-2', { 'opacity-0': alwaysExpanded })}
      fill={Colors.white.default}
    />
  );
  return (
    <Accordion
      className={accordionStyles.root}
      expanded={expanded}
      onChange={alwaysExpanded ? () => {} : onChange}
    >
      <AccordionSummary expandIcon={expandIcon} className={accordionStyles.summary}>
        {summary}
      </AccordionSummary>
      <AccordionDetails className={accordionStyles.details}>
        <div className="w-full h-full">{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

AccordionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  alwaysExpanded: PropTypes.bool.isRequired,
  summary: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AccordionWrapper;
