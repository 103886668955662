import {
  SET_ADVERTISING_CAMPAIGN_DETAILS,
  SET_ADVERTISING_CAMPAIGNS,
  SET_ADVERTISING_FETCHING
} from '../saga/advertising';

const initialState = {
  campaigns: [],
  campaignDetails: null,
  fetching: {
    campaigns: false,
    campaignDetails: false
  }
};

export const advertisingSelector = state => state.advertising;

const expenses = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ADVERTISING_CAMPAIGNS: {
      return { ...state, campaigns: payload };
    }
    case SET_ADVERTISING_CAMPAIGN_DETAILS: {
      return { ...state, campaignDetails: payload };
    }
    case SET_ADVERTISING_FETCHING: {
      return { ...state, fetching: { ...state.fetching, ...payload } };
    }
    default: {
      return state;
    }
  }
};

export default expenses;
