import {
  all,
  put,
  select,
  call,
  fork,
  cancelled,
  takeLatest,
  takeEvery
} from '@redux-saga/core/effects';
import API, { LONG_REQUEST_TIMEOUT } from '@sm/api';
import { prepareCampaignsData, prepareMessagesData } from 'seller/pages/email-tool/utils';
import { axiosCall, dateToStr, getCancelToken, getApiFormatFiles } from '@utils';
import ROUTES from 'seller/routing';
import { setNetworkActivityAction, setSuccessMsgAction } from 'seller/store/actions';
import {
  filterScheduledMessageAction,
  setActiveCampaignAction,
  setCampaignsAction,
  setDynamicVariablesAction,
  setEditedMessageAction,
  setEmailPreviewAction,
  setEmailToolFetching,
  setMessagesAction,
  setScheduledMessagesAction,
  setSentMessagesAction,
  PULL_DYNAMIC_VARIABLES,
  PULL_CAMPAIGNS,
  PULL_MESSAGE_TEMPLATES,
  POST_NEW_CAMPAIGN,
  POST_NEW_MESSAGE_TEMPLATE,
  PUT_CAMPAIGN,
  DELETE_CAMPAIGN,
  PAUSE_CAMPAIGN,
  PAUSE_MESSAGE_TEMPLATE,
  PUT_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  UNSUBSCRIBE_EMAIL_TOOL,
  CANCEL_SCHEDULED_MESSAGE,
  SEND_TEST_EMAIL,
  PULL_EMAIL_PREVIEW,
  PULL_EMAIL_TOOL_TABLE_DATA
} from './actions';
import { emailToolStateSelector, userSelector, selectorSelector } from '../../reducers';
import { failHandler } from '../utils';

export function* pullMessageTemplatesSaga(action) {
  const quietRefresh = action?.quietRefresh;
  if (!action?.quietRefresh) yield put(setEmailToolFetching({ MESSAGE_TEMPLATES: true }));
  const { activeCampaign } = yield select(emailToolStateSelector);
  const { userId } = yield select(userSelector);
  const params = { params: { campaign: activeCampaign } };
  if (userId) params.params.userId = userId;
  try {
    const { data } = yield axiosCall.get(API.emailTool.messageTemplates, params);
    yield put(setMessagesAction(prepareMessagesData(data)));
  } catch (e) {
    yield fork(failHandler, [params, 'Load campaign messages failed', e]);
  }
  if (!quietRefresh) yield put(setEmailToolFetching({ MESSAGE_TEMPLATES: false }));
}

export function* pullScheduledMessagesSaga() {
  const { cancelToken, cancelRequest } = getCancelToken();
  yield put(setScheduledMessagesAction([]));
  yield put(setEmailToolFetching({ SCHEDULED_MESSAGES: true }));
  const { marketplaceID } = yield select(selectorSelector);
  const { userId } = yield select(userSelector);
  const params = {
    params: { marketplaceID },
    timeout: LONG_REQUEST_TIMEOUT
  };
  if (userId) params.params.userId = userId;
  let sagaResult;
  try {
    const { data } = yield axiosCall.get(API.emailTool.messages, { ...params, cancelToken });
    sagaResult = data;
    yield put(setScheduledMessagesAction(data));
  } catch (e) {
    yield fork(failHandler, [params, 'Load scheduled messages failed', e]);
  } finally {
    if (yield cancelled()) cancelRequest();
    yield put(setEmailToolFetching({ SCHEDULED_MESSAGES: false }));
  }
  return sagaResult;
}

export function* pullCampaignsSaga(action) {
  const noCleanUp = action?.noCleanUp;
  const { cancelToken, cancelRequest } = getCancelToken();
  yield put(setEmailToolFetching({ CAMPAIGNS: true }));
  if (!noCleanUp) yield put(setCampaignsAction([]));
  const {
    marketplaceID,
    dateRange: { from, to }
  } = yield select(selectorSelector);
  const { userId } = yield select(userSelector);
  const params = {
    params: { from: dateToStr(from), to: dateToStr(to), marketplaceID },
    timeout: LONG_REQUEST_TIMEOUT
  };
  if (userId) params.params.userId = userId;
  try {
    const [campaigns, scheduledMessages] = yield all([
      axiosCall.get(API.emailTool.campaigns, { ...params, cancelToken }),
      call(pullScheduledMessagesSaga)
    ]);
    yield put(setCampaignsAction(prepareCampaignsData(campaigns.data, scheduledMessages)));
  } catch (e) {
    yield fork(failHandler, [params, 'Load campaigns failed', e]);
  } finally {
    if (yield cancelled()) cancelRequest();
    yield put(setEmailToolFetching({ CAMPAIGNS: false }));
  }
}

export function* pullSentMessagesSaga() {
  const { cancelToken, cancelRequest } = getCancelToken();
  yield put(setEmailToolFetching({ SENT_MESSAGES: true }));
  yield put(setSentMessagesAction([]));
  const {
    marketplaceID,
    dateRange: { from, to }
  } = yield select(selectorSelector);
  const { userId } = yield select(userSelector);
  const params = {
    params: { from: dateToStr(from), to: dateToStr(to), marketplaceID },
    timeout: LONG_REQUEST_TIMEOUT
  };
  if (userId) params.params.userId = userId;
  try {
    const { data } = yield axiosCall.get(API.emailTool.messages, { ...params, cancelToken });
    yield put(setSentMessagesAction(data));
  } catch (e) {
    yield fork(failHandler, [params, 'Load sent messages failed', e]);
  } finally {
    if (yield cancelled()) cancelRequest();
    yield put(setEmailToolFetching({ SENT_MESSAGES: false }));
  }
}

export function* postNewMessageTemplateSaga({ payload, history }) {
  yield put(setNetworkActivityAction(true));
  try {
    const base64Files = yield getApiFormatFiles(payload.files);
    yield axiosCall.post(API.emailTool.messageTemplates, { ...payload, files: base64Files });
    yield put(setSuccessMsgAction('Message was created successfully'));
  } catch (e) {
    yield fork(failHandler, [payload, 'Message create failed', e]);
  }
  yield put(setNetworkActivityAction(false));
  yield history.push(ROUTES.email_tool.campaign);
}

export function* putMessageTemplateSaga({ payload, history }) {
  yield put(setNetworkActivityAction(true));
  try {
    const base64Files = yield getApiFormatFiles(payload.files);
    yield axiosCall.put(`${API.emailTool.messageTemplates}/${payload.id}/`, {
      ...payload,
      files: base64Files
    });
    yield put(setSuccessMsgAction('Message was updated successfully'));
  } catch (e) {
    yield fork(failHandler, [payload, 'Message update failed', e]);
  }
  yield put(setNetworkActivityAction(false));
  if (!payload.isRenaming) {
    yield history.push(ROUTES.email_tool.campaign);
    yield put(setEditedMessageAction(null));
  }
}

export function* pauseMessageTemplateSaga({ payload }) {
  try {
    yield axiosCall.put(`${API.emailTool.messageTemplates}/${payload.id}/`, {
      ...payload,
      active: !payload.active
    });
    yield put(
      setSuccessMsgAction(`Message was ${payload.active ? 'paused' : 'resumed'} successfully`)
    );
  } catch (e) {
    yield fork(failHandler, [payload, 'Pause message failed', e]);
  }
  yield call(pullMessageTemplatesSaga, { quietRefresh: true });
}

export function* deleteMessageTemplateSaga({ payload }) {
  try {
    yield axiosCall.delete(`${API.emailTool.messageTemplates}/${payload.id}/`);
    yield put(setSuccessMsgAction('Message was deleted successfully'));
  } catch (e) {
    yield fork(failHandler, [payload, 'Delete message failed', e]);
  }
  yield call(pullMessageTemplatesSaga);
}

export function* postNewCampaignSaga(action) {
  try {
    const { data } = yield axiosCall.post(API.emailTool.campaigns, action.campaign);
    yield put(setActiveCampaignAction(data.id));
    yield action.history.push(ROUTES.email_tool.campaign);
  } catch (e) {
    yield fork(failHandler, [action, 'Failed to create a campaign', e]);
    yield action.history.push(ROUTES.email_tool.root);
  }
  yield call(pullCampaignsSaga);
}

export function* putCampaignSaga({ payload }) {
  yield put(setNetworkActivityAction(true));
  try {
    yield axiosCall.put(`${API.emailTool.campaigns}/${payload.id}/`, payload);
    yield put(setSuccessMsgAction('Campaign was edited successfully'));
  } catch (e) {
    yield fork(failHandler, [payload, 'Edit campaign failed', e]);
  }
  yield call(pullCampaignsSaga, { noCleanUp: true });
  yield put(setNetworkActivityAction(false));
}

export function* deleteCampaignSaga({ payload }) {
  try {
    yield axiosCall.delete(`${API.emailTool.campaigns}/${payload}/`);
    yield put(setSuccessMsgAction('Campaign was deleted successfully'));
  } catch (e) {
    yield fork(failHandler, [payload, 'Delete campaign failed', e]);
  }
  yield put(setActiveCampaignAction(null));
  yield fork(pullCampaignsSaga);
  yield fork(pullSentMessagesSaga);
}

export function* pauseCampaignSaga({ payload }) {
  yield put(setEmailToolFetching({ CAMPAIGNS: true }));
  try {
    yield axiosCall.put(`${API.emailTool.campaigns}/${payload.id}/`, {
      ...payload,
      active: !payload.active
    });
    yield put(
      setSuccessMsgAction(`Campaign was ${payload.active ? 'paused' : 'resumed'} successfully`)
    );
  } catch (e) {
    yield fork(failHandler, [payload, 'Pause campaign failed', e]);
  }
  yield fork(pullCampaignsSaga, { noCleanUp: true });
  yield fork(pullSentMessagesSaga);
}

export function* unsubscribeEmailToolSaga({ payload }) {
  try {
    yield axiosCall.post(API.emailTool.blacklist, payload);
  } catch (e) {
    yield fork(failHandler, [payload, 'Unsubscribe failed', e]);
  }
  yield call(pullSentMessagesSaga);
}

export function* cancelScheduledMessageSaga({ payload }) {
  yield put(setEmailToolFetching({ SCHEDULED_MESSAGES: true }));
  try {
    if (payload.request_review) {
      yield axiosCall.patch(`${API.emailTool.requestReview}/${payload.id}/`, { canceled: true });
    } else {
      yield axiosCall.patch(API.emailTool.messages, { canceled: true, ...payload });
    }
    yield put(filterScheduledMessageAction(payload));
    yield put(setSuccessMsgAction('Scheduled message was successfully cancelled'));
  } catch (e) {
    yield fork(failHandler, [payload, 'Cancel message failed', e]);
  }
  yield put(setEmailToolFetching({ SCHEDULED_MESSAGES: false }));
}

export function* sendTestEmailSaga({ payload }) {
  try {
    const base64Files = yield getApiFormatFiles(payload.files);
    yield axiosCall.post(API.emailTool.sendEmail, {
      ...payload,
      files: base64Files
    });
    yield put(setSuccessMsgAction('Test email sent successfully'));
  } catch (e) {
    yield fork(failHandler, [payload, 'Test email send failed', e]);
  }
}

export function* pullDynamicVariablesSaga() {
  const { activeCampaign } = yield select(emailToolStateSelector);
  const { userId } = yield select(userSelector);
  const params = { params: { campaign: activeCampaign } };
  if (userId) params.params.userId = userId;
  try {
    const { data } = yield axiosCall.get(API.emailTool.dynamicVariables, params);
    yield put(setDynamicVariablesAction(data));
  } catch (e) {
    yield fork(failHandler, [params, 'Load dynamic variables failed', e]);
  }
}

export function* pullEmailPreviewSaga({ payload }) {
  yield put(setEmailToolFetching({ EMAIL_PREVIEW: true }));
  const { userId } = yield select(userSelector);
  const params = { params: { ...payload } };
  if (userId) params.params.userId = userId;
  try {
    const { data } = yield axiosCall.get(
      `${API.emailTool.messages}/${payload.message}/preview`,
      params
    );
    yield put(setEmailPreviewAction(data));
  } catch (e) {
    yield fork(failHandler, [params, 'Load message preview failed', e]);
  }
  yield put(setEmailToolFetching({ EMAIL_PREVIEW: false }));
}

export function* pullEmailToolTableDataSaga() {
  yield fork(pullCampaignsSaga);
  yield fork(pullSentMessagesSaga);
}

export function* emailToolSagaWatcher() {
  yield takeLatest(PULL_EMAIL_TOOL_TABLE_DATA, pullEmailToolTableDataSaga);
  yield takeLatest(PULL_CAMPAIGNS, pullCampaignsSaga);
  yield takeEvery(PULL_DYNAMIC_VARIABLES, pullDynamicVariablesSaga);
  yield takeEvery(PULL_MESSAGE_TEMPLATES, pullMessageTemplatesSaga);
  yield takeEvery(POST_NEW_CAMPAIGN, postNewCampaignSaga);
  yield takeEvery(POST_NEW_MESSAGE_TEMPLATE, postNewMessageTemplateSaga);
  yield takeEvery(PUT_CAMPAIGN, putCampaignSaga);
  yield takeEvery(DELETE_CAMPAIGN, deleteCampaignSaga);
  yield takeEvery(PAUSE_CAMPAIGN, pauseCampaignSaga);
  yield takeEvery(PAUSE_MESSAGE_TEMPLATE, pauseMessageTemplateSaga);
  yield takeEvery(PUT_MESSAGE_TEMPLATE, putMessageTemplateSaga);
  yield takeEvery(DELETE_MESSAGE_TEMPLATE, deleteMessageTemplateSaga);
  yield takeEvery(UNSUBSCRIBE_EMAIL_TOOL, unsubscribeEmailToolSaga);
  yield takeEvery(CANCEL_SCHEDULED_MESSAGE, cancelScheduledMessageSaga);
  yield takeEvery(SEND_TEST_EMAIL, sendTestEmailSaga);
  yield takeEvery(PULL_EMAIL_PREVIEW, pullEmailPreviewSaga);
}
