import { isXLargeSize, isMediumSize, isLargeSize } from '@sm/WindowSizes';

const offset = isXLargeSize() ? 120 : 135;

const tagWidth = 220;
const dateWidth = isMediumSize() ? 100 : 130;

let noteColumnWidth = window.innerWidth - tagWidth - dateWidth - offset;
if (isMediumSize()) {
  noteColumnWidth = 320;
} else if (isLargeSize()) {
  noteColumnWidth = 500;
}

const columns = [
  {
    name: 'Date',
    width: dateWidth
  },
  {
    name: 'Tag',
    width: tagWidth
  },
  {
    name: 'Note',
    width: noteColumnWidth
  }
];

export default columns;
