/* eslint-disable import/extensions */
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import '@fake-db';
import 'focus-visible/dist/focus-visible.min.js';

import LoadingSpinner from '@components/common/LoadingSpinner';
import { ErrorBoundary } from 'seller/containers';
import { CommonToolsProvider } from '@components/providers';
import { retryImport, sendError } from '@utils';
import { AddSvgGrads, FullHeightLayout } from './layouts';
import { FontObserver } from './containers/observer';
import store, { persistor } from './store';
import HomepageRoutes from './HomepageRoutes';
import ROUTES from './routing';
import MobileAppBanner from './containers/mobile-app-link/MobileAppBanner';
import { SetupPasswordPage } from './homepageIndex';
import Consent from './Consent';

const AppRoutes = lazy(() => retryImport(() => import('./AppRoutes.jsx')), sendError);

// FIXME: https://rainforest-life.atlassian.net/browse/SM-3591
const handleRedirectsToBackend = () => {
  console.log('window.location.href', window.location.href);
  if (
    window.location.href.includes('amazon/spa/') ||
    window.location.href.includes('/api/') ||
    window.location.href.includes('api-token-auth') ||
    window.location.href.includes('api-token-refresh')
  ) {
    window.location = window.location.href.includes('www.')
      ? window.location.href.replace('https://www.', 'https://www.api.')
      : window.location.href.replace('https://', 'https://api.');
  }
};

const App = () => {
  handleRedirectsToBackend();
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingSpinner />} persistor={persistor}>
        <Consent />
        <Router>
          <ErrorBoundary>
            <FullHeightLayout>
              <AddSvgGrads />
              <FontObserver>
                <CommonToolsProvider>
                  <Switch>
                    <Route
                      path={[ROUTES.root, ROUTES.login, ROUTES.registration, ROUTES.resetPassword]}
                      exact
                      component={HomepageRoutes}
                    />
                    <Route path={ROUTES.setupPassword} component={SetupPasswordPage} />
                    <Route path="/">
                      <Suspense fallback={<LoadingSpinner />}>
                        <AppRoutes />
                      </Suspense>
                    </Route>
                  </Switch>
                  <MobileAppBanner />
                </CommonToolsProvider>
              </FontObserver>
            </FullHeightLayout>
          </ErrorBoundary>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
