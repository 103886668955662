import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import { ChevronRightIcon } from '@components/icons';
import Colors from 'styles/colors';

export const ArrowsView = ({ onClick, onDoubleClick, rotated, className }) => {
  const iconClassName = 'z-10 h-3 w-3';
  const iconClassNameLeft = 'z-10 h-3 w-3 -mr-2';
  const iconButtonClassName = 'flex h-8 w-8 p-0';
  const iconColor = onClick ? Colors.black.default : Colors.gray.default;
  const doubleIconColor = onDoubleClick ? Colors.black.default : Colors.gray.default;
  return (
    <div
      className={classNames('flex', className)}
      style={rotated ? { transform: 'rotate(-180deg)' } : {}}
    >
      <IconButton className={iconButtonClassName} disabled={!onDoubleClick} onClick={onDoubleClick}>
        <ChevronRightIcon className={iconClassNameLeft} fill={doubleIconColor} />
        <ChevronRightIcon className={iconClassName} fill={doubleIconColor} />
      </IconButton>
      <IconButton className={iconButtonClassName} disabled={!onClick} onClick={onClick}>
        <ChevronRightIcon className={iconClassName} fill={iconColor} />
      </IconButton>
    </div>
  );
};

ArrowsView.propTypes = {
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  rotated: PropTypes.bool,
  className: PropTypes.string
};

ArrowsView.defaultProps = {
  onClick: null,
  onDoubleClick: null,
  rotated: false,
  className: ''
};
