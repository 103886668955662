// eslint-disable-next-line import/prefer-default-export
export const loginReducerMigration = {
  1: state => {
    const { tokenDate } = state;
    const lsTokenDate = localStorage.getItem('token-date');
    localStorage.removeItem('token-date');
    return { ...state, tokenDate: tokenDate || +lsTokenDate || null };
  }
};

export const commonPersistReducerMigration = {
  1: state => {
    const { warnings } = state;
    if (!warnings) return state;
    const {
      accessDeniedSkipped,
      lastIgnoredAvailableAppVersion,
      lastShownGlobalSystemNotificationId
    } = warnings;
    return {
      userPreferences: { accessDeniedSkipped },
      globalSystemNotification: {
        lastIgnoredAvailableAppVersion,
        lastShownGlobalSystemNotificationId
      }
    };
  }
};
