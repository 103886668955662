import { isObject } from '@utils';
import { sortMessageTemplates } from './sort';

const getScheduledStats = scheduledMessages =>
  scheduledMessages.reduce(
    (acc, scheduledMessage) => {
      const { campaign, request_review: isReview, message } = scheduledMessage;
      const msgType = isReview ? 'request_reviews_scheduled' : 'emails_scheduled';
      if (message in acc.messageTemplates) {
        acc.messageTemplates[message][msgType] = acc.messageTemplates[message][msgType]
          ? acc.messageTemplates[message][msgType] + 1
          : 1;
      } else {
        acc.messageTemplates[message] = { [msgType]: 1 };
      }
      if (campaign in acc.campaigns) {
        acc.campaigns[campaign][msgType] = acc.campaigns[campaign][msgType]
          ? acc.campaigns[campaign][msgType] + 1
          : 1;
      } else {
        acc.campaigns[campaign] = { [msgType]: 1 };
      }
      return acc;
    },
    { campaigns: {}, messageTemplates: {} }
  );

export const prepareCampaignsData = (campaigns, scheduledMessages) => {
  const scheduledStats = getScheduledStats(scheduledMessages);
  return campaigns
    ?.filter(campaign => !campaign.deleted)
    .map(campaign => {
      const messageTemplates = campaign.messagetemplates.reduce(
        (messageTemplatesAcc, messageTemplate) => {
          if (!isObject(messageTemplate) || messageTemplate.deleted) return messageTemplatesAcc;
          if (messageTemplate.request_review) {
            messageTemplatesAcc.reviews.push(messageTemplate);
          } else {
            messageTemplatesAcc.emails.push(messageTemplate);
          }
          messageTemplatesAcc.all.push(messageTemplate);
          return messageTemplatesAcc;
        },
        {
          all: [],
          emails: [],
          reviews: []
        }
      );

      return {
        ...campaign,
        request_reviews_scheduled:
          scheduledStats.campaigns?.[campaign.id]?.request_reviews_scheduled,
        emails_scheduled: scheduledStats.campaigns?.[campaign.id]?.emails_scheduled,
        recordId: campaign.id,
        children: messageTemplates.all
          .map(template => {
            return {
              ...template,
              request_reviews_scheduled:
                scheduledStats.messageTemplates?.[template.id]?.request_reviews_scheduled,
              emails_scheduled: scheduledStats.messageTemplates?.[template.id]?.emails_scheduled,
              reviews: null,
              feedback: null,
              type: 'child'
            };
          })
          .sort(sortMessageTemplates)
      };
    });
};

export const prepareMessagesData = messages => {
  const fieldsStringToNumber = ['schedule_weekday', 'include_order', 'exclude_order'];
  const fieldsNullToString = ['content', 'include_countries', 'exclude_countries'];
  return messages.map(message => {
    const keys = Object.keys(message);
    const modifiedFields = {};
    keys.forEach(key => {
      if (fieldsStringToNumber.includes(key)) modifiedFields[key] = message[key].map(item => +item);
      if (fieldsNullToString.includes(key)) modifiedFields[key] = message[key] || '';
    });
    return { ...message, ...modifiedFields };
  });
};

export const modMessagesDataToDisplay = (messages, campaigns) => {
  if (!messages.length) return messages;
  return messages
    .map(message => {
      const targetCampaign = campaigns.find(el => el.id === message.campaign);
      const targetMessage = targetCampaign
        ? targetCampaign.children.find(el => el.id === message.message)
        : null;
      const marketplace = targetCampaign?.marketplace || 0;
      return {
        ...message,
        name: message.product_name,
        smallImage: message.product_image,
        marketplace,
        campaignName: targetCampaign ? targetCampaign.name : 'none',
        messageName: targetMessage ? targetMessage.name : 'none'
      };
    })
    .filter(message => isObject(message.sent) || Date.parse(message.sent));
};
