import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import CheckBox from '@components/check-box';
import { GradButton } from '@components';
import { isDemoBuild, isProductionDomain } from '@utils';

const DEFAULT_SETTINGS = {
  necessary: true,
  analytics: false,
  preferences: false,
  marketing: false
};

const ENABLED_ALL_SETTINGS = Object.keys(DEFAULT_SETTINGS).reduce(
  (acc, key) => ({ ...acc, [key]: true }),
  {}
);

const REJECT_ALL_SETTINGS = Object.keys(ENABLED_ALL_SETTINGS).reduce(
  (acc, key) => ({ ...acc, [key]: false }),
  {}
);

const LOCAL_STORAGE_KEY = 'consentMode';

const Consent = () => {
  // https://github.com/googleanalytics/ga4-tutorials/blob/main/src/public/partials/consent.eta
  const localStorageSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
  const [settings, _setSettings] = useState({ ...DEFAULT_SETTINGS });
  const [hidden, setHidden] = useState(!!localStorageSettings || isDemoBuild());
  const setSettings = (name, value) => _setSettings(prev => ({ ...prev, [name]: value }));
  const onApply = config => {
    const consentMode = {
      functionality_storage: config.necessary ? 'granted' : 'denied',
      security_storage: config.necessary ? 'granted' : 'denied',
      ad_storage: config.marketing ? 'granted' : 'denied',
      analytics_storage: config.analytics ? 'granted' : 'denied',
      personalization_storage: config.preferences ? 'granted' : 'denied',
      ad_user_data: config.marketing ? 'granted' : 'denied',
      ad_personalization: config.marketing ? 'granted' : 'denied'
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(consentMode));
    if (window.gtag) window.gtag('consent', 'update', consentMode);
    setHidden(true);
  };

  if (hidden) return null;
  return (
    <Paper className="fixed w-full bottom-0 z-9999 flex flex-col justify-center items-center bg-gray-light p-4 border-top border-gray-darkest">
      <div className="text-2xl my-2 font-medium">Cookie Settings</div>
      <div className="text-lg">
        We use cookies to provide you with the best possible experience. They also allow us to
        analyze user behavior in order to constantly improve the website for you.
      </div>
      <div className="flex gap-2 mt-4">
        <GradButton grad="bg-green" onClick={() => onApply(ENABLED_ALL_SETTINGS)}>
          Accept All
        </GradButton>
        <GradButton grad="bg-gray-dark" onClick={() => onApply(settings)}>
          Accept Selection
        </GradButton>
        <GradButton grad="bg-red" onClick={() => onApply(REJECT_ALL_SETTINGS)}>
          Reject All
        </GradButton>
      </div>
      <div className="flex gap-4 mt-4">
        <CheckBox label="Necessary" value disabled />
        <CheckBox
          label="Analytics"
          value={settings.analytics}
          onCheckHandler={value => setSettings('analytics', value)}
        />
        <CheckBox
          label="Preferences"
          value={settings.preferences}
          onCheckHandler={value => setSettings('preferences', value)}
        />
        <CheckBox
          label="Marketing"
          value={settings.marketing}
          onCheckHandler={value => setSettings('marketing', value)}
        />
      </div>
    </Paper>
  );
};

export default Consent;
