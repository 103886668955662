import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';

function Logo(props) {
  return (
    <Link {...props}>
      <img src={logo} alt="logo" />
    </Link>
  );
}

export default Logo;
