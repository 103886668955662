export const getToken = () => localStorage.getItem('token');

export const setToken = token => {
  if (!token) {
    localStorage.removeItem('token');
  } else {
    localStorage.setItem('token', token);
  }
};

export const getTrackingId = () => localStorage.getItem('tap_vid');

export const clearSessionStorage = () => {
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('from');
  sessionStorage.removeItem('to');
};
