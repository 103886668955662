import React from 'react';
import PropTypes from 'prop-types';
import { FlatButton, GradButton } from './index';

const ModernCancelConfirmButtons = ({
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  confirmActive
}) => {
  return (
    <div
      className="w-full flex items-center justify-end bg-white-gray px-6 flex-shrink-0"
      style={{ height: '4.5rem' }}
    >
      <FlatButton onClick={onCancel} className="pr-8 text-gray-black font-medium text-sm">
        {cancelText}
      </FlatButton>
      <GradButton onClick={onConfirm} active={confirmActive}>
        {confirmText}
      </GradButton>
    </div>
  );
};

ModernCancelConfirmButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmActive: PropTypes.bool
};

ModernCancelConfirmButtons.defaultProps = {
  confirmActive: true
};

export default ModernCancelConfirmButtons;
