import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import FLAGS from '@sm/Flags';
import OutlinedSelect from './OutlinedSelect';

const MarketPlaceSelect = ({
  wrapperClassName,
  onChange,
  value,
  id,
  name,
  marketplaces,
  title,
  disabled
}) => {
  return (
    <OutlinedSelect
      title={title}
      wrapperClassName={wrapperClassName}
      name={name}
      id={id}
      fullWidth
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {marketplaces.map(flagId => {
        const flag = FLAGS.find(flagInfo => flagInfo.id === flagId);
        if (!flag) {
          console.error(`flagId ${flagId} not found`);
          return null;
        }
        return (
          <MenuItem key={flag.id} className="sm-min-height-48px" value={flagId}>
            <div className="flex items-center">
              <img src={flag.src} alt="flag" width="30" height="16" className="rounded-sm" />
              <p className="ml-2 text-gray-darkest">
                {flagId !== 0 ? flag.name : 'All marketplaces'}
              </p>
            </div>
          </MenuItem>
        );
      })}
    </OutlinedSelect>
  );
};

MarketPlaceSelect.propTypes = {
  marketplaces: PropTypes.arrayOf(PropTypes.number).isRequired,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

MarketPlaceSelect.defaultProps = {
  wrapperClassName: '',
  onChange: () => {},
  disabled: false
};

export default MarketPlaceSelect;
