import {
  SET_USER_ID,
  SET_USER_PROFILE,
  SET_USER_ACCOUNTS,
  SET_USER_LAST_30D_BILLING_PLAN,
  USER_LOGOUT
} from '../actions';

const initialState = {
  profile: {},
  defaultSettings: {},
  last30dPlan: null
};

export const userSelector = state => state.user;

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE: {
      const { profile } = action;
      return {
        ...state,
        profile
      };
    }
    case SET_USER_ID: {
      const { userId } = action;
      return {
        ...state,
        userId
      };
    }
    case SET_USER_ACCOUNTS: {
      const { accounts } = action;
      return {
        ...state,
        accounts
      };
    }
    case SET_USER_LAST_30D_BILLING_PLAN: {
      const { data } = action;
      return {
        ...state,
        last30dPlan: data
      };
    }
    case USER_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default user;
