import React from 'react';

import { format } from 'date-fns';
import classNames from 'classnames';

import Colors from 'styles/colors';
import { DashedUnderlineWrapper } from '@components';
import { CartIcon, ExternalLinkIcon, PercentIcon, SyncIcon } from '@components/icons/Icons';
import { getFormattedValue } from '@utils/format';
import { getFlagsById } from '@sm/Flags';
import { getMarketTime } from 'seller/utils/common';
import NewTabReference from '@components/common/NewTabReference';
import { FlatButton } from '@components/buttons';
import ModernBreakdown from '@components/modern-breakdown';
import BlackTooltip from '@components/common/BlackTooltip';
import { TABLE_CELL_FORMAT } from '@utils';
import {
  getTotalsForBreakdown,
  getPrepareNetProfitBreakdownDataFunc,
  ORDER_LIST_BREAKDOWN
} from '../dashboard/prepareModernBreakdownData';

export const PENDING = 'Pending';
export const UNSHIPPED = 'Unshipped';
export const SHIPPED = 'Shipped';
export const CANCELED = 'Canceled';
export const REFUNDED = 'Refunded';
export const REPLACEMENT = 'Replacement';
export const BUYER_AVAILABLE_WHEN_SHIPPED = 'Available when shipped';
export const BUYER_NOT_AVAILABLE = 'Not available';

export const ORDER_STATUS_LIST = [PENDING, UNSHIPPED, SHIPPED, CANCELED, REFUNDED, REPLACEMENT];

export const purchaseDateView = (data, columnIndex, key, rowIndex) => {
  const { purchaseDateTime, marketplace } = data[rowIndex];
  if (!purchaseDateTime) return null;
  const dtValue = getMarketTime(marketplace, purchaseDateTime);
  if (!dtValue) return null;
  return (
    <div className="flex flex-col">
      <div>{format(dtValue, TABLE_CELL_FORMAT.date)}</div>
      <div>{format(dtValue, TABLE_CELL_FORMAT.time)}</div>
    </div>
  );
};

export const buyerDateView = (
  data,
  columnIndex,
  key,
  rowIndex,
  newStyle,
  rowClicked,
  selectedParents,
  extraCellProps,
  setKeyword
) => {
  const { buyer, buyerOrders } = data[rowIndex];

  let buyerName = buyer;
  let className = 'text-yellow';
  if (buyer === BUYER_NOT_AVAILABLE || buyer === BUYER_AVAILABLE_WHEN_SHIPPED) {
    className = 'text-gray-dark';
  } else buyerName = buyer.substring(0, buyer.lastIndexOf('@'));

  const buyerOrdersRow =
    buyer && buyerOrders ? (
      <p className="mt-1 text-xs text-gray-dark">
        {getFormattedValue(buyerOrders)} {`order${buyerOrders > 1 ? 's' : ''} from this buyer`}
      </p>
    ) : null;
  return (
    <FlatButton
      onClick={() => setKeyword(buyerName)}
      className="flex flex-col items-end justify-start"
    >
      <BlackTooltip arrow title="View all orders from this buyer" placement="top">
        <p className={className}>{buyerName}</p>
      </BlackTooltip>
      {buyerOrdersRow}
    </FlatButton>
  );
};

export const amountView = (
  data,
  columnIndex,
  key,
  rowIndex,
  newStyle,
  rowClicked,
  selectedParents,
  extraCellProps,
  setKeyword,
  columns,
  currency
) => {
  const { pullOrderDetails } = extraCellProps;
  const order = getTotalsForBreakdown(data[rowIndex]);
  const { sales, status, discounted, subscribed } = order;

  const value = (
    <DashedUnderlineWrapper tooltipContent="Click to view breakdown">
      {getFormattedValue(sales, true)}
    </DashedUnderlineWrapper>
  );
  if (!sales || status === CANCELED) return <div className="text-gray-dark text-sm ">none</div>;
  const getBreakdownData = callback => {
    pullOrderDetails(orderDetails => callback(getTotalsForBreakdown(orderDetails)), order);
  };

  return (
    <div className="flex flex-col items-end h-full w-full justify-start">
      <ModernBreakdown
        offset="0px,8px"
        placement="bottom-end"
        currency={currency}
        getData={getBreakdownData}
        buildViewData={getPrepareNetProfitBreakdownDataFunc(ORDER_LIST_BREAKDOWN)}
        headerTitle="Order Breakdown"
        buttonContent={value}
        buttonClassName="flex"
      />
      <div className="flex items-center justify-end mt-1">
        {discounted && (
          <BlackTooltip arrow title="This product sold with discount" placement="top">
            <div className="ml-1 bg-green-lighter rounded p-2px flex items-center justify-center">
              <PercentIcon className="h-2 w-2" fill={Colors.white.default} />
            </div>
          </BlackTooltip>
        )}
        {subscribed && (
          <BlackTooltip arrow title="Subscribe & Save" placement="top">
            <div className="ml-1 h-3 w-3 p-px flex items-center justify-center relative">
              <SyncIcon className="h-4 w-4 absolute" fill={Colors.yellow.default} />
              <CartIcon className="w-2 h-2 mr-px" fill={Colors.gray.black} />
            </div>
          </BlackTooltip>
        )}
      </div>
    </div>
  );
};

export const statusView = (data, columnIndex, key, rowIndex) => {
  const { status } = data[rowIndex];

  const rootClassName = classNames('rounded font-medium p-1 h-6', {
    'text-yellow-lighter bg-yellow-ultra-light': status === PENDING || status === UNSHIPPED,
    'text-gray-dark bg-white-dark': status === CANCELED || status === REPLACEMENT,
    'text-red-lighter bg-red-pink': status === REFUNDED,
    'text-green-lighter bg-green-ultra-light': status === SHIPPED
  });

  return <div className={rootClassName}>{status}</div>;
};

export const sellerCentralView = (data, columnIndex, key, rowIndex) => {
  const { marketplace, orderId } = data[rowIndex];
  const href = `https://sellercentral.amazon.${
    getFlagsById(marketplace)?.domain
  }/orders-v3/order/${orderId}`;
  return (
    <div className="text-yellow">
      <NewTabReference href={href}>
        <div className="flex items-center hover:underline">
          <p>Open</p>
          <ExternalLinkIcon className="ml-1 w-3 h-3" fill={Colors.yellow.default} />
        </div>
      </NewTabReference>
    </div>
  );
};
