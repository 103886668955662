import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Button, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ROUTES from 'seller/routing';
import ArrowIcon from '@components/icons/ic_arrow_left.svg';
import Logo from '@components/common/Logo';
import { resetPasswordAction } from 'seller/store/saga/login';
import { validateEmail } from '@utils/validators';
import ProTipsView from '@components/pro-tips-view';
import GenerateInputs from '@components/inputs/GenerateInputs';
import { EnvelopeIcon } from '@components';
import { useTextInput } from '../profile/Hooks';
import { ImageObserver } from '../../containers/observer';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const email = useTextInput('');
  const [emailSent, setEmailSent] = useState(false);

  const inputsList = [{ icon: EnvelopeIcon, type: 'Email', props: email }];

  const sendEmail = () => dispatch(resetPasswordAction(email.value, () => setEmailSent(true)));

  const emailInputIsValid = validateEmail(email.value);
  return (
    <ImageObserver className="flex bg white w-full sm-full-min-h-mobile">
      <div className="flex flex-col items-center justify-center bg-white md:w-2/4 w-full box-shadow-default min-h-screen z-0">
        <div className="flex flex-col items-center w-10/12 sm:sm-w-300px">
          <Logo className="w-2/3 sm:sm-w-160px" to={ROUTES.root} />
          <div className="flex items-center w-full -ml-3 mt-10 mb-2">
            <Link to={ROUTES.login}>
              <IconButton>
                <img src={ArrowIcon} alt="arrow-left" className="h-4 w-4" />
              </IconButton>
            </Link>
            <p className="text-2xl font-bold  mr-auto text-black">Reset Password</p>
          </div>
          {!emailSent ? (
            <div className="h-48">
              <p className="text-gray-dark text-base mb-4">
                We will send you a link so you can setup a new password.
              </p>
              <form className="w-full" onSubmit={sendEmail}>
                <GenerateInputs data={inputsList} />
                <Button
                  variant="contained"
                  className={classNames('w-full p-2 mt-6 text-white capitalize text-sm', {
                    'bg-grad-2': emailInputIsValid
                  })}
                  onClick={sendEmail}
                  disabled={!emailInputIsValid}
                >
                  Reset Password
                </Button>
              </form>
            </div>
          ) : (
            <div className="h-48">
              <p className="text-gray-dark text-base mt-4">
                Please check your email for the password reset link.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-2/4 flex justify-center items-center bg-white-blue hidden md:flex ">
        <ProTipsView />
      </div>
    </ImageObserver>
  );
};

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;
