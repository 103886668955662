import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Colors from 'styles/colors';
import { AmazonIcon } from '../icons';

function AmzButton(props) {
  const { onClick, className, children } = props;
  return (
    <Button className={`bg-grad-4 rounded p-2 box-shadow-light ${className}`} onClick={onClick}>
      <div className="flex justify-between items-center">
        <AmazonIcon fill={Colors.black.default} />
        <p className="text-black font-medium normal-case mx-2">{children}</p>
      </div>
    </Button>
  );
}

AmzButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

AmzButton.defaultProps = {
  className: ''
};

export default AmzButton;
