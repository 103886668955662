import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';

const OutlinedWrapper = React.forwardRef((props, ref) => {
  const { title, className, children, onClick, disabled } = props;
  const [isFocused, setIsFocused] = useState(false);
  const classes = classNames(
    'border rounded relative flex justify-start pl-4 pr-2 items-center',
    className,
    {
      'border-gray': !isFocused,
      'border-yellow': isFocused,
      'hover:border-yellow': !disabled,
      'bg-white-dark': disabled
    }
  );
  const extraProps = {
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    onClose: () => setIsFocused(false)
  };
  return (
    <div
      ref={ref}
      className={classes}
      style={{ minHeight: '3.0625rem' }}
      onClick={onClick}
      onKeyPress={() => {}}
      role="menuitem"
      tabIndex="-1"
    >
      <Typography
        className="absolute text-xs text-gray-black bg-white px-1 truncate max-w-3/4"
        style={{ left: '0.8rem', top: '-0.6rem' }}
      >
        {title}
      </Typography>
      <children.type {...children.props} {...extraProps} />
    </div>
  );
});
OutlinedWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

OutlinedWrapper.defaultProps = {
  className: '',
  onClick: () => {},
  disabled: false
};

export default OutlinedWrapper;
