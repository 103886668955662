import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import Colors from 'styles/colors';
import { setMobileAppBannerSkippedAction } from 'seller/store/saga/userPreferences';
import { userPreferencesSelector } from 'seller/store/reducers';
import { isAndroid, isIos } from '@utils';
import NewTabReference from '@components/common/NewTabReference';
import { CrossSolidIcon } from '@components/icons/Icons';
import { GradButton } from '@components/buttons';
import { SafeImage } from '@components/images';
import { APPSTORE_LINK, PLAYMARKET_LINK } from '../../pages/homepage/Platforms';
import mobileAppIcon from './mobile-app-icon.png';

const MobileAppBanner = () => {
  const dispatch = useDispatch();
  const onSkip = () => dispatch(setMobileAppBannerSkippedAction(true));
  const { mobileAppBannerSkipped } = useSelector(userPreferencesSelector);
  if (mobileAppBannerSkipped) return null;
  const isIosDevice = isIos();
  if (!isIosDevice && !isAndroid()) return null;
  const link = isIosDevice ? APPSTORE_LINK : PLAYMARKET_LINK;
  return (
    <div className="sm:hidden flex fixed bottom-0 z-9999 w-full justify-center py-10 bg-black-darkTransparent">
      <div className="rounded-lg flex items-center relative bg-white shadow mx-5 p-3">
        <div className="rounded-full border border-gray-default absolute top-0 right-0 -mr-4 -mt-4 z-10 bg-white">
          <IconButton className="p-2" onClick={onSkip}>
            <CrossSolidIcon fill={Colors.gray.black} />
          </IconButton>
        </div>
        <NewTabReference className="flex-shrink-0" href={link}>
          <SafeImage src={mobileAppIcon} className="" style={{ height: '5.5rem' }} />
        </NewTabReference>
        <div className="flex flex-col p-2 py-1 ml-2 justify-between items-start h-full">
          <div className="font-medium text-lg text-grey-dark ml-1">
            Seller Metrix - Track your
            <br /> Amazon Sales & Profit
          </div>
          <NewTabReference href={link}>
            <GradButton className="text-base font-normal" paddingY="py-1">
              Go to Mobile App for {isIosDevice ? 'iOS' : 'Android'}
            </GradButton>
          </NewTabReference>
        </div>
      </div>
    </div>
  );
};

export default MobileAppBanner;
