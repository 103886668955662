import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Typography } from '@material-ui/core';
import Colors from 'styles/colors';
import { isArray } from '@utils';
import { GradButton } from '../buttons';
import RadioCard from '../radio/RadioCard';
import { SVG_GRAD_ONE } from '../gradients';

const OptionsDialog = ({ open, onCancel, onConfirm, confirmText, title, subTitle, cards }) => {
  const [state, setState] = useState(
    isArray(cards) && cards.length ? cards.find(item => !item.disabled).value : null
  );
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onCancel}
      PaperProps={{ className: 'px-4 pb-4 pt-3 max-w-530px' }}
    >
      <Typography className="font-medium text-gray-darkest">{title}</Typography>
      <div className="underline-sm bg-grad-8 mb-4" />
      <Typography className="text-gray-dark">{subTitle}</Typography>
      <div className="mb-2 mt-6 items-center">
        {cards.map(card => {
          return (
            <RadioCard
              key={card.value}
              onClick={setState}
              header={card.header}
              description={card.description}
              imgSrc={
                <>
                  <card.icon.type
                    {...card.icon.props}
                    fill={state === card.value ? SVG_GRAD_ONE : Colors.gray.default}
                  />
                </>
              }
              state={state}
              value={card.value}
              disabled={!!card.disabled}
            />
          );
        })}
      </div>
      <div className="flex justify-end mt-12 pt-2">
        <Button
          variant="outlined"
          className="text-gray-dark mr-4 normal-case text-xs h-8 leading-none"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <GradButton
          grad="bg-grad-8"
          className="text-xs h-8 leading-none"
          active
          onClick={() => onConfirm(state)}
        >
          {confirmText}
        </GradButton>
      </div>
    </Dialog>
  );
};

OptionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  cards: PropTypes.instanceOf(Object).isRequired
};

export default OptionsDialog;
