import {
  SET_PRODUCT,
  SET_PRODUCTS,
  START_PRODUCTS_LOADING,
  SET_MISSING_INFO_FLAG,
  SET_BULK_UPLOADING
} from '../saga/products';

const initialState = {
  productsList: [],
  productsLoading: false,
  showOnlyWithMissingInfo: false,
  bulkUploading: false
};
export const productsSelector = state => state.products;

const products = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS: {
      const { productsList } = action;
      return {
        ...state,
        productsList,
        productsLoading: false
      };
    }
    case START_PRODUCTS_LOADING: {
      return {
        ...state,
        productsList: [],
        productsLoading: true
      };
    }
    case SET_MISSING_INFO_FLAG: {
      const { showOnlyWithMissingInfo } = action;
      return {
        ...state,
        showOnlyWithMissingInfo
      };
    }
    case SET_PRODUCT: {
      const newProductData = action.payload;
      const productsList = [...state.productsList];
      const productIndex = productsList.findIndex(
        product =>
          product.marketplace === newProductData.marketplace &&
          product.asin === newProductData.asin &&
          product.sku === newProductData.sku
      );
      productsList.splice(productIndex, 1, newProductData);
      return { ...state, productsList };
    }
    case SET_BULK_UPLOADING: {
      return { ...state, bulkUploading: !state.bulkUploading };
    }
    default: {
      return state;
    }
  }
};

export default products;
