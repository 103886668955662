import { SET_NETWORK_ACTIVITY, SET_SERVER_UNAVAILABLE } from '../actions';

const initialState = {
  isFetching: false,
  serverUnavailable: false
};

export const networkSelector = state => state.network;

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_NETWORK_ACTIVITY: {
      const { isFetching } = action;
      return {
        ...state,
        isFetching
      };
    }
    case SET_SERVER_UNAVAILABLE: {
      return { ...state, serverUnavailable: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default dashboard;
