import React from 'react';
import { endOfDay, format, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DayPicker } from 'react-day-picker';
import { CalendarIcon } from '../icons';
import Colors from '../../styles/colors';
import { usePopper } from '../../@utils';
import { FlippedPopper } from '../poppers';
import { OutlinedWrapper } from './index';
import CalendarNavbar from '../date-picker/calendar/CalendarNavbar';
import CalendarHeader from '../date-picker/calendar/CalendarHeader';

const DATE_FORMAT = 'MMM d, yyyy';

const DateInput = ({ date, setDate, title, lifeTime, className }) => {
  const popper = usePopper({});
  const today = new Date();
  return (
    <div ref={popper.anchorRef} className={classNames(className, 'z-10')}>
      <OutlinedWrapper title={title} onClick={() => popper.show()} className="cursor-pointer">
        <div className="w-full flex items-center justify-between pr-1">
          <div>{format(date, DATE_FORMAT)}</div>
          <CalendarIcon className="h-4 w-4" fill={Colors.gray.dark} />
        </div>
      </OutlinedWrapper>
      <FlippedPopper
        open={popper.open}
        anchorEl={popper.anchorRef.current}
        closeOnBlur
        offset="0, 16px"
        placement="bottom-center"
        paperClassName="flex z-100"
        maxHeight="auto"
        onClose={() => popper.hide()}
        disablePortal
      >
        <ClickAwayListener onClickAway={() => popper.hide()} touchEvent={false}>
          <div className="p-2">
            <div className="inline-block">
              <DayPicker
                className="Selectable"
                numberOfMonths={1}
                selectedDays={[date]}
                onDayClick={day => {
                  if (day > endOfDay(today) || day < startOfDay(lifeTime)) return;
                  setDate(day);
                  popper.hide();
                }}
                initialMonth={date}
                captionElement={CalendarHeader}
                disabledDays={day => day > endOfDay(today) || day < startOfDay(lifeTime)}
                navbarElement={
                  <CalendarNavbar today={today} lifeTime={lifeTime} twoCalendarsView={null} />
                }
              />
            </div>
          </div>
        </ClickAwayListener>
      </FlippedPopper>
    </div>
  );
};

DateInput.propTypes = {
  date: PropTypes.instanceOf(Object).isRequired,
  lifeTime: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  setDate: PropTypes.func.isRequired
};
DateInput.defaultProps = {
  className: ''
};

export default DateInput;
