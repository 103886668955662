import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, ClickAwayListener } from '@material-ui/core';
import Colors from 'styles/colors';
import { CheckIcon, CrossSolidIcon, PenIcon } from '../icons';
import AccountCardInfo from './AccountCardInfo';
import AccountCardLinks from './AccountCardLinks';
import MultiLineTruncate from '../common/MultiLineTruncate';
import AccountCardNoAccess from './AccountCardNoAccess';

const cardStyle = {
  width: '18.75rem',
  nameInput: {
    width: '14.375rem'
  },
  flagsContainer: {
    width: '8.125rem'
  },
  flag: {
    width: '1.875rem',
    height: '1.0625rem'
  }
};

function AccountCard(props) {
  const { name: _name, sellerId, className, cardHeight, onRename } = props;
  const [name, setName] = useState(_name);
  const [isRenaming, setIsRenaming] = useState(false);
  const nameInput = React.createRef();

  useEffect(() => {
    if (isRenaming && nameInput.current) {
      nameInput.current.focus();
    }
  }, [isRenaming, nameInput]);

  useEffect(() => {
    setName(_name);
  }, [_name]);

  const handleRenameSuccess = success => {
    if (!success) {
      setName(_name);
    }
  };

  const onRenameCancel = () => {
    if (isRenaming) {
      setIsRenaming(false);
      setName(_name);
    }
  };

  const onRenameConfirm = () => {
    if (name) {
      onRename(name, sellerId, handleRenameSuccess);
      setIsRenaming(false);
    } else {
      onRenameCancel();
    }
  };
  const onRenameStart = () => setIsRenaming(true);

  if (!_name || !sellerId) {
    return null;
  }

  const buttonClasses = 'flex-shrink-0 -mt-1 -mr-1 ml-2 p-2';

  const cardHeader = !isRenaming ? (
    <>
      <div>
        <MultiLineTruncate className="text-base font-medium text-black" lines={2} text={name} />
        <div className="underline-sm bg-grad-2 mt-1" />
      </div>
      <IconButton key="edit" onClick={onRenameStart} className={buttonClasses}>
        <PenIcon width="0.8125rem" fill={Colors.gray.default} />
      </IconButton>
    </>
  ) : (
    <>
      <div className="flex-grow flex flex-col justify-start items-start">
        <form
          className="mr-2 w-full flex"
          onSubmit={e => {
            e.preventDefault();
            onRenameConfirm();
          }}
        >
          <input
            type="text"
            defaultValue={name}
            className="w-full outline-none text-base font-medium text-black"
            onChange={event => setName(event.currentTarget.value)}
            ref={nameInput}
            onFocus={e => e.target.select()}
          />
        </form>
        <div className="underline-sm bg-grad-2 mt-1" />
      </div>
      <IconButton key="cancel" onClick={onRenameCancel} className={buttonClasses}>
        <CrossSolidIcon fill={Colors.red.default} width="0.8125rem" />
      </IconButton>
      <IconButton key="confirm" onClick={onRenameConfirm} className={buttonClasses}>
        <CheckIcon fill={Colors.green.lighter} width="0.8125rem" />
      </IconButton>
    </>
  );

  const getCardBody = cardProps => {
    if (!cardProps?.linkedSPA)
      return (
        <div className="flex-grow">
          <AccountCardInfo {...cardProps} />
          <AccountCardNoAccess {...cardProps} />
        </div>
      );
    return (
      <div className="flex-grow flex flex-col justify-between">
        <AccountCardInfo {...cardProps} />
        <AccountCardLinks {...cardProps} />
      </div>
    );
  };
  return (
    <div
      className={`rounded p-4 box-shadow-light bg-white ${className}`}
      style={{ ...cardStyle, height: cardHeight }}
    >
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col">
          <ClickAwayListener onClickAway={onRenameCancel}>
            <div className="flex items-start justify-between h-16">{cardHeader}</div>
          </ClickAwayListener>
        </div>
        {getCardBody(props)}
      </div>
    </div>
  );
}

AccountCard.propTypes = {
  name: PropTypes.string.isRequired,
  sellerId: PropTypes.string.isRequired,
  cardHeight: PropTypes.string.isRequired,
  className: PropTypes.string,
  onRename: PropTypes.func.isRequired
};

AccountCard.defaultProps = {
  className: ''
};

export default AccountCard;
