import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { GLOBAL_MK } from '@sm/Flags';
import {
  activateSearch as activateSearchAction,
  setCompareDateRangeAction,
  setDateRangeAction,
  setInput as setInputAction,
  setMarketplaceAction
} from '../../store/actions';
import SelectorViewComponent from './SelectorViewComponent';

const ordersColumns = {
  name: 'Product',
  asin: 'ASIN',
  sku: 'SKU',
  orderId: 'Order ID',
  status: 'Status',
  quantity: 'Qty.',
  purchaseDateTime: 'Purchase Date',
  product_sales: 'Amount',
  currency: 'Currency',
  marketplace: 'Marketplace',
  buyer: 'Buyer ID',
  buyerOrders: '#Orders',
  discounted: 'Discounted',
  new_tax: 'Tax',
  fba_fees: 'FBA Fees',
  referral_fees: 'Referral Fees',
  subscribed: 'SnS'
};

const refundsColumns = {
  name: 'Product',
  asin: 'ASIN',
  sku: 'SKU',
  orderId: 'Order ID',
  status: 'Status',
  marketplace: 'Marketplace',
  reason: 'Reason',
  return_quantity: 'Qty. Returned',
  quantity_refunded: 'Qty. Refunded',
  refund_value: 'Amount Refunded',
  latest_refund_timestamp: 'Refund Date',
  return_date: 'Returned to Amazon',
  returned_to_inventory: 'Returned to Inv.',
  disposition: 'Disposition',
  customer_comments: 'Customer Comment'
};

const PREFERABLE_MK = [3, 8];

const SelectorView = props => {
  const dispatch = useDispatch();
  const setMarketplace = useCallback(marketplace => dispatch(setMarketplaceAction(marketplace)), [
    dispatch
  ]);
  const setDateRange = useCallback(dateRange => dispatch(setDateRangeAction(dateRange)), [
    dispatch
  ]);
  const setCompareDateRange = useCallback(
    compareDateRange => dispatch(setCompareDateRangeAction(compareDateRange)),
    [dispatch]
  );
  useEffect(() => {
    if (props.hideGlobalMk && props.marketplaceID === GLOBAL_MK) {
      const preferableMk = props.profile.marketplaces.find(item => PREFERABLE_MK.includes(item));
      setMarketplace(preferableMk || props.profile.marketplaces.find(item => item));
    }
    // eslint-disable-next-line
  }, []);
  console.log('props', props);
  return (
    <SelectorViewComponent
      {...props}
      setMarketplace={setMarketplace}
      setDateRange={setDateRange}
      setCompareDateRange={setCompareDateRange}
    />
  );
};

function mapStateToProps(state) {
  const isOrders = state.app.app === 'Orders';
  return {
    keyword: state.productSearch.keyword,
    profile: state.user.profile,
    marketplaceID: state.selector.marketplaceID,
    dateRange: state.selector.dateRange,
    compareDateRange: state.selector.compareDateRange,
    dataTableFilteredData: state.dataTable.filteredData,
    fileName: isOrders ? 'Orders' : 'Refunds',
    columns: isOrders ? ordersColumns : refundsColumns
  };
}

export default connect(mapStateToProps, {
  setInput: setInputAction,
  activateSearch: activateSearchAction
})(SelectorView);
