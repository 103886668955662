import { SET_FILTERED_TABLE_DATA } from '../actions';

const initialState = {
  filteredData: []
};

export const dataTableSelector = state => state.dataTable;

const dataTable = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERED_TABLE_DATA: {
      return { ...state, filteredData: action.payload };
    }
    default:
      return state;
  }
};

export default dataTable;
