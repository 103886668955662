import API from '@sm/api';
import mock from '../mock';

const ordersDB = {
  orders: [
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '141-9486492-9651049',
      orderItemId: '112179177624441',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-22T01:13:24Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '121-3837699-5796347',
      orderItemId: '112168917883841',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-22T00:30:54Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-3432252-8626223',
      orderItemId: '112421307994441',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T23:30:50Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-1960598-2687940',
      orderItemId: '112412987973081',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T22:23:52Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-1511467-2856636',
      orderItemId: '112397426935521',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T20:41:13Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-6523703-7009649',
      orderItemId: '112396984587161',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T19:55:15Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -12.48,
      referral_fees: -11.25,
      orderId: '141-0810230-2895174',
      orderItemId: '112388669651241',
      status: 'Pending',
      quantity: 3,
      purchaseDateTime: '2024-10-21T18:44:45Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -11.7,
      _shipping_cost: -0.24,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 74.91,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-4076814-1402977',
      orderItemId: '112388573264281',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T18:37:49Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-4688045-4363357',
      orderItemId: '112166601926081',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T18:33:14Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '121-9161479-9640919',
      orderItemId: '112393501487481',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T17:15:38Z',
      buyer: '',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-9526908-5898053',
      orderItemId: '112373202563121',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T16:58:15Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '111-3489671-8146407',
      orderItemId: '112383019182561',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T15:25:21Z',
      buyer: '',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-1971229-0721125',
      orderItemId: '112163509235001',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T11:25:51Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-6612516-9831573',
      orderItemId: '112374031495521',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T09:00:49Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '121-1770267-7730366',
      orderItemId: '112177031034801',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T08:22:40Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '121-7662851-2050358',
      orderItemId: '112179989434041',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T07:46:36Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-1595619-1431853',
      orderItemId: '112155824889121',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-21T07:23:05Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-0779456-8554990',
      orderItemId: '112176754419961',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T02:47:18Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -12.81,
      referral_fees: -17.97,
      orderId: '111-7493374-8951422',
      orderItemId: '112358447571481',
      status: 'Pending',
      quantity: 3,
      purchaseDateTime: '2024-10-21T02:41:50Z',
      buyer: '',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -29.4,
      _shipping_cost: -3.15,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 119.85,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-2340598-8201236',
      orderItemId: '112351759837801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-21T01:17:23Z',
      buyer: 'f1n39fybts5qdb2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-2419981-3389754',
      orderItemId: '112351753252561',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-21T01:14:50Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-9910986-1900554',
      orderItemId: '112354866716001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-21T00:26:07Z',
      buyer: 'kcm570qrzh8lb19@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-9349435-6214132',
      orderItemId: '112337986708041',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T23:33:34Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '141-7120745-6540276',
      orderItemId: '112332802639641',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T23:19:04Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-1708308-9849471',
      orderItemId: '112165429703881',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T23:18:36Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-8503360-0528271',
      orderItemId: '112329127562241',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-20T22:13:31Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-4084036-6013819',
      orderItemId: '112346865849281',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T22:00:43Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '112-1528031-2084072',
      orderItemId: '112327452710921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T22:00:41Z',
      buyer: '5qyv9vl36g7y25h@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '121-0376970-4146792',
      orderItemId: '112343268973641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T21:25:12Z',
      buyer: 'm5xrwx2mtv82gkq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-8019342-6620241',
      orderItemId: '112152623548601',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-20T19:03:11Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-0186884-7760126',
      orderItemId: '112339219785361',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T18:48:35Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-7874743-0465216',
      orderItemId: '112316762332361',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T18:23:24Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.32,
      referral_fees: -7.5,
      orderId: '311-7920743-1739401',
      orderItemId: '112173236493921',
      status: 'Pending',
      quantity: 2,
      purchaseDateTime: '2024-10-20T17:54:00Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-7850266-6693672',
      orderItemId: '112311572867281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T15:59:20Z',
      buyer: '1y38wtw5bhjfhhy@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-5558959-8150445',
      orderItemId: '112323817917081',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T15:49:02Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-8715031-2902961',
      orderItemId: '112300884116321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T15:25:21Z',
      buyer: '0ym0cj24m6z6zmv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-1344106-2140142',
      orderItemId: '112321174381161',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T14:20:35Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-6659787-5303828',
      orderItemId: '112139710918201',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T14:07:57Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-6418116-4449469',
      orderItemId: '112285822214961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T13:57:50Z',
      buyer: 'w03pnj37cp63m13@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '113-5387045-1047660',
      orderItemId: '112166288083281',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T05:25:31Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-1603031-1513425',
      orderItemId: '112274746139721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T03:46:22Z',
      buyer: 'rgpvthrn860rgx3@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-0896472-0654700',
      orderItemId: '112176402838001',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-20T02:52:43Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '131-7142331-2541650',
      orderItemId: '112283873391601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T02:04:38Z',
      buyer: '',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-3595753-4364072',
      orderItemId: '112305678652561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-20T01:19:35Z',
      buyer: 'tbnnc56j51ydyt6@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-7153005-2055314',
      orderItemId: '112276721334801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T22:27:50Z',
      buyer: 'n6qd8wmzznsxcdc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-2312813-0818787',
      orderItemId: '112275565286521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T19:00:44Z',
      buyer: 'fzn6msy7s669mbg@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 38,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-0623000-6564394',
      orderItemId: '112232582573441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T18:33:13Z',
      buyer: 'r92t47g7wqtdr4m@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 6,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '311-8034184-0645101',
      orderItemId: '112252997426601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T16:27:18Z',
      buyer: '07dm52wlg09922g@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-5286723-6598062',
      orderItemId: '112235031099441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T15:55:50Z',
      buyer: 'tbnnc56j51ydyt6@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-3168069-0110980',
      orderItemId: '112259834038801',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-19T15:40:45Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-4070684-8806359',
      orderItemId: '112260505236761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T15:38:24Z',
      buyer: 'hlgw2c3ghm064lc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '311-0180740-3661044',
      orderItemId: '112154780928281',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-19T15:36:49Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-7584494-1131476',
      orderItemId: '112252559361361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T14:53:26Z',
      buyer: 'mrhxzh8l9pp8bxt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-0914138-7981084',
      orderItemId: '112174246515961',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-19T12:13:36Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-4336183-8043472',
      orderItemId: '112250064966161',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-19T10:41:31Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.32,
      referral_fees: -7.5,
      orderId: '111-6076143-4266936',
      orderItemId: '112175768062081',
      status: 'Pending',
      quantity: 2,
      purchaseDateTime: '2024-10-19T09:56:46Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-5853375-4606386',
      orderItemId: '112163938803641',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-19T06:58:41Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '141-1003364-7320546',
      orderItemId: '112230151842321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T04:59:23Z',
      buyer: 'smxw7n468twn0xh@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.32,
      referral_fees: -7.5,
      orderId: '111-0098723-1231542',
      orderItemId: '112242440580321',
      status: 'Pending',
      quantity: 2,
      purchaseDateTime: '2024-10-19T04:41:33Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '121-7909532-3057959',
      orderItemId: '112215177402441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T04:35:04Z',
      buyer: '3fjjcfz31kk1h8d@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-8401937-9464228',
      orderItemId: '112247065600921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T04:25:28Z',
      buyer: '9jz0cp37h2x486f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-9746995-4081321',
      orderItemId: '112248041399081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T03:06:01Z',
      buyer: '3d2cpn0n88l938m@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-2922838-8116940',
      orderItemId: '112240706681641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T01:12:25Z',
      buyer: '9x7b23n856m8w3f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '113-4199218-2658711',
      orderItemId: '112202900907881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T00:26:21Z',
      buyer: 'pp56477v4rtlqh2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 8,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '113-4492977-2760169',
      orderItemId: '112235805004281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T00:14:28Z',
      buyer: 'yh5bfhtm3qp6xth@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '113-6663304-0395412',
      orderItemId: '112219738908841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-19T00:10:45Z',
      buyer: 'j66cxk4d61mv8pm@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '114-9736864-6541904',
      orderItemId: '112216746423881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T18:17:29Z',
      buyer: '0tcmxhn197x5z7y@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-0080824-2003132',
      orderItemId: '112207230303241',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-18T17:52:18Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '112-9490018-0229600',
      orderItemId: '112182837999441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T16:28:45Z',
      buyer: '2wtcx38tkl0sbtv@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-5031644-0140566',
      orderItemId: '112193892601921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T16:20:19Z',
      buyer: 'f9mwdp193f8bhtb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 12,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '113-0718757-0646275',
      orderItemId: '112156101758721',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-18T14:31:27Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '113-2779907-7746460',
      orderItemId: '112195509608241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T12:19:18Z',
      buyer: '8st2s6w63bfc993@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '141-6156963-1332098',
      orderItemId: '112181503671521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T11:04:30Z',
      buyer: 'rn1m59v4mc1spp2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '311-3628414-6770885',
      orderItemId: '112181267019841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T09:49:53Z',
      buyer: '7w04s4s945b66lv@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-3983256-1491818',
      orderItemId: '112156608683121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T05:36:31Z',
      buyer: 'zqk4f1vp4nr9zng@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-6856168-4824722',
      orderItemId: '111985230265361',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-18T05:17:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.32,
      referral_fees: -7.5,
      orderId: '112-4636620-5672246',
      orderItemId: '112151504906801',
      status: 'Pending',
      quantity: 2,
      purchaseDateTime: '2024-10-18T04:37:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '112-9817562-0600859',
      orderItemId: '112151372740561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T04:23:50Z',
      buyer: 'y75k0qgkbx95ryx@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-3464347-3308269',
      orderItemId: '112150198317361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T04:00:58Z',
      buyer: 'd20r3rd2dy4bzs1@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-5017204-8924070',
      orderItemId: '111998541147401',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-18T01:38:26Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '114-4870721-4424274',
      orderItemId: '112132195719921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-18T00:59:31Z',
      buyer: 'gy86zntwz2ctnct@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-3600805-1763226',
      orderItemId: '112131215000201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T22:53:02Z',
      buyer: 'l5qycphcb2l4t5c@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-4083120-2229710',
      orderItemId: '112114812343961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T21:33:19Z',
      buyer: 'flpgqrsz8v7vz5n@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '131-0195390-2442050',
      orderItemId: '112129127515881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T21:30:41Z',
      buyer: 'h8vn2lk38cyfmmn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 51,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '131-0316522-4567274',
      orderItemId: '112125093293121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T19:43:23Z',
      buyer: 'w8vn3yzlbl2qtb7@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '113-0702164-6224528',
      orderItemId: '112118577064481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T17:59:43Z',
      buyer: 'zg1pnldcdry4f6z@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '311-7782054-4767481',
      orderItemId: '112001847070681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T17:55:16Z',
      buyer: 'wj85858lfyrvr9k@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 7,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-4078036-0485186',
      orderItemId: '112117154049681',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-17T16:59:21Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -2.7,
      orderId: '121-6346105-0662904',
      orderItemId: '112100256202161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T13:52:15Z',
      buyer: 'thxt5lthnp8xy2x@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 17.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-1354030-3636684',
      orderItemId: '112100020815721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T12:50:53Z',
      buyer: 'pqqnmp4wl5nq0d3@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 13,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-2019311-6866394',
      orderItemId: '112013473996601',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-17T12:06:02Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-7298426-9559430',
      orderItemId: '112097104864161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T10:49:04Z',
      buyer: 'jdbzcmwbk24y6zs@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '114-2327966-2848601',
      orderItemId: '112099267241041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T09:19:29Z',
      buyer: 'lc4yxkdz9pl46td@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-6066161-8213229',
      orderItemId: '112077581511441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T06:15:46Z',
      buyer: 'qztchrsbvtncdqf@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '311-7502295-3570905',
      orderItemId: '112088258722121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T04:21:05Z',
      buyer: 'tw615k6lv82lcf5@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-9261798-3347398',
      orderItemId: '112088201851761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T04:07:38Z',
      buyer: 'bwqsd44mgk1bx0g@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-4663157-9071937',
      orderItemId: '112070485815201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-17T01:59:16Z',
      buyer: 'zffdrcfmbvlsyr2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '114-6892239-7658951',
      orderItemId: '112073303952321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T23:28:03Z',
      buyer: '9c5841cpc65n6y0@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-3393287-5464153',
      orderItemId: '112064675090601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T21:26:26Z',
      buyer: '4t8fd9c6hjcw4px@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.32,
      referral_fees: -7.5,
      orderId: '111-8297885-3688591',
      orderItemId: '112043588867241',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-16T21:16:56Z',
      buyer: 'hv51qf31zj10rhn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-9386274-6484626',
      orderItemId: '112041110490681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T20:26:06Z',
      buyer: 'wbs3vg3jm2scskp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '121-2478993-4018753',
      orderItemId: '112049901084401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T19:31:24Z',
      buyer: '4k3c9fw5440gclz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-1167746-4323114',
      orderItemId: '112059337852241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T17:39:10Z',
      buyer: 't391c4nry9dqv4b@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-3509080-0013001',
      orderItemId: '112047277008361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T16:41:37Z',
      buyer: 'dcwgx99n36yqfpr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '131-7793242-3250883',
      orderItemId: '112056634613201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T15:58:33Z',
      buyer: 'mvg91jvgl30ncss@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-1210781-2207581',
      orderItemId: '112045780050561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T15:17:01Z',
      buyer: 'qqkg27h6yhl728n@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-2936155-5050412',
      orderItemId: '112046340876081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T14:58:26Z',
      buyer: 'mczrt6cjwkqgl5w@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-8622899-6344033',
      orderItemId: '112035848119241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T13:02:16Z',
      buyer: 'nll5l73fj0h3zmp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-0395902-2692859',
      orderItemId: '112002610960241',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-16T09:49:07Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-0607220-3424285',
      orderItemId: '112026954415841',
      status: 'Refunded',
      quantity: 1,
      purchaseDateTime: '2024-10-16T08:29:12Z',
      buyer: 'kvs9nxzxr0n7grk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-1777525-1042631',
      orderItemId: '111981304841041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T03:39:53Z',
      buyer: '3chk8zhprn8gnyy@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-8691320-0752576',
      orderItemId: '111989068966881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T03:13:26Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-9621071-1714024',
      orderItemId: '111989243837201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T02:37:02Z',
      buyer: '4kfmqkbc1vx7cnk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '113-1767242-5778721',
      orderItemId: '111976985933561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T02:31:44Z',
      buyer: 'g06kdx6ygn7lr4c@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-0031541-6368325',
      orderItemId: '111960258842601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T01:27:50Z',
      buyer: 'l4j1cfzxs0m025z@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '112-3627556-1875662',
      orderItemId: '111959874464681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T00:33:48Z',
      buyer: 'xj55s5x1zr0jkxq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-5653429-7856130',
      orderItemId: '111956418674761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-16T00:18:07Z',
      buyer: 'l9bqclgb65mk7dz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 22,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-9196428-4962190',
      orderItemId: '111971798506321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T23:58:06Z',
      buyer: 'mmxf7gwz5w0c8wq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '113-0878121-2586449',
      orderItemId: '111970804960001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T22:39:00Z',
      buyer: '3q2rypzn2syjtqz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 6,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-3134313-7416376',
      orderItemId: '111953080361561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T19:17:58Z',
      buyer: 'gn1przz299gm4wk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 52,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '121-6259405-2080416',
      orderItemId: '111952928198361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T19:02:09Z',
      buyer: '5khpwfs57t3rps8@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-3420177-2402472',
      orderItemId: '111920516809481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T17:17:37Z',
      buyer: 'f78w0j7c461wdv7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.54,
      referral_fees: -11.98,
      orderId: '141-6238276-1866836',
      orderItemId: '111948122118321',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-15T16:25:38Z',
      buyer: 'pc6lf343tv26574@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 15,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -19.6,
      _shipping_cost: -2.1,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 79.9,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-9440622-4075420',
      orderItemId: '111901969423561',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-15T12:31:43Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-1995975-3504701',
      orderItemId: '111929884779721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T12:13:29Z',
      buyer: '9jjz3yjcsxtj8k2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '114-4618513-0818329',
      orderItemId: '111929607167521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T12:02:08Z',
      buyer: '4zgr8qtrz9407bf@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '311-5415225-7647599',
      orderItemId: '111929698739601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T11:37:06Z',
      buyer: '74p5qc5t7t70dsw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-7979761-3051651',
      orderItemId: '111889176422281',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-15T11:07:14Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.32,
      referral_fees: -7.5,
      orderId: '114-4604943-4212642',
      orderItemId: '111937801529361',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-15T10:18:35Z',
      buyer: '4qdpk8jss72qxzs@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-5694486-6708276',
      orderItemId: '111924000674361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T10:15:01Z',
      buyer: 'gc3wk7qwgvwrpq6@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-7206782-4261370',
      orderItemId: '111891479020641',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-15T09:43:05Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '113-0272269-5854124',
      orderItemId: '111882531278721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T07:21:03Z',
      buyer: 'cb15lq9zfv1nrrh@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.27,
      referral_fees: -5.99,
      orderId: '112-1763162-0643890',
      orderItemId: '111876500599961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T04:59:41Z',
      buyer: 'qykb6r6t0zqy9zg@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-1394691-5286886',
      orderItemId: '111601045383801',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-15T03:49:37Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '111-4748166-0967861',
      orderItemId: '111875484482001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T02:54:49Z',
      buyer: 'l3hdx2pjd1ryp48@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '114-0680488-3635835',
      orderItemId: '111874782232561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-15T02:39:55Z',
      buyer: 'ffggnzbfl38w4hm@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-9642028-8510512',
      orderItemId: '111845659918361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T23:46:48Z',
      buyer: '22d0n30fxm147n8@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 7,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-7566171-2854041',
      orderItemId: '111600728555361',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-14T22:26:04Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-3869334-1029765',
      orderItemId: '111853933557881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T22:16:25Z',
      buyer: '6k8y2l7bzwd0svd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-9088388-8841652',
      orderItemId: '111846827777081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T21:47:58Z',
      buyer: 'dgvydjld31rz3d8@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-9049578-6881213',
      orderItemId: '111852361528401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T21:38:48Z',
      buyer: 'tct9dv5qs7l2mkd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '114-8168852-1710445',
      orderItemId: '111858485691761',
      status: 'Replacement',
      quantity: 2,
      purchaseDateTime: '2024-10-14T20:44:39Z',
      buyer: 'w9d05fwj395j9yz@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -19.6,
      _shipping_cost: -2.1,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-4537987-1842728',
      orderItemId: '111823010281201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T19:14:50Z',
      buyer: 'l98ywjhm48vgplp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-9089380-0665405',
      orderItemId: '111849215294641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T18:49:14Z',
      buyer: '0453gx0kvslr5pj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2235313-5599436',
      orderItemId: '111822306075401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T17:19:29Z',
      buyer: 'pbxjd37xjrc8vkd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '113-8969553-4059084',
      orderItemId: '111842077010681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T17:11:33Z',
      buyer: '9k33vz0r7xtpvqy@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -15.6,
      referral_fees: -15.0,
      orderId: '131-6917732-2759854',
      orderItemId: '111599164279761',
      status: 'Pending',
      quantity: 4,
      purchaseDateTime: '2024-10-14T16:55:42Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -15.6,
      _shipping_cost: -0.32,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 99.88,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '411-2769871-2129217',
      orderItemId: '111840607997401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T16:48:41Z',
      buyer: 'd1kn34gfv79y2vv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-2027236-4284423',
      orderItemId: '111631621917121',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-14T07:27:24Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-9503457-4439871',
      orderItemId: '111627319930081',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-14T06:04:02Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '112-2745377-6006119',
      orderItemId: '111631598236201',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-14T04:45:58Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.02,
      referral_fees: -11.98,
      orderId: '111-1337965-8540285',
      orderItemId: '111802334229401',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-14T02:33:53Z',
      buyer: '8fy0clymrpkty8z@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -19.6,
      _shipping_cost: -2.1,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 79.9,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-3817996-4819479',
      orderItemId: '111807407832081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T02:18:32Z',
      buyer: 'zhc7g1b1p6hyflv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8074204-4025063',
      orderItemId: '111792299651641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T01:12:25Z',
      buyer: '5d3nxffg4sm4bky@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-7687568-0905548',
      orderItemId: '111795532492241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-14T00:46:52Z',
      buyer: 'ftlg7vfv01h3zkw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-4123442-3642734',
      orderItemId: '111805130816241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T23:39:01Z',
      buyer: 'xvltc5z9pyhz4w8@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '112-8245426-2914810',
      orderItemId: '111599203443961',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-13T22:15:00Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-1721024-5360463',
      orderItemId: '111635977360921',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-13T21:42:12Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7144156-0973464',
      orderItemId: '111784245634081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T20:23:48Z',
      buyer: '5vplkgwtqcrv2hd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -23.4,
      referral_fees: -22.5,
      orderId: '141-2446097-8285961',
      orderItemId: '111769000537961',
      status: 'Shipped',
      quantity: 6,
      purchaseDateTime: '2024-10-13T18:59:56Z',
      buyer: 'm4l50sqz7m51w7v@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 14,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -23.4,
      _shipping_cost: -0.48,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 149.82,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.32,
      referral_fees: -7.5,
      orderId: '111-6475657-0600990',
      orderItemId: '111773424028521',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-13T16:26:56Z',
      buyer: '09182rssszdb3kv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-0147480-4526406',
      orderItemId: '111771833947121',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-13T15:55:33Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7064804-2226385',
      orderItemId: '111545070089241',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-13T15:01:58Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-5249926-8300149',
      orderItemId: '111582425720401',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-13T15:00:34Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-7594698-0565822',
      orderItemId: '111559589354041',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-13T14:56:16Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-3496260-1436144',
      orderItemId: '111750890874641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T13:35:09Z',
      buyer: 'jvqfsw52rqwzc6p@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-2354844-3102342',
      orderItemId: '111639375133921',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-13T13:17:06Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-2446783-1004150',
      orderItemId: '111756677598121',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-13T12:28:40Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '211-0566314-0580199',
      orderItemId: '111750607175681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T11:57:35Z',
      buyer: '9zs0y3w5j9xvh5d@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-5677956-2225532',
      orderItemId: '111760966632801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T08:03:47Z',
      buyer: 'w5y1m7f8yq1nrq7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7668979-1661978',
      orderItemId: '111605624030881',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-13T06:21:04Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-4961285-0805519',
      orderItemId: '111591677733161',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-13T05:20:21Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-4074604-4407907',
      orderItemId: '111753358649801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T03:09:45Z',
      buyer: 'sh3d1dcsrp1dn07@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-4006115-6254724',
      orderItemId: '111711425112361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T02:26:51Z',
      buyer: 'f7kjj2zfxkc9wxt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-2959884-6968422',
      orderItemId: '111748474644281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T02:19:47Z',
      buyer: 'rs2m0y7s6l3vzzb@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-6143008-4104541',
      orderItemId: '111744073074521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T02:05:08Z',
      buyer: 'tp1pgybxfd4fjls@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '311-8682892-6392202',
      orderItemId: '111601797268921',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-13T01:16:08Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.16,
      referral_fees: -3.75,
      orderId: '121-4698679-7631464',
      orderItemId: '111741479789121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-13T01:13:24Z',
      buyer: 'hqvcnbdrfppn8jk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '114-7331655-5880963',
      orderItemId: '111710796293281',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-13T00:33:03Z',
      buyer: '78cxxt2x7l6y2t7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '411-9801809-3568657',
      orderItemId: '111739173148801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T22:37:25Z',
      buyer: '5zcrwxmy30zm5xk@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '111-3962206-5826474',
      orderItemId: '111693821598441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T20:02:42Z',
      buyer: 'ty7f39338d1jtzm@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-4335255-3824264',
      orderItemId: '111735770470681',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-12T18:45:06Z',
      buyer: 'vnd3ktd40fr58q8@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-6853652-6241836',
      orderItemId: '111720609686401',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-12T14:47:44Z',
      buyer: 'sxzn4mtcsm2y9zr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-5372628-7035492',
      orderItemId: '111684711892161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T13:52:13Z',
      buyer: 'qtzfgj0hflgp58f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-6731180-3228068',
      orderItemId: '111720155586001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T12:50:28Z',
      buyer: 'gnd57qnql0jwpvp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-8910407-4538697',
      orderItemId: '111605632560161',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-12T12:30:22Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-6838952-5632923',
      orderItemId: '111691728259961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T12:27:40Z',
      buyer: 'm37rmkwbsgpxx9g@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-9662117-6099133',
      orderItemId: '111645027580081',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-12T10:10:40Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-3971127-8767223',
      orderItemId: '111705980750921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T07:44:40Z',
      buyer: 'y0lmt8b7kq7pc0n@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-1234843-4063344',
      orderItemId: '111624544815921',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-12T06:13:06Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-0102240-1126223',
      orderItemId: '111672356328081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T04:26:43Z',
      buyer: '12fgvcd81w3x8y2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-4028507-2978612',
      orderItemId: '111696892484081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T04:09:40Z',
      buyer: 'llpcw1swdwly248@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-2492514-5709658',
      orderItemId: '111600983109241',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-12T03:51:28Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-8312602-5557284',
      orderItemId: '111685879648681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-12T00:35:05Z',
      buyer: 'mjbp4bkf7wyld66@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8314260-2088424',
      orderItemId: '111681215277641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-11T23:59:50Z',
      buyer: '58pvwk3dd4lm1wj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-5492568-4499274',
      orderItemId: '111674898734681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-11T20:16:27Z',
      buyer: 'm7fn86m3ns4hlbt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3156801-9642920',
      orderItemId: '111675113456561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-11T20:06:32Z',
      buyer: '8wfr8c6f7qhx93f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 7,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-2100502-1238753',
      orderItemId: '111666302569241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-11T18:30:02Z',
      buyer: 'slmjtht39kmcb29@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-3405411-2009688',
      orderItemId: '111665445494161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-11T17:46:13Z',
      buyer: 'btr25pnn9jp7yw6@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '141-8045218-2095535',
      orderItemId: '111663960592761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-11T17:23:47Z',
      buyer: 'db1p5vj5rj0c441@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-1263062-4071680',
      orderItemId: '111285404080481',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-11T05:17:24Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-0723812-3186262',
      orderItemId: '111574378170001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-11T01:48:57Z',
      buyer: '7qbx4gb67rm7mzr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2282493-2550460',
      orderItemId: '111553705571441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T19:37:37Z',
      buyer: 'q1fjzch3v4bnwf2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3904163-8552526',
      orderItemId: '111549563583201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T17:52:57Z',
      buyer: '0k6qlsyq5z6c6pw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-8282942-6154270',
      orderItemId: '111508494390761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T12:44:52Z',
      buyer: '0hkx0vv5nhrxzdj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0022651-0284465',
      orderItemId: '111497762555401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T12:37:17Z',
      buyer: 'xkhdpvrqy4hryrl@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-7952667-3903634',
      orderItemId: '111536197226841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T12:29:12Z',
      buyer: 'rhfp40q4kns0sm1@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-4575993-4254135',
      orderItemId: '111098329712201',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-10T11:05:00Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-1035787-6436433',
      orderItemId: '111532667849601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T10:10:03Z',
      buyer: '3l1qzbpkvc7xj5v@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-0271640-8713998',
      orderItemId: '111501937553761',
      status: 'Pending',
      quantity: 2,
      purchaseDateTime: '2024-10-10T07:33:00Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-9925868-4438217',
      orderItemId: '111497022766801',
      status: 'Refunded',
      quantity: 1,
      purchaseDateTime: '2024-10-10T07:11:40Z',
      buyer: 'lcw6mpnzcbr267g@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-6384385-2852568',
      orderItemId: '111520299742961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T05:40:15Z',
      buyer: 'tb4l42ffh59gfyq@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 35,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-4059563-5021720',
      orderItemId: '111517580678041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T04:45:35Z',
      buyer: 'bvvjbq7p81bcl0d@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 11,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-9218131-8624445',
      orderItemId: '111242796316921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T04:43:41Z',
      buyer: 'qqvttmvjkhhfkz2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 29,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-9831370-8507862',
      orderItemId: '111517738368881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T03:41:28Z',
      buyer: 'fpxslknwxf46h0q@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 11,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-4569664-2193547',
      orderItemId: '111515854686921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T03:40:01Z',
      buyer: '21jc91rfcfcwtv4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-9751085-6140772',
      orderItemId: '111501391739001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-10T02:18:35Z',
      buyer: '4hq997y56hhlmxr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7032848-4469266',
      orderItemId: '111092726947801',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-10T01:40:13Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '131-7143917-8773090',
      orderItemId: '111492882800721',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-10T00:22:02Z',
      buyer: '37ttd41fmpmk3jr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-6175326-5219214',
      orderItemId: '111492035232521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T23:59:24Z',
      buyer: 'pl6l5r9zkvc3y6g@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 12,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-4012415-0077512',
      orderItemId: '111482991534241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T23:36:47Z',
      buyer: '7bmkrdp9swz0dnq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 66,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-9221839-6674885',
      orderItemId: '111485491714681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T23:32:10Z',
      buyer: 'vj4mx2yq3qzfbj5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '112-0525649-7349356',
      orderItemId: '111481037886681',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-09T23:08:56Z',
      buyer: '8wv61601c4cv213@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '141-3403177-0502590',
      orderItemId: '111481932275641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T22:20:20Z',
      buyer: '6pr1cp4crj8g0j7@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7978147-3782868',
      orderItemId: '111455351293761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T20:54:36Z',
      buyer: 'ljrz4dt1l3tkm67@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-4933428-9626223',
      orderItemId: '111472911407441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T20:53:33Z',
      buyer: 'qs49mghrl4y7j0b@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-6274888-1443703',
      orderItemId: '111459694213881',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-09T19:25:24Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-8555579-8336230',
      orderItemId: '111423408553241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T19:03:14Z',
      buyer: '2p308crmzpbs78h@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-7246143-0141418',
      orderItemId: '111460540386881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T18:41:25Z',
      buyer: 'cvg7spz1hgm9vsj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '114-3455958-2450959',
      orderItemId: '111298159954321',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-09T17:52:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-0921080-4713691',
      orderItemId: '111203199960401',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-09T17:37:54Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-2258130-0389772',
      orderItemId: '111409673138961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T16:36:56Z',
      buyer: 'j98lmhzrjvx2klt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-9151990-7336950',
      orderItemId: '111390152588081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T13:41:26Z',
      buyer: 'z55dwk5k9dk7fg4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-8095967-9757522',
      orderItemId: '111405745459561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T12:36:27Z',
      buyer: '4v53sqqrzjnqnh4@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '113-8774546-9246149',
      orderItemId: '111262391549441',
      status: 'Pending',
      quantity: 2,
      purchaseDateTime: '2024-10-09T10:39:50Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-6924611-7607748',
      orderItemId: '111407001920681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T10:27:45Z',
      buyer: 'z7nf5zmy3fk9k4k@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '121-3512810-0274679',
      orderItemId: '111195480201001',
      status: 'Pending',
      quantity: 2,
      purchaseDateTime: '2024-10-09T06:47:32Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-5039238-5132154',
      orderItemId: '111372640741881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T04:36:59Z',
      buyer: 'npw71rptrbnbpfx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-4028554-0102254',
      orderItemId: '111387509950161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T04:35:36Z',
      buyer: '1pgj52b8nb49z3b@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-6279366-4768640',
      orderItemId: '111360504486201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-09T01:52:07Z',
      buyer: 'tcw9922gfcz2wsb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '141-0651795-8150308',
      orderItemId: '111357602064041',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-09T01:44:42Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-7341481-0669570',
      orderItemId: '111329583107961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T23:59:56Z',
      buyer: 'kql1w59n5qkyw9v@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-5979187-4479303',
      orderItemId: '111355680201921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T23:25:33Z',
      buyer: '7y9f6h0mhjdhf7h@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-6330152-9611226',
      orderItemId: '111325696815321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T22:23:24Z',
      buyer: '3jwxq7g3tqrncb8@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-8516087-8722009',
      orderItemId: '111355081010801',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-08T22:23:03Z',
      buyer: '99hxqgv1f6jpv2n@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 18,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-1899500-2888028',
      orderItemId: '110888601048801',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-08T21:40:01Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-0511913-0668717',
      orderItemId: '110471097303001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T20:18:25Z',
      buyer: '0jtcjn84xhjh90y@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 2,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '113-9899286-6435463',
      orderItemId: '111322099315641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T19:20:36Z',
      buyer: 'c5dp7wqvq4trgvz@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '211-5003669-3433646',
      orderItemId: '111299017681801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T18:14:00Z',
      buyer: 'n1lvgsz1k0n2sgk@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '121-2311005-3125693',
      orderItemId: '111296091492121',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-08T18:12:09Z',
      buyer: '',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '411-7055684-5182760',
      orderItemId: '111315481530001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T16:01:02Z',
      buyer: 'fl84ftsygxm3wtx@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '121-3823245-2882611',
      orderItemId: '111314304277001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T15:57:09Z',
      buyer: '56n0vgv6n76w17t@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8289076-2822220',
      orderItemId: '111313322583521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T15:20:38Z',
      buyer: 'x32pp31ynwkq4zp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-9749666-5819380',
      orderItemId: '111309538582961',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-08T14:57:31Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-6209862-0315878',
      orderItemId: '111266944447081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T13:39:02Z',
      buyer: '9yvbvljwsxyxlvf@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '111-4493947-4451160',
      orderItemId: '111264782279681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T13:32:27Z',
      buyer: '3rk9ddvjmcc88fy@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 11,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2668754-5415142',
      orderItemId: '111280314058561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T12:43:15Z',
      buyer: 'fb93wmxqm0bn9ts@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-2100236-3970623',
      orderItemId: '111262575762721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T09:23:22Z',
      buyer: 'grjkm578vqv22f5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-1712719-6076763',
      orderItemId: '110900849417841',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-08T08:17:11Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-4813362-8001319',
      orderItemId: '111251014670841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T04:51:11Z',
      buyer: 'yww2dcsfqfr6lrr@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-7460280-4902866',
      orderItemId: '111250975636121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T04:44:18Z',
      buyer: '2q5y906tlkqkyfy@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 15,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8351761-4567272',
      orderItemId: '111254677754281',
      status: 'Refunded',
      quantity: 1,
      purchaseDateTime: '2024-10-08T03:54:21Z',
      buyer: 'b1w8yx69pjqhzbc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-6553533-1402001',
      orderItemId: '111221623353961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T03:49:16Z',
      buyer: 'gn1przz299gm4wk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 52,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-0579962-7151346',
      orderItemId: '111250635408001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T03:40:44Z',
      buyer: 'qqfzkp80w636jdn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0734421-6450205',
      orderItemId: '111254509996041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T03:15:19Z',
      buyer: 'klb792wrwm9rvrw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3356386-2505881',
      orderItemId: '110993353929041',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-08T02:29:48Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0560175-9876634',
      orderItemId: '110503101400041',
      status: 'Pending',
      quantity: 1,
      purchaseDateTime: '2024-10-08T01:50:38Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0507952-4398925',
      orderItemId: '111249033624921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T01:44:37Z',
      buyer: '49j1ghwr91hqp32@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-1944470-2354571',
      orderItemId: '111244852340801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-08T00:05:39Z',
      buyer: 'vrdhhffgp2zh4ly@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -2.7,
      orderId: '131-0785172-1636019',
      orderItemId: '111244365554121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T23:40:00Z',
      buyer: 'gqq3jmgjj4s8ybz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 17.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '112-4896327-0352076',
      orderItemId: '111244336882401',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-07T23:31:14Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-9760817-5372078',
      orderItemId: '111228239600601',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-07T23:29:34Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-8602896-8860503',
      orderItemId: '111226904417321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T20:46:22Z',
      buyer: '27gg39pxfzm77d2@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-3976138-0615448',
      orderItemId: '111219669572721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T18:20:00Z',
      buyer: 'wd6vrxn2phv89n2@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-1744183-2116841',
      orderItemId: '111207717320161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T16:31:43Z',
      buyer: '5bcb94w6v2y2vpt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-4617582-2529880',
      orderItemId: '111214787827401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T16:27:05Z',
      buyer: 'w09hywm79kjw0xj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-8405135-6843035',
      orderItemId: '111210979710041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T15:41:54Z',
      buyer: 'cj8ybzn2l6m2q0r@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-3094132-1504784',
      orderItemId: '111207376711161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T14:26:41Z',
      buyer: '6y7tbgwyx8x89xz@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-8548599-9156398',
      orderItemId: '111190488302801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T14:01:38Z',
      buyer: 'pjmw72jcgc97s60@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-5200767-1678497',
      orderItemId: '111200117460961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T12:53:51Z',
      buyer: 'p07b1vh3ty8s5nf@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-0074559-7730802',
      orderItemId: '110876066520401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T04:28:50Z',
      buyer: '2bh96thw8dflgbr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 70,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '211-8309599-1490893',
      orderItemId: '111159350520841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-07T01:51:24Z',
      buyer: '37nww0cfhpyqkqg@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-5694388-4412222',
      orderItemId: '111138394135721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T23:10:34Z',
      buyer: 'qgdys9h4qf61hcr@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2034838-6652579',
      orderItemId: '111159084010521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T22:19:09Z',
      buyer: 'fbsy0hwbmyrxb6b@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-1720972-3341159',
      orderItemId: '111136783819361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T19:02:36Z',
      buyer: 'zdg3mj10ltxdsrs@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '411-4147206-3737618',
      orderItemId: '111102304438041',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-06T17:34:30Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-5232320-0324435',
      orderItemId: '111142438622201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T17:22:48Z',
      buyer: '797rqxctp61jrlp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-1169457-2484373',
      orderItemId: '110492663938441',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-06T17:12:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-6508908-6697040',
      orderItemId: '111142859441921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T16:55:05Z',
      buyer: 'rbxjh56vfpjl6t4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-0352616-3837674',
      orderItemId: '111094642131841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T16:51:05Z',
      buyer: '87yxfvd39v1gqzn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '211-3218457-0232262',
      orderItemId: '110468269087881',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-06T16:27:41Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-8597237-1810764',
      orderItemId: '111141691626841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T16:18:39Z',
      buyer: '739m614l0n40tyn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '141-1506047-8952894',
      orderItemId: '111131350716561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T15:48:13Z',
      buyer: 'v4g2rl2tw52hx0w@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-3005940-0540152',
      orderItemId: '110873764920481',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-06T15:08:12Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-7632423-4735960',
      orderItemId: '111132211004321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T15:01:19Z',
      buyer: 'f45mq8365nb3rqj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-4547368-7936245',
      orderItemId: '110941609710601',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-06T14:49:26Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-6086341-4486153',
      orderItemId: '111125171947321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T14:35:18Z',
      buyer: 't02gzkw47hm7qzq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-5983386-8901820',
      orderItemId: '111091753909721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T11:10:36Z',
      buyer: 'v7r1bwhzgwf1m8f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -31.2,
      referral_fees: -30.0,
      orderId: '111-3946560-9020105',
      orderItemId: '111117195639761',
      status: 'Shipped',
      quantity: 8,
      purchaseDateTime: '2024-10-06T09:40:19Z',
      buyer: '0nxy7xfz1dcpf4c@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 30,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -31.2,
      _shipping_cost: -0.64,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 199.76,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '311-0176874-9386542',
      orderItemId: '111063718668801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T01:54:20Z',
      buyer: 'r2zslyc38d359sn@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '311-3365493-4678606',
      orderItemId: '111107702257001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-06T01:44:57Z',
      buyer: 'c5dp7wqvq4trgvz@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-6014295-6239076',
      orderItemId: '111087540540121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T20:44:19Z',
      buyer: '5yxr0mw1b08727f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-5086884-8249357',
      orderItemId: '111060044538881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T20:34:20Z',
      buyer: '7slz48zxpz87zkr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-4303305-2260708',
      orderItemId: '111082936451321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T19:39:58Z',
      buyer: 'sqvrwdbkxbyfvqp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-4971046-1181433',
      orderItemId: '110442585934201',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-05T17:44:21Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-7091919-9023098',
      orderItemId: '111058554301561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T16:47:26Z',
      buyer: 'jtqvrc0nq19y0cd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-7185711-8623643',
      orderItemId: '110483505046801',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-05T16:12:25Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-4577880-8248581',
      orderItemId: '111075694869121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T16:06:12Z',
      buyer: 'zlqtrffc06nr2cr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-5169854-0853467',
      orderItemId: '111030124063161',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-05T13:50:34Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-0170035-3116863',
      orderItemId: '111052930364521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T12:06:08Z',
      buyer: '99dtpw3mx21wf8m@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-0025322-4024209',
      orderItemId: '111034229544481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T11:03:12Z',
      buyer: 'jnkb32xj1b9b253@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 18,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '141-9724560-3218636',
      orderItemId: '111050271097561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T10:59:22Z',
      buyer: '8yyjq9t53k2rk7w@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '311-8859964-9991947',
      orderItemId: '111029556749841',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-05T04:56:17Z',
      buyer: 'rn64dj725mkmycb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3713154-4403026',
      orderItemId: '110445933858641',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-05T04:34:34Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-7503252-3360862',
      orderItemId: '111044434929161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T03:40:06Z',
      buyer: 'b634sp84zkfxqh4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-2082007-2236742',
      orderItemId: '110465352601401',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-05T03:35:30Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-1917474-5026883',
      orderItemId: '110994978071841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T02:30:54Z',
      buyer: 'hkjy861f7jxr123@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-4272362-0245221',
      orderItemId: '110468888472761',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-05T01:42:50Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-7281105-5844760',
      orderItemId: '111021583128441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-05T00:38:24Z',
      buyer: '46r4gytj5t5xtsv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '111-6991487-8748005',
      orderItemId: '110486214516201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T23:07:44Z',
      buyer: 'hkcr306zfzsn0c1@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 59,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-1726258-4784761',
      orderItemId: '111017445381921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T19:49:41Z',
      buyer: '0k3x1dj16jkcfsn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-7156374-2616567',
      orderItemId: '110992540910081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T19:21:28Z',
      buyer: '65pnk578x8hxcst@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-7322621-9899636',
      orderItemId: '111002216328321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T18:51:52Z',
      buyer: 'ks7bshq76yfwf1k@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '113-2320301-7647756',
      orderItemId: '110906180729161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T17:20:01Z',
      buyer: 'xzn8chmsdlw7w4h@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 17,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-6481310-2544466',
      orderItemId: '110959850681521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T15:26:34Z',
      buyer: 'hzbdbs13cjzqtdk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -2.7,
      orderId: '113-6205421-8618658',
      orderItemId: '110984497711321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T12:53:58Z',
      buyer: 'k8c8rf9lkfkxb9x@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 17.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-8721736-3088773',
      orderItemId: '110989630877841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T11:47:52Z',
      buyer: '1jh0nswb6xyj5fy@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-2517752-0186467',
      orderItemId: '110970479425961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T11:32:11Z',
      buyer: 'fn2zjl7rtqd1lxb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 6,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '211-5130688-9721489',
      orderItemId: '110888221016921',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-04T10:22:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '121-4218971-0464499',
      orderItemId: '110953063433081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T07:43:30Z',
      buyer: '6rnjfnz6kt8xcr9@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 5,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-3174437-9877931',
      orderItemId: '110925533930801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T05:11:50Z',
      buyer: 'w3tbnpzs3641sjk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '411-8805029-6560639',
      orderItemId: '110949254302761',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-04T05:04:13Z',
      buyer: 'dn4zy620nbg9qrd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-8629807-0694933',
      orderItemId: '110935411750401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T01:02:13Z',
      buyer: 'hyryqs6qltd9ntx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-9431857-5417406',
      orderItemId: '110938116340281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-04T00:34:32Z',
      buyer: 'w1by17jclgggyy3@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8532063-2456580',
      orderItemId: '110478395143961',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T23:44:48Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-4456640-0612843',
      orderItemId: '110935955130641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T23:41:29Z',
      buyer: 'zq76k96q7105wm7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-0300246-5204483',
      orderItemId: '110935746193481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T23:13:01Z',
      buyer: '81wbjnmbtt3b20c@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '211-6748606-9506825',
      orderItemId: '110919513671761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T21:37:26Z',
      buyer: 'sfd7h1yfhsnz73z@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-1417364-5980641',
      orderItemId: '110482812573201',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T20:43:34Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '113-2906919-4723528',
      orderItemId: '110911209563161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T18:35:05Z',
      buyer: 'qgh0t3920s77kqw@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '121-9639362-1269748',
      orderItemId: '110915082519081',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-03T18:10:12Z',
      buyer: 'yh562cb3kv5pyzf@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-5314818-8839445',
      orderItemId: '110901365596441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T16:27:31Z',
      buyer: 'w3ss73pw91yjg3x@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-0959314-7571841',
      orderItemId: '110900121455401',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T16:02:30Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-0574221-4428613',
      orderItemId: '110900742686121',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T14:52:02Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2489845-3145423',
      orderItemId: '110898950817201',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T14:22:17Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '211-3753019-8035690',
      orderItemId: '110462777930921',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T12:11:24Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-2022433-8710303',
      orderItemId: '110860642466361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T08:47:11Z',
      buyer: '0m6fgr2y6ckfkgw@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '411-6021579-2605341',
      orderItemId: '110447011406081',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T06:59:00Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-0240794-2902471',
      orderItemId: '110883568588601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T06:36:43Z',
      buyer: '68cy6ndpchq8y6v@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '114-4288333-6210372',
      orderItemId: '110860100242121',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-03T05:36:02Z',
      buyer: 'y2jqhcy8lrwt56q@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '411-2683497-3386486',
      orderItemId: '110859521584641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T03:27:54Z',
      buyer: 'hy4zkw1cvcmj9bq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 4,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-4601687-3698268',
      orderItemId: '110857207504481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T02:17:08Z',
      buyer: '3rjw75qhdtn2yfl@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-9672777-1830827',
      orderItemId: '110870501518881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T02:12:35Z',
      buyer: 'zcfx4bll5dc6d08@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-0959047-3057176',
      orderItemId: '110867973308121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T02:05:00Z',
      buyer: 'b4brcvwqsmzyr6v@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-1261148-6051341',
      orderItemId: '110867543114401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-03T01:38:42Z',
      buyer: 'ts08ppsnpwpj6pq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '113-0479258-9616629',
      orderItemId: '110500197235521',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-03T00:15:39Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-9714339-9006316',
      orderItemId: '110832082829881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T23:32:45Z',
      buyer: '80dlbtk0xz3ltlr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7225393-9462494',
      orderItemId: '110856173503881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T22:56:17Z',
      buyer: 'h4zx5chznxtbhkn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.02,
      referral_fees: -11.98,
      orderId: '111-1246700-9585601',
      orderItemId: '110849827119881',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-02T19:07:59Z',
      buyer: 'x02khs0j7bpl4dy@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -19.6,
      _shipping_cost: -2.1,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 79.9,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -15.6,
      referral_fees: -10.8,
      orderId: '111-7056649-6200860',
      orderItemId: '110847195113841',
      status: 'Shipped',
      quantity: 4,
      purchaseDateTime: '2024-10-02T18:46:04Z',
      buyer: 'nv0wrnfjk0m0y54@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 11,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -15.6,
      _shipping_cost: -0.32,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 71.88,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '131-5656395-5147335',
      orderItemId: '110846205159121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T17:45:44Z',
      buyer: 'mgm8lcsv6pg6vn5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-1812875-7446628',
      orderItemId: '110463863087921',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-02T16:43:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-3537024-3947487',
      orderItemId: '110826048672481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T16:10:53Z',
      buyer: 'tv8s9ry0vfyjqmb@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-1509506-4626361',
      orderItemId: '110836033397521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T15:13:04Z',
      buyer: '3rry5x6cmn0p0ny@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-3062226-4781490',
      orderItemId: '110832793335641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T13:57:45Z',
      buyer: 'yyslx7yql8l08nc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3115541-6905350',
      orderItemId: '110833792471361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T13:49:00Z',
      buyer: 'm37rmkwbsgpxx9g@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2987665-6824956',
      orderItemId: '110824181996681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T12:42:06Z',
      buyer: 'm09mhxwpd52q3t5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 18,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-5762147-3692528',
      orderItemId: '110824402975841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T11:48:12Z',
      buyer: '7l91bldx1ldw74f@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-7423926-3182381',
      orderItemId: '110818634440161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T09:54:26Z',
      buyer: 'jx3ry16cm2g0dpt@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-9547593-1730741',
      orderItemId: '110772961328281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T05:13:26Z',
      buyer: 'rn73z876kpppl3s@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-6570816-2831826',
      orderItemId: '110804454814561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T03:34:14Z',
      buyer: 'vh7pntskr77215f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '121-3203219-4710381',
      orderItemId: '110803623716841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-02T03:20:04Z',
      buyer: '2gx3rdcqfldkg83@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -15.6,
      referral_fees: -15.0,
      orderId: '311-3789289-1740490',
      orderItemId: '110774668039161',
      status: 'Shipped',
      quantity: 4,
      purchaseDateTime: '2024-10-02T01:22:07Z',
      buyer: 'sqr4wgvj6s87vt2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -15.6,
      _shipping_cost: -0.32,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 99.88,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '131-3639937-5262943',
      orderItemId: '110447295003721',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-01T22:04:22Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -4.16,
      referral_fees: -3.37,
      orderId: '211-9016260-7126431',
      orderItemId: '110467403007921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T21:33:26Z',
      buyer: 'mk76nk89sj9yh37@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 18,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2628926-5118733',
      orderItemId: '110770392015361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T21:01:50Z',
      buyer: '9r72x86lx3tmv7x@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-4533874-0928114',
      orderItemId: '110450882534801',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-01T20:05:48Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-8912637-4393240',
      orderItemId: '110751891157281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T19:57:01Z',
      buyer: '7m8g3wg25gsz76w@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '112-9280259-3521543',
      orderItemId: '110768527307121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T19:55:57Z',
      buyer: 'v19thw8pn2tmyfh@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 2,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-8790582-8248442',
      orderItemId: '110751696538561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T19:24:28Z',
      buyer: 'g1vkcllzl052l4d@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-2142664-4945825',
      orderItemId: '110761395198801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T18:04:19Z',
      buyer: 'ltng5ng5gn99z9q@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8284179-5856645',
      orderItemId: '110727735139201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T17:00:43Z',
      buyer: '3lhxqfpxtl622xv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-9083466-7514500',
      orderItemId: '110757083950321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T16:41:13Z',
      buyer: 'llrlq789hmkdf7l@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 14,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -2.7,
      orderId: '211-2900896-5684709',
      orderItemId: '110753576489201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T15:46:08Z',
      buyer: 'rqypmjdsg4k94w9@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 17.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-6942433-7853681',
      orderItemId: '110757373483601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T15:22:24Z',
      buyer: 'dv1xjbslr64722z@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-5615871-7549560',
      orderItemId: '110454269305801',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-01T14:30:55Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-4493286-6758475',
      orderItemId: '110749600479001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T14:28:02Z',
      buyer: 'p8z8wks3jjk137s@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-4612913-2204234',
      orderItemId: '110753100584641',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-01T10:30:35Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '121-7086241-6284423',
      orderItemId: '110736399260801',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-01T05:44:51Z',
      buyer: 'frphwldh8wwfyrx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 108,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '211-2434245-9073361',
      orderItemId: '110718150508481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T05:32:44Z',
      buyer: 'jnys0zk2z7613ns@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '121-3307641-9476308',
      orderItemId: '110717686708441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T03:41:33Z',
      buyer: 'xgx7ctnrhsjxscb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '113-6199432-2690055',
      orderItemId: '110725176605561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T03:17:35Z',
      buyer: '1fkzndxl955fs7z@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '113-5368487-7253451',
      orderItemId: '110732407808521',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-10-01T02:32:09Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-1553751-2680019',
      orderItemId: '110729544797281',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-10-01T01:50:54Z',
      buyer: 'xbs186x6vd56ykx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 14,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-3779714-3418493',
      orderItemId: '110714128041081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T01:50:39Z',
      buyer: '1k51zznwctgfpp3@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-5839129-2946444',
      orderItemId: '110714085636801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T01:43:57Z',
      buyer: 'krv0g717744y3wf@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-0374011-3360784',
      orderItemId: '110729932362401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T01:35:35Z',
      buyer: 'rwvgzb114bb4ccz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 12,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-2554646-5024393',
      orderItemId: '110720769149361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T01:29:41Z',
      buyer: '8p6bwh18qj0f2yr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-9854775-3000012',
      orderItemId: '110721079199521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T00:51:35Z',
      buyer: 'zz1ysjk9hs5rfj7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-1514318-8975541',
      orderItemId: '110723082915361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-10-01T00:41:25Z',
      buyer: 'twqbld1m1rddcv9@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '131-4358149-4672944',
      orderItemId: '110467268396201',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-30T23:38:39Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-9464747-7126016',
      orderItemId: '110707979253281',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-30T22:07:37Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-3677424-4113821',
      orderItemId: '110706393194881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T21:20:45Z',
      buyer: 'd6h6hcn1bxxq1mm@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-5782391-9341836',
      orderItemId: '110677557659241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T21:12:50Z',
      buyer: 'y0gnbs9g2wbljcx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '114-4368313-7925610',
      orderItemId: '110704662285401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T21:05:29Z',
      buyer: 's2knpzcvwqfr9tp@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-6509064-0540181',
      orderItemId: '110497277778881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T20:34:28Z',
      buyer: 'q9cqhkxy1gzl400@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 18,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '111-7511310-3932087',
      orderItemId: '110500818535561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T20:13:35Z',
      buyer: '50fhdsp69g459jc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 67,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0406870-3289364',
      orderItemId: '110677010277401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T19:31:27Z',
      buyer: 'bmr4szsm74b9kdv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-0474437-0031988',
      orderItemId: '110693422099841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T18:43:46Z',
      buyer: 'mgf73s06dgbkwzq@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-4643855-0862234',
      orderItemId: '110660284958641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T17:42:59Z',
      buyer: 'm09mhxwpd52q3t5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 18,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '311-5453272-6402201',
      orderItemId: '110675652746281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T15:13:19Z',
      buyer: 'y0g3t9h7hwdb4l0@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '311-5147720-6971360',
      orderItemId: '110466246505801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T15:12:04Z',
      buyer: '82xv1mtntx0090n@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 26,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-7468385-0841513',
      orderItemId: '110675232440601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T13:43:11Z',
      buyer: '8k61fp0d0p17pzn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-8867728-5589160',
      orderItemId: '110646106609161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T13:00:23Z',
      buyer: 'n5540x885sxnbjd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '141-7625141-1417307',
      orderItemId: '110674323962481',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-30T11:44:06Z',
      buyer: 'vbd1hsm22dg6vq9@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '113-5475855-7301689',
      orderItemId: '110642173796841',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-30T05:15:33Z',
      buyer: 'yxlxfbqpb8h5bbw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 12,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-4309053-1390698',
      orderItemId: '110672530996361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T05:02:10Z',
      buyer: '37hmc6k5ps1tksj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-1003351-9906562',
      orderItemId: '110661734246641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T04:13:25Z',
      buyer: '7l9khwp5d4sj5t7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '114-0192898-0132393',
      orderItemId: '110670044271561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T04:11:47Z',
      buyer: 'wvgjlpv6jpt4zjh@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-8382626-2344456',
      orderItemId: '110641898736081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T04:00:05Z',
      buyer: 'nw4dhlcdc4m5y1h@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -4.99,
      fba_fees: -7.8,
      referral_fees: -6.74,
      orderId: '111-8100732-1449042',
      orderItemId: '110500114728521',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-30T03:43:31Z',
      buyer: 'svckq45myrz4813@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 12,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0963587-9478146',
      orderItemId: '110441547565721',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-30T03:06:47Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-4974685-1779150',
      orderItemId: '110640962283561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T01:20:15Z',
      buyer: 'jl0z7xc5011vty6@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '131-6422201-1734504',
      orderItemId: '110656363536401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T00:03:53Z',
      buyer: 'nnpvgwxbhyghlcj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 2,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-1754387-7767382',
      orderItemId: '110655946653081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-30T00:02:21Z',
      buyer: 'tzh7pkw5gxmsv8w@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-6040815-9060840',
      orderItemId: '110656775594241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T23:53:07Z',
      buyer: '95d40zwm4hdc492@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-7818307-9012225',
      orderItemId: '110656516160641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T23:49:23Z',
      buyer: '815cl2ng3zhk1by@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-3794729-7471930',
      orderItemId: '110638245079801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T18:54:03Z',
      buyer: 'jgd2jyrs25t7b70@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-3063266-1513380',
      orderItemId: '110452685830401',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-29T18:33:18Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-4921826-0696430',
      orderItemId: '110597317251601',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T17:50:17Z',
      buyer: 'fzp61ltfj67pg9f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-2658470-4454081',
      orderItemId: '110604638025281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T17:10:10Z',
      buyer: 'm0t3lj4w07f1pw9@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '211-9210720-5806653',
      orderItemId: '110503546406761',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-29T16:44:51Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-1027975-3574587',
      orderItemId: '110630473638281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T15:58:45Z',
      buyer: 'k9gz88ch8lm2trp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '311-1683552-0617022',
      orderItemId: '110623461660321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T15:40:27Z',
      buyer: 'y0g3t9h7hwdb4l0@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '113-6277350-5115974',
      orderItemId: '110628946290681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T14:45:21Z',
      buyer: 'nqq9x2ltnb3qfjp@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-1416664-0828599',
      orderItemId: '110620914236121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T14:43:26Z',
      buyer: '4dh8zpx4pzc7xwv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-9800952-8904598',
      orderItemId: '110621916905521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T14:08:53Z',
      buyer: 'lh8t4kyzrg8gt9t@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-6975209-3467204',
      orderItemId: '110618159716841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T08:52:05Z',
      buyer: 'nkbtlj1w5pwrjm1@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '311-9970530-2257140',
      orderItemId: '110437320079601',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-29T05:11:22Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-8453312-0868361',
      orderItemId: '110594971406641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T04:44:00Z',
      buyer: '8ps9fdzzqltr1ql@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '114-6725316-5421592',
      orderItemId: '110602391722881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T04:04:23Z',
      buyer: 'km0w46k7vy4byvl@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-3046292-8653478',
      orderItemId: '110408194127201',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-29T03:48:27Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-8269416-2459402',
      orderItemId: '110605623655001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T02:36:10Z',
      buyer: 'mxksw6h2j1sghmj@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-7515832-2605942',
      orderItemId: '110594204488041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T01:51:44Z',
      buyer: '4k3c9fw5440gclz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '141-5872777-9653960',
      orderItemId: '110464387947481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T01:40:18Z',
      buyer: 'x0jqyqssn2751sx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 18,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-2722155-3608260',
      orderItemId: '110500111947121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T00:53:42Z',
      buyer: 'td1l95c0v41wzd4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 53,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-8009964-5523895',
      orderItemId: '110598899432681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-29T00:42:41Z',
      buyer: '1xgmsmcj1fw30v5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-0628803-5841236',
      orderItemId: '110591938847561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T23:52:35Z',
      buyer: 'sw8931tk42t8zjn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-3339785-0819938',
      orderItemId: '110554579020361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T21:13:29Z',
      buyer: 'v82806r6fhp7wrt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '112-5231302-0267565',
      orderItemId: '110574235935681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T19:22:51Z',
      buyer: 'swfbw2xtjjy3q6b@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-9735819-6401209',
      orderItemId: '110553681931801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T18:24:05Z',
      buyer: 'ptj0nvb7t9js4yr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 13,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-5511541-4546486',
      orderItemId: '110583231908841',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-28T17:35:42Z',
      buyer: '09182rssszdb3kv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '111-8296187-0748341',
      orderItemId: '110572151141961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T17:34:44Z',
      buyer: '09182rssszdb3kv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 4,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '121-2330651-5430071',
      orderItemId: '110571043467281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T16:43:14Z',
      buyer: 'hnpsdtzp8xyf210@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7034862-0700103',
      orderItemId: '110568851779201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T16:24:48Z',
      buyer: '95lm8s0crhs48w3@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '114-4252368-4166551',
      orderItemId: '110441524455921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T16:20:49Z',
      buyer: 'q0s0v27t4s9ybp2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 17,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-8218948-2324026',
      orderItemId: '110553004459201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T16:16:39Z',
      buyer: '5syg4dn22c6jv9y@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-6894900-8613489',
      orderItemId: '110503613082881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T16:09:59Z',
      buyer: 'ktwvpjhbxcxwycx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-4029650-4483028',
      orderItemId: '110450768886601',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-28T16:09:31Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-5735905-5615802',
      orderItemId: '110542549539281',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-28T15:44:41Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.02,
      referral_fees: -11.98,
      orderId: '111-9663043-6087026',
      orderItemId: '110570552212001',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-28T15:32:41Z',
      buyer: '8fy0clymrpkty8z@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -19.6,
      _shipping_cost: -2.1,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 79.9,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '111-8189169-5648729',
      orderItemId: '110542429202721',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-28T15:20:25Z',
      buyer: 'xvbbxbdtzr20fyz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-1730749-2456724',
      orderItemId: '110565628795201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T14:10:23Z',
      buyer: 'flz3tnfnf7cpgxk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-3808400-1661982',
      orderItemId: '110565719503401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T13:34:00Z',
      buyer: 'lx6r98tzb1hcvs4@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.02,
      referral_fees: -11.98,
      orderId: '111-1089283-4114853',
      orderItemId: '110562560887081',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-28T10:51:30Z',
      buyer: 'blm1j453qczrqn6@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -19.6,
      _shipping_cost: -2.1,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 79.9,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-0937912-0354320',
      orderItemId: '110450743698321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T10:43:00Z',
      buyer: 'f97v3whrnwls8t5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '131-4093955-0263628',
      orderItemId: '110558456127841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T07:57:07Z',
      buyer: '4k6tp47c4rhl6p5@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-3449187-8164538',
      orderItemId: '110557101494721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T06:33:43Z',
      buyer: '1jyt6trwr4bwljv@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-1380430-6770813',
      orderItemId: '110517325771641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T05:16:35Z',
      buyer: 'lm7vf830s0ns832@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '114-1595184-4248801',
      orderItemId: '110540667679041',
      status: 'Refunded',
      quantity: 1,
      purchaseDateTime: '2024-09-28T03:59:50Z',
      buyer: 'zg1c3m5sr1xj3fc@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 10.85,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-6004038-7600466',
      orderItemId: '110516854047321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-28T03:04:12Z',
      buyer: '4tj53lrzkdfnwxk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 9,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -4.99,
      fba_fees: -7.8,
      referral_fees: -6.74,
      orderId: '111-3838572-0595742',
      orderItemId: '110446860495321',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-28T01:18:04Z',
      buyer: 'wd94ffn2z4ly95c@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 25,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-9520809-8565693',
      orderItemId: '110538387001721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T23:47:15Z',
      buyer: '887h508r8mg5wgz@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-9299808-1856227',
      orderItemId: '110536526307161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T22:32:21Z',
      buyer: '2t4tzb5mhln41ld@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-8163794-4774173',
      orderItemId: '110534663674401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T21:09:00Z',
      buyer: 'pdttxt6p458qhtd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-7228384-0653268',
      orderItemId: '110531610244841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T18:52:24Z',
      buyer: '4yt7hph0gl1ysyb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-4125851-5081068',
      orderItemId: '110526079498801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T18:20:25Z',
      buyer: 'g0pwxdpgs1ml4j7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '112-5534990-6530564',
      orderItemId: '110526002625241',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-27T18:19:59Z',
      buyer: 'xwff5wwc6hbrxlt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '111-8318116-8141901',
      orderItemId: '110459235569361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T17:17:13Z',
      buyer: '4r2g35n2kpqs0lw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 17,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-5488199-0510822',
      orderItemId: '110514077520761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T16:45:10Z',
      buyer: 'ty10b41d4c5p7d2@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-3570661-0129508',
      orderItemId: '110514100822961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T15:11:18Z',
      buyer: 'gn1przz299gm4wk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 52,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-6899011-5006084',
      orderItemId: '110487897643201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T13:43:08Z',
      buyer: '9zv3tdwsj1c0scl@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3364839-4681280',
      orderItemId: '110501671871841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T12:53:52Z',
      buyer: '0k6qlsyq5z6c6pw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0462061-9961672',
      orderItemId: '110475538559921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T12:45:13Z',
      buyer: '468trqpzss20hdk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-3475702-9218609',
      orderItemId: '110447741854561',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-27T12:40:31Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '111-7782817-3749506',
      orderItemId: '110424260102201',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-27T08:06:10Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-8679869-3435941',
      orderItemId: '110440963749361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T05:50:41Z',
      buyer: 'lfnjtcz7xjr7lgd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '113-8146133-5008406',
      orderItemId: '110325358463841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T04:28:33Z',
      buyer: 'w92sxtnzbn3b0t1@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 3,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-7000947-9403423',
      orderItemId: '110436457070841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T03:56:31Z',
      buyer: '2vhkx633gqk1258@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-7329359-2020942',
      orderItemId: '110432870494921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T02:51:03Z',
      buyer: 'ddvsd51mt9vgxjh@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-5668294-5296602',
      orderItemId: '110433812556761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T02:34:58Z',
      buyer: '78s9q8hnq4pl939@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 31,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-6473522-5982562',
      orderItemId: '110435025555801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T02:13:56Z',
      buyer: 't9f2qnd16yf2t3q@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-1898153-5055229',
      orderItemId: '110421733420481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-27T01:15:07Z',
      buyer: 'nrmcq4tx37l9ccg@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0950343-4056209',
      orderItemId: '110335606535681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T23:04:27Z',
      buyer: 'fpt3lzh1dvmr9g4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -4.99,
      fba_fees: -7.8,
      referral_fees: -6.74,
      orderId: '111-1483471-2166446',
      orderItemId: '110418048792401',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-26T21:47:34Z',
      buyer: '17l33pdlq61cnmg@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 15,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '112-0347667-1242293',
      orderItemId: '110418798916161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T21:21:49Z',
      buyer: '5t3hq1v3640wc50@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-6745202-0436061',
      orderItemId: '110380985742401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T19:48:09Z',
      buyer: '78cxxt2x7l6y2t7@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -9.99,
      fba_fees: -15.6,
      referral_fees: -13.48,
      orderId: '114-7098224-7046691',
      orderItemId: '110366381002361',
      status: 'Shipped',
      quantity: 4,
      purchaseDateTime: '2024-09-26T19:14:33Z',
      buyer: 'k3h5bt2j0x5xydl@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 29,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -15.6,
      _shipping_cost: -0.32,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 99.88,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7940522-3322536',
      orderItemId: '110413398074961',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T17:50:29Z',
      buyer: 'dqy8w8r32rzpsbd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '211-5283472-1807322',
      orderItemId: '110380360593401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T17:39:50Z',
      buyer: 'd70f1tvd8z2skv2@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '114-0901531-3060320',
      orderItemId: '110376744805321',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-26T17:15:00Z',
      buyer: '9d20c43hlvjgh4y@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-8852643-5200288',
      orderItemId: '110376662305721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T16:59:15Z',
      buyer: '1v8yl44vb5q214d@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-5723909-7904722',
      orderItemId: '110376635241561',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-26T16:52:56Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-9274595-7916294',
      orderItemId: '110376436352681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T16:09:51Z',
      buyer: '7dqh8djnzyx54nb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-4499264-1751496',
      orderItemId: '110376390833881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T15:58:36Z',
      buyer: 'z36bd0hzwwnnf21@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-4235907-0486781',
      orderItemId: '110400305842121',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-26T15:24:58Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-6856789-6045564',
      orderItemId: '110372746122681',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-26T15:17:05Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '111-4574781-8300637',
      orderItemId: '110372581335281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T14:41:45Z',
      buyer: 'r8x76yrf350nq6d@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8245230-3549465',
      orderItemId: '110396030064681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T14:33:06Z',
      buyer: 'hc93w5xk8hk3zvk@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 6,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-8928685-8409303',
      orderItemId: '110360806297521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T13:41:49Z',
      buyer: 'f2cd174yh0gctf9@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -8.02,
      referral_fees: -11.98,
      orderId: '113-2223595-1020348',
      orderItemId: '110326202086401',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-26T11:46:56Z',
      buyer: 'w9d05fwj395j9yz@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -19.6,
      _shipping_cost: -2.1,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 79.9,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-1972321-4314399',
      orderItemId: '110308610602801',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-26T10:47:46Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '121-9783201-5716486',
      orderItemId: '110326340742561',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-26T09:14:19Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-8700041-8402227',
      orderItemId: '110313424676681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T08:09:14Z',
      buyer: '7qs6kdlb2qcpktg@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3696549-4662814',
      orderItemId: '110313988149321',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-26T08:06:28Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-3078416-4974828',
      orderItemId: '110330422453281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T07:07:05Z',
      buyer: 'cfjqcrysgmn1trb@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-3466342-6520813',
      orderItemId: '110282198205481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T02:46:22Z',
      buyer: 'b824w44j75bmflm@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3583659-4376540',
      orderItemId: '110174557546361',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-26T01:04:55Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '113-4570101-4436872',
      orderItemId: '110294640113441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-26T01:00:28Z',
      buyer: '9hdc6mgm33rcmrv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 1,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -4.99,
      fba_fees: -7.8,
      referral_fees: -6.74,
      orderId: '131-2507330-2580965',
      orderItemId: '110158649558001',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-26T00:36:09Z',
      buyer: 'pn32xt9vfr332bh@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 12,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-9450690-6869156',
      orderItemId: '110281217541201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T23:52:42Z',
      buyer: 'hc7g8hwmsszbkzt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-5490038-1824188',
      orderItemId: '110304741004241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T22:44:01Z',
      buyer: 'hj5vxrhhkzpjd6w@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-2860031-2043073',
      orderItemId: '110301321030361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T22:30:27Z',
      buyer: 'n06l4d7n37fm55c@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -2.7,
      orderId: '121-5355886-7224202',
      orderItemId: '110283295995921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T20:24:41Z',
      buyer: 'rn5632wr8lw1wqc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 17.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-2044150-9458437',
      orderItemId: '110297259118161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T19:55:47Z',
      buyer: 'qhqhlb3dn316hc5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-1035010-8218200',
      orderItemId: '110290241138321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T19:41:21Z',
      buyer: 'my616rmt8m9ysrl@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '113-3334431-5046315',
      orderItemId: '110150609722321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T19:35:42Z',
      buyer: 'f35sfvm1c7f8pv4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 3,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-8254820-2993658',
      orderItemId: '110276481952761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T15:15:14Z',
      buyer: 'qm60b1th6rkppgx@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-9641327-6703969',
      orderItemId: '110251735571881',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T14:59:24Z',
      buyer: 'yrzjymzyvnx8g4f@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-3601515-3210232',
      orderItemId: '110276448427081',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-25T14:42:19Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '141-8975131-5568153',
      orderItemId: '110275141076001',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-25T14:41:09Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-4624953-6741156',
      orderItemId: '110275196178681',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-25T14:27:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-7265000-9709071',
      orderItemId: '110251252811361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T12:45:14Z',
      buyer: 'lkzxc48sxnjqrrg@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-9569585-2882561',
      orderItemId: '110260681052321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T11:51:05Z',
      buyer: 'wxw5t84tb8l8j9g@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '411-9120820-9709609',
      orderItemId: '110270068324601',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-25T07:14:10Z',
      buyer: 'xgfx6sp6vf6j8v1@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-7259654-1851764',
      orderItemId: '110225233321281',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T03:33:27Z',
      buyer: 'sprh6h74fvlmkwh@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -23.4,
      referral_fees: -22.5,
      orderId: '131-1509868-8593366',
      orderItemId: '110264908722481',
      status: 'Shipped',
      quantity: 6,
      purchaseDateTime: '2024-09-25T02:08:49Z',
      buyer: 'w5p0gtfhlzp2mgd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -23.4,
      _shipping_cost: -0.48,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 149.82,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-4760299-0261893',
      orderItemId: '110265089815801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T01:57:15Z',
      buyer: '0k6qlsyq5z6c6pw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 5,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '113-3134228-5769661',
      orderItemId: '110169820960761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-25T01:10:40Z',
      buyer: '0z2z3xxpndy47vf@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 8,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7552324-8690047',
      orderItemId: '110245765543921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T22:37:03Z',
      buyer: 'l50ct9srykzl5mt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 12,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-7993423-1622646',
      orderItemId: '110167421184161',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-24T22:11:43Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '411-2655507-8674651',
      orderItemId: '110223459153161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T22:01:00Z',
      buyer: 'ptj0nvb7t9js4yr@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 13,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-9087584-2561304',
      orderItemId: '110243120990241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T21:01:32Z',
      buyer: 'ksp95yml1kwtc8l@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3425005-3816772',
      orderItemId: '110231518010121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T17:37:06Z',
      buyer: 's29hzc283g64kcw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-9443173-8131341',
      orderItemId: '110228974601481',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T17:22:29Z',
      buyer: '3lpyspvwx1pcgwp@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-1293470-3540786',
      orderItemId: '110227738640321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T17:10:48Z',
      buyer: '3qdtxd6wn7gls6y@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-9696001-0120498',
      orderItemId: '110225978007521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T14:30:25Z',
      buyer: '4yj6r9gzp66ccss@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '141-9790408-9462069',
      orderItemId: '110226155583761',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T14:30:17Z',
      buyer: '1qkmvrgwqznz9ww@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-9806603-2280101',
      orderItemId: '110192579170121',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T12:59:56Z',
      buyer: 'c5vg85qgrk0zb7k@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '112-9973873-8166646',
      orderItemId: '110214600101561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T12:58:22Z',
      buyer: '1ltfj64vdfk2dww@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-7100950-0446159',
      orderItemId: '110189499054081',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T12:21:49Z',
      buyer: 'h8vn2lk38cyfmmn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 51,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '114-7133554-1627528',
      orderItemId: '110183483632161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T09:24:34Z',
      buyer: '36p6c4ybkwq6z09@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 10,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: 0.0,
      referral_fees: 0.0,
      orderId: '114-9444398-6538117',
      orderItemId: '110126758085321',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-24T08:58:33Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: 0.0,
      _shipping_cost: 0.0,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 0.0,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-6680640-9190736',
      orderItemId: '110154696295201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T08:10:25Z',
      buyer: 'm2q20gw2bpjb5p8@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-2659810-0475471',
      orderItemId: '110122059296561',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T04:58:01Z',
      buyer: 'gdlf102cncyk9dd@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 6,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-2229213-3249584',
      orderItemId: '110146881074401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T04:44:27Z',
      buyer: '8f0ptynbs5k12cv@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '121-5492901-5666049',
      orderItemId: '110139963376721',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T04:44:21Z',
      buyer: '71l28yd7lf52s32@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '411-2216699-2318749',
      orderItemId: '110027629208201',
      status: 'Refunded',
      quantity: 1,
      purchaseDateTime: '2024-09-24T03:58:37Z',
      buyer: '53qkpgn0pwqgdhf@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 23,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-9557018-1861371',
      orderItemId: '110125017822321',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T03:04:40Z',
      buyer: 'm0sgzxlnqbzs32g@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-3855306-5450162',
      orderItemId: '110137789429441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T01:59:12Z',
      buyer: 'kykznwkd2n99zhz@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '311-4076719-7016030',
      orderItemId: '110137157156041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T01:21:45Z',
      buyer: 'p46wdl9pnq4ddtc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 5,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-0009125-2220210',
      orderItemId: '110134872812921',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T00:32:59Z',
      buyer: 'gppxrzfg8vx0x02@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '141-5714299-8255750',
      orderItemId: '110084166683241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-24T00:04:42Z',
      buyer: 'zljznrwdp20sy8k@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 4,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-6357741-1968612',
      orderItemId: '110109086821641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T23:50:26Z',
      buyer: 't8h5n7nw2jd7zmt@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 14,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-6954841-0074514',
      orderItemId: '110132785122521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T23:47:52Z',
      buyer: 'bvtl1ft829r73jb@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-9118803-5424970',
      orderItemId: '110062999317081',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-23T23:21:40Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '211-7550890-4690048',
      orderItemId: '110120115123241',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-23T23:16:54Z',
      buyer: 'tcryl1f22jdl4mn@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-9591920-3160281',
      orderItemId: '110131297250241',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T22:57:31Z',
      buyer: 'hg2fprqsg6hpdf3@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '113-5654097-6390683',
      orderItemId: '110119321051361',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T22:25:28Z',
      buyer: '3x74nsmpf7b5xly@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '112-8944802-8695092',
      orderItemId: '110086736146241',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-23T21:41:40Z',
      buyer: 'w2sdnsvrq0k8qj1@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '111-6542711-4975228',
      orderItemId: '110118476704801',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T21:23:11Z',
      buyer: 'dpfx26pt54wkkd0@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7766019-3419894',
      orderItemId: '110117418755001',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T21:14:20Z',
      buyer: 'hs38jzgf2phxw6g@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-1257834-7625414',
      orderItemId: '110093378456681',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T20:39:53Z',
      buyer: 'v5nmlh62896cxxw@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-8567785-8616151',
      orderItemId: '110114126617161',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T18:42:28Z',
      buyer: 'nkmc1jnq6cpmtfb@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 8,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '311-1828562-5206622',
      orderItemId: '110082122889401',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T17:34:57Z',
      buyer: 'm7510wrs42g5nqd@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 2,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-3771883-4979566',
      orderItemId: '110085055235521',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T16:05:19Z',
      buyer: '85bwsdcdnbtgcrg@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 3,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '112-6452398-4097207',
      orderItemId: '110064705785241',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-23T15:08:11Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '114-9791715-6350688',
      orderItemId: '110102585685841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T14:54:13Z',
      buyer: 'lls8rydhzqvgmr4@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 10,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'A554K46TJL',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -4.01,
      referral_fees: -5.99,
      orderId: '114-3281163-1959642',
      orderItemId: '110079426169201',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T14:50:35Z',
      buyer: 'yh3chrvhzn8pvz0@marketplace.amazon.com',
      sku: 'BU-4HE3-PV122',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      _production_cost: -9.8,
      _shipping_cost: -1.05,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 39.95,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '113-4036125-3039067',
      orderItemId: '110060483666041',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T14:16:10Z',
      buyer: 'k5jqm875pj6skhc@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 32,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '211-0073540-6247176',
      orderItemId: '110084342677761',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-23T13:37:55Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '311-9498847-4436869',
      orderItemId: '110059923264841',
      status: 'Canceled',
      quantity: 0,
      purchaseDateTime: '2024-09-23T12:54:23Z',
      buyer: '',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: null,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -7.8,
      referral_fees: -7.5,
      orderId: '113-4263082-4556772',
      orderItemId: '110084296734601',
      status: 'Shipped',
      quantity: 2,
      purchaseDateTime: '2024-09-23T12:47:54Z',
      buyer: 'cmjkcnrd9pn3sh5@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 22,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -7.8,
      _shipping_cost: -0.16,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 49.94,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: -2.5,
      fba_fees: -3.9,
      referral_fees: -3.37,
      orderId: '112-7857738-3412838',
      orderItemId: '110068989517641',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T11:19:59Z',
      buyer: 'kkw4d8bf6x5t1j8@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: true,
      buyerOrders: 9,
      subscribed: true,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '111-7730141-2957007',
      orderItemId: '110078237944441',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T10:36:49Z',
      buyer: 'smdfkw59ybqdjjq@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 1,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    },
    {
      asin: 'H515DFT59R',
      shipping: 0.0,
      marketplace: 3,
      discounts: 0.0,
      fba_fees: -3.9,
      referral_fees: -3.75,
      orderId: '131-8947457-4650220',
      orderItemId: '110039923708841',
      status: 'Shipped',
      quantity: 1,
      purchaseDateTime: '2024-09-23T07:43:53Z',
      buyer: '3ln8px0kl9vnh04@marketplace.amazon.com',
      sku: 'GG-51B4-DTG2',
      discounted: false,
      buyerOrders: 6,
      subscribed: false,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      _production_cost: -3.9,
      _shipping_cost: -0.08,
      _reimbursed_inventory: 0.0,
      _refunded_cogs: 0.0,
      product_sales: 24.97,
      new_tax: 0.0
    }
  ]
};

mock.onGet(API.orders.list).reply(() => {
  return [200, ordersDB];
});
