import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, Typography, Button } from '@material-ui/core';
import { isXLargeSize } from '@sm/WindowSizes';
import Colors from 'styles/colors';
import { ExclamationIcon } from '../../icons';
import { GradButton } from '../../buttons';
import Calendar from '../calendar/Calendar';
import { getNewSelection } from '../snippets';
import { isOverlap, getFullDaysRange, rangeToApiFormat } from './utils';

const DEFAULT_RANGE = { from: new Date(), to: new Date() };

const DatePickerNoOverlapDialog = ({
  loading,
  open,
  onClose,
  onConfirm,
  lifeTime,
  editedRange,
  existRanges,
  defaultRange,
  today
}) => {
  const [isSecondSelection, setIsSecondSelection] = useState(false);
  const rangeReducer = (state, range) =>
    range ? rangeToApiFormat(range) : rangeToApiFormat(defaultRange);
  const [currentRange, setCurrentRange] = useReducer(rangeReducer, rangeToApiFormat(defaultRange));

  useEffect(() => {
    if (open) {
      setCurrentRange(editedRange);
      setIsSecondSelection(false);
    }
  }, [editedRange, open]);

  const updateSelection = date => {
    const result = getNewSelection(
      date,
      getFullDaysRange(currentRange),
      '',
      today,
      lifeTime,
      isSecondSelection
    );
    if (result.range) setCurrentRange(result.range);
    setIsSecondSelection(!isSecondSelection);
  };

  const calendarProps = {
    showSelectedRangeEnd: true,
    lastAvailableDate: today,
    lifeTime,
    updateSelection,
    range: getFullDaysRange(currentRange)
  };
  const calendarContainerMinHeight = {
    largeScreen: 333,
    smallScreen: 291
  };
  const rangesIsOverlap = isOverlap(existRanges, currentRange);
  const buttonsClasses = 'text-xs font-medium h-8 w-28 leading-none';
  return (
    <Dialog PaperProps={{ className: 'pt-3' }} open={open} onClose={onClose} maxWidth={false}>
      <div className="px-4">
        <Typography className="font-medium text-gray-darkest">Select a New Date Range</Typography>
        <div className="underline-sm bg-grad-8" />
      </div>
      <div
        className="self-center"
        style={{
          minHeight: isXLargeSize()
            ? calendarContainerMinHeight.smallScreen
            : calendarContainerMinHeight.largeScreen
        }}
      >
        <Calendar {...calendarProps} />
      </div>
      <div className="sm:h-16 px-4 bg-white-gray flex items-center justify-between flex-wrap pt-2 sm:pt-0 pb-4 sm:pb-0">
        <div
          className={classNames('flex items-center w-full mb-2 sm:mb-0 sm:w-auto', {
            'opacity-0': !rangesIsOverlap
          })}
        >
          <ExclamationIcon fill={Colors.red.default} width="14px" height="14px" className="mb-px" />
          <p className="ml-2 text-sm text-red">There can be no overlaps.</p>
        </div>
        <Button
          disabled={loading}
          variant="outlined"
          className={classNames('ml-auto text-gray-dark mr-4 normal-case', buttonsClasses)}
          onClick={onClose}
        >
          Cancel
        </Button>
        <GradButton
          active={!rangesIsOverlap && !loading}
          grad="bg-grad-8"
          onClick={() => onConfirm(currentRange)}
          className={buttonsClasses}
        >
          Confirm
        </GradButton>
      </div>
    </Dialog>
  );
};

DatePickerNoOverlapDialog.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  lifeTime: PropTypes.instanceOf(Date).isRequired,
  editedRange: PropTypes.instanceOf(Object),
  today: PropTypes.instanceOf(Date),
  existRanges: PropTypes.instanceOf(Array),
  defaultRange: PropTypes.instanceOf(Object)
};
DatePickerNoOverlapDialog.defaultProps = {
  loading: false,
  editedRange: null,
  existRanges: [],
  defaultRange: DEFAULT_RANGE,
  today: new Date()
};

export default DatePickerNoOverlapDialog;
