import { put, select, takeLeading } from '@redux-saga/core/effects';
import API, { isIncompleted, isOnboarding } from '@sm/api';
import { axiosCall, sendTextError } from '@utils';
import { globalSystemNotificationSelector, loginSelector, userSelector } from '../../reducers';
import { setNotificationMsgAction } from '../../actions';
import {
  HANDLE_GLOBAL_SYSTEM_NOTIFICATION,
  setLastGlobalSystemNotificationIdAction
} from './action';

function* handleGlobalSystemNotificationSaga({ payload }) {
  try {
    const { lastShownGlobalSystemNotificationId } = yield select(globalSystemNotificationSelector);
    const { token } = yield select(loginSelector);
    const { profile } = yield select(userSelector);
    const skipNotification =
      !Object.keys(profile).length || isIncompleted(profile) || isOnboarding(profile);
    if (lastShownGlobalSystemNotificationId === payload || !token || skipNotification) return;
    const { data } = yield axiosCall.get(`${API.systemNotification}/${payload}/`);
    if (data) yield put(setNotificationMsgAction(data.text, data.type, false, false, false));
    yield put(setLastGlobalSystemNotificationIdAction(payload));
  } catch (e) {
    console.log('handleGlobalSystemNotificationSaga error', e);
    sendTextError('handleGlobalSystemNotificationSaga', e);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* globalSystemNotificationSagaWatcher() {
  yield takeLeading([HANDLE_GLOBAL_SYSTEM_NOTIFICATION], handleGlobalSystemNotificationSaga);
}
