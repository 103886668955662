import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Colors from 'styles/colors';
import { getFlagsById } from '@sm/Flags';
import { ExpandIcon } from '@components/icons';
import DateTableInput from '@components/data-table/DataTableInput';
import PlusIcon from './plus-solid.svg';
import NoteDialog from './NoteDialog';
import NotesTable from './NotesTable';

const minSearchLength = 3;

const tagSearchableFields = ['name', 'sku', 'asin'];

function searchFilter(data, keyword) {
  return data.filter(item => {
    let flag = getFlagsById(item.marketplace);
    flag = flag ? flag.name.toLowerCase().includes(keyword) : false;

    return (
      item.description.toLowerCase().includes(keyword) ||
      flag ||
      (item.product &&
        tagSearchableFields.find(
          key => item.product[key] && item.product[key].toLowerCase().includes(keyword)
        ))
    );
  });
}

function NotesList(props) {
  const refContainer = useRef(null);

  const { onNoteAdd, onNoteEdit, onNoteDelete, marketplaces, lifetime, ui } = props;
  let { data } = props;

  const [dialog, setDialog] = useState(false);
  const [extended, setExtended] = useState(false);
  const [keyword, setKeyword] = useState('');

  function onAdd(note, date, marketplace, tag) {
    setDialog(false);
    onNoteAdd(note, date, marketplace, tag);
  }

  function onEdit(id, note, date, marketplace, tag) {
    setDialog(false);
    console.log('ref container');
    console.log(refContainer);

    onNoteEdit(id, note, date, marketplace, tag, () => refContainer.current.update());
  }

  data = keyword.length >= minSearchLength ? searchFilter(data, keyword.toLowerCase()) : data;
  const openDialog = Boolean(dialog || dialog === 0);
  const isNotes = data && data.length > 0;

  return (
    <div className="flex flex-col bg-white px-6 pb-6 rounded-b">
      <div className="flex items-center justify-between sm-border-bottom-gray py-1">
        <p className="text-lg text-gray-darkest font-medium">Your Notes</p>
        <div className="flex">
          <Button
            className="flex items-center normal-case sm-w-120px"
            onClick={() => setDialog(true)}
          >
            <p className="text-yellow mr-3 text-base">Add Note</p>
            <img src={PlusIcon} alt="plus" className="h-4" />
          </Button>
          {isNotes && (
            <Button
              className="flex items-center normal-case sm-w-120px ml-2"
              onClick={() => setExtended(!extended)}
            >
              <p className="text-gray mr-3 text-base">{extended ? 'Collapse' : 'Expand'}</p>
              <ExpandIcon
                selected={extended}
                className="h-4"
                width="auto"
                fill={Colors.gray.default}
              />
            </Button>
          )}
        </div>
      </div>
      {extended && (
        <>
          <div className="flex items-center justify-end mt-2">
            <DateTableInput
              onChange={newKeyword => setKeyword(newKeyword)}
              onFocus={() => {}}
              keyword={keyword}
              placeholder="Search notes"
              ui={ui}
            />
          </div>
          {isNotes && (
            <NotesTable
              ref={refContainer}
              data={data}
              onChange={id => setDialog(id)}
              onDelete={onNoteDelete}
            />
          )}
        </>
      )}
      {openDialog && (
        <NoteDialog
          open={openDialog}
          onAdd={onAdd}
          onEdit={onEdit}
          onClose={() => setDialog(false)}
          editData={typeof dialog === 'number' && data[dialog]}
          marketplaces={marketplaces}
          lifetime={lifetime}
        />
      )}
    </div>
  );
}

NotesList.propTypes = {
  onNoteAdd: PropTypes.func.isRequired,
  onNoteDelete: PropTypes.func.isRequired,
  onNoteEdit: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
  ui: PropTypes.instanceOf(Object).isRequired,
  marketplaces: PropTypes.instanceOf(Array).isRequired,
  lifetime: PropTypes.instanceOf(Date).isRequired
};

NotesList.defaultProps = {
  data: null
};

export default NotesList;
