/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoadingSpinner from '@components/common/LoadingSpinner';
import { DEFAULT_OBSERVER_TIMEOUT } from './const';

const getImgLoadPromise = img => {
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img.height);
    img.onerror = reject;
  });
};

const getTimeoutPromise = timeout =>
  new Promise(resolve => setTimeout(() => resolve('timeout'), timeout));

const ImageObserver = ({
  children,
  timeout,
  spinnerProps,
  className,
  loadingClassname,
  ...props
}) => {
  const rootRef = useRef(null);
  const componentIsMounted = useRef(true);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const imgs = rootRef.current?.getElementsByTagName('img') || [];
    const imgsPromises = [...imgs].map(img => getImgLoadPromise(img));
    const onLoad = () => {
      if (componentIsMounted.current) setIsLoading(false);
    };
    Promise.race([Promise.all(imgsPromises), getTimeoutPromise(timeout)])
      .then(onLoad)
      .catch(onLoad);
    return () => {
      componentIsMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <LoadingSpinner {...spinnerProps} />}
      <div
        ref={rootRef}
        className={classNames(className, {
          'opacity-0': isLoading,
          [loadingClassname]: isLoading,
          [className]: !isLoading
        })}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

ImageObserver.propTypes = {
  children: PropTypes.node.isRequired,
  timeout: PropTypes.number
};

ImageObserver.defaultProps = {
  timeout: DEFAULT_OBSERVER_TIMEOUT
};

export default ImageObserver;
