import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const FlatButton = forwardRef((props, ref) => {
  const { children, onClick, disabled, ...others } = props;
  return (
    <div
      ref={ref}
      role="button"
      tabIndex="0"
      onClick={disabled ? () => {} : onClick}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onClick(e);
        }
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick(e);
        }
      }}
      {...others}
    >
      {children}
    </div>
  );
});

FlatButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

FlatButton.defaultProps = {
  disabled: false
};

export default FlatButton;
