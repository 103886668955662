import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from '@material-ui/core';
import { AddFilterButton, GradButton } from '../buttons';

const FilterWidget = ({
  activeFiltersView,
  addFilterButtonOnClick,
  filterPopover,
  filters,
  onApply,
  onResetFilters
}) => {
  return (
    <div className="flex flex-row flex-1 items-center mr-6 min-w-0">
      <div className="hidden sm:flex flex-row overflow-auto invisible-scroll-container max-w-3/4 mr-auto">
        {activeFiltersView}
      </div>
      <AddFilterButton onClick={addFilterButtonOnClick} />
      <Popover
        open={!!filterPopover.target}
        onClose={filterPopover.clearTarget}
        anchorEl={filterPopover.target}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="mt-5"
      >
        <div className="p-4 flex flex-col">
          {filters}
          <GradButton className="h-10 mt-4 text-sm" onClick={onApply}>
            Apply Filter
          </GradButton>
          <Button
            variant="outlined"
            className="sm:hidden h-10 text-gray-dark mt-4 normal-case text-sm leading-none"
            onClick={onResetFilters}
          >
            Reset filters
          </Button>
        </div>
      </Popover>
    </div>
  );
};

FilterWidget.propTypes = {
  activeFiltersView: PropTypes.node.isRequired,
  addFilterButtonOnClick: PropTypes.func.isRequired,
  filterPopover: PropTypes.instanceOf(Object).isRequired,
  filters: PropTypes.node.isRequired,
  onApply: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired
};

export default FilterWidget;
