import React from 'react';

import classNames from 'classnames';
import { Input } from '@material-ui/core/';

const CustomInput = props => {
  const {
    onFocus,
    onBlur,
    onEnter,
    value,
    onChange,
    inputClassName,
    isEditPanelActive,
    ...others
  } = props;

  const inputClass = classNames({
    'p-0 h-4 border-bottom-gray-dotted text-sm w-16': !isEditPanelActive,
    'h-8 rounded border-gray-default-no-important text-sm px-4 py-1': isEditPanelActive
  });

  const inputValue = value === null ? '' : value;

  return (
    <Input
      {...others}
      type="text"
      value={inputValue}
      onChange={onChange}
      onKeyPress={e => {
        if (e.key === 'Enter' && onEnter) onEnter();
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      classes={{
        root: inputClass,
        focused: 'rounded h-8 border-yellow-default',
        input: inputClassName || 'text-right',
        disabled: 'p-0 h-4 border-bottom-gray-dotted text-sm'
      }}
      disableUnderline
    />
  );
};

export default CustomInput;
