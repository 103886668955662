import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core';
import MWS_TOKEN_MIN_LENGTH from '@sm/mws';
import Colors from 'styles/colors';
import TextFieldIcon from '../inputs/TextFieldIcon';
import CancelConfirmButtons from '../buttons/CancelConfirmButtons';

const inputStyle = () => ({
  formControl: {
    left: 0,
    top: '-0.1rem',
    fontSize: '0.875rem !important'
  },
  root: {
    '& label.Mui-focused': {
      color: Colors.yellow.default
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: Colors.yellow.default
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: Colors.gray.default
      },
      '&.Mui-focused fieldset': {
        borderColor: Colors.yellow.default
      }
    }
  }
});

const CssTextField = withStyles(inputStyle)(TextFieldIcon);

const relinkDialogStyle = {
  wrapper: {
    width: '23.75rem'
  },
  button: {
    width: '7.5rem'
  }
};

function RelinkDialog(props) {
  const [token, setToken] = useState(null);
  const { onClose, sellerId, onTokenAdd, open, hideCancelButton } = props;

  function onConfirmClick() {
    onTokenAdd(token);
    onClose();
  }

  const confirmActive = token && token.length >= MWS_TOKEN_MIN_LENGTH;

  return (
    <Dialog onClose={onClose} className="p-4 rounded" open={open}>
      <div className="p-4 flex flex-col" style={relinkDialogStyle.wrapper}>
        <div className="flex flex-col">
          <p className="text-black font-medium text-base">Re-link Seller Central Account</p>
          <div className="bg-grad-2 underline-sm" />
        </div>
        <p className="text-xs text-gray-dark mt-4 mb-2">
          Something happen and we lost access to your Seller Central account. Just input a new MWS
          Auth Token so we can validate it. You’ll be ready in not time.
        </p>
        {sellerId && <p className="text-sm text-gray-darkest mb-2">Seller ID: {sellerId}</p>}
        <CssTextField
          inputProps={{ className: 'text-sm h-3 m-0' }}
          variant="outlined"
          label="Enter MWS Auth Token here"
          className="mt-4"
          value={token}
          onChange={event => setToken(event.currentTarget.value)}
        />
        <CancelConfirmButtons
          className="mt-12"
          onConfirm={onConfirmClick}
          onCancel={onClose}
          confirmActive={confirmActive}
          hideCancelButton={hideCancelButton}
        />
      </div>
    </Dialog>
  );
}

RelinkDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sellerId: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  onTokenAdd: PropTypes.func.isRequired
};

RelinkDialog.defaultProps = {
  sellerId: '',
  hideCancelButton: false
};

export default RelinkDialog;
