import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Colors from 'styles/colors';
import { isXLargeSize } from '@sm/WindowSizes';

export const INPUT_ROOT_STYLES = {
  '& label.Mui-focused': {
    color: Colors.yellow.default
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: Colors.yellow.default
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.gray.default
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.yellow.default
    },
    '& :-webkit-autofill': {
      '-webkit-box-shadow': `0 0 0 30px ${Colors.white.default} inset !important`
    }
  }
};
const styles = () => ({
  root: INPUT_ROOT_STYLES,
  formControl: {
    left: '1.875rem',
    top: isXLargeSize() ? '-0.2rem' : '0rem'
  },
  inputLabel: {
    fontSize: '0.875rem',
    '&.shrink': {
      left: 0,
      top: 0
    }
  },
  disabled: {}
});

const TextFieldIcon = props => {
  const [shrink, setShrink] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const {
    classes,
    endIcon,
    autoComplete,
    startIcon,
    label,
    onFocus,
    onBlur,
    value,
    ...other
  } = props;

  function shrinkLabel(event) {
    setShrink(true);
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  }

  function unShrinkLabel(event) {
    setIsFocused(false);
    if (event.target.value.length === 0) {
      setShrink(false);
    }
    if (onBlur) {
      onBlur(event);
    }
  }

  useEffect(() => {
    if ((value && value.length !== 0) || isFocused) {
      setShrink(true);
    } else {
      setShrink(false);
    }
  }, [isFocused, value]);

  return (
    <TextField
      {...other}
      value={value}
      classes={classes}
      onFocus={shrinkLabel}
      onBlur={unShrinkLabel}
      InputLabelProps={{
        shrink,
        classes: { ...classes, root: classes.inputLabel, focused: 'focused', shrink: 'shrink' }
      }}
      label={label}
      variant="outlined"
      InputProps={{
        autoComplete,
        endAdornment: endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>,
        startAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>
      }}
    />
  );
};

export default withStyles(styles)(TextFieldIcon);
