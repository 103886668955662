import { put, select, takeEvery } from '@redux-saga/core/effects';
import API from '@sm/api';
import { axiosCall } from '@utils';
import { prepareInventoryProducts } from 'seller/utils/convertRawApiData';
import { selectorSelector, userSelector } from '../../reducers';
import { setNotificationMsgAction } from '../../actions';
import { PULL_INVENTORY, setInventoryAction, setInventoryLoadingAction } from './action';

export function* pullInventorySaga() {
  yield put(setInventoryLoadingAction(true));
  try {
    const { userId } = yield select(userSelector);
    const { marketplaceID, productStatus: status } = yield select(selectorSelector);
    const { data } = yield axiosCall.get(API.products.inventory, {
      params: { userId, marketplaceID, status }
    });
    yield put(
      setInventoryAction({ totals: data.totals, products: prepareInventoryProducts(data.products) })
    );
  } catch (e) {
    console.log('pullInventorySaga error', e);
    yield put(setNotificationMsgAction('Pull inventory failed'));
  } finally {
    yield put(setInventoryLoadingAction(false));
  }
}

export function* inventorySagaWatcher() {
  yield takeEvery(PULL_INVENTORY, pullInventorySaga);
}
