import { GLOBAL_MK } from '@sm/Flags';
import {
  SET_AGENCY_CLIENTS,
  SET_AGENCY_CLIENTS_FETCHING,
  SET_AGENCY_CLIENTS_DATA,
  SET_AGENCY_MARKETPLACES,
  SET_AGENCY_CLIENT_SEARCH_KEYWORD,
  SET_SELECTED_AGENCY_CLIENTS
} from '../actions';

const initialState = {
  clients: [],
  clientsFetching: false,
  clientsData: null,
  marketplaces: [GLOBAL_MK],
  searchKeyword: null,
  selectedClients: []
};
export const agencySelector = state => state.agency;

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENCY_CLIENTS_DATA: {
      return { ...state, clientsData: action.payload };
    }
    case SET_AGENCY_CLIENT_SEARCH_KEYWORD: {
      return { ...state, searchKeyword: action.payload };
    }
    case SET_SELECTED_AGENCY_CLIENTS: {
      return { ...state, selectedClients: action.payload };
    }
    case SET_AGENCY_MARKETPLACES: {
      return { ...state, marketplaces: action.payload };
    }
    case SET_AGENCY_CLIENTS: {
      const { clients } = action;
      return { ...state, clients: [...clients], clientsFetching: false };
    }
    case SET_AGENCY_CLIENTS_FETCHING: {
      return { ...state, clientsFetching: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default dashboard;
