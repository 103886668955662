import React, { useState } from 'react';

import Dots from 'material-ui-dots';
import SwipeableViews from 'react-swipeable-views';
import ProTipImg from './pro-tip-img1.png';

const proTipsData = [
  {
    img: ProTipImg,
    name: 'Pro Tip 1',
    description:
      'Set your Production & Shipping CoGS to get an accurate profit calculation in Seller Metrix.'
  },
  {
    img: ProTipImg,
    name: 'Pro Tip 2',
    description: 'Go to the Product Section to find the profit for each of your products.'
  },
  {
    img: ProTipImg,
    name: 'Pro Tip 3',
    description:
      'You can search and select any of your products to see how they performed over time.'
  },
  {
    img: ProTipImg,
    name: 'Pro Tip 4',
    description:
      'You can click on columns to arrange the list in Seller Metrix. For example, click on the "Refunds" column to find your products with the most Refunds.'
  }
];

function ProTipsView() {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = newIndex => {
    setIndex(newIndex);
  };

  function getSlideComponent(slide) {
    return (
      <div key={slide.name} className="flex flex-col items-center z-0">
        <img src={slide.img} alt="sliderImg " className="mb-8 sm-h-300px" />
        <p className="font-medium text-2xl mb-2">{slide.name}</p>
        <p className="text-center text-base text-gray-black m w-7/12">{slide.description}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        springConfig={{
          duration: '0.8s',
          easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
          delay: '0s'
        }}
      >
        {proTipsData.map(slide => {
          return getSlideComponent(slide);
        })}
      </SwipeableViews>
      <Dots
        classes={{
          dot: 'bg-yellow'
        }}
        index={index}
        count={proTipsData.length}
        onDotClick={newIndex => setIndex(newIndex)}
      />
    </div>
  );
}

export default ProTipsView;
