import { axiosCall } from '@utils';
import API from '@sm/api';

export const getShopifyCallbackData = location => {
  const query = new URLSearchParams(location.search);
  const shopifyHmac = query.get('shopify_hmac');
  const shopifyShopUrl = query.get('shopify_shop_url');
  if (!shopifyShopUrl || !shopifyHmac) return null;
  return { shopify_hmac: shopifyHmac, shopify_shop_url: shopifyShopUrl };
};

// eslint-disable-next-line import/prefer-default-export
export const addShopifyShop = async (location, history) => {
  try {
    const shopifyCallbackData = getShopifyCallbackData(location);
    if (!shopifyCallbackData) return false;
    await axiosCall.post(API.shopify.shop, shopifyCallbackData);
    history.replace(history.location.pathname);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
