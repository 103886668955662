import { UNKNOWN_PRODUCT } from '@sm/api';
import {
  BUYER_AVAILABLE_WHEN_SHIPPED,
  BUYER_NOT_AVAILABLE,
  CANCELED,
  PENDING,
  SHIPPED,
  UNSHIPPED
} from '../pages/orders/views';
import { EXPENSE_TYPES } from '../pages/expenses/consts';
import {
  getTotalsForBreakdown,
  sumOfSafeValues
} from '../pages/dashboard/prepareModernBreakdownData';
import {
  getCurrencyAbbreviationByMk,
  getCurrencySymbolByCurrencyName
} from '../containers/GetCurrency';

export const prepareProductList = list =>
  list.map(product => {
    const currency = getCurrencyAbbreviationByMk(product.marketplace);
    return {
      ...product,
      name: product.name || UNKNOWN_PRODUCT,
      inventory: (product.fba || 0) + (product.fbm || 0),
      currency,
      currency_symbol: getCurrencySymbolByCurrencyName(currency)
    };
  });

export const prepareInventoryProducts = products => {
  if (!products || !products.length) return products;
  const daysInWeek = 7;
  const daysInMonth = 30;
  return products.map(product => {
    return {
      ...product,
      inventory_count: product.fba + product.fbm,
      dayVelocity:
        product.sales_velocity_30d && (product.sales_velocity_30d / daysInMonth).toFixed(2),
      weekVelocity:
        product.sales_velocity_30d &&
        ((product.sales_velocity_30d / daysInMonth) * daysInWeek).toFixed(2),
      monthVelocity: product.sales_velocity_30d,
      days_remaining: product.days_remaining && Math.floor(product.days_remaining)
    };
  });
};

export const prepareBreakdownData = data => ({
  ...data,
  total_sf: (data.ltsf_12_months || 0) + (data.ltsf_6_months || 0)
});

export const prepareOrders = orders =>
  orders.map(order => {
    const { status, name: _name } = order;
    const name = _name || UNKNOWN_PRODUCT;
    let { buyer } = order;
    if (!buyer) {
      if (status === PENDING || status === UNSHIPPED) {
        buyer = BUYER_AVAILABLE_WHEN_SHIPPED;
      } else if (status === SHIPPED) {
        buyer = BUYER_NOT_AVAILABLE;
      }
    }
    let amount = sumOfSafeValues([
      order.product_sales,
      order.discounts,
      order.new_tax,
      order.shipping
    ]);
    if (order.status === CANCELED) amount = 0;
    return { ...order, buyer, name, amount };
  });

const prepareProduct = product => {
  const result = {
    ...getTotalsForBreakdown(product),
    name: product.name || UNKNOWN_PRODUCT
  };
  if (result.children) result.children = result.children.map(prepareProduct);
  return result;
};

export const prepareSales = sales => ({
  ...sales,
  products: sales.products?.map(prepareProduct)
});

export const prepareRefunds = refunds =>
  refunds.map(refund => ({ ...refund, name: refund.name || UNKNOWN_PRODUCT }));

export const prepareProfitAndLoss = response => ({
  ...response,
  data: response.data.map((row, index) => ({
    ...row,
    recordId: index,
    children: row.children?.map(child => ({ ...child, type: 'child' }))
  }))
});

export const prepareExpenses = data => [
  ...data.custom_expenses.map(item => ({ ...item, expenseType: EXPENSE_TYPES.custom })),
  ...data.amazon_expenses.map(item => ({ ...item, expenseType: EXPENSE_TYPES.amazon }))
];
