import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, withStyles } from '@material-ui/core';

const VideoDialogBase = withStyles({
  paper: {
    width: '60rem',
    height: '33.75rem'
  }
})(Dialog);

function VideoDialog(props) {
  const { open, onClose, src } = props;

  return (
    <VideoDialogBase open={open} onClose={onClose} maxWidth="xl">
      <iframe
        className="w-full h-full"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="youtube-video"
      />
    </VideoDialogBase>
  );
}

VideoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired
};

VideoDialog.defaultProps = {
  open: false
};

export default VideoDialog;
