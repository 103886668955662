import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import GenerateInputs from '@components/inputs/GenerateInputs';
import { setupNewPasswordAction } from 'seller/store/saga/login';
import ROUTES from 'seller/routing';
import { addInputFocusHandler } from '@utils';
import { LockIcon } from '@components';
import logo from '@components/common/logo.png';
import ProTipsView from '@components/pro-tips-view';
import { useTextInput } from '../profile/Hooks';

const getActionDataFromPath = path => {
  try {
    const [token, userId] = path.split('/').reverse();
    return { token, userId };
  } catch (e) {
    return null;
  }
};

const SetupPasswordPage = ({ location }) => {
  const dispatch = useDispatch();
  const newPassword = useTextInput('');
  const confirmPassword = useTextInput('');
  const [passwordChanged, setPasswordChanged] = useState(null);
  const inputRefs = useRef([]);

  const actionData = getActionDataFromPath(location.pathname);

  const inputIsValid = newPassword.value && newPassword.value === confirmPassword.value;

  const onPasswordChange = () => {
    if (!inputIsValid) return;
    dispatch(
      setupNewPasswordAction({
        password: newPassword.value,
        userId: actionData?.userId,
        token: actionData?.token,
        callback: success => setPasswordChanged(success)
      })
    );
  };

  const inputsList = [
    {
      label: 'Insert new password',
      type: 'password',
      icon: LockIcon,
      props: { ...newPassword, inputProps: { autoComplete: 'new-password' } }
    },
    {
      label: 'Confirm new password',
      type: 'password',
      icon: LockIcon,
      props: { ...confirmPassword, inputProps: { autoComplete: 'new-password' } }
    }
  ];

  addInputFocusHandler(inputsList, onPasswordChange, inputRefs);

  const changePasswordForm = (
    <>
      <p className="text-gray-dark text-base mb-4">
        Please enter your new password twice so we can verify ypu typed it in correctly.
      </p>
      <form className="w-full">
        <GenerateInputs data={inputsList} />
        <Button
          variant="contained"
          className={classNames('w-full p-2 mt-6 text-white capitalize text-sm', {
            'bg-grad-2': inputIsValid
          })}
          disable={!inputIsValid}
          onClick={onPasswordChange}
        >
          Change My Password
        </Button>
      </form>
    </>
  );
  const passwordChangedContent = (
    <>
      <p className="text-gray-dark text-base mb-4 self-start">Password successfully changed.</p>
      <Link
        component={RouterLink}
        to={ROUTES.login}
        color="inherit"
        className="text-yellow self-start"
      >
        Go to Login
      </Link>
    </>
  );
  const passwordChangeFailedContent = (
    <>
      <p className="text-gray-dark text-base mb-4 self-start">Setup new password failed.</p>
      <Link
        component={RouterLink}
        to={ROUTES.resetPassword}
        color="inherit"
        className="text-yellow self-start"
      >
        Go to Forgot my Password
      </Link>
    </>
  );

  const getContent = () => {
    if (passwordChanged === null) return changePasswordForm;
    if (!passwordChanged) return passwordChangeFailedContent;
    return passwordChangedContent;
  };

  return (
    <div className="flex bg white w-full h-full">
      <div className="flex flex-col items-center justify-center bg-white md:w-2/4 w-full box-shadow-default min-h-screen z-0">
        <div className="flex flex-col items-center sm-w-300px">
          <img src={logo} alt="logo" className="sm-w-160px" />
          <div className="flex items-center w-full mt-16 mb-3">
            <p className="text-2xl font-bold  mr-auto text-black">Setup New Password</p>
          </div>
          {getContent()}
        </div>
      </div>
      <div className="w-2/4 flex justify-center items-center bg-white-blue hidden md:flex">
        <ProTipsView />
      </div>
    </div>
  );
};

SetupPasswordPage.propTypes = { location: PropTypes.instanceOf(Object).isRequired };

export default withRouter(SetupPasswordPage);
