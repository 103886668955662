export const SET_ORDERS = 'SET_ORDERS';
export const START_ORDERS_LOADING = 'START_ORDERS_LOADING';
export const PULL_ORDERS = 'PULL_ORDERS';
export const PULL_ORDER_DETAILS = 'PULL_ORDER_DETAILS';
export const SET_ORDERS_PAGE_ORDER_EVENT_TYPE = 'SET_ORDERS_PAGE_ORDER_EVENT_TYPE';

export const setOrdersAction = ordersList => ({
  type: SET_ORDERS,
  ordersList
});

export const startOrdersLoadingAction = () => ({ type: START_ORDERS_LOADING });

export const pullOrdersAction = () => ({ type: PULL_ORDERS });

export const setOrdersPageOrderEventTypeAction = payload => ({
  type: SET_ORDERS_PAGE_ORDER_EVENT_TYPE,
  payload
});

export const pullOrderDetailsAction = payload => ({ type: PULL_ORDER_DETAILS, payload });
