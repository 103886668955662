import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import GradButton from '../buttons/GradButton';

const ConfirmDialog = ({ message, description, onConfirm, onCancel, confirmText }) => {
  return (
    <div className="px-6 pb-6 pt-4">
      <Typography align="left" className="font-medium text-lg text-gray-black">
        {message}
      </Typography>
      <div className="underline-sm bg-grad-8" />
      {description && (
        <Typography className="text-sm text-gray-black text-left mt-4">{description}</Typography>
      )}
      <div className="flex justify-center mt-6">
        <Button
          variant="text"
          className="text-gray-dark mr-4 normal-case h-8 leading-none"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <GradButton className="text-sm h-8" active onClick={onConfirm}>
          <div className="flex items-center">{confirmText}</div>
        </GradButton>
      </div>
    </div>
  );
};

ConfirmDialog.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

ConfirmDialog.defaultProps = {
  confirmText: 'Save',
  description: ''
};

export default ConfirmDialog;
