import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '@utils';
import AutoSizer from 'react-virtualized-auto-sizer';
import ProductsList from './ProductsList';

/* eslint-disable no-param-reassign */
const setMetadata = data => {
  if (data && data.length > 0) {
    data[0].isFirst = true;
    data[data.length - 1].isLast = true;
  }
};

const getSection = name => {
  return { type: 'title', name };
};

function ProductsSearchResult({ data, selected, onUpdate, onAccept, onCancel, hideCancelConfirm }) {
  let readyData = [];

  const isSelected = object => {
    return selected.find(item => item.id === object.id && item.type === object.type) != null;
  };

  if (data && data.brands && data.parent_products && data.products) {
    setMetadata(data.brands);
    setMetadata(data.parent_products);
    setMetadata(data.products);

    data.brands.forEach(object => {
      object.type = 'brand';
      object.selected = isSelected(object);
    });
    data.parent_products.forEach(object => {
      object.type = 'parent';
      object.selected = isSelected(object);
    });
    data.products.forEach(object => {
      object.type = object.variation_type_one ? 'variation' : 'default';
      object.selected = isSelected(object);
    });

    if (!isEmpty(data.brands)) {
      readyData = [getSection('Brands'), ...data.brands];
    }

    if (!isEmpty(data.parent_products)) {
      readyData = [...readyData, getSection('Parents'), ...data.parent_products];
    }

    if (!isEmpty(data.products)) {
      readyData = [...readyData, getSection('Products'), ...data.products];
    }
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <ProductsList
          data={readyData}
          axis="vertical"
          width={width}
          height={height}
          className="scroll-container"
          onCheck={onUpdate}
          onCancel={onCancel}
          onAccept={onAccept}
          hideCancelConfirm={hideCancelConfirm}
        />
      )}
    </AutoSizer>
  );
}

ProductsSearchResult.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  hideCancelConfirm: PropTypes.bool.isRequired
};

export default ProductsSearchResult;
