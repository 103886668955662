export const getFilter = (searchableFields, extraKey) => (data, keyword) => {
  const checkField = (row, field) => {
    const value = extraKey ? row?.[extraKey]?.[field] : row?.[field];
    return `${value}`.toLowerCase().includes(keyword.toLowerCase());
  };
  const checkFields = item => searchableFields.find(fieldName => checkField(item, fieldName));
  return data.filter(checkFields);
};

export const boolToText = value => (value ? 'Yes' : 'No');
export const textToBool = value => value === 'Yes';

export const BINARY_OPTIONS = ['Yes', 'No'];
