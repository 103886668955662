import React from 'react';
import PropTypes from 'prop-types';

function NewTabReference(props) {
  const { children, ...others } = props;
  return (
    <a target="_blank" rel="noopener noreferrer" {...others}>
      {children}
    </a>
  );
}

NewTabReference.propTypes = {
  children: PropTypes.node.isRequired
};

export default NewTabReference;
