export const PULL_REPORTS_LIST = 'PULL_REPORTS_LIST';
export const SET_REPORTS_LIST = 'SET_REPORTS_LIST';
export const POST_REPORT_REQUEST = 'POST_REPORT_REQUEST';
export const SET_REPORTS_FETCHING = 'SET_REPORTS_FETCHING';
export const PULL_REPORT_DETAILS = 'PULL_REPORT_DETAILS';
export const SET_REPORT_DETAILS = 'SET_REPORT_DETAILS';
export const SET_REPORTS_LIST_ACTIVE_TAB = 'SET_REPORTS_LIST_ACTIVE_TAB';
export const DELETE_REPORT = 'DELETE_REPORT';
export const RECALCULATE_REPORT = 'RECALCULATE_REPORT';
export const RENAME_REPORT = 'RENAME_REPORT';
export const ADD_REPORT_IN_PROCESSING = 'ADD_REPORT_IN_PROCESSING';
export const REMOVE_REPORT_IN_PROCESSING = 'REMOVE_REPORT_IN_PROCESSING';
export const SET_REPORT_IN_PROGRESS = 'SET_REPORT_IN_PROGRESS';

export const pullReportsListAction = payload => ({ type: PULL_REPORTS_LIST, payload });

export const setReportsListAction = payload => ({ type: SET_REPORTS_LIST, payload });

export const postReportRequestAction = payload => ({ type: POST_REPORT_REQUEST, payload });

export const setReportsFetchingAction = payload => ({ type: SET_REPORTS_FETCHING, payload });

export const pullReportDetailsAction = payload => ({ type: PULL_REPORT_DETAILS, payload });

export const setReportDetailsAction = payload => ({ type: SET_REPORT_DETAILS, payload });

export const recalculateReportAction = payload => ({ type: RECALCULATE_REPORT, payload });

export const renameReportAction = payload => ({ type: RENAME_REPORT, payload });

export const setReportsListActiveTabAction = payload => ({
  type: SET_REPORTS_LIST_ACTIVE_TAB,
  payload
});

export const deleteReportAction = payload => ({ type: DELETE_REPORT, payload });

export const addReportInProcessingAction = payload => ({ type: ADD_REPORT_IN_PROCESSING, payload });

export const removeReportInProcessingAction = payload => ({
  type: REMOVE_REPORT_IN_PROCESSING,
  payload
});

export const setReportInProgressAction = payload => ({ type: SET_REPORT_IN_PROGRESS, payload });
