import API from '@sm/api';
import mock from '../mock';

const campaigns = [
  {
    id: 22,
    api_campaign_id: 93009192166262,
    campaign_name:
      'Activene PRC Video (Exact2) Activene PRC Video (Exact2) Activene PRC Video (Exact2) Activene PRC Video (Exact2)',
    targeting_type: 'Auto',
    state: 'paused',
    budget: 41231200.0,
    budget_type: 'lifetime',
    start_date: '2021-04-19',
    end_date: null,
    created_on: '2022-02-04T05:43:43.180055Z',
    updated_on: '2022-02-04T05:43:43.180059Z',
    advertiser_marketplace_profile: 1,
    ad_format: 'video',
    linked_products_count: 0
  },
  {
    id: 23,
    api_campaign_id: 93009192166262,
    campaign_name:
      'Activene XXX PRC Video (Exact2) Activene PRC Video (Exact2) Activene PRC Video (Exact2) Activene PRC Video (Exact2)',
    targeting_type: 'Auto',
    state: 'archived',
    budget: 400.0,
    budget_type: 'lifetime',
    start_date: '2021-04-19',
    end_date: null,
    created_on: '2022-02-04T05:43:43.180055Z',
    updated_on: '2022-02-04T05:43:43.180059Z',
    advertiser_marketplace_profile: 1,
    ad_format: 'productCollection',
    linked_products_count: 1
  },
  {
    id: 21,
    api_campaign_id: 241731265107783,
    campaign_name: 'Activene PRC Video (Exact)',
    targeting_type: 'Manual',
    state: 'enabled',
    budget: 80.0,
    budget_type: 'daily',
    start_date: '2021-04-17',
    end_date: null,
    created_on: '2022-02-04T05:43:43.180032Z',
    updated_on: '2022-02-04T05:43:43.180036Z',
    advertiser_marketplace_profile: 1,
    ad_format: 'productCollection',
    linked_products_count: 12
  }
];

const campaignDetails = {
  id: 22,
  api_campaign_id: 93009192166262,
  campaign_name:
    'Activene PRC Video (Exact2) Activene PRC Video (Exact2) Activene PRC Video (Exact2) Activene PRC Video (Exact2)',
  targeting_type: 'Auto',
  state: 'paused',
  budget: 40123120.0,
  budget_type: 'lifetime',
  start_date: '2021-04-19',
  end_date: null,
  created_on: '2022-02-04T05:43:43.180055Z',
  updated_on: '2022-02-04T05:43:43.180059Z',
  advertiser_marketplace_profile: 1,
  ad_format: 'sponsored_brands',
  products: [
    {
      id: 1,
      brand: 'Zegaro',
      marketplace: 3,
      asin: 'B07JHYD2PL',
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | Black",
      smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
      sku: 'EBC02-MB',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 2,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      brand: 'Zegaro',
      asin: 'B07JHYT5VH',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | Black",
      variation_value_one: 'Midnight Black',
      smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
      color: 'Midnight Black',
      sku: 'EBC02-MB',
      variation_type_two: 'Size',
      variation_value_two: 'Large'
    },
    {
      id: 3,
      parent_asin: 'B07JHYD2PL',
      variation_type_one: 'Color',
      variation_value_one: 'Cloud White',
      variation_type_two: 'Size',
      variation_value_two: 'Large',
      brand: 'Zegaro',
      asin: 'B07JJGXVN5',
      marketplace: 3,
      name:
        "Zegaro Digital Alarm Clock for Bedroom - Large 4.2'' LCD Display with Blue LED Back Light (2019 Model) | Big Numbers, Date & Temperature | Easy to Use, Battery Operated | Stainless Steel Case | White",
      smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
      sku: 'EBC02-CW'
    }
  ]
};

mock.onGet(new RegExp(`${API.advertising.campaigns}.+/`)).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  return [200, campaignDetails];
});

mock.onGet(API.advertising.campaigns).reply(async () => {
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  return [200, campaigns];
});

mock.onPatch(new RegExp(`${API.advertising.campaigns}.+/`)).reply(async config => {
  const data = JSON.parse(config.data);
  console.log('config', config);
  console.log('onPatch data', data);
  await new Promise(resolve => setTimeout(() => resolve(), 500));
  return [200];
});
