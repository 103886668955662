import { SET_BILLING_INFO } from '../actions';

const initialState = {
  billingInfo: {}
};

const billing = function(state = initialState, action) {
  switch (action.type) {
    case SET_BILLING_INFO: {
      const { billingInfo } = action;
      return {
        ...state,
        billingInfo
      };
    }
    default: {
      return state;
    }
  }
};

export default billing;
