import { put, select, takeLatest, takeLeading, call } from '@redux-saga/core/effects';
import { axiosCall, DECIMAL_SCALE, getTempId } from '@utils';
import API, { rangeToAPI, selectedProductsToAPI } from '@sm/api';
import { prepareExpenses } from 'seller/utils/convertRawApiData';
import { allocationsToStr } from 'seller/pages/expenses/utils';
import {
  addProcessingExpenseAction,
  CREATE_EXPENSE,
  DELETE_EXPENSE,
  PULL_EXPENSE_DETAILS,
  PULL_EXPENSES_LIST,
  removeProcessingExpenseAction,
  setExpenseDetailsAction,
  setExpenseProcessingAction,
  setExpensesFetchingAction,
  setExpensesListAction,
  UPDATE_EXPENSE_DETAILS
} from './action';
import { setNotificationMsgAction, setSuccessMsgAction } from '../../actions';
import { expensesSelector, selectorSelector } from '../../reducers';

const SKIP_CLEAN_UP_ACTION = { skipCleanUp: true };

function* pullExpensesListSaga(action) {
  try {
    const { showAll } = yield select(expensesSelector);
    const skipCleanUp = action?.skipCleanUp;
    const tempId = action?.tempId;
    const requestParams = action?.params || {};
    const callback = action?.callback;
    if (!skipCleanUp) {
      yield put(setExpensesFetchingAction({ list: true }));
      yield put(setExpensesListAction({ data: [] }));
    }
    const { marketplaceID, dateRange } = yield select(selectorSelector);
    const params = { marketplaceID, ...rangeToAPI(dateRange), ...requestParams };
    if (showAll) params.show_all = showAll;
    const { data } = yield axiosCall.get(API.expenses.root, { params });
    if (callback) callback(data);
    yield put(setExpensesListAction({ data: prepareExpenses(data), tempId }));
  } catch (e) {
    console.log('pullExpensesListSaga', e);
    yield put(setNotificationMsgAction('Pull expenses failed'));
  } finally {
    yield put(setExpensesFetchingAction({ list: false }));
  }
}

function* pullExpenseDetailsSaga({ payload }) {
  try {
    const { expenseData, mode, callback } = payload;
    const { expenseType, id } = expenseData;
    const { data } = yield axiosCall.get(`${API.expenses.root}${id}/`);
    const result = { ...expenseData, ...data, mode, expenseType };
    const isCoupon = !!expenseData.couponComponents;
    if (isCoupon) {
      result.start = expenseData.start;
      result.end = expenseData.end;
      result.total_amount = expenseData.total_amount.toFixed(DECIMAL_SCALE);
    }
    yield put(setExpenseDetailsAction(result));
    if (callback) callback();
  } catch (e) {
    console.log('pullExpenseDetailsSaga', e);
    yield put(setNotificationMsgAction('Pull expense details failed'));
  }
}

function* deleteExpenseSaga({ payload }) {
  try {
    const { id } = payload;
    yield put(setExpenseProcessingAction({ id, value: true }));
    yield axiosCall.delete(`${API.expenses.root}${id}/`);
    yield call(pullExpensesListSaga, SKIP_CLEAN_UP_ACTION);
    yield put(setSuccessMsgAction('Expense deleted successfully'));
  } catch (e) {
    console.log('deleteExpenseSaga', e);
    yield put(setNotificationMsgAction('Delete expense failed'));
  }
}

function* createExpenseSaga({ payload }) {
  const tempId = getTempId();
  try {
    const { data, products, callback, needToChangeSelectorDateRange } = payload;
    const processingExpenseData = {
      ...data,
      allocation: allocationsToStr(data),
      id: tempId,
      product_count: products.length,
      processing: true,
      temp: true
    };
    if (!needToChangeSelectorDateRange) {
      yield put(addProcessingExpenseAction(processingExpenseData));
    }
    callback();
    const postData = {
      ...data,
      allocation: allocationsToStr(data),
      ...selectedProductsToAPI(products)
    };
    yield axiosCall.post(API.expenses.root, postData);
    yield call(pullExpensesListSaga, { ...SKIP_CLEAN_UP_ACTION, tempId });
  } catch (e) {
    console.log('createExpenseSaga', e);
    yield put(removeProcessingExpenseAction(tempId));
    yield put(setNotificationMsgAction('Create expense failed'));
  }
}

function* updateExpenseDetailsSaga({ payload }) {
  try {
    const { id, data, products, callback, expenseType, applyToAllObjects } = payload;
    yield put(setExpenseProcessingAction({ id, value: true }));
    if (callback) callback();
    const patchData = {
      ...data,
      allocation: allocationsToStr(data),
      applyToAllObjects,
      ...selectedProductsToAPI(products),
      expense_type: expenseType
    };
    yield axiosCall.patch(`${API.expenses.root}${id}/`, patchData);
    yield call(pullExpensesListSaga, SKIP_CLEAN_UP_ACTION);
  } catch (e) {
    console.log('updateExpenseDetailsSaga', e);
    yield put(setNotificationMsgAction('Update expense details failed'));
  } finally {
    yield put(setExpenseProcessingAction({ id: +payload.id, value: false }));
  }
}

export function* expensesSagaWatcher() {
  yield takeLeading(PULL_EXPENSES_LIST, pullExpensesListSaga);
  yield takeLatest(PULL_EXPENSE_DETAILS, pullExpenseDetailsSaga);
  yield takeLatest(DELETE_EXPENSE, deleteExpenseSaga);
  yield takeLatest(CREATE_EXPENSE, createExpenseSaga);
  yield takeLatest(UPDATE_EXPENSE_DETAILS, updateExpenseDetailsSaga);
}
