import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

export const DATE_RANGE_PRESETS = {
  today: 'Today',
  yersterday: 'Yesterday',
  last_7_days: 'Last 7 days',
  last_30_days: 'Last 30 days',
  last_month: 'Last Month',
  this_year: 'This Year',
  life_time: 'Lifetime'
};

export const getDatePresetByName = name =>
  Object.keys(DATE_RANGE_PRESETS).find(
    key => DATE_RANGE_PRESETS[key]?.toLowerCase() === name?.toLowerCase()
  );

export default function RangeList({ presets, setSnippet, currentSnippet, hideSelected }) {
  const presetsKeys = Object.keys(presets);
  if (!presetsKeys.length) return null;
  const listItems = presetsKeys.map(value => (
    <Button
      key={value}
      onClick={() => setSnippet(value)}
      className={classNames('rounded text-sm px-3 text-left normal-case h-11 w-36', {
        'bg-grad-2 text-white font-medium': currentSnippet === value && !hideSelected
      })}
    >
      <p className="w-full text-left">{presets[value]}</p>
    </Button>
  ));
  return (
    <div className="hidden xxs:inline-block pl-1 pr-0 xxs:p-4 sm-border-left-gray">
      <div className="m-0 p-0 flex flex-col">{listItems}</div>
    </div>
  );
}

RangeList.propTypes = {
  setSnippet: PropTypes.func.isRequired,
  currentSnippet: PropTypes.string,
  hideSelected: PropTypes.bool.isRequired,
  presets: PropTypes.shape({
    [PropTypes.string]: PropTypes.string
  }).isRequired
};

RangeList.defaultProps = {
  currentSnippet: ''
};
