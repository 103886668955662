import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import OutlinedWrapper from './OutlinedWrapper';
import { SafeImage } from '../images';

const OutlinedSelectedProductsBox = ({
  title,
  products,
  onEdit,
  className,
  emptyViewClassName,
  disabled
}) => {
  const onClick = () => {
    if (!disabled) onEdit();
  };
  if (!products.length) {
    return (
      <OutlinedWrapper title={title} className={emptyViewClassName} disabled>
        <p className="text-gray-dark text-sm">You haven’t linked any products yet.</p>
      </OutlinedWrapper>
    );
  }
  const images = products.map(item => {
    return (
      <div className="border border-gray-lighter flex items-center justify-center h-9 w-9 flex-shrink-0 mr-2 mb-2 rounded-md">
        <SafeImage src={item.smallImage} className="h-8 w-auto object-fit" />
      </div>
    );
  });
  return (
    <OutlinedWrapper title={title} className={className} disabled={disabled}>
      <div className="h-full w-full pt-4 pr-2 pb-2 flex flex-col justify-between">
        <div
          className="mb-2 w-full flex flex-wrap justify-start items-start overflow-auto scroll-container"
          style={{ height: '5.5rem' }}
        >
          {images}
        </div>
        <div className="w-full flex items-center justify-between">
          <p className="text-xs text-gray-black">
            {products.length} product{products.length ? 's' : ''}
          </p>
          <Button
            className="font-medium text-yellow py-0 min-w-0 px-2 normal-case"
            onClick={onClick}
          >
            Edit
          </Button>
        </div>
      </div>
    </OutlinedWrapper>
  );
};

OutlinedSelectedProductsBox.propTypes = {
  title: PropTypes.string.isRequired,
  products: PropTypes.instanceOf(Array).isRequired,
  onEdit: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  emptyViewClassName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default OutlinedSelectedProductsBox;
