export const PULL_ADVERTISING_CAMPAIGNS = 'PULL_ADVERTISING_CAMPAIGNS';
export const SET_ADVERTISING_CAMPAIGNS = 'SET_ADVERTISING_CAMPAIGNS';
export const PULL_ADVERTISING_CAMPAIGN_DETAILS = 'PULL_ADVERTISING_CAMPAIGN_DETAILS';
export const SET_ADVERTISING_CAMPAIGN_DETAILS = 'SET_ADVERTISING_CAMPAIGN_DETAILS';
export const UPDATE_ADVERTISING_CAMPAIGN_DETAILS = 'UPDATE_ADVERTISING_CAMPAIGN_DETAILS';
export const SET_ADVERTISING_FETCHING = 'SET_ADVERTISING_FETCHING';

export const pullAdvertisingCampaignsAction = payload => ({
  type: PULL_ADVERTISING_CAMPAIGNS,
  payload
});
export const setAdvertisingCampaignsAction = payload => ({
  type: SET_ADVERTISING_CAMPAIGNS,
  payload
});

export const pullAdvertisingCampaignDetailsAction = payload => ({
  type: PULL_ADVERTISING_CAMPAIGN_DETAILS,
  payload
});

export const setAdvertisingCampaignDetailsAction = payload => ({
  type: SET_ADVERTISING_CAMPAIGN_DETAILS,
  payload
});

export const updateAdvertisingCampaignDetailsAction = payload => ({
  type: UPDATE_ADVERTISING_CAMPAIGN_DETAILS,
  payload
});

export const setAdvertisingFetchingAction = payload => ({
  type: SET_ADVERTISING_FETCHING,
  payload
});
