import colors from 'styles/colors';
import { formatValue, mainAxis, secondAxis } from './params';

const getMax = data => {
  return Math.max(
    ...data.map(o => {
      return o[1];
    })
  );
};

const getMin = data => {
  return Math.min(
    ...data.map(o => {
      return o[1];
    })
  );
};

const getTicksParams = (values, isBarView) => {
  const suggestedMax = getMax(values);
  const suggestedMin = getMin(values);

  let maxTicksLimit = 3;
  if (suggestedMax === suggestedMin) {
    maxTicksLimit = 2;
  }

  let result = {
    suggestedMax,
    suggestedMin,
    maxTicksLimit
  };
  if (isBarView && result.suggestedMax) {
    // eslint-disable-next-line no-restricted-properties
    const roundToValue = Math.pow(10, Math.ceil(Math.log10(result.suggestedMax / 10)));
    result = {
      max: Math.ceil(Math.ceil((result.suggestedMax * 1.1) / roundToValue) * roundToValue),
      maxTicksLimit: 2
    };
  }
  return result;
};

export const getLeftYAxis = (
  currency,
  values,
  suffix = null,
  isBarView = false,
  startFromZero = true
) => {
  const tixProps = getTicksParams(values, isBarView);

  return {
    id: mainAxis,
    position: 'left',
    display: true,
    scaleLabel: {
      fontSize: 16
    },
    gridLines: {
      display: true,
      drawBorder: false,
      lineWidth: 1,
      color: colors.gray.lighter,
      zeroLineWidth: 0.15,
      zeroLineColor: colors.yellow.default
    },
    ticks: {
      display: true,
      beginAtZero: startFromZero,
      callback(value) {
        let fValue = formatValue(value);
        fValue = fValue.substring(0, fValue.length - 3);
        let result = currency ? `${currency} ${fValue}` : fValue;
        if (suffix) {
          result += ` ${suffix}`;
        }
        return result;
      },
      ...tixProps,
      padding: 8,
      fontColor: colors.gray.dark
    }
  };
};

export const getRightYAxis = (
  currency,
  values,
  suffix = null,
  isBarView = false,
  startFromZero = true
) => {
  const tixProps = getTicksParams(values, isBarView);

  return {
    id: secondAxis,
    position: 'right',
    display: true,
    scaleLabel: {
      fontSize: 16
    },
    gridLines: {
      display: false,
      drawBorder: false
    },
    ticks: {
      display: true,
      beginAtZero: startFromZero,
      callback(value) {
        let fValue = formatValue(value);
        fValue = fValue.substring(0, fValue.length - 3);
        let result = currency ? `${currency} ${fValue}` : fValue;
        if (suffix) {
          result += ` ${suffix}`;
        }
        return result;
      },
      ...tixProps,
      padding: 8,
      fontColor: colors.gray.dark
    }
  };
};

export const getXAxis = () => {
  return {
    display: true,
    gridLines: {
      drawOnChartArea: false,
      drawTicks: true,
      drawBorder: false
    },
    ticks: {
      display: true,
      fontColor: colors.gray.dark,
      padding: 8
    },
    autoSkipPadding: 2
  };
};
