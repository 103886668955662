/* eslint-disable global-require */
import { getWebpImg } from '@utils';

const marketImages = {
  'il_appstore.png': require('./il_appstore.png'),
  'il_appstore.webp': require('./il_appstore.webp'),
  'il_googleplay.png': require('./il_googleplay.png'),
  'il_googleplay.webp': require('./il_googleplay.webp')
};

const appStoreWebImg = getWebpImg(marketImages, 'il_appstore');
const playMarketWebImg = getWebpImg(marketImages, 'il_googleplay');

export { appStoreWebImg, playMarketWebImg };
