import { v4 as uuidv4 } from 'uuid';
import { format, parseISO, toDate } from 'date-fns';
import InfinityProgress from '@components/common/InfinityProgress';

const DATE_API_FORMAT = 'yyyy-MM-dd';
const MONTH_API_FORMAT = 'yyyy-MM';

export const TABLE_CELL_FORMAT = { date: 'MMM dd, yyyy', time: 'hh:mm a' };

export const dateToStr = datetime => format(datetime, DATE_API_FORMAT);
export const listToStr = list => list.join(',');
export const strToList = listStr => listStr.split(',');
export const dateFromStr = str => str && toDate(parseISO(str), DATE_API_FORMAT);
export const monthToStr = datetime => format(datetime, MONTH_API_FORMAT);
export const monthFromStr = str => str && toDate(parseISO(str), MONTH_API_FORMAT);

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const createRange = (from = new Date(), to = new Date()) => ({ from, to });

export const compareFlatArray = (lhs, rhs) => {
  return JSON.stringify(lhs.sort()) === JSON.stringify(rhs.sort());
};

/* eslint-disable no-useless-escape */
const escapeRegExp = str => {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
};

export const replaceAll = (str, find, replace) => {
  return !str ? str : str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const getProgress = (_a, _b, positiveDecrease, decimalDigits = 0) => {
  const a = _a || 0;
  const b = _b || 0;
  if (a === 0 && b === 0) return { value: 0, text: '+0%' };
  if (a === 0 && b > 0) return { value: 1, text: InfinityProgress(!positiveDecrease) };
  if (a === 0 && b < 0) return { value: -1, text: InfinityProgress(!!positiveDecrease) };
  let result = (((b - a) / a) * 100).toFixed(decimalDigits);
  if (a < 0 && b < 0) result = -result;
  return { value: result, text: `${result < 0 ? '' : '+'}${result}%` };
};

export const stringToArray = str => str.split(' ').filter(item => item.length);
export const arrayToString = array => array.join(' ');

export const PRODUCT_API_FIELDS = ['marketplace', 'asin', 'sku'];
export const getProductIdentity = product =>
  PRODUCT_API_FIELDS.reduce((acc, key) => ({ ...acc, [key]: product[key] }), {});

export const getLastItemFromArray = array => array?.[array?.length - 1];

export const getFilterByKeysValues = (compareData, invert) => item =>
  Object.keys(compareData).reduce((acc, key) => {
    const compareValue = compareData[key];
    const keyResult = invert ? item[key] !== compareValue : item[key] === compareValue;
    return acc && keyResult;
  }, true);

export const getTempId = () => `temp-${uuidv4()}`;

export const getNumber = value => +value || 0;

export const DATE_FORMAT = 'MMM d, yyyy';
