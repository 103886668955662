import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Colors from 'styles/colors';
import classNames from 'classnames';

function LoadingSpinner({ className, override, loading, size, color }) {
  if (!loading) {
    return null;
  }

  const root = classNames({
    'absolute inset-0 z-9999 flex items-center justify-center h-full w-full pointer-events-none': !override,
    [className]: className
  });

  return (
    <div className={root}>
      <CircularProgress style={{ color }} size={size} />
    </div>
  );
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  override: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string
};

LoadingSpinner.defaultProps = {
  className: '',
  override: false,
  loading: true,
  size: undefined,
  color: Colors.yellow.default
};

export default LoadingSpinner;
