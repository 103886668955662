import { getFlagsById } from '@sm/Flags';
import { getMarketTime } from 'seller/utils/common';
import { zonedTimeToUtc, format } from 'date-fns-tz';

export const convertTimeTo12Hr = time => {
  if (!time) return time;
  const [hr24, mn] = time.split(':');
  const dayPart = hr24 < 12 ? 'am' : 'pm';
  const hr12 = hr24 % 12 || 12;
  return `${hr12}:${mn} ${dayPart}`;
};

export const convertTimeTo24Hr = time => {
  if (!time) return time;
  const [hr12, secondPart] = time.split(':');
  const [mn, dayPart] = secondPart.split(' ');
  let hr24;
  if (+hr12 === 12 && dayPart.toLowerCase() === 'am') {
    hr24 = +hr12 - 12;
  } else if (+hr12 >= 1 && +hr12 <= 11 && dayPart.toLowerCase() === 'pm') {
    hr24 = +hr12 + 12;
  } else {
    hr24 = +hr12;
  }
  return `${hr24}:${mn}`;
};

export const timeFormatValid = time => {
  try {
    const [hr, secondPart] = time.split(':');
    const [mn, dayPart] = secondPart.split(' ');
    return (
      hr <= 12 &&
      hr >= 1 &&
      mn >= 0 &&
      mn <= 59 &&
      (dayPart.toLowerCase() === 'am' || dayPart.toLowerCase() === 'pm')
    );
  } catch {
    return false;
  }
};

const getDateByTime = (time, isUtc = false) => {
  if (!time) return null;
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  const [hr, mn] = time.split(':');
  if (isUtc) {
    return new Date(Date.UTC(year, month, day, hr, mn));
  }
  return format(new Date(year, month, day, hr, mn), 'yyyy-MM-dd HH:mm');
};

const getTimeByDate = (date, isUtc = false) => {
  if (!date) return null;
  let hr;
  let mn;
  if (isUtc) {
    hr = date.getUTCHours();
    mn = date.getUTCMinutes();
  } else {
    hr = date.getHours();
    mn = date.getMinutes();
  }
  const hours = hr > 9 ? hr : `0${hr}`;
  const minutes = mn > 9 ? mn : `0${mn}`;
  return `${hours}:${minutes}`;
};

export const getLocalTime = (marketplace, time) => {
  if (!time || !marketplace) return null;
  const utcDate = getDateByTime(time, true);
  const localDate = getMarketTime(marketplace, utcDate);
  return getTimeByDate(localDate);
};

export const getUtcTime = (marketplace, localTime) => {
  if (!localTime || !marketplace) return null;
  const timeZone = getFlagsById(marketplace)?.timezone;
  const date = getDateByTime(localTime);
  const utcDate = zonedTimeToUtc(date, timeZone);
  return getTimeByDate(utcDate, true);
};
