/* eslint-disable no-param-reassign */
import { cancelled, select } from '@redux-saga/core/effects';
import { put, takeLatest } from 'redux-saga/effects';
import { axiosCall, getCancelToken, listToStr } from '@utils';
import API, { LONG_REQUEST_TIMEOUT, rangeToAPI } from '@sm/api';
import { setNotificationMsgAction } from 'seller/store/actions';
import { pnlDashboardSelector } from 'seller/store/reducers';
import {
  PULL_PNL_DASHBOARD_BRANDS,
  PULL_PNL_DASHBOARD_TABLE_DATA,
  setPnlDashboardBrandsAction,
  setPnlDashboardTableDataAction,
  setPnlDashboardTableLoadingAction
} from './index';

const addRecordIds = (objs, recordId = 0, recursionLevel = 0, parentsData = []) => {
  // For correct work of the DataTable component, need to add unique recordId to each object
  objs.forEach(obj => {
    obj.recordId = recordId;
    obj.level = recursionLevel;
    recordId += 1;
    obj.parentsData = parentsData;
    if (obj.children) {
      recordId = addRecordIds(obj.children, recordId, recursionLevel + 1, [
        ...parentsData,
        obj.metadata
      ]);
      recordId += obj.children.length;
    }
  });
  return recordId;
};

function* pullTableDataSaga() {
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    yield put(setPnlDashboardTableLoadingAction(true));
    // Need to wait 1 second before starting the request
    yield new Promise(resolve => setTimeout(resolve, 1000));
    const {
      segmentation,
      timeGrain,
      dateRange,
      granularity,
      selectedBrands,
      platforms
    } = yield select(pnlDashboardSelector);
    const params = {
      segmentation: listToStr(segmentation.filter(item => !item.disabled).map(item => item.key)),
      sales_channels: listToStr(platforms),
      brands: listToStr(selectedBrands),
      metrics: [],
      time_grain: timeGrain.toLowerCase(),
      granularity,
      ...rangeToAPI(dateRange)
    };
    const { data } = yield axiosCall.get(API.pnl_dashboard.metrics, {
      params,
      cancelToken,
      timeout: LONG_REQUEST_TIMEOUT
    });
    addRecordIds(data.data);
    yield put(setPnlDashboardTableDataAction(data));
  } catch (error) {
    yield put(
      setNotificationMsgAction(
        'Pull data failed. Please contact support@sellermetrix.com if problem persists.'
      )
    );
  } finally {
    yield put(setPnlDashboardTableLoadingAction(false));
    if (yield cancelled()) cancelRequest();
  }
}

function* pullBrandsSaga() {
  try {
    const { data } = yield axiosCall.get(API.agency_clients);
    yield put(setPnlDashboardBrandsAction(data));
  } catch (error) {
    yield put(
      setNotificationMsgAction(
        'Pull data failed. Please contact support@sellermetrix.com if problem persists.'
      )
    );
  }
}

export function* pnlDashboardSagaWatcher() {
  yield takeLatest(PULL_PNL_DASHBOARD_TABLE_DATA, pullTableDataSaga);
  yield takeLatest(PULL_PNL_DASHBOARD_BRANDS, pullBrandsSaga);
}
