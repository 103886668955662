/* eslint-disable global-require */
import { getWebpImg } from '@utils';

const platformImages = {
  'il_platforms-mobile.png': require('./il_platforms-mobile.png'),
  'il_platforms-mobile.webp': require('./il_platforms-mobile.webp'),
  'il_platforms.png': require('./il_platforms.png'),
  'il_platforms.webp': require('./il_platforms.webp')
};
const platformsWebImg = getWebpImg(platformImages, 'il_platforms');

export default platformsWebImg;
