import { differenceInDays } from 'date-fns';
import { getMarketTime } from 'seller/utils/common';

export const REASON_MAP = {
  MISSED_ESTIMATED_DELIVERY: 'Missed estimated delivery date',
  UNDELIVERABLE_UNKNOWN: 'Undeliverable - Unknown',
  DEFECTIVE: 'Defective',
  ORDERED_WRONG_ITEM: 'Ordered wrong item',
  UNWANTED_ITEM: 'Unwanted item',
  UNDELIVERABLE_CARRIER_MISS_SORTED: 'Undeliverable - Carrier missorted',
  UNDELIVERABLE_INSUFFICIENT_ADDRESS: 'Undeliverable - Insufficient address',
  NOT_AS_DESCRIBED: 'Not as described',
  DAMAGED_BY_CARRIER: 'Damaged by carrier',
  DAMAGED_BY_FC: 'Damaged by fulfillment center',
  UNAUTHORIZED_PURCHASE: 'Unauthorized purchase',
  MISSING_PARTS: 'Missing parts',
  UNDELIVERABLE_REFUSED: 'Undeliverable - Refused',
  FOUND_BETTER_PRICE: 'Found better price',
  NEVER_ARRIVED: 'Never arrived',
  MISORDERED: 'Misordered',
  QUALITY_UNACCEPTABLE: 'Quality unacceptable',
  NOT_COMPATIBLE: 'Not compatible',
  SWITCHEROO: 'Switched to another product',
  NO_REASON_GIVEN: 'No reason given',
  UNDELIVERABLE_MISSING_LABEL: 'Undeliverable - Missing label',
  DID_NOT_LIKE_FABRIC: 'Did not like fabric',
  DID_NOT_LIKE_COLOR: 'Did not like color',
  APPAREL_STYLE: 'Apparel style',
  APPAREL_TOO_LARGE: 'Apparel too large',
  APPAREL_TOO_SMALL: 'Apparel too small',
  UNDELIVERABLE_FAILED_DELIVERY_ATTEMPTS: 'Undeliverable Failed',
  UNDELIVERABLE_UNCLAIMED: 'Undeliverable Unclaimed'
};

const capitalizeStr = str => {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
};

export const capitalizeWords = value => {
  if (!value) {
    return value;
  }
  const values = value.split('_');
  const capitalized = values.map(val => capitalizeStr(val));
  return capitalized.join(' ');
};

export const AWAITING = 'Awaiting Return';
export const NOT_RETURNED = 'Not Returned';
export const RETURNED = 'Returned';
export const FBM_RETURN = 'FBM Return';
export const REIMBURSED = 'Reimbursed';
export const REVERSED = 'Reversed';

export const STATUSES = [AWAITING, NOT_RETURNED, RETURNED, FBM_RETURN, REIMBURSED, REVERSED];

export const getRefundTime = refund => {
  const { marketplace } = refund;
  const refundDt = refund.latest_refund_timestamp;
  const dtValue = getMarketTime(marketplace, refundDt);
  return differenceInDays(Date.now(), dtValue);
};

const RETURNED_STATUS = ['IMMEDIATE_DISPOSAL', 'Repackaged Successfully'];

export const convertStatus = refund => {
  const { status, marketplace } = refund;
  let statusUI = status;
  if (RETURNED_STATUS.includes(statusUI)) {
    statusUI = RETURNED;
  } else if (statusUI === 'Unit returned to inventory') {
    statusUI = 'Returned';
  } else if (!statusUI) {
    const refundDt = refund.latest_refund_timestamp;
    const dtValue = getMarketTime(marketplace, refundDt);
    const diff = differenceInDays(Date.now(), dtValue);
    if (diff >= 46) {
      statusUI = NOT_RETURNED;
    } else {
      statusUI = AWAITING;
    }
  }
  return statusUI;
};

export const convertData = data => {
  if (!data) {
    return data;
  }

  return data.map(rec => {
    const res = {
      ...rec,
      status: convertStatus(rec),
      reason: capitalizeWords(rec.reason),
      disposition: capitalizeWords(rec.disposition)
    };

    res.children =
      rec.children &&
      rec.children.map(child => {
        return {
          ...child,
          status: convertStatus(child),
          reason: capitalizeWords(child.reason),
          disposition: capitalizeWords(child.disposition)
        };
      });
    return res;
  });
};
