/* eslint-disable global-require */
import { getWebpImg } from '@utils';

const images = {
  'il_marketplaces-mobile.png': require('./il_marketplaces-mobile.png'),
  'il_marketplaces-mobile.webp': require('./il_marketplaces-mobile.webp'),
  'il_marketplaces.png': require('./il_marketplaces.png'),
  'il_marketplaces.webp': require('./il_marketplaces.webp')
};
const marketplacesWebImg = getWebpImg(images, 'il_marketplaces');

export default marketplacesWebImg;
