export const PULL_SQP = 'PULL_SQP';
export const SET_SQP = 'SET_SQP';
export const SET_SQP_LOADING = 'SET_SQP_LOADING';
export const PULL_SQP_STATUS = 'PULL_SQP_STATUS';
export const SET_SQP_STATUS = 'SET_SQP_STATUS';
export const SET_SQP_ACCOUNTS_STATUSES = 'SET_SQP_ACCOUNTS_STATUSES';

export const pullSqpAction = payload => ({ type: PULL_SQP, payload });
export const setSqpAction = payload => ({ type: SET_SQP, payload });
export const setSqpLoadingAction = payload => ({ type: SET_SQP_LOADING, payload });
export const pullSqpStatusAction = payload => ({ type: PULL_SQP_STATUS, payload });
export const setSqpStatusAction = payload => ({ type: SET_SQP_STATUS, payload });
export const setSqpAccountsStatusesAction = payload => ({
  type: SET_SQP_ACCOUNTS_STATUSES,
  payload
});
