export const PROFIT_AND_LOSS_REPORT_TYPE = 'profit-and-loss';
export const DAILY_SALES_REPORT_TYPE = 'daily-sales';

export const REPORT_COMPLETED = 'completed';
export const REPORT_IN_PROGRESS = 'in_progress';
export const REPORT_FAILED = 'failed';

export const SUMMARY_CURRENCY = { full: 'USD', short: '$' };
export const EXCHANGE_RATES_TO_USD = {
  CAD: 0.78,
  MXN: 0.049,
  USD: 1,
  EUR: 1.17,
  GBP: 1.367,
  INR: 0.013,
  JPY: 0.009,
  AUD: 0.723,
  BRL: 0.189,
  SGD: 0.739,
  AED: 0.272,
  EGP: 0.063,
  SAR: 0.266,
  SEK: 0.114,
  TRY: 0.115,
  PLN: 0.254
};

export const DECEMBER_MONTH_INDEX = 11;
