import React from 'react';
import { GradButton } from '@components/buttons';
import { startNewMessage } from '@utils/intercom';

function HomepageChat() {
  return (
    <div className="flex flex-col items-center my-16 md:my-32">
      <h3 className="text-black text-2xl md:text-4xl font-bold">We are here for you</h3>
      <p className="text-gray-dark text-lg mt-6 w-11/12 sm:sm-w-512px text-center">
        If you have any questions, big or small, you can always reach out to us. Just click the
        button below to chat with a real person from Seller Metrix. We try to always be available
        and respond in minutes.
      </p>
      <GradButton onClick={() => startNewMessage()} className="h-12 text-base mt-10 sm-w-144px">
        Start Chat
      </GradButton>
    </div>
  );
}

export default HomepageChat;
