import { SET_REFUNDS, START_REFUNDS_LOADING, ENABLE_VIDEO_DIALOG } from '../actions';
import { convertData } from '../../pages/refunds/consts';
import { SET_REFUNDS_TOTALS } from '../saga/dashboard';

const initialState = {
  data: [],
  isLoading: false,
  totals: [],
  totalsLoading: false,
  showVideoDialog: false
};

const refunds = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFUNDS: {
      const { data } = action;
      const convertedData = convertData(data);
      return {
        ...state,
        data: convertedData,
        isLoading: false
      };
    }
    case START_REFUNDS_LOADING: {
      return {
        ...state,
        refunds: [],
        isLoading: true,
        totalsLoading: true
      };
    }
    case SET_REFUNDS_TOTALS: {
      const { totals } = action;
      return {
        ...state,
        totals,
        totalsLoading: false
      };
    }
    case ENABLE_VIDEO_DIALOG: {
      const { enable } = action;
      return {
        ...state,
        showVideoDialog: enable
      };
    }
    default: {
      return state;
    }
  }
};

export default refunds;
