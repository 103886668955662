export const SET_SKIPPED_DISCONNECTED_ACCOUNTS = 'WARNINGS/SET_SKIPPED_DISCONNECTED_ACCOUNTS';
export const SET_SKIPPED_PPC_NEEDS_RECONNECT = 'WARNINGS/SET_SKIPPED_PPC_NEEDS_RECONNECT';
export const SKIP_PPC_NEEDS_RECONNECT_WARNING = 'WARNINGS/SKIP_PPC_NEEDS_RECONNECT_WARNING';
export const SKIP_ACCESS_DENIED_WARNING = 'WARNINGS/SKIP_ACCESS_DENIED_WARNING';
export const SET_ACCESS_DENIED_WARNING_SHOW = 'WARNINGS/SET_ACCOUNTS_WARNING_SHOW';
export const SET_MOBILE_APP_BANNER_SKIPPED = 'SET_MOBILE_APP_BANNER_SKIPPED';
export const SET_TABLE_CONFIG = 'SET_TABLE_CONFIG';
export const SET_REPORTS_CONFIG = 'SET_REPORTS_CONFIG';
export const SET_HIDE_PRODUCTS_WITHOUT_FINANCES = 'SET_HIDE_PRODUCTS_WITHOUT_FINANCES';
export const SET_SP_API_EXPIRED_WARNING_SHOW = 'WARNINGS/SET_SP_API_EXPIRED_WARNING_SHOW';
export const SET_SP_API_EXPIRED_WARNING_SKIPPED = 'WARNINGS/SET_SP_API_EXPIRED_WARNING_SKIPPED';
export const SKIP_SP_API_EXPIRED_WARNING = 'WARNINGS/SKIP_SP_API_EXPIRED_WARNING';

export const setSkippedDisconnectedAccountsAction = payload => ({
  type: SET_SKIPPED_DISCONNECTED_ACCOUNTS,
  payload
});

export const skipAccessDeniedWarningAction = payload => ({
  type: SKIP_ACCESS_DENIED_WARNING,
  payload
});

export const setSkippedPpcNeedsReconnectAction = payload => ({
  type: SET_SKIPPED_PPC_NEEDS_RECONNECT,
  payload
});

export const skipPpcNeedsReconnectWarningAction = payload => ({
  type: SKIP_PPC_NEEDS_RECONNECT_WARNING,
  payload
});

export const setAccessDeniedWarningShowAction = payload => ({
  type: SET_ACCESS_DENIED_WARNING_SHOW,
  payload
});

export const skipSpApiExpiredWarningAction = payload => ({
  type: SKIP_SP_API_EXPIRED_WARNING,
  payload
});

export const setTableConfigAction = (id, config) => ({ type: SET_TABLE_CONFIG, id, config });

export const setMobileAppBannerSkippedAction = payload => ({
  type: SET_MOBILE_APP_BANNER_SKIPPED,
  payload
});

export const setReportsConfigAction = payload => ({ type: SET_REPORTS_CONFIG, payload });

export const setHideProductsWithoutFinancesAction = payload => ({
  type: SET_HIDE_PRODUCTS_WITHOUT_FINANCES,
  payload
});

export const setSpApiExpiredWarningShowAction = payload => ({
  type: SET_SP_API_EXPIRED_WARNING_SHOW,
  payload
});

export const setSpApiExpiredWarningSkippedAction = payload => ({
  type: SET_SP_API_EXPIRED_WARNING_SKIPPED,
  payload
});
