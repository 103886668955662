import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FlatButton from './FlatButton';
import { CaretDownIcon } from '../icons';
import MenuComponent from '../common/Menu';

const MenuButton = ({ name, data, onSelected, disabledItem, disabled, className }) => {
  const [anchor, setAnchor] = useState(null);
  const menuId = 'menuId2';

  const handleClick = event => {
    setAnchor(event.currentTarget);
  };

  const handleClose = index => {
    setAnchor(null);
    if (typeof index === 'string') {
      onSelected(index);
    }
  };

  return (
    <div className={className}>
      <FlatButton
        aria-owns={anchor ? menuId : undefined}
        aria-haspopup="true"
        className={disabled ? 'text-gray flex flex-row items-center' : 'flex flex-row items-center'}
        onClick={handleClick}
        disabled={disabled}
      >
        {name}
        <CaretDownIcon
          fill="#c3c6d6"
          width="16px"
          height="14px"
          className="ml-2 mr-4 hidden xs:block"
        />
      </FlatButton>
      <MenuComponent
        id={menuId}
        data={data}
        handleClose={handleClose}
        anchor={anchor}
        disabledItem={disabledItem}
      />
    </div>
  );
};

MenuButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledItem: PropTypes.string
};

MenuButton.defaultProps = {
  disabled: false,
  disabledItem: null,
  className: ''
};

export default MenuButton;
