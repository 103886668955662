import React from 'react';
import InfinityIcon from './img/ic_infinity.svg';
import HandIcon from './img/ic_hand.svg';
import ListIcon from './img/ic_list.svg';
import VatIcon from './img/ic_vat.svg';
import ShieldIcon from './img/ic_shield.svg';
import AwardIcon from './img/ic_award.svg';
import SearchIcon from './img/ic_search-dollar.svg';
import CalcIcon from './img/ic_calc.svg';
import CashIcon from './img/ic_cash.svg';
import ListOlIcon from './img/ic_list-ol.svg';

const featuresList = {
  'All Your Amazon Expenses': ListOlIcon,
  'Return on Investment Calculation': HandIcon,
  'VAT Calculation': VatIcon,
  'Unlimited Seller Central Accounts': InfinityIcon,
  'Secure Data Handling': ShieldIcon,
  'GDPR Compliant': AwardIcon,
  'Analyze any Combination of Products': SearchIcon,
  'Detailed Costs Breakdown': ListIcon,
  'Gross & Net Profit Calculation': CalcIcon,
  'IGST For Amazon India Sellers': CashIcon
};

function MoreFeatures() {
  const featureListContent = Object.keys(featuresList).map(feature => (
    <div
      className="w-1/2 md:w-1/3 lg:w-1/5 flex flex-col items-center mb-12 md:mb-16"
      key={feature}
    >
      <div className="h-12 w-12 rounded-lg box-shadow-default flex items-center justify-center">
        {typeof featuresList[feature] === 'string' ? (
          <img src={featuresList[feature]} alt="feature" className="h-5" />
        ) : (
          featuresList[feature]
        )}
      </div>
      <h3 className="text-black font-medium text-lg text-center mt-4 w-40">{feature}</h3>
    </div>
  ));

  return (
    <div className="w-full flex justify-center mt-12 md:mt-24">
      <div className="w-11/12 flex flex-col items-center">
        <div className="flex flex-col items-center">
          <h2 className="text-black tracking-tight text-4xl font-bold">Many More Features</h2>
          <p className="text-gray-dark text-lg text-center">
            We have a lot more features in Seller Metrix. Here are some examples:
          </p>
        </div>
        <div className="mt-16 flex flex-wrap justify-center w-full">{featureListContent}</div>
      </div>
    </div>
  );
}

export default MoreFeatures;
