import { setAppVersionActon, setPrimaryNotificationMsgAction } from 'seller/store/actions';
import { setLastShownAvailableAppVersionAction } from 'seller/store/saga/globalSystemNotification';
import { ReloadNotificationView } from '@components/notifications';
import { WARNING } from '@components/notifications/NotificationView';

// eslint-disable-next-line import/prefer-default-export
export const getHandleAppVersion = store => (availableVersion, isError) => {
  if (!availableVersion) return;
  const { app } = store.getState();
  if (!app.version) {
    store.dispatch(setAppVersionActon(availableVersion));
    return;
  }
  const { lastIgnoredAvailableAppVersion } = store.getState().globalSystemNotification;
  if (
    app.version !== availableVersion &&
    (availableVersion !== lastIgnoredAvailableAppVersion || isError)
  ) {
    store.dispatch(setPrimaryNotificationMsgAction(ReloadNotificationView(), WARNING, true));
    store.dispatch(setLastShownAvailableAppVersionAction(availableVersion));
  }
};
