import React from 'react';
import { FontButton } from '../buttons';

const ReloadNotificationView = () => {
  const button = (
    <FontButton onClick={() => window.location.reload()} className="underline">
      reload
    </FontButton>
  );
  return (
    <p className="text-white ml-4 font-medium">
      New application version available. Please {button} the page.
    </p>
  );
};

export default ReloadNotificationView;
