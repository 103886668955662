import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const getYearMonths = year => {
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    months.push(new Date(year, i));
  }
  return months;
};

export const YearView = ({
  year,
  selectedDateRange,
  onClick,
  firstAvailableDate,
  lastAvailableDate
}) => {
  const months = getYearMonths(year);

  return (
    <div className="w-full">
      <div className="grid grid-cols-3 gap-2">
        {months.map(monthFirstDay => {
          const monthName = monthFirstDay.toLocaleString('en-us', { month: 'short' });
          const isMonthSelected =
            selectedDateRange.start.getTime() <= monthFirstDay.getTime() &&
            monthFirstDay.getTime() <= selectedDateRange.end.getTime();
          const isDisabled =
            monthFirstDay.getTime() < firstAvailableDate.getTime() ||
            monthFirstDay.getTime() > lastAvailableDate.getTime();
          return (
            <Box
              onClick={() => !isDisabled && onClick(monthFirstDay)}
              className={classNames('flex-grow px-1 py-2 text-center rounded', {
                'bg-yellow text-white': isMonthSelected,
                'border border-gray-transparent': !isMonthSelected,
                'text-gray-light cursor-default': isDisabled,
                'cursor-pointer': !isDisabled
              })}
            >
              {monthName}
            </Box>
          );
        })}
      </div>
    </div>
  );
};

YearView.propTypes = {
  year: PropTypes.number.isRequired,
  selectedDateRange: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  firstAvailableDate: PropTypes.instanceOf(Date).isRequired,
  lastAvailableDate: PropTypes.instanceOf(Date).isRequired
};
