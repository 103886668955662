import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Colors from 'styles/colors';
import { ChevronDownIcon } from './index';

const ExpandIcon = ({ selected, className, fill, width, height }) => {
  const imgStyle = selected ? { transform: 'rotate(-180deg)' } : {};
  return (
    <ChevronDownIcon
      width={width}
      height={height}
      fill={fill}
      style={imgStyle}
      className={classNames(className, 'transition-all duration-200')}
    />
  );
};

ExpandIcon.propTypes = {
  selected: PropTypes.bool,
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

ExpandIcon.defaultProps = {
  className: '',
  fill: Colors.gray.darkest,
  width: '10px',
  height: 'auto',
  selected: false
};

export default ExpandIcon;
