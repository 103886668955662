import {
  SET_ACTIVE_CAMPAIGN,
  SET_CAMPAIGNS,
  SET_EDITED_CAMPAIGN,
  SET_EDITED_MESSAGE,
  SET_FETCHING,
  SET_IS_EDIT,
  SET_MESSAGES,
  SET_NEW_MESSAGE_TYPE,
  SET_SENT_MESSAGES,
  SET_DYNAMIC_VARIABLES,
  SET_SCHEDULED_MESSAGES,
  SET_EMAIL_PREVIEW,
  FILTER_SCHEDULED_MESSAGE
} from '../saga/emailTool';

const initialState = {
  campaigns: [],
  messages: [],
  sentMessages: [],
  scheduledMessages: [],
  activeCampaign: null,
  isRequestReview: false,
  editedCampaign: null,
  editedMessage: null,
  isEdit: false,
  fetching: {
    CAMPAIGNS: false,
    SENT_MESSAGES: false,
    SCHEDULED_MESSAGES: false,
    MESSAGE_TEMPLATES: false,
    EMAIL_PREVIEW: false
  },
  dynamicVariables: {},
  emailPreview: '',
  spaWarningSkipped: false
};

export const emailToolStateSelector = state => state.emailTool;

const emailTool = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_SCHEDULED_MESSAGE: {
      const { payload } = action;
      const msgFilter = payload.request_review
        ? msg => msg.id !== payload.id
        : msg => msg.message !== payload.message || msg.amazon_order_id !== payload.amazon_order_id;
      return { ...state, scheduledMessages: state.scheduledMessages.filter(msgFilter) };
    }
    case SET_DYNAMIC_VARIABLES:
      return {
        ...state,
        dynamicVariables: action.payload
      };
    case SET_EMAIL_PREVIEW:
      return {
        ...state,
        emailPreview: action.payload
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };
    case SET_SENT_MESSAGES:
      return {
        ...state,
        sentMessages: action.payload
      };
    case SET_SCHEDULED_MESSAGES:
      return {
        ...state,
        scheduledMessages: action.payload
      };
    case SET_FETCHING:
      return {
        ...state,
        fetching: { ...state.fetching, ...action.payload }
      };
    case SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload
      };
    case SET_ACTIVE_CAMPAIGN:
      return {
        ...state,
        activeCampaign: action.payload
      };
    case SET_NEW_MESSAGE_TYPE:
      return {
        ...state,
        isRequestReview: action.payload
      };
    case SET_EDITED_CAMPAIGN:
      return {
        ...state,
        editedCampaign: action.payload
      };
    case SET_EDITED_MESSAGE:
      return {
        ...state,
        editedMessage: action.payload
      };
    case SET_IS_EDIT:
      return {
        ...state,
        isEdit: action.payload
      };
    default: {
      return state;
    }
  }
};

export default emailTool;
