import { getFilterByKeysValues } from '@utils';
import { PROFIT_AND_LOSS_REPORT_TYPE, REPORT_IN_PROGRESS } from 'seller/pages/reports/consts';
import {
  ADD_REPORT_IN_PROCESSING,
  REMOVE_REPORT_IN_PROCESSING,
  SET_REPORT_DETAILS,
  SET_REPORT_IN_PROGRESS,
  SET_REPORTS_FETCHING,
  SET_REPORTS_LIST,
  SET_REPORTS_LIST_ACTIVE_TAB
} from '../saga/reports';

const initialState = {
  fetching: { reports: false, reportDetails: false, createReport: false },
  reports: [],
  reportsInProcessing: [],
  reportDetails: null,
  reportsListActiveTab: PROFIT_AND_LOSS_REPORT_TYPE
};

export const reportsSelector = state => state.reports;

const reports = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORTS_LIST: {
      return {
        ...state,
        reports: action.payload.reports,
        reportsInProcessing: state.reportsInProcessing.filter(
          getFilterByKeysValues({ id: action.payload.tempReportId }, true)
        )
      };
    }
    case SET_REPORTS_FETCHING: {
      return { ...state, fetching: { ...state.reports, ...action.payload } };
    }
    case SET_REPORT_IN_PROGRESS: {
      const targetReport = state.reports.find(getFilterByKeysValues({ id: action.payload }));
      targetReport.status = REPORT_IN_PROGRESS;
      targetReport.isBlocked = true;
      return state;
    }
    case SET_REPORT_DETAILS: {
      return { ...state, reportDetails: action.payload };
    }
    case SET_REPORTS_LIST_ACTIVE_TAB: {
      return { ...state, reportsListActiveTab: action.payload };
    }
    case ADD_REPORT_IN_PROCESSING: {
      return { ...state, reportsInProcessing: [...state.reportsInProcessing, action.payload] };
    }
    case REMOVE_REPORT_IN_PROCESSING: {
      return {
        ...state,
        reportsInProcessing: state.reportsInProcessing.filter(item => item.id !== action.payload)
      };
    }
    default: {
      return state;
    }
  }
};

export default reports;
