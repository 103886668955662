import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ROUTES from 'seller/routing';
import { WebImg } from '@components';
import { FontButton } from '@components/buttons';
import BurgerMenu from './BurgerMenu';
import logoWebImg from './img/logo';

export const SCROLL_LINK_OFFSET = -100;

export const FEATURES_ANCHOR = 'features';
export const PRICING_ANCHOR = 'pricing';

export const ALLOWED_SCROLL_HASHES = [FEATURES_ANCHOR, PRICING_ANCHOR];

const menuList = [
  <ScrollLink to={FEATURES_ANCHOR} smooth offset={SCROLL_LINK_OFFSET}>
    Features
  </ScrollLink>,
  <ScrollLink to={PRICING_ANCHOR} smooth offset={SCROLL_LINK_OFFSET}>
    Pricing
  </ScrollLink>,
  <Link to={ROUTES.login}>Login</Link>
];

const menuIndexesForHide = [0, 1];

function HomepageHeader({ scrollMode, hideMenuItems }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const filteredMenuList = hideMenuItems
    ? menuList.filter((item, i) => !menuIndexesForHide.includes(i))
    : menuList;

  return (
    <div
      className={classNames(
        'flex justify-center py-4 w-full fixed top-0 right-0 z-30 sm-transition-03s',
        {
          'bg-white box-shadow-default bg-white': scrollMode,
          'bg-white md:bg-transparent': !scrollMode
        }
      )}
    >
      <div className="w-11/12 flex items-center justify-between flex-wrap p-0 lg:pr-4 xl:p-0">
        <div className="w-full flex md:w-auto justify-between">
          <Link to={ROUTES.root}>
            <WebImg
              {...logoWebImg}
              alt="logo"
              imgClassName={classNames('sm-transition-03s h-10', {
                'lg:h-12': !scrollMode
              })}
            />
          </Link>
          <BurgerMenu onClick={() => setMobileMenu(!mobileMenu)} />
        </div>

        <div
          className={classNames(
            { flex: mobileMenu, hidden: !mobileMenu },
            'md:flex items-stretch flex-col md:flex-row w-full md:w-auto'
          )}
        >
          <div className="md:flex flex-col md:flex-row items-center">
            {filteredMenuList.map((value, index) => (
              <FontButton
                key={`${index + 1}`}
                className="block py-3 text-black font-medium text-base mb-4 md:mb-0 md:ml-6 md:p-0 w-full md:w-auto text-center"
              >
                {value}
              </FontButton>
            ))}
          </div>
          <div className="md:ml-10">
            <Link to={ROUTES.registration}>
              <Button
                className={classNames(
                  'text-white bg-grad-3 w-full md:w-32 text-base font-medium normal-case rounded box-shadow-green h-12 sm-transition-03s',
                  { 'md:h-10': scrollMode }
                )}
              >
                Sign up
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

HomepageHeader.propTypes = {
  scrollMode: PropTypes.bool,
  hideMenuItems: PropTypes.bool
};

HomepageHeader.defaultProps = {
  scrollMode: false,
  hideMenuItems: false
};

export default HomepageHeader;
