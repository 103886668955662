import CSV from 'comma-separated-values';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';

import { getFlagsById } from '@sm/Flags';
import { PENDING, UNSHIPPED } from 'seller/pages/orders/views';
import { DECIMAL_SCALE, replaceAll } from '@utils';

export const CSV_COLUMNS = {
  asin: 'ASIN',
  sku: 'SKU',
  unit_cost: 'Product CoGS',
  unit_shipping_cost: 'Inbound Shipping',
  fbm: 'FBM Shipping',
  fbm_return_cost: 'Cost per FBM Return',
  currency: 'Currency',
  marketplace: 'Marketplace',
  inventory: 'Current Inventory'
};

const getProductsFromCSV = csvString => {
  let csvList = new CSV(csvString);
  csvList = csvList.parse();

  const columnIndex = {};
  const productList = [];

  csvList.forEach((item, rowIndex) => {
    if (rowIndex === 0) {
      item.forEach((key, index) => {
        columnIndex[key] = index;
      });
    } else {
      productList.push({
        asin: item[columnIndex[CSV_COLUMNS.asin]],
        sku: item[columnIndex[CSV_COLUMNS.sku]],
        unit_cost: item[columnIndex[CSV_COLUMNS.unit_cost]],
        unit_shipping_cost: item[columnIndex[CSV_COLUMNS.unit_shipping_cost]],
        fbm_shipping_cost: item[columnIndex[CSV_COLUMNS.fbm]],
        fbm_return_cost: item[columnIndex[CSV_COLUMNS.fbm_return_cost]],
        currency: item[columnIndex[CSV_COLUMNS.currency]],
        marketplace: item[columnIndex[CSV_COLUMNS.marketplace]],
        inventory: item[columnIndex[CSV_COLUMNS.inventory]]
      });
    }
  });
  return productList;
};

export const getHumanReadableBool = value => (value ? 'Yes' : 'No');

export const getShortDate = str => {
  try {
    const [result] = str.split('T');
    return result;
  } catch (e) {
    console.log(e);
    return str;
  }
};

export function getColumn(columns, row, currency) {
  return Object.keys(columns).map(key => {
    if (['name', 'qp_asin_query'].includes(key)) {
      let productName = row[key];
      productName = replaceAll(productName, ',', '');
      return productName ? productName.replace(',', '').substring(0, 100) : '';
    }
    if (key === 'buyer') {
      const { status } = row;
      if (status === PENDING || status === UNSHIPPED) {
        return 'Available when shipped';
      }
      return row.buyer;
    }
    if (key === 'currency') {
      return currency || row.currency;
    }
    if (key === 'marketplace') {
      const marketplaceId = row[key];
      const mkInfo = getFlagsById(marketplaceId);
      return mkInfo ? mkInfo.abbreviation : '';
    }
    if (key === 'organic_product_sales') {
      const { product_sales: productSales, ad_sales: adSales } = row;
      return ((productSales || 0) - (adSales || 0)).toFixed(DECIMAL_SCALE);
    }
    if (key === 'organic_orders') {
      return (row.orders || 0) - (row.ppc_orders || 0);
    }
    if (key.includes('variation')) {
      let value = row[key];
      value = replaceAll(value, ',', '');
      return value ? value.replace(',', '').substring(0, 100) : '';
    }
    if (key === 'conv_rate') {
      const { orders } = row;
      const { sessions } = row;
      let convRate = sessions ? (orders * 100) / sessions : 0;
      convRate = Math.round((convRate + Number.EPSILON) * 100) / 100;
      return convRate;
    }
    if (key === 'discounted' || key === 'subscribed') return getHumanReadableBool(row[key]);
    if (key === 'date') return getShortDate(row[key]);
    if (key === 'tags') return row[key]?.map(item => item.replaceAll(',', ' ')).join(' | ') || '';
    if (key === 'parent_asin') return row[key] || '';
    const result = row[key] || 0;
    if (Number.isInteger(result) || typeof result === 'string') return result;
    return result?.toFixed ? result.toFixed(DECIMAL_SCALE) : 0;
  });
}

export function uploadCsv(csvData, columns, fileName) {
  const header = Object.values(columns);
  const csvFromArrayOfObjects = [header, ...csvData].join('\n');
  const blob = new Blob([csvFromArrayOfObjects], {
    type: 'text/csv;charset=utf-8',
    endings: 'native'
  });
  saveAs(blob, `${fileName}.csv`);
}

export const exportCSV = (data, fileName, columns) => {
  const csvData = data.map(row => getColumn(columns, row));
  uploadCsv(csvData, columns, fileName);
};

const getCsvFileNameDateRangeString = dateRange => {
  const dateFormat = 'MMM,d,yyyy';
  return `${format(dateRange.from, dateFormat)}-${format(dateRange.to, dateFormat)}`;
};
export const getCsvFileName = ({ dashboardName, fileName, marketplaceId, dateRange }) => {
  let result = 'SellerMetrix';
  if (dashboardName) result += `-${dashboardName}`;
  if (fileName) result += `-${fileName}`;
  if (marketplaceId) result += `-${getFlagsById(marketplaceId).name}`;
  if (dateRange) result += `-${getCsvFileNameDateRangeString(dateRange)}`;
  return result;
};

export const exportCSVBreakdown = async (
  data,
  fileName,
  columns,
  dateRange,
  marketplaceID,
  onHandle
) => {
  onHandle(true);
  const mkInfo = getFlagsById(marketplaceID);
  const currency = mkInfo ? mkInfo.currency : '';

  let flatData = [];
  data.forEach(i => {
    if (i.is_parent) {
      flatData = [...flatData, ...i.children];
    } else {
      flatData.push(i);
    }
  });

  const csvData = flatData.map(row => getColumn(columns, row, currency));
  uploadCsv(csvData, columns, fileName);
  onHandle(false);
};

export default getProductsFromCSV;
