import { getFilterByKeysValues } from '@utils';
import {
  ADD_PROCESSING_EXPENSE,
  REMOVE_PROCESSING_EXPENSE,
  SET_EXPENSE_DETAILS,
  SET_EXPENSE_PROCESSING,
  SET_EXPENSES_FETCHING,
  SET_EXPENSES_LIST,
  SET_EXPENSES_PAGE,
  SET_SELECTED_EXPENSES_CATEGORY,
  SET_SHOW_ALL_EXPENSES
} from '../saga/expenses';

const initialState = {
  list: [],
  selectedCategory: null,
  details: {},
  fetching: {
    list: false,
    details: false
  },
  page: 'EXPENSES_LIST',
  showAll: false
};

export const expensesSelector = state => state.expenses;

const expenses = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EXPENSES_LIST: {
      return {
        ...state,
        list: [
          ...payload.data,
          ...state.list.filter(item => item.temp && item.id !== payload.tempId)
        ]
      };
    }
    case SET_EXPENSE_DETAILS: {
      return { ...state, details: payload };
    }
    case SET_EXPENSES_FETCHING: {
      return { ...state, fetching: { ...state.fetching, ...payload } };
    }
    case ADD_PROCESSING_EXPENSE: {
      return { ...state, list: [...state.list, payload] };
    }
    case REMOVE_PROCESSING_EXPENSE: {
      return { ...state, list: state.list.filter(item => item.id !== payload) };
    }
    case SET_EXPENSE_PROCESSING: {
      const targetExpense = state.list.find(getFilterByKeysValues({ id: payload.id }));
      if (targetExpense) {
        // eslint-disable-next-line no-param-reassign
        targetExpense.processing = payload.value;
      }
      return { ...state, list: [...state.list] };
    }
    case SET_SELECTED_EXPENSES_CATEGORY: {
      return { ...state, selectedCategory: payload };
    }
    case SET_EXPENSES_PAGE: {
      return { ...state, page: payload };
    }
    case SET_SHOW_ALL_EXPENSES: {
      return { ...state, showAll: payload };
    }
    default: {
      return state;
    }
  }
};

export default expenses;
