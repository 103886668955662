import React from 'react';
import PropTypes from 'prop-types';
import NoResultsIcon from './il_searchstack.svg';

function ProductsNoResults(props) {
  const { searchText } = props;

  return (
    <div className="flex flex-col items-center justify-center h-full bg-violet-white">
      <img className="sm-w-80px" src={NoResultsIcon} alt="no-results" />
      <p className="text-sm text-gray-dark mt-6">There are no results for “{searchText}”</p>
    </div>
  );
}

ProductsNoResults.propTypes = {
  searchText: PropTypes.string.isRequired
};

export default ProductsNoResults;
