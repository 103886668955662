import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import { format } from 'date-fns';
import Colors from 'styles/colors';
import { getFlagsById } from '@sm/Flags';
import ProductCard from '@components/products/product-card/ProductCard';
import { SafeImage } from '@components/images';
import NoteCell from './NoteCell';
import columns from './columns';

const rowHeight = 80;
const headerRowHeight = 42;
const stickyRow = 1;

const styledTopGrid = {
  borderColor: Colors.gray.lighter,
  borderBottomWidth: '1px'
};

function getColumnWidth({ index }) {
  return columns[index].width;
}

class NotesTable extends React.Component {
  constructor(props) {
    super(props);
    this.refContainer = React.createRef();
  }

  cellRendererDate = (style, date, key) => {
    const dateFormat = 'MMM dd, y';

    return (
      <div
        key={key}
        className="text-base flex items-center border-b border-gray-lighter-05"
        style={style}
      >
        {date && format(new Date(date), dateFormat)}
      </div>
    );
  };

  update = () => this.refContainer.current.forceUpdateGrids();

  cellRendererRoot = ({ key, columnIndex, rowIndex, style }) => {
    const { onChange, onDelete, data } = this.props;
    const columnName = columns[columnIndex].name;
    if (rowIndex === 0) {
      return (
        <div
          key={key}
          style={style}
          className="text-base font-medium flex items-center border-b border-gray-lighter-05"
        >
          {columnName}
        </div>
      );
    }

    const dataElement = data[rowIndex - stickyRow];

    if (columnName === 'Tag') {
      return dataElement.product ? (
        <div key={key} style={style} className="flex items-center border-b border-gray-lighter-05">
          <ProductCard imageClassName="w-11-5" className="w-11/12" {...dataElement.product} />
        </div>
      ) : (
        this.cellRendererMarketplace(style, dataElement.marketplace, key)
      );
    }
    if (columnName === 'Note') {
      return (
        <NoteCell
          key={key}
          style={style}
          note={dataElement.description}
          onChange={() => onChange(rowIndex - stickyRow)}
          onDelete={() => onDelete(dataElement.id)}
        />
      );
    }
    if (columnName === 'Date') {
      return this.cellRendererDate(style, dataElement.datetime, key);
    }

    return <div style={style} />;
  };

  cellRendererMarketplace = (style, marketplaceId, key) => {
    const marketplaceItem = getFlagsById(marketplaceId || 0);

    return (
      <div key={key} className="flex items-center border-b border-gray-lighter-05" style={style}>
        <SafeImage src={marketplaceItem?.src} alt="flag" className="h-6" />
        <p className="font-medium ml-2">{marketplaceItem?.name}</p>
      </div>
    );
  };

  render() {
    const { data } = this.props;
    return (
      <div className="sm-h-400px">
        <AutoSizer>
          {({ height, width }) => (
            <MultiGrid
              ref={this.refContainer}
              cellRenderer={this.cellRendererRoot}
              rowHeight={({ index }) => (index === 0 ? headerRowHeight : rowHeight)}
              height={height}
              width={width}
              columnCount={columns.length}
              rowCount={data.length + stickyRow}
              fixedRowCount={stickyRow}
              columnWidth={getColumnWidth}
              styleTopRightGrid={styledTopGrid}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

NotesTable.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default NotesTable;
