import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  label: {
    justifyContent: 'flex-start'
  }
});

const ContextMenu = ({ options, callback }) => {
  const classes = useStyles();
  const buttonsCommonProps = {
    style: { height: '2.6875rem' },
    classes: { label: classes.label }
  };
  const buttonCommonClasses = 'normal-case text-gray-darkest hover:text-white sm-w-140px px-4';
  return (
    <div className="p-2 flex flex-col">
      {options.map(({ onClick, className, title }) => (
        <Button
          key={title}
          onClick={event => {
            callback();
            onClick(event);
          }}
          className={classNames(className || 'hover-bg-grad-8', buttonCommonClasses)}
          {...buttonsCommonProps}
        >
          {title}
        </Button>
      ))}
    </div>
  );
};

ContextMenu.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  callback: PropTypes.func
};

ContextMenu.defaultProps = {
  callback: () => {}
};

export default ContextMenu;
