import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import { uiSelector } from 'seller/store/reducers';
import LoadingSpinner from '@components/common/LoadingSpinner';
import { setFontReadyAction } from 'seller/store/actions';
import { DEFAULT_OBSERVER_TIMEOUT } from './const';

export const weightList = [400, 500, 700];
export const robotoObserver = callback => {
  const promises = weightList.map(weight =>
    new FontFaceObserver('Roboto', { weight }).load(null, DEFAULT_OBSERVER_TIMEOUT)
  );
  Promise.all(promises).then(callback, err => {
    callback();
    console.log('Fonts load error', err);
  });
};

const FontObserver = ({ children }) => {
  const dispatch = useDispatch();
  const { fontReady } = useSelector(uiSelector);
  useEffect(() => {
    robotoObserver(() => dispatch(setFontReadyAction()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={classNames('w-full h-full absolute', { 'opacity-0': !fontReady })}>
        {children}
      </div>
      <LoadingSpinner loading={!fontReady} />
    </>
  );
};

FontObserver.propTypes = {
  children: PropTypes.node.isRequired
};

export default FontObserver;
