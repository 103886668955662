import axios from 'axios';

export const SUCCESS_STATUSES = [200, 201, 202, 204];
export const HTTP_409_CONFLICT = 409;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_410_GONE = 410;
export const HTTP_SERVER_UNAVAILABLE = 502;
export const COMMON_HTTP_ERRORS = [HTTP_UNAUTHORIZED, HTTP_SERVER_UNAVAILABLE];
export const isCommonError = status => COMMON_HTTP_ERRORS.includes(status);
export const isSuccessStatus = status => SUCCESS_STATUSES.includes(status);
export const INCORRECT_CREDENTIALS_ERROR_TEXT = 'Unable to log in with provided credentials.'; // FIXME: improve login failed error trigger
export const EXISTED_EMAIL_ERROR_TEXT =
  'This email is already registered to an existing user account. Please sign in to continue.'; // FIXME: improve existed email error trigger
export const TOKEN_EXPIRED_ERROR_TEXT = 'Signature has expired.'; // FIXME: improve expired token error trigger
export const TOKEN_REFRESH_PERIOD_EXPIRED = 'Refresh has expired.'; // FIXME: improve expired refresh token period error trigger

const handleResponseStatus = response => {
  const { status } = response;
  if (isSuccessStatus(status)) return response;
  throw new Error(`Request failed with status code ${status}`);
};

export const axiosCall = {
  get: async (url, config) => handleResponseStatus(await axios.get(url, config)),
  post: async (url, data, config) => handleResponseStatus(await axios.post(url, data, config)),
  put: async (url, data, config) => handleResponseStatus(await axios.put(url, data, config)),
  delete: async (url, config) => handleResponseStatus(await axios.delete(url, config)),
  patch: async (url, data, config) => handleResponseStatus(await axios.patch(url, data, config))
};

export const getCancelToken = () => {
  const { token, cancel } = axios.CancelToken.source();
  return { cancelToken: token, cancelRequest: cancel };
};
