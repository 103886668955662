import { put, takeLatest, takeEvery, call } from '@redux-saga/core/effects';
import { startOfMonth, endOfMonth, isAfter, addMonths, startOfDay, endOfToday } from 'date-fns';
import API, { selectedProductsToAPI } from '@sm/api';
import { axiosCall, dateFromStr, dateToStr, getTempId, isArray } from '@utils';
import { setNotificationMsgAction, setSuccessMsgAction } from 'seller/store/actions';
import { PROFIT_AND_LOSS_REPORT_TYPE, REPORT_IN_PROGRESS } from 'seller/pages/reports/consts';
import {
  POST_REPORT_REQUEST,
  PULL_REPORT_DETAILS,
  PULL_REPORTS_LIST,
  DELETE_REPORT,
  RECALCULATE_REPORT,
  RENAME_REPORT,
  setReportDetailsAction,
  setReportsFetchingAction,
  setReportsListAction,
  setReportsListActiveTabAction,
  addReportInProcessingAction,
  removeReportInProcessingAction,
  setReportInProgressAction
} from './action';

const PULL_REPORTS_SILENT_ACTION = { payload: { silent: true } };

function* pullReportsListSaga(action) {
  const isSilent = action?.payload?.silent;
  const tempReportId = action?.payload?.tempReportId;
  try {
    if (!isSilent) yield put(setReportsFetchingAction({ reports: true }));
    const { data } = yield axiosCall.get(API.reports);
    if (isArray(data)) yield put(setReportsListAction({ reports: data, tempReportId }));
  } catch (e) {
    console.log('pullSearchBookmarksSaga', e);
    yield put(setNotificationMsgAction('Pull reports failed'));
    if (tempReportId) yield put(removeReportInProcessingAction(tempReportId));
  } finally {
    if (!isSilent) yield put(setReportsFetchingAction({ reports: false }));
  }
}

function* pullReportDetailsSaga({ payload: reportId }) {
  try {
    yield put(setReportsFetchingAction({ reportDetails: true }));
    const { data } = yield axiosCall.get(`${API.reports}${reportId}`);
    yield put(setReportDetailsAction(data));
  } catch (e) {
    console.log('pullReportDetailsSaga', e);
    yield put(setNotificationMsgAction('Pull report details failed'));
  } finally {
    yield put(setReportsFetchingAction({ reportDetails: false }));
  }
}

const getWholeMothDateRange = ({ from, to }, lifeTime) => {
  let reportFromDate = startOfMonth(dateFromStr(from));
  if (isAfter(startOfDay(lifeTime), reportFromDate)) reportFromDate = addMonths(reportFromDate, 1);
  let reportToDate = endOfMonth(dateFromStr(to));
  if (isAfter(reportFromDate, reportToDate)) reportToDate = addMonths(reportToDate, 1);
  if (isAfter(reportToDate, endOfToday())) reportToDate = endOfToday();
  return { from: dateToStr(reportFromDate), to: dateToStr(reportToDate) };
};

function* postReportRequestSaga(action) {
  const { data, callback } = action.payload;
  const tempReportId = getTempId();
  let { dateRange } = data;
  if (data.reportType === PROFIT_AND_LOSS_REPORT_TYPE)
    dateRange = getWholeMothDateRange(dateRange, data.lifeTime);
  try {
    const reportData = {
      type: data.reportType,
      marketplace_id: data.marketplace,
      ...selectedProductsToAPI(data.selectedProducts),
      name: data.name,
      ...dateRange
    };
    const tempReport = {
      name: reportData.name,
      type: reportData.type,
      start: reportData.from,
      end: reportData.to,
      status: REPORT_IN_PROGRESS,
      isBlocked: true,
      request_date: new Date().toISOString(),
      marketplace_id: reportData.marketplace_id,
      id: tempReportId
    };
    yield put(addReportInProcessingAction(tempReport));
    yield put(setReportsListActiveTabAction(data.reportType));
    if (callback) callback();
    yield axiosCall.post(API.reports, reportData);
    yield call(pullReportsListSaga, { payload: { silent: true, tempReportId } });
  } catch (e) {
    console.log('postReportRequestSaga', e);
    yield put(setNotificationMsgAction('Report creating failed'));
    yield put(removeReportInProcessingAction(tempReportId));
  }
}

function* deleteReportsSaga({ payload: reportId }) {
  yield put(setReportInProgressAction(reportId));
  try {
    yield axiosCall.delete(`${API.reports}${reportId}`);
    yield put(setSuccessMsgAction('Report deleted successfully'));
  } catch (e) {
    console.log('pullReportDetailsSaga', e);
    yield put(setNotificationMsgAction('Delete report failed'));
  } finally {
    yield call(pullReportsListSaga, PULL_REPORTS_SILENT_ACTION);
  }
}

function* recalculateReportSaga({ payload: reportId }) {
  yield put(setReportInProgressAction(reportId));
  try {
    yield axiosCall.post(`${API.reports}${reportId}`);
  } catch (e) {
    console.log('recalculateReportSaga', e);
    yield put(setNotificationMsgAction('Recalculate report failed'));
  } finally {
    yield call(pullReportsListSaga, PULL_REPORTS_SILENT_ACTION);
  }
}

function* renameReportSaga({ payload }) {
  try {
    yield put(setReportInProgressAction(payload.id));
    yield axiosCall.patch(`${API.reports}${payload.id}`, { name: payload.name });
  } catch (e) {
    console.log('renameReportSaga', e);
    yield put(setNotificationMsgAction('Rename report failed'));
  } finally {
    yield call(pullReportsListSaga, PULL_REPORTS_SILENT_ACTION);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* reportsSagaWatcher() {
  yield takeLatest(PULL_REPORTS_LIST, pullReportsListSaga);
  yield takeEvery(POST_REPORT_REQUEST, postReportRequestSaga);
  yield takeLatest(PULL_REPORT_DETAILS, pullReportDetailsSaga);
  yield takeEvery(DELETE_REPORT, deleteReportsSaga);
  yield takeEvery(RECALCULATE_REPORT, recalculateReportSaga);
  yield takeEvery(RENAME_REPORT, renameReportSaga);
}
