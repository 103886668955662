import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiSelector, userPreferencesSelector } from 'seller/store/reducers';
import DataTable from '@components/data-table/DataTable';
import { setFilteredTableDataAction } from 'seller/store/actions';
import { setTableConfigAction } from 'seller/store/saga/userPreferences';
import { sendTextError } from '@utils';

const DataTableContainer = props => {
  const dispatch = useDispatch();
  const ui = useSelector(uiSelector);
  const userPreferences = useSelector(userPreferencesSelector);

  const setFilteredTableData = useCallback(
    (...args) => dispatch(setFilteredTableDataAction(...args)),
    [dispatch]
  );
  const setTableConfig = useCallback((...args) => dispatch(setTableConfigAction(...args)), [
    dispatch
  ]);
  const { data } = props;
  if (!Array.isArray(data)) {
    sendTextError('Wrong data table args', data);
    return null;
  }
  return (
    <DataTable
      {...props}
      ui={ui}
      userPreferences={userPreferences}
      setFilteredTableData={setFilteredTableData}
      setTableConfig={setTableConfig}
    />
  );
};

export default DataTableContainer;
