import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

import { getFormattedValue } from '@utils/format';
import LoadingSpinner from '@components/common/LoadingSpinner';
import { DashedUnderlineWrapper, MultiLineTruncate } from '@components/index';
import Colors from 'styles/colors';

import AccordionWrapper from './AccordionWrapper';

const ROOT_DEEP_NESTING = 1;
const SECOND_LEVEL_DEEP_NESTING = 2;

const getRowExpandId = (rowData, nestingLevel, index) =>
  `${rowData.title}-${nestingLevel}-${index}`;

const getBreakdownExpandedRow = (setExpandConfig, expandConfig, rowData, nestingLevel, index) => {
  const {
    title,
    value,
    children,
    rootMetric,
    separator,
    profitColor,
    isPercentage,
    optional,
    integer,
    hidden,
    tooltip,
    alwaysExpanded
  } = rowData;
  if (hidden) return null;
  if (separator)
    return (
      <div className="w-full py-2">
        <div className="bg-gray-black" style={{ height: '1px' }} />
      </div>
    );

  const getValueView = () => {
    if (!value) return <div className="opacity-50 ">none</div>;
    if (isPercentage) return <div>{value.toFixed(2)}%</div>;
    return <div>{getFormattedValue(value, !integer)}</div>;
  };
  const summaryView = (
    <div
      className={classNames('text-base w-full flex py-1 items-center justify-between', {
        'pl-6': !children && nestingLevel > ROOT_DEEP_NESTING && !rootMetric,
        'pl-4': rootMetric,
        'text-green-light': profitColor && value && value > 0,
        'text-red': profitColor && value && value < 0,
        'text-white': !profitColor || !value
      })}
      style={{ minHeight: nestingLevel < SECOND_LEVEL_DEEP_NESTING ? '2.5rem' : '2rem' }}
    >
      <div
        className={classNames('mr-4 min-w-0 opacity-80', {
          'font-medium': nestingLevel <= ROOT_DEEP_NESTING
        })}
      >
        <DashedUnderlineWrapper tooltipContent={tooltip || ''} placement="left" arrow>
          <MultiLineTruncate lines={2} text={title} />
        </DashedUnderlineWrapper>
      </div>
      {getValueView()}
    </div>
  );

  if (optional && !value) return null;
  if (!children?.length) return summaryView;
  const expandId = getRowExpandId(rowData, nestingLevel, index);
  const expanded = expandConfig[expandId];

  const onExpand = () => setExpandConfig({ ...expandConfig, [expandId]: !expanded });

  return (
    <AccordionWrapper
      summary={summaryView}
      expanded={!!expanded}
      onChange={onExpand}
      alwaysExpanded={alwaysExpanded}
    >
      {children.map((childRowData, childIndex) =>
        getBreakdownExpandedRow(
          setExpandConfig,
          expandConfig,
          childRowData,
          nestingLevel + 1,
          childIndex
        )
      )}
    </AccordionWrapper>
  );
};

const getExpandConfig = data => {
  const result = {};
  const addRowIdToConfig = (rowData, nestingLevel, index) => {
    if (rowData.children) {
      result[getRowExpandId(rowData, nestingLevel, index)] = !!rowData?.alwaysExpanded;
      rowData.children.forEach((childRowData, childIndex) =>
        addRowIdToConfig(childRowData, nestingLevel + 1, childIndex)
      );
    }
  };
  data.forEach((rowData, index) => addRowIdToConfig(rowData, ROOT_DEEP_NESTING, index));
  return result;
};

function ModernBreakdownView({ data, loading, headerTitle, headerButton }) {
  const [expandConfig, setExpandConfig] = useState({});
  const onHeaderClick = () => {
    const allRowsExpanded = Object.values(expandConfig).reduce((acc, item) => acc && item, true);
    const result = Object.keys(expandConfig).reduce(
      (acc, key) => ({ ...acc, [key]: !allRowsExpanded }),
      {}
    );
    setExpandConfig(result);
  };
  useEffect(() => {
    setExpandConfig(getExpandConfig(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div
      className={classNames(
        'relative w-96 bg-gray-darkest py-2 pl-4 rounded scroll-container black-scroll-container overflow-auto'
      )}
      style={{
        minHeight: loading ? '18rem' : '0',
        maxHeight: '35.5rem',
        scrollbarGutter: 'stable',
        paddingRight: 'calc( 1rem - 6px )'
      }}
    >
      <LoadingSpinner loading={loading} color={Colors.white.default} className="opacity-75" />
      <Box
        className="h-6 my-2 text-white text-base font-medium flex justify-between items-center cursor-pointer"
        onClick={onHeaderClick}
      >
        <p>{headerTitle}</p>
        <div className="opacity-50">{headerButton}</div>
      </Box>
      <div className="">
        {data.map((rowData, index) =>
          getBreakdownExpandedRow(setExpandConfig, expandConfig, rowData, ROOT_DEEP_NESTING, index)
        )}
      </div>
    </div>
  );
}

ModernBreakdownView.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  headerTitle: PropTypes.bool.isRequired,
  headerButton: PropTypes.func.isRequired
};

export default ModernBreakdownView;
