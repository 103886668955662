import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';

function CustomButton(props) {
  const { children, className, onClick, active, paddingY } = props;
  return (
    <Box border={0}>
      <Button
        variant="outlined"
        border={1}
        className={classNames(className, paddingY)}
        onClick={onClick}
        disabled={!active}
      >
        {children}
      </Button>
    </Box>
  );
}

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  paddingY: PropTypes.string
};

CustomButton.defaultProps = {
  className: '',
  onClick: () => {},
  active: true,
  paddingY: 'py-2'
};

export default CustomButton;
