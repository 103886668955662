import { takeLatest } from 'redux-saga/effects';
import { select, takeEvery } from '@redux-saga/core/effects';

import { axiosCall, dateToStr, monthToStr } from '@utils';
import API from '@sm/api';
import { pullDataBaseSaga } from 'seller/store/saga/base';
import { monthlyBrandReviewSelector } from 'seller/store/reducers';
import {
  PATCH_MBR_REPORT_DATA,
  PULL_MBR_REPORT,
  PULL_PRIMARY_MBR_CHART_DATA,
  PULL_SECONDARY_MBR_CHART_DATA,
  setMbrPatchLoadingAction,
  setMbrReportAction,
  setMbrReportLoadingAction,
  setPrimaryMbrChartDataAction,
  setPrimaryMbrChartLoadingAction,
  setSecondaryMbrChartDataAction,
  setSecondaryMbrChartLoadingAction
} from './index';

function* pullMbrReportSaga(action) {
  const params = { month: monthToStr(action.payload.month) };
  yield pullDataBaseSaga(
    () => axiosCall.get(API.monthly_brand_review.root, { params }),
    setMbrReportAction,
    params,
    setMbrReportLoadingAction
  );
}
const getPullMbrChartSaga = (setDataAction, setLoadingAction) => {
  return function* pullMbrChartSaga(action) {
    yield pullDataBaseSaga(
      () =>
        axiosCall.get(API.monthly_brand_review.chart, {
          params: {
            from: dateToStr(action.payload.from),
            to: dateToStr(action.payload.to),
            metric: action.payload.metric
          }
        }),
      setDataAction,
      [],
      setLoadingAction
    );
  };
};

function* patchMbrReportSaga(action) {
  const { reportData } = yield select(monthlyBrandReviewSelector);
  yield pullDataBaseSaga(
    () => axiosCall.patch(`${API.monthly_brand_review.root}${reportData.id}/`, action.payload),
    setMbrReportAction,
    {},
    setMbrPatchLoadingAction
  );
}
export function* monthlyBrandReviewSagaWatcher() {
  yield takeLatest(PULL_MBR_REPORT, pullMbrReportSaga);
  yield takeEvery(PATCH_MBR_REPORT_DATA, patchMbrReportSaga);
  yield takeLatest(
    PULL_PRIMARY_MBR_CHART_DATA,
    getPullMbrChartSaga(setPrimaryMbrChartDataAction, setPrimaryMbrChartLoadingAction)
  );
  yield takeLatest(
    PULL_SECONDARY_MBR_CHART_DATA,
    getPullMbrChartSaga(setSecondaryMbrChartDataAction, setSecondaryMbrChartLoadingAction)
  );
}
