import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import { ArrowIcon } from '@components/icons';
import Colors from 'styles/colors';

const BackIconButton = ({ onClick, className }) => {
  return (
    <ButtonBase
      variant="text"
      className={classNames(
        'p-2 normal-case text-sm rounded hover:bg-gray-lighter transition duration-200',
        className
      )}
      size="small"
      onClick={onClick}
    >
      <ArrowIcon fill={Colors.gray.darkest} width="14px" height="14px" />
    </ButtonBase>
  );
};

BackIconButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

BackIconButton.defaultProps = {
  onClick: () => {},
  className: ''
};

export default BackIconButton;
