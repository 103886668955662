import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import CancelConfirmButtons from '@components/buttons/CancelConfirmButtons';

function DeleteDialog(props) {
  const { open, onDelete, onClose } = props;
  return (
    <Dialog open={open} PaperProps={{ className: 'p-4' }} onClose={onClose}>
      <div className="flex flex-col mb-2">
        <p className="text-black font-medium">Delete Note</p>
        <div className="underline-md bg-grad" />
      </div>
      <p className="text-base text-black mb-12">Do you really want to delete this note?</p>
      <CancelConfirmButtons
        onConfirm={onDelete}
        onCancel={onClose}
        confirmText="Delete"
        confirmActive
      />
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DeleteDialog;
