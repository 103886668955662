import {
  SET_KEYWORD,
  START_SEARCH,
  SET_RESULT,
  SET_INPUT_FAILURE,
  ACTIVATE_SEARCH,
  SET_SELECTED_PRODUCTS_TO_REDUCER
} from '../actions';
import {
  SET_BOOKMARKS,
  SET_BOOKMARKS_SIDEBAR_SHOW,
  SET_BOOKMARKS_LOADING,
  SET_SELECTED_BOOKMARK,
  CLEANUP_BOOKMARK
} from '../saga/bookmarks';

const initialState = {
  keyword: '',
  products: {},
  selectedProducts: [],
  tempBookmark: null,
  active: false,
  loading: false,
  selectedBookmark: null,
  bookmarks: null,
  bookmarksSidebarOpen: false,
  bookmarksLoading: false,
  isPersistBookmark: false
};

export const productSearchSelector = state => state.productSearch;

const productSearch = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_BOOKMARK: {
      return { ...state, selectedBookmark: action.payload };
    }
    case SET_BOOKMARKS_LOADING: {
      return { ...state, bookmarksLoading: action.payload };
    }
    case SET_BOOKMARKS_SIDEBAR_SHOW: {
      return { ...state, bookmarksSidebarOpen: action.payload };
    }
    case SET_BOOKMARKS: {
      return { ...state, bookmarks: action.payload };
    }
    case CLEANUP_BOOKMARK: {
      return { ...state, bookmarks: state.bookmarks?.filter(item => item.id !== action.payload) };
    }
    case SET_KEYWORD: {
      const { keyword } = action;
      let { active } = state;
      if (keyword.length <= 2 && active) {
        active = false;
      }

      return {
        ...state,
        active,
        keyword
      };
    }
    case SET_RESULT: {
      const { products } = action;
      return {
        ...state,
        loading: false,
        products
      };
    }
    case SET_SELECTED_PRODUCTS_TO_REDUCER: {
      const { selectedProducts, tempBookmark, isPersistBookmark } = action.payload;
      return {
        ...state,
        selectedProducts,
        selectedBookmark: selectedProducts.length ? state.selectedBookmark : null,
        tempBookmark,
        isPersistBookmark
      };
    }
    case START_SEARCH: {
      return {
        ...state,
        active: true,
        loading: true
      };
    }
    case SET_INPUT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false
      };
    }
    case ACTIVATE_SEARCH: {
      const { active } = action;
      if (active && state.keyword.length <= 2) {
        return state;
      }
      return {
        ...state,
        active
      };
    }
    default: {
      return state;
    }
  }
};

export default productSearch;
