import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { uiSelector } from 'seller/store/reducers';
import { HEADER_HEIGHT, SELECTOR_VIEW_HEIGHT } from '@sm/layout';

const ContentHeightWrapper = ({
  children,
  selector,
  heightReduce,
  className,
  extraWrapperClasses
}) => {
  const { viewportHeight } = useSelector(uiSelector);
  const getContent = () => {
    if (!extraWrapperClasses) return children;
    return <div className={extraWrapperClasses}>{children}</div>;
  };
  return (
    <div
      style={{
        height: `calc(${viewportHeight} - ${HEADER_HEIGHT} - ${
          selector ? SELECTOR_VIEW_HEIGHT : '0px'
        } - ${heightReduce})`
      }}
      className={className}
    >
      {getContent()}
    </div>
  );
};

ContentHeightWrapper.propTypes = {
  className: PropTypes.string,
  heightReduce: PropTypes.string,
  extraWrapperClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
  selector: PropTypes.bool
};

ContentHeightWrapper.defaultProps = {
  className: '',
  heightReduce: '0px',
  selector: false,
  extraWrapperClasses: ''
};

export default ContentHeightWrapper;
