/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const sendError = (errorEvent, data, errorText) => {
  Sentry.withScope(scope => {
    if (data) scope.setExtra('data', data);
    if (errorText) scope.setTag('error-type', errorText);
    Sentry.captureException(errorEvent);
  });
};

const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;

// eslint-disable-next-line import/no-mutable-exports
export let sendTextError = (...args) => console.log(...args);

export const configSentry = config => {
  Sentry.configureScope(scope => scope.setUser(config));
};

export const initSentry = (store, addSentSentryErrorTypeAction) => {
  if (!SENTRY_KEY) return;
  Sentry.init({
    dsn: SENTRY_KEY,
    release: process.env.REACT_APP_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
  sendTextError = (errorText, data) => {
    const state = store.getState();
    const { sentErrorsTypes } = state.sentry;
    if (sentErrorsTypes?.includes(errorText)) return;
    store.dispatch(addSentSentryErrorTypeAction(errorText));
    Sentry.withScope(scope => {
      if (data) scope.setExtra('data', data);
      if (data?.url) scope.setTag('request-url', data?.url);
      if (data?.status) scope.setTag('request-status', data?.status);
      Sentry.captureMessage(errorText);
    });
  };
};

const RESTRICTED_FIELD_NAMES = [
  'token',
  'email',
  'username',
  'mwstoken',
  'sellerid',
  'companyname',
  'address',
  'taxid'
];

const isRestrictedFieldName = fieldName =>
  RESTRICTED_FIELD_NAMES.find(restrictedName =>
    fieldName
      ?.toLowerCase()
      ?.replaceAll('_', '')
      ?.includes(restrictedName)
  );

export const getFilteredRequestData = requestData => {
  if (!requestData) return requestData;
  try {
    const parsedRequestData = JSON.parse(requestData);
    Object.keys(parsedRequestData).forEach((key, i) => {
      if (isRestrictedFieldName(key)) {
        delete parsedRequestData[key];
        const newKey = key.toLowerCase().replace('key', `key-${i}`);
        parsedRequestData[newKey] = 'restricted key was here';
      }
    });
    return JSON.stringify(parsedRequestData);
  } catch (e) {
    return requestData;
  }
};
