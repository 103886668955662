import { SET_ORDERS, SET_ORDERS_PAGE_ORDER_EVENT_TYPE, START_ORDERS_LOADING } from '../saga/orders';

const initialState = {
  ordersList: [],
  ordersLoading: false,
  orderEventType: null
};

export const ordersSelector = state => state.orders;

const orders = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS: {
      const { ordersList } = action;
      return {
        ...state,
        ordersList,
        ordersLoading: false
      };
    }
    case START_ORDERS_LOADING: {
      return {
        ...state,
        orders: [],
        ordersLoading: true
      };
    }
    case SET_ORDERS_PAGE_ORDER_EVENT_TYPE: {
      return { ...state, orderEventType: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default orders;
