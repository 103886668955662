import colors from 'styles/colors';

export const formatMap = {
  Hourly: 'h aaaa',
  Daily: 'd MMM',
  Weekly: 'd MMM',
  Monthly: 'MMM yyyy'
};

export const mainLineColor = colors.yellow.default;
export const mainAxis = 'main';

export const secondLineColor = colors.blue.default;
export const secondAxis = 'second';

export const formatValue = value => {
  return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
