import { isLargeSize, isMediumSize, isSmallSize, isXLargeSize } from '@sm/WindowSizes';

// eslint-disable-next-line import/prefer-default-export
export const getAdjustedWidth = (small, medium, large, xLarge, full) => {
  if (isSmallSize()) return small;
  if (isMediumSize()) return medium;
  if (isLargeSize()) return large;
  if (isXLargeSize()) return xLarge;
  return full;
};
