import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, TextField, withStyles } from '@material-ui/core';
import MarketplaceSelector from 'seller/containers/selector-view/MarketplaceSelector';
import { isMediumSize } from '@sm/WindowSizes';
import CancelConfirmButtons from '@components/buttons/CancelConfirmButtons';
import DatePicker from '@components/date-picker/DatePicker';
import { createRange } from '@utils';
import { INPUT_ROOT_STYLES } from '@components/inputs/TextFieldIcon';
import NoteDialogInput from './NoteDialogInput';

const minNoteLength = 3;

const CustomTextArea = withStyles({
  root: INPUT_ROOT_STYLES
})(TextField);

function NoteDialog(props) {
  const { marketplaces, onAdd, onEdit, open, onClose, editData, lifetime } = props;

  const [marketplace, setMarketplace] = useState(editData.marketplace || marketplaces[0]);
  const [date, setDate] = useState(new Date(editData.datetime || new Date()));
  const [note, setNote] = useState(editData.description || '');
  const [tag, setTag] = useState(editData.product);

  function onConfirm() {
    if (editData) {
      onEdit(editData.id, note, date, marketplace, tag);
    } else {
      onAdd(note, date, marketplace, tag);
    }
  }

  return (
    <Dialog
      open={open}
      fullScreen={isMediumSize()}
      onClose={onClose}
      PaperProps={{ className: 'overflow-hidden' }}
    >
      <div className="p-4 w-full md:sm-w-448px h-full flex flex-col justify-center">
        <div className="flex flex-col">
          <p className="text-black text-lg font-medium">
            {editData ? 'Edit a Note' : 'Add a Note'}
          </p>
          <div className="bg-grad-2 underline-md" />
        </div>
        <MarketplaceSelector
          marketplaces={marketplaces}
          marketplaceID={marketplace}
          onMarketplaceChanged={newMarketplace => {
            setMarketplace(newMarketplace);
            setTag(null);
          }}
          className="w-32 mt-2"
          showRegionTitle
        />
        <NoteDialogInput
          marketplaceID={marketplace}
          onChange={setTag}
          value={tag}
          initTag={editData && editData.product}
        />
        <div className="flex items-center border-gray-default h-12 rounded mt-3">
          <DatePicker
            oneDaySelectionAllowed
            lifeTime={lifetime}
            onRangeChange={newDate => setDate(newDate.from)}
            initRange={createRange(date, date)}
            className="w-full px-4 cursor-pointer"
            profile={{ marketplaces }}
            hideTimezone
          />
        </div>
        <CustomTextArea
          multiline
          rows="4"
          className="mt-4 w-full"
          label="Note"
          placeholder="Use this to describe what changed or marked this moment"
          variant="outlined"
          inputProps={{ className: 'text-sm' }}
          InputLabelProps={{ className: 'text-sm' }}
          value={note}
          onChange={event => setNote(event.currentTarget.value)}
        />
        <CancelConfirmButtons
          onConfirm={onConfirm}
          confirmActive={note.length >= minNoteLength}
          confirmText={editData ? 'Edit Note' : 'Add Note'}
          className="mt-4"
          onCancel={onClose}
        />
      </div>
    </Dialog>
  );
}

NoteDialog.propTypes = {
  marketplaces: PropTypes.instanceOf(Array).isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]).isRequired,
  lifetime: PropTypes.instanceOf(Date).isRequired
};

export default NoteDialog;
