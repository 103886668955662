import {
  AMAZON_SALES_CHANNEL_ID,
  SHOPIFY_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID
} from '@sm/SalesChannels';
import {
  PNL_DASHBOARD_SET_DATE_RANGE,
  PNL_DASHBOARD_SET_MARKETPLACES,
  PNL_DASHBOARD_SET_METRICS,
  PNL_DASHBOARD_SET_PLATFORMS,
  PNL_DASHBOARD_SET_SELECTED_BRANDS,
  PNL_DASHBOARD_SET_TARGETS_ENABLED,
  PNL_DASHBOARD_SET_TIME_GRAIN,
  SET_PNL_DASHBOARD_BRANDS,
  SET_PNL_DASHBOARD_CHART_METRICS,
  SET_PNL_DASHBOARD_GRANULARITY,
  SET_PNL_DASHBOARD_PERCENTAGE_ENABLED,
  SET_PNL_DASHBOARD_SEGMENTATION,
  SET_PNL_DASHBOARD_TABLE_DATA,
  SET_PNL_DASHBOARD_TABLE_LOADING
} from 'seller/store/saga/pnlDashboard';

const initialState = {
  marketplaces: [0],
  platforms: [AMAZON_SALES_CHANNEL_ID, SHOPIFY_SALES_CHANNEL_ID, WALMART_SALES_CHANNEL_ID],
  brands: null,
  selectedBrands: [],
  segmentation: [
    { key: 'metric', title: 'Metric', disabled: false, locked: true },
    { key: 'brand', title: 'Brand', disabled: false },
    { key: 'sales_channel', title: 'Sales Channel', disabled: false }
  ],
  chartMetrics: [],
  metrics: ['sales', 'refunds', 'advertising'],
  granularity: 2,
  timeGrain: 'Month',
  isTargetsEnabled: true,
  isPercentageEnabled: false,
  dateRange: null,
  tableData: null,
  tableLoading: false
};
export const pnlDashboardSelector = state => state.pnlDashboard;

const pnlDashboard = (state = initialState, action) => {
  switch (action.type) {
    case PNL_DASHBOARD_SET_MARKETPLACES: {
      return { ...state, marketplaces: action.payload };
    }
    case PNL_DASHBOARD_SET_PLATFORMS: {
      return { ...state, platforms: action.payload };
    }
    case PNL_DASHBOARD_SET_TARGETS_ENABLED: {
      return { ...state, isTargetsEnabled: action.payload };
    }
    case PNL_DASHBOARD_SET_DATE_RANGE: {
      return { ...state, dateRange: action.payload };
    }
    case PNL_DASHBOARD_SET_METRICS: {
      return { ...state, metrics: action.payload };
    }
    case PNL_DASHBOARD_SET_SELECTED_BRANDS: {
      return { ...state, selectedBrands: action.payload };
    }
    case PNL_DASHBOARD_SET_TIME_GRAIN: {
      return { ...state, timeGrain: action.payload };
    }
    case SET_PNL_DASHBOARD_TABLE_LOADING: {
      return { ...state, tableLoading: action.payload };
    }
    case SET_PNL_DASHBOARD_TABLE_DATA: {
      return { ...state, tableData: action.payload };
    }
    case SET_PNL_DASHBOARD_SEGMENTATION: {
      return { ...state, segmentation: action.payload };
    }
    case SET_PNL_DASHBOARD_PERCENTAGE_ENABLED: {
      return { ...state, isPercentageEnabled: action.payload };
    }
    case SET_PNL_DASHBOARD_BRANDS: {
      return { ...state, brands: action.payload };
    }
    case SET_PNL_DASHBOARD_GRANULARITY: {
      return { ...state, granularity: action.payload };
    }
    case SET_PNL_DASHBOARD_CHART_METRICS: {
      return { ...state, chartMetrics: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default pnlDashboard;
