import {
  SET_LAST_GLOBAL_SYSTEM_NOTIFICATION_ID,
  SET_LAST_SHOWN_AVAILABLE_APP_VERSION
} from '../saga/globalSystemNotification';
import { USER_LOGOUT } from '../actions';

const initialState = {
  lastIgnoredAvailableAppVersion: null,
  lastShownGlobalSystemNotificationId: null
};

export const globalSystemNotificationSelector = state => state.globalSystemNotification;

const globalSystemNotification = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_SHOWN_AVAILABLE_APP_VERSION: {
      return { ...state, lastIgnoredAvailableAppVersion: action.payload };
    }
    case SET_LAST_GLOBAL_SYSTEM_NOTIFICATION_ID: {
      return { ...state, lastShownGlobalSystemNotificationId: action.payload };
    }
    case USER_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default globalSystemNotification;
