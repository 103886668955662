import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import { commonPersistReducerMigration } from './migration';

const persistConfig = {
  key: 'sellermetrix',
  storage,
  whitelist: ['globalSystemNotification', 'reactBoundary', 'userPreferences'],
  stateReconciler: autoMergeLevel2,
  version: 1,
  migrate: createMigrate(commonPersistReducerMigration)
};

export const saga = createSagaMiddleware();

const enhancer = composeWithDevTools(applyMiddleware(thunk, saga));

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, enhancer);

export const persistor = persistStore(store);

export default store;
