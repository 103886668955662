import { SET_NOTIFICATION_MSG, SET_PRIMARY_NOTIFICATION_MSG } from '../actions';

const initialState = {
  primaryNotification: '',
  primaryNotificationType: '',
  nodeTypePrimaryNotification: false,
  msg: '',
  msgType: '',
  nodeTypeMessage: false,
  autoHideMsg: false,
  closeOnBlur: true
};

export const notificationSelector = state => state.notification;

const notification = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_MSG: {
      const { msg, msgType, nodeTypeMessage, autoHideMsg, closeOnBlur } = action;
      return {
        ...state,
        msg,
        msgType,
        autoHideMsg,
        closeOnBlur,
        nodeTypeMessage: !!nodeTypeMessage
      };
    }
    case SET_PRIMARY_NOTIFICATION_MSG: {
      const { msg, msgType, nodeTypeMessage } = action;
      return {
        ...state,
        primaryNotification: msg,
        primaryNotificationType: msgType,
        nodeTypePrimaryNotification: !!nodeTypeMessage
      };
    }
    default: {
      return state;
    }
  }
};

export default notification;
