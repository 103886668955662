import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import FLAGS from '@sm/Flags';

import { getFormattedValue } from '@utils/format';
import Colors from 'styles/colors';
import { IdCardIcon, LayersIcon, EarthIcon } from '../icons';
import ProgressBar from '../progress-bar';
import IconInfo from '../icons/ic-info-circle.svg';

const useStylesTooltip = makeStyles(() => ({
  tooltip: {
    maxWidth: '11.25rem',
    textAlign: 'center',
    background: Colors.white.default,
    boxShadow: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.2)',
    color: Colors.black.default,
    fontSize: '0.75rem',
    fontWeight: 400,
    borderRadius: '‭0.125rem'
  }
}));

const tooltipTitle = 'An email will automatically be sent to you when the import is completed';

function AccountCardInfo(props) {
  const classesTooltip = useStylesTooltip();
  const { sellerId, products, marketplaces, state } = props;

  const cardStyle = {
    underline: {
      width: '1.375rem',
      height: '0.1875rem'
    },
    flagsContainer: {
      width: marketplaces?.length > 6 ? '9rem' : '8.125rem'
    },
    flag: {
      width: '1.875rem',
      height: '1.0625rem',
      margin: '0.125rem 0.125rem'
    }
  };

  const isOnboarding = state === 0;

  return (
    <div className="flex flex-col text-black">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="round bg-white-dark h-6 w-6 mr-2 flex items-center justify-center">
            <IdCardIcon fill={Colors.gray.dark} className="sm-w-14px sm-h-14px" />
          </div>
          <p className="text-sm font-medium">Seller ID:</p>
        </div>
        <p className="text-sm font-medium">{sellerId}</p>
      </div>
      <div className={`flex items-center justify-between mt-2 ${isOnboarding && 'mb-2'}`}>
        <div className="flex w-full justify-between items-center">
          <div className="round bg-white-dark h-6 w-6 mr-2 flex items-center justify-center">
            <LayersIcon className="sm-w-14px sm-h-14px" fill={Colors.gray.dark} />
          </div>
          <div className="flex flex-col w-11/12">
            <div className="flex justify-between items-center">
              <p className="text-sm font-medium">{isOnboarding ? 'Status:' : 'Products:'}</p>
              {!isOnboarding ? (
                <p className="text-sm font-medium">{getFormattedValue(products)}</p>
              ) : (
                <div className="flex items-center">
                  <p className="text-sm text-gray-dark mr-2">Importing</p>
                  <Tooltip title={tooltipTitle} placement="top" classes={classesTooltip}>
                    <div className="w-full cursor-pointer">
                      <img src={IconInfo} alt="info" width="1rem" height="1rem" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
            {isOnboarding && (
              <ProgressBar
                percentage={35}
                className="rounded-sm mt-2"
                pathClassName="rounded-sm"
                pathColor={Colors.grad.second}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex items-start justify-between my-2">
        <div className="flex items-center">
          <div className="round bg-white-dark h-6 w-6 mr-2 flex items-center justify-center">
            <EarthIcon fill={Colors.gray.dark} className="sm-w-14px sm-h-14px" />
          </div>
          <p className="text-sm font-medium">Marketplaces:</p>
        </div>
        <div
          className="flex content-center flex-wrap flex-row-reverse"
          style={cardStyle.flagsContainer}
        >
          {marketplaces.map(flagId => {
            const flag = FLAGS.find(flagInfo => flagInfo.id === flagId);
            if (!flag) {
              return null;
            }

            return (
              <img
                key={flag.src}
                className="rounded-sm"
                style={cardStyle.flag}
                src={flag.src}
                alt="flag"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

AccountCardInfo.propTypes = {
  sellerId: PropTypes.string.isRequired,
  products: PropTypes.number,
  state: PropTypes.number.isRequired,
  marketplaces: PropTypes.instanceOf(Array).isRequired
};

AccountCardInfo.defaultProps = {
  products: 0
};

export default AccountCardInfo;
