export const SET_LAST_SHOWN_AVAILABLE_APP_VERSION = 'SET_LAST_SHOWN_AVAILABLE_APP_VERSION';
export const SET_LAST_GLOBAL_SYSTEM_NOTIFICATION_ID = 'SET_LAST_GLOBAL_SYSTEM_NOTIFICATION_ID';
export const HANDLE_GLOBAL_SYSTEM_NOTIFICATION = 'HANDLE_GLOBAL_SYSTEM_NOTIFICATION';

export const setLastShownAvailableAppVersionAction = payload => ({
  type: SET_LAST_SHOWN_AVAILABLE_APP_VERSION,
  payload
});

export const setLastGlobalSystemNotificationIdAction = payload => ({
  type: SET_LAST_GLOBAL_SYSTEM_NOTIFICATION_ID,
  payload
});

export const handleGlobalSystemNotificationAction = payload => ({
  type: HANDLE_GLOBAL_SYSTEM_NOTIFICATION,
  payload
});
