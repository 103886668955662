import React, { useCallback, useReducer, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FlippedPopper } from '@components/poppers';

export const usePopover = (initState = null) => {
  const reducer = (state, action) => ({ ...state, ...action });
  const [popoverData, setPopoverData] = useReducer(reducer, initState || { target: null });
  const setPopoverTarget = useCallback(e => setPopoverData({ target: e.currentTarget }), []);
  const clearTarget = useCallback(() => setPopoverData({ target: null }), []);

  return {
    data: popoverData,
    target: popoverData.target,
    changeData: setPopoverData,
    targetClickHandler: setPopoverTarget,
    clearTarget
  };
};

export const usePopper = ({
  closeOnBlur,
  offset,
  placement,
  paperClassName,
  maxHeight,
  disablePortal,
  onClose,
  preventOverflow
}) => {
  const anchorRef = useRef(null);
  const [open, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const changeData = newData => setData({ ...data, ...newData });
  const show = () => setIsOpen(true);
  const hide = () => {
    setIsOpen(false);
    if (onClose) onClose();
  };
  const setAnchorRef = el => {
    anchorRef.current = el;
  };

  const wrapper = ({ children }) => {
    return (
      <FlippedPopper
        anchorEl={anchorRef.current}
        open={open}
        offset={offset}
        placement={placement}
        paperClassName={paperClassName}
        maxHeight={maxHeight}
        disablePortal={disablePortal}
        preventOverflow={preventOverflow}
      >
        <ClickAwayListener onClickAway={closeOnBlur ? hide : () => {}} touchEvent={false}>
          <div className="max-h-full overflow-auto flex flex-col scroll-container">{children}</div>
        </ClickAwayListener>
      </FlippedPopper>
    );
  };
  wrapper.propTypes = {
    children: PropTypes.node.isRequired
  };
  return {
    data,
    setData,
    changeData,
    anchorRef,
    setAnchorRef,
    show,
    hide,
    open,
    wrapper
  };
};

export const useNodeSize = () => {
  const [nodeWidth, setNodeWidth] = useState(0);
  const [nodeHeight, setNodeHeight] = useState(0);
  const nodeRef = useRef(null);
  useEffect(() => {
    if (
      nodeRef.current &&
      (nodeRef.current.clientWidth !== nodeWidth || nodeRef.current.clientHeight !== nodeHeight)
    ) {
      setNodeWidth(nodeRef.current.clientWidth);
      setNodeHeight(nodeRef.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { nodeRef, nodeWidth, nodeHeight };
};

export const useScrollbarIsVisible = () => {
  const nodeRef = useRef(null);
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setVisible(nodeRef.current?.scrollHeight > nodeRef.current?.clientHeight);
  });
  return { nodeRef, visible };
};
