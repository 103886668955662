import React from 'react';
import PropTypes from 'prop-types';
// https://css-tricks.com/almanac/properties/l/line-clamp/
const MultiLineTruncate = ({ text, lines, className }) => {
  return (
    <p
      className={className}
      style={{
        display: '-webkit-box',
        '-webkit-line-clamp': `${lines}`,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
      }}
    >
      {text}
    </p>
  );
};

MultiLineTruncate.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  lines: PropTypes.number.isRequired
};

MultiLineTruncate.defaultProps = {
  className: ''
};

export default MultiLineTruncate;
