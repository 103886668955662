import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'styles/colors';
import TextFieldIcon from './TextFieldIcon';

function GenerateInputs(props) {
  const { data } = props;
  return data.map(input => {
    const labelText = input.label || input.type;
    const capitalizedType = labelText.charAt(0).toUpperCase() + labelText.slice(1);
    let inputProps = { className: 'py-4' };
    if (input.props?.inputProps) inputProps = { ...inputProps, ...input.props?.inputProps };
    return (
      <TextFieldIcon
        key={capitalizedType}
        startIcon={<input.icon fill={Colors.gray.default} />}
        type={input.type || 'text'}
        name={input.type}
        label={capitalizedType}
        fullWidth
        className="m-0 mt-4"
        {...input.props}
        inputProps={inputProps}
      />
    );
  });
}

GenerateInputs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func.isRequired,
      props: PropTypes.instanceOf(Object).isRequired
    })
  ).isRequired
};

export default GenerateInputs;
