import { SVG_GRAD_ID_ONE, SVG_GRAD_ONE } from '@components/gradients';

export const WEEK_DAYS = [
  { value: 1, name: 'M', fullName: 'Mondays' },
  { value: 2, name: 'T', fullName: 'Tuesdays' },
  { value: 3, name: 'W', fullName: 'Wednesdays' },
  { value: 4, name: 'T', fullName: 'Thursdays' },
  { value: 5, name: 'F', fullName: 'Fridays' },
  { value: 6, name: 'S', fullName: 'Saturdays' },
  { value: 7, name: 'S', fullName: 'Sundays' }
];

export const SEND_AFTER_DAYS = new Array(31).fill('x').map((item, i) => i);
export const REQUEST_REVIEW_ALLOWED_DAYS = { start: 5, end: 30 };
export const STATUS_SHIPPED_VALUE = 2;
export const STATUS_DELIVERED_VALUE = 3;
export const ORDER_STATUSES = [
  // { value: 1, name: 'Order Placed', shortName: 'placed' },
  { value: 11, text: 'Unshipped', shortName: 'unshipped', sortIndex: 0 },
  { value: STATUS_SHIPPED_VALUE, text: 'Shipped', shortName: 'shipped', sortIndex: 1 },
  { value: STATUS_DELIVERED_VALUE, text: 'Delivered', shortName: 'delivered', sortIndex: 2 },
  { value: 4, text: 'Refunded', shortName: 'refunded', excludeValue: 9, sortIndex: 3 }
  // { value: 5, name: '1', shortName: '1* feedback', excludeValue: 1, isFeedbackOption: true },
  // { value: 6, name: '2', shortName: '2* feedback', excludeValue: 2, isFeedbackOption: true },
  // { value: 7, name: '3', shortName: '3* feedback', excludeValue: 3, isFeedbackOption: true }
  // { value: 8, name: '4* feedback', shortName: '4* feedback' },
  // { value: 9, name: '5* feedback', shortName: '5* feedback' },
  // { value: 10, name: 'Any feedback', shortName: 'any feedback' }
];

export const ORDER_INDEXES = [
  { value: 1, name: '1st order', text: 'first order', shortText: '1st' },
  { value: 2, name: '2nd order', text: 'second order', shortText: '2nd' },
  { value: 3, name: '3rd order', text: 'third order', shortText: '3rd' },
  { value: 4, name: '4th order', text: 'fourth order', shortText: '4th' },
  { value: 5, name: '5th & more orders', text: 'fifth or more orders', shortText: '5th' }
];

export const FEEDBACK_EXCLUDE_TYPE = 'FEEDBACK_EXCLUDE_TYPE';
export const SPECIAL_EXCLUDE_TYPE = 'SPECIAL_EXCLUDE_TYPE';
export const REFUNDED_EXCLUDE_TYPE = 'REFUNDED_EXCLUDE_TYPE';
export const REFUNDED_EXCLUDE_VALUE = 9;

export const SMALL_STAR_SIZE = '0.92rem';
export const STAR_SIZE = '1.1875rem';

export const EXCLUDE_ORDERS = [
  {
    value: 1,
    type: FEEDBACK_EXCLUDE_TYPE
  },
  {
    value: 2,
    type: FEEDBACK_EXCLUDE_TYPE
  },
  {
    value: 3,
    type: FEEDBACK_EXCLUDE_TYPE
  },
  // { value: 4, name: 'With 4* feedback', type: FEEDBACK_EXCLUDE_TYPE },
  // { value: 5, name: 'With 5* feedback', type: FEEDBACK_EXCLUDE_TYPE },
  { value: 6, name: 'With discount', shortName: 'discounted orders', type: SPECIAL_EXCLUDE_TYPE },
  // { value: 7, name: 'Repeat buyer', shortName: 'repeat buyer', type: SPECIAL_EXCLUDE_TYPE },
  // { value: 8, name: 'New buyer', shortName: 'new buyer', type: SPECIAL_EXCLUDE_TYPE },
  { value: 9, name: 'Order refunded', type: REFUNDED_EXCLUDE_TYPE, statusValue: 4 }
];
export const ALL_PRODUCTS = 'all_products';
export const SKU_VALUES = 'skus';
export const ASINS_VALUES = 'asins';
export const EXC_SKU_VALUES = 'exclude_skus';
export const EXC_ASINS_VALUES = 'exclude_asins';

export const SEPARATOR = /[\s,]|\r?\n/gm;

export const SUMMERNOTE_TOOLBAR_HEIGHT = 43;
export const SUMMERNOTE_ROOT_HEIGHT = 500;

export const LAYOUTS = {
  campaigns: 'CAMPAIGNS',
  messageTemplates: 'MESSAGE_TEMPLATES',
  sentMessages: 'SENT_MESSAGES',
  scheduledMessages: 'SCHEDULED_MESSAGES',
  emailPreview: 'EMAIL_PREVIEW'
};
export { SVG_GRAD_ID_ONE, SVG_GRAD_ONE };

export const SETUP_APPROVED_SENDER_TEXT =
  'To send standard email messages, please set up an approved sender email at the ';
