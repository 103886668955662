export const API_EXPENSES_TYPES = {
  warehouse: 'warehouse',
  employees: 'employees',
  photography_and_branding: 'photography_and_branding',
  advertising: 'advertising',
  other: 'other'
};

export const API_EXPENSES_ALLOCATIONS = {
  profit_and_loss: 'profit_and_loss',
  dashboard: 'dashboard',
  all: 'all'
};

export const TOTALS_ROW_TYPE = 'TOTALS_ROW_TYPE';
export const EXPENSES_LIST = 'EXPENSES_LIST';
export const EXPENSE_FORM = 'EXPENSE_FORM';
export const COUPONS_CATEGORY = 'Coupons';

export const CUSTOM_EXPENSES_CATEGORIES = [
  {
    category: 'Warehouse',
    types: [API_EXPENSES_TYPES.warehouse],
    viewType: API_EXPENSES_TYPES.warehouse
  },
  {
    category: 'Employees',
    types: [API_EXPENSES_TYPES.employees],
    viewType: API_EXPENSES_TYPES.employees
  },
  {
    category: 'Advertising',
    types: [API_EXPENSES_TYPES.advertising],
    viewType: API_EXPENSES_TYPES.advertising
  },
  {
    category: 'Other',
    types: [API_EXPENSES_TYPES.photography_and_branding, API_EXPENSES_TYPES.other],
    viewType: API_EXPENSES_TYPES.other
  }
];

export const EXPENSE_FORM_MODS = {
  edit: 'edit',
  copy: 'copy'
};

export const EXPENSE_TYPES = {
  custom: 'custom',
  amazon: 'amazon'
};
