import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import Colors from 'styles/colors';
import { CrossIcon } from '../icons';

const SmallTextCard = ({ title, onClose, className, colors }) => {
  const classes = classNames(
    'flex flex-row h-5 p-1 leading-normal whitespace-no-wrap items-center text-xs font-medium rounded',
    className
  );
  return (
    <div className={classes} style={{ backgroundColor: colors.background }}>
      <div style={{ color: colors.text }}>{title}</div>
      <IconButton
        className="p-0 ml-2 flex justify-center items-center w-3 h-3"
        onClick={onClose}
        style={{ backgroundColor: colors.text }}
      >
        <CrossIcon className="flex justify-center items-center h-2 w-2" fill={colors.background} />
      </IconButton>
    </div>
  );
};

SmallTextCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  colors: PropTypes.instanceOf(Object)
};

SmallTextCard.defaultProps = {
  className: '',
  colors: { text: Colors.violet.dark, background: Colors.violet.lighter }
};

export default SmallTextCard;
