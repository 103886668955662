import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Popper } from '@material-ui/core';

const FlippedPopper = ({
  open,
  anchorEl,
  maxHeight,
  placement,
  children,
  offset,
  paperClassName,
  disablePortal,
  preventOverflow
}) => {
  return (
    <div className="z-9999 overflow-visible">
      <Popper
        disablePortal={disablePortal}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        modifiers={{
          offset: {
            offset
          },
          flip: {
            enabled: true
          },
          hide: { enabled: false },
          preventOverflow: {
            enabled: preventOverflow,
            boundariesElement: 'window'
          }
        }}
      >
        <Paper elevation={8} className={paperClassName} style={{ maxHeight }}>
          {children}
        </Paper>
      </Popper>
    </div>
  );
};

FlippedPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  maxHeight: PropTypes.string,
  offset: PropTypes.string,
  placement: PropTypes.string,
  paperClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  disablePortal: PropTypes.bool,
  preventOverflow: PropTypes.bool
};

FlippedPopper.defaultProps = {
  maxHeight: '21rem',
  offset: '0, 10px',
  placement: 'bottom',
  anchorEl: null,
  paperClassName: 'p-2 rounded flex flex-col overflow-auto w-64',
  disablePortal: true,
  preventOverflow: false
};

export default FlippedPopper;
