import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, ButtonBase, IconButton } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { usePopper } from '@utils';
import { GradButton, RoundButton } from '@components/buttons';
import { DownloadIcon } from '@components/icons';
import Colors from 'styles/colors';
import ModernBreakdownView from './ModernBreakdownView';
import { FlippedPopper } from '../poppers';

const ModernBreakdown = props => {
  const {
    getData,
    buildViewData,
    buttonClassName,
    offset,
    onDownload,
    headerTitle,
    placement,
    buttonContent,
    currency,
    disablePercentageView
  } = props;
  const [breakdownData, setBreakdownData] = React.useState(null);
  const [contextMenu, setContextMenu] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const wrapperRef = useRef();
  const [isPercentageView, setIsPercentageView] = useState(false);

  const popper = usePopper({});

  const onDataLoaded = _breakdownData => {
    setBreakdownData(_breakdownData);
    setLoading(false);
  };

  const handleClick = () => {
    setBreakdownData(null);
    setLoading(true);
    getData(onDataLoaded);
    popper.show();
  };

  const onContextMenuClose = () => setContextMenu(null);

  const onDownloadClick = () => {
    onDownload(breakdownData);
    onContextMenuClose();
  };

  const onContextMenu = event => {
    event.preventDefault();
    if (!Object.keys(breakdownData || {}).length || !onDownload) return;
    setContextMenu({ left: event.clientX, top: event.clientY });
  };

  const headerButton = (
    <div className="flex items-center">
      {onDownload && (
        <IconButton
          onClick={event => {
            event.stopPropagation();
            onDownload(breakdownData);
          }}
          className="p-2 mr-1"
        >
          <DownloadIcon fill={Colors.white.default} className="h-4 w-4" />
        </IconButton>
      )}
      <RoundButton
        onClick={event => {
          event.stopPropagation();
          if (disablePercentageView) return;
          setIsPercentageView(!isPercentageView);
        }}
        className="w-3 flex-shrink-0 p-1 -mr-1"
      >
        <p className="text-white">{isPercentageView ? '%' : currency}</p>
      </RoundButton>
    </div>
  );

  const getBreakdownViewData = () => {
    if (!breakdownData) return [];
    return buildViewData(breakdownData, isPercentageView);
  };

  return (
    <>
      <ButtonBase
        variant="text"
        onClick={handleClick}
        className={buttonClassName}
        ref={popper.anchorRef}
      >
        {buttonContent}
      </ButtonBase>
      <div onContextMenu={onContextMenu} ref={wrapperRef}>
        <FlippedPopper
          open={popper.open}
          anchorEl={popper.anchorRef.current}
          closeOnBlur
          offset={offset}
          placement={placement}
          paperClassName="flex"
          maxHeight="auto"
          disablePortal={false}
          preventOverflow
          onClose={() => setIsPercentageView(false)}
        >
          <ClickAwayListener onClickAway={() => popper.hide()} touchEvent={false}>
            <div>
              <ModernBreakdownView
                data={getBreakdownViewData()}
                loading={isLoading}
                headerTitle={headerTitle}
                headerButton={headerButton}
                onViewTypeButtonClick={() => setIsPercentageView(!isPercentageView)}
                percentageView={isPercentageView}
              />
            </div>
          </ClickAwayListener>
        </FlippedPopper>
        <Popover
          open={contextMenu}
          onClose={onContextMenuClose}
          anchorPosition={contextMenu}
          anchorEl={wrapperRef.current}
          anchorReference="anchorPosition"
        >
          <GradButton onClick={onDownloadClick} className="h-10">
            Download CSV
          </GradButton>
        </Popover>
      </div>
    </>
  );
};

ModernBreakdown.propTypes = {
  buttonClassName: PropTypes.string,
  buildViewData: PropTypes.func.isRequired,
  offset: PropTypes.string,
  placement: PropTypes.string,
  getData: PropTypes.func,
  onClose: PropTypes.func,
  buttonContent: PropTypes.instanceOf(Object).isRequired
};

ModernBreakdown.defaultProps = {
  buttonClassName: 'ml-1 w-3 h-3',
  offset: '0, 10px',
  placement: 'bottom',
  getData: null,
  onClose: () => {}
};

export default ModernBreakdown;
