import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import MenuComponent from '@components/common/Menu';
import { CaretDownIcon, FitInIcon } from '@components/icons/Icons';
import FlatButton from '@components/buttons/FlatButton';
import BlackTooltip from '@components/common/BlackTooltip';
import LoadingSpinner from '@components/common/LoadingSpinner';
import Colors from 'styles/colors';
import ChartComponent from './ChartJS';

const Bullet = ({ color }) => {
  const root = classNames('round mr-1', {
    [color]: color
  });

  return <div className={root} style={{ width: '0.375rem', height: '0.375rem' }} />;
};

const MenuButton = ({ name, bullet, data, onSelected, disabledItem }) => {
  const [anchor, setAnchor] = useState(null);
  const menuId = 'menuId2';

  const handleClick = event => {
    setAnchor(event.currentTarget);
  };

  const handleClose = index => {
    setAnchor(null);
    if (typeof index === 'string') {
      onSelected(index);
    }
  };

  return (
    <>
      <FlatButton
        aria-owns={anchor ? menuId : undefined}
        aria-haspopup="true"
        className="flex flex-row items-center"
        onClick={handleClick}
      >
        {bullet}
        {name}
        <CaretDownIcon
          fill="#c3c6d6"
          width="16px"
          height="14px"
          className="ml-2 mr-4 hidden xs:block"
        />
      </FlatButton>
      <MenuComponent
        id={menuId}
        data={data}
        handleClose={handleClose}
        anchor={anchor}
        disabledItems={[disabledItem]}
      />
    </>
  );
};

const Chart = ({
  values,
  primaryMetricInfo,
  secondMetricInfo,
  secondaryMetricsData,
  onIntervalChanged,
  onPrimaryMetricChanged,
  onSecondaryMetricChanged,
  disabledInterval,
  interval,
  chartLoading,
  end,
  currency,
  scales,
  metrics,
  isBarView
}) => {
  const [startFromZero, setStartFromZero] = useState(true);
  const getHeader = () => {
    return (
      <div className="flex flex-row justify-between text-sm text-gray-darkest mb-6">
        <div className="flex flex-row items-center">
          <MenuButton
            name={primaryMetricInfo.title}
            data={metrics}
            bullet={<Bullet color="bg-yellow" />}
            onSelected={onPrimaryMetricChanged}
          />
          <span className="ml-4 xs:ml-0 mr-4 text-gray-dark">vs.</span>
          <MenuButton
            name={
              secondMetricInfo ? (
                secondMetricInfo.title
              ) : (
                <div className="text-blue">Select a metric</div>
              )
            }
            data={metrics}
            bullet={<Bullet color="bg-blue" />}
            onSelected={onSecondaryMetricChanged}
          />
        </div>
        <BlackTooltip title={startFromZero ? 'Fit values' : 'Show zero'} placement="top" arrow>
          <IconButton
            className="ml-auto mr-2"
            onClick={() => {
              setStartFromZero(!startFromZero);
            }}
          >
            <FitInIcon
              fill={!startFromZero ? Colors.yellow.default : Colors.gray.default}
              width="20px"
              height="20px"
            />
          </IconButton>
        </BlackTooltip>
        {scales.length > 1 && (
          <MenuButton
            name={interval}
            data={scales}
            onSelected={item => {
              onIntervalChanged(item);
            }}
            disabledItem={disabledInterval}
          />
        )}
      </div>
    );
  };
  const loadingSpinner = chartLoading ? (
    <LoadingSpinner
      className="absolute inset-0 z-10 pointer-events-none flex items-center justify-center h-12 w-full"
      override
    />
  ) : null;

  return (
    <div className="relative px-4 pt-6 bg-white rounded-t">
      {loadingSpinner}
      {getHeader()}
      <ChartComponent
        values={values}
        primaryMetricInfo={primaryMetricInfo}
        secondMetricInfo={secondMetricInfo}
        secondData={secondaryMetricsData}
        interval={interval}
        end={end}
        currency={currency}
        isBarView={isBarView}
        startFromZero={startFromZero}
      />
    </div>
  );
};

Bullet.propTypes = {
  color: PropTypes.string.isRequired
};

Chart.propTypes = {
  values: PropTypes.instanceOf(Array).isRequired,
  primaryMetricInfo: PropTypes.instanceOf(Object).isRequired,
  secondMetricInfo: PropTypes.instanceOf(Object),
  secondaryMetricsData: PropTypes.instanceOf(Array),
  onIntervalChanged: PropTypes.func,
  onPrimaryMetricChanged: PropTypes.func.isRequired,
  onSecondaryMetricChanged: PropTypes.func.isRequired,
  interval: PropTypes.string.isRequired,
  disabledInterval: PropTypes.string,
  chartLoading: PropTypes.bool,
  end: PropTypes.instanceOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
  scales: PropTypes.instanceOf(Array).isRequired,
  metrics: PropTypes.instanceOf(Array).isRequired,
  isBarView: PropTypes.bool
};

Chart.defaultProps = {
  secondMetricInfo: null,
  secondaryMetricsData: null,
  disabledInterval: null,
  chartLoading: null,
  onIntervalChanged: () => {},
  isBarView: false
};

export default Chart;
