import { SET_INVENTORY, SET_INVENTORY_LOADING } from '../saga/inventory';

const initialState = {
  totals: {},
  products: [],
  loading: false
};

export const inventorySelector = state => state.inventory;

const inventory = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVENTORY: {
      const { totals, products } = action.payload;
      return { ...state, totals, products };
    }
    case SET_INVENTORY_LOADING: {
      return { ...state, loading: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default inventory;
