import { dateFromStr } from '@utils';
import {
  COUPONS_CATEGORY,
  CUSTOM_EXPENSES_CATEGORIES,
  EXPENSE_TYPES,
  TOTALS_ROW_TYPE,
  API_EXPENSES_ALLOCATIONS
} from './consts';

const reduceByKey = (list, key = 'total_amount') => list.reduce((acc, item) => acc + item[key], 0);

const getGroupAmountMetrics = groupComponents => ({
  total_amount: reduceByKey(groupComponents),
  range_amount: reduceByKey(groupComponents, 'range_amount'),
  daily_amount: reduceByKey(groupComponents, 'daily_amount')
});

export const getExpensesDataWithSummary = list => {
  if (!list.length) return list;
  let totalRangeAmount = 0;
  const getChildren = children =>
    children.map(item => {
      if (item.children) {
        const calculatedChildren = getChildren(item.children);
        const partiallyOverlapped = calculatedChildren.reduce(
          (acc, child) => acc || child.partiallyOverlapped,
          false
        );
        return {
          ...item,
          children: calculatedChildren,
          partiallyOverlapped,
          ...getGroupAmountMetrics(calculatedChildren)
        };
      }
      totalRangeAmount += item.range_amount || 0;
      return { ...item, partiallyOverlapped: item.total_amount !== item.range_amount };
    });
  const result = getChildren(list);
  const totalRow = { type: TOTALS_ROW_TYPE, range_amount: totalRangeAmount };
  return [...result, totalRow];
};

export const allocationsFromStr = str => ({
  dashboardAllocation:
    str === API_EXPENSES_ALLOCATIONS.all || str === API_EXPENSES_ALLOCATIONS.dashboard,
  pnlAllocation:
    str === API_EXPENSES_ALLOCATIONS.all || str === API_EXPENSES_ALLOCATIONS.profit_and_loss
});

export const allocationsToStr = ({ dashboardAllocation, pnlAllocation }) => {
  if (dashboardAllocation && pnlAllocation) return API_EXPENSES_ALLOCATIONS.all;
  if (dashboardAllocation) return API_EXPENSES_ALLOCATIONS.dashboard;
  return API_EXPENSES_ALLOCATIONS.profit_and_loss;
};

const getSortExpenseByDateFunc = (key = 'start', desc) => (a, b) =>
  dateFromStr((desc ? b : a)[key]) - dateFromStr((desc ? a : b)[key]);

const getExpenseListDateRange = list => {
  const [{ start }] = list.sort(getSortExpenseByDateFunc('start'));
  const [{ end }] = list.sort(getSortExpenseByDateFunc('end', true));
  return { start, end };
};

export const getGroupSummaryRow = (list, filterFunc, category, type, imported) => {
  const groupComponents = list
    ?.filter(filterFunc)
    .map(item => ({ ...item, end: item.end || item.start, imported, category }));
  if (!groupComponents?.length) return null;
  const processing = !!groupComponents.filter(item => item.processing).length;
  const { start, end } = getExpenseListDateRange(groupComponents);
  const allocations = [...new Set(groupComponents.map(item => item.allocation))];
  const allocation = allocations.length === 1 ? allocations[0] : 'mixed';
  return {
    category,
    start,
    end,
    allocation,
    children: groupComponents,
    type,
    imported,
    processing,
    ...getGroupAmountMetrics(groupComponents)
  };
};

export const getCouponComponents = (list, couponId) => {
  const couponComponents = list
    .filter(item => item.coupon_id === couponId)
    .map(item => ({ ...item, end: item.end || item.start }));
  const { start, end } = getExpenseListDateRange(couponComponents);
  const allocation = couponComponents[0]?.allocation;
  const productCount = couponComponents[0]?.product_count;
  const name = `Coupon ${couponId}`;
  const processing = !!couponComponents.filter(item => item.processing).length;
  const [{ id }] = couponComponents;
  return {
    id,
    name,
    allocation,
    start,
    end,
    couponComponents,
    processing,
    imported: true,
    product_count: productCount,
    expenseType: EXPENSE_TYPES.amazon,
    ...getGroupAmountMetrics(couponComponents)
  };
};

export const getCouponsSummaryRow = list => {
  if (!list) return null;
  const couponsIds = [...new Set(list.map(item => item.coupon_id).filter(item => item))];
  const groupedCoupons = couponsIds.map(couponId => getCouponComponents(list, couponId));
  return getGroupSummaryRow(
    groupedCoupons,
    item => item,
    COUPONS_CATEGORY,
    EXPENSE_TYPES.amazon,
    true
  );
};

export const getCommonExpensesList = list => {
  const result = [];

  CUSTOM_EXPENSES_CATEGORIES.forEach(({ category, types, viewType }) => {
    const groupSummaryRow = getGroupSummaryRow(
      list,
      expense => types.includes(expense.type),
      category,
      viewType
    );
    if (!groupSummaryRow) return;
    result.push(groupSummaryRow);
  });

  if (list) {
    list.forEach(item => {
      if (item.rootMetric) {
        result.push({ ...item, category: item.name });
        return;
      }
      if (item.category && !result.find(row => row.category === item.category)) {
        const categorySummaryRow = getGroupSummaryRow(
          list,
          expense => expense.category === item.category && !expense.coupon_id,
          item.category,
          EXPENSE_TYPES.amazon,
          true
        );
        if (!categorySummaryRow) return;
        result.push(categorySummaryRow);
      }
    });
  }
  const couponsSummaryRow = getCouponsSummaryRow(list);
  if (couponsSummaryRow) result.push(couponsSummaryRow);

  return result;
};
