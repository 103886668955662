import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';

function FontButton(props) {
  const { children, className, ...others } = props;
  return (
    <Link
      component="span"
      color="inherit"
      className={classNames('cursor-pointer', className)}
      {...others}
    >
      {children}
    </Link>
  );
}

FontButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

FontButton.defaultProps = {
  className: ''
};

export default FontButton;
