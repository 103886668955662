import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Colors from 'styles/colors';
import { ExpandIcon, PenIcon } from '@components/icons';
import BinIcon from './ic_trash.svg';
import DeleteDialog from './DeleteDialog';

function NoteCell(props) {
  const { note, style, onChange, onDelete } = props;
  const [extended, setExtended] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  // const largeScreenMultiplier = 2.75;
  // const mediumScreenMultiplier = 2.6;
  // const cutLength =
  //   columns[2].width / (isXLargeSize() ? mediumScreenMultiplier : largeScreenMultiplier);

  const isNoteLong = false;
  const noteText = note;
  const notePrefix = null;

  // const noteText = note && note.substring(0, extended ? note.length : cutLength);
  // const notePrefix = !extended && isNoteLong && '...';

  return (
    <div style={style} className="flex border-b border-gray-lighter-05 justify-between">
      <div className="w-10/12 flex items-center">
        <p className="text-black text-sm whitespace-pre-wrap overflow-auto max-h-full">
          {noteText}
          {notePrefix}
        </p>
        {isNoteLong && (
          <IconButton className="h-10 w-10 ml-2" onClick={() => setExtended(!extended)}>
            <ExpandIcon selected={extended} className="h-4 w-4" fill={Colors.gray.default} />
          </IconButton>
        )}
      </div>
      <div className="flex mt-1">
        <IconButton className="h-10 w-10" onClick={onChange}>
          <PenIcon fill={Colors.gray.default} className="sm-w-14px" />
        </IconButton>
        <IconButton className="h-10 w-10" onClick={() => setDeleteDialog(true)}>
          <img src={BinIcon} className="sm-w-14px" alt="bin" />
        </IconButton>
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        onDelete={() => {
          setDeleteDialog(false);
          onDelete();
        }}
      />
    </div>
  );
}

NoteCell.propTypes = {
  note: PropTypes.string.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default NoteCell;
