import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { commonTableFilter, isProductionDomain } from '@utils';
import { FlatButton, TabSwitcher } from '@components';
import {
  exportCSVBreakdown,
  getCsvFileName
} from 'seller/containers/products-selector-view/csvHelpers';
import DataTable from 'seller/containers/DataTableContainer';
import { getColumns } from './columns';
import csvColumns from './csvColumns';

export const rowRootComponent = (
  value,
  key,
  rowStyle,
  rowClassName,
  content,
  rowIndex,
  rowClicked
) => {
  if (value.is_parent && value.children && value.children.length) {
    return (
      <FlatButton
        key={key}
        style={rowStyle}
        className={rowClassName}
        onClick={() => {
          if (value.is_parent) {
            rowClicked(rowIndex, value);
          }
        }}
      >
        {content}
      </FlatButton>
    );
  }

  return (
    <div key={key} style={rowStyle} className={rowClassName}>
      {content}
    </div>
  );
};

const SUMMARY_TAB = 'Summary';
const UNITS_ECONOMIC_TAB = 'Unit Economics';

const ProductsTable = ({
  productMetrix,
  salesLoading,
  currency,
  dateRange,
  marketplaceID,
  userPreferences
}) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(SUMMARY_TAB);
  const { hideProductsWithoutFinances, beta } = userPreferences;
  const unitEconomicsEnabled = beta?.unitsEconomics || !isProductionDomain();
  const isUnitEconomicsView = activeTab === UNITS_ECONOMIC_TAB;
  const csvProps = {
    columns: csvColumns,
    data: productMetrix,
    fileName: getCsvFileName({
      dashboardName: 'Dashboard',
      fileName: 'Product-List',
      marketplaceId: marketplaceID,
      dateRange
    }),
    onClick: exportCSVBreakdown,
    dateRange,
    title: 'Download Product Data',
    marketplaceID,
    onHandle: inProcess => setLoading(inProcess),
    active: !loading
  };
  const getFilteredProducts = () => {
    if (hideProductsWithoutFinances) {
      return productMetrix.filter(
        product => product.profit || product.product_sales || product.refunds || product.orders
      );
    }
    return productMetrix;
  };
  const columns = getColumns(isUnitEconomicsView);

  const tabSwitcher = unitEconomicsEnabled ? (
    <TabSwitcher
      options={[
        { text: SUMMARY_TAB, value: SUMMARY_TAB },
        { text: UNITS_ECONOMIC_TAB, value: UNITS_ECONOMIC_TAB }
      ]}
      activeTab={activeTab}
      changeActiveTab={setActiveTab}
      className="ml-auto hidden md:flex"
      compactView
    />
  ) : null;

  return (
    <DataTable
      isLoading={loading || salesLoading}
      data={getFilteredProducts()}
      title="Product List"
      columnsConfig={columns}
      fixedColumnRowComponent={rowRootComponent}
      currency={currency}
      filter={commonTableFilter}
      csvProps={csvProps}
      headerWidget={tabSwitcher}
      selectionResetDeps={[activeTab]}
    />
  );
};

ProductsTable.propTypes = {
  salesLoading: PropTypes.bool,
  productMetrix: PropTypes.instanceOf(Array),
  currency: PropTypes.string.isRequired,
  dateRange: PropTypes.instanceOf(Object).isRequired,
  marketplaceID: PropTypes.number.isRequired,
  userPreferences: PropTypes.instanceOf(Object).isRequired
};

ProductsTable.defaultProps = {
  salesLoading: false,
  productMetrix: []
};

export default ProductsTable;
