import { API_V2_1 } from '@sm/api';
import mock from '../mock';

const salesDb = {
  products: [
    {
      recordId: 'B078RNVH89',
      product: 'B078RNVH89',
      marketplace: 3,
      acos: 75.324,
      tacos: 23.43252,
      is_parent: true,
      refunds: 0.0,
      ppc_orders: 0,
      p: 38.83,
      type: 'parent',
      product__brand: 'Activene',
      units: 4,
      brand: '',
      qty_refunded: 0,
      variation_type_five: '',
      asin: 'B078RNVH89',
      smallImage: 'http://ecx.images-amazon.com/images/I/513djQP9sjL._SL75_.jpg',
      orders: 4,
      package_quantity: 0,
      promo_units_qty: 1,
      margin: 61.59581218274111,
      full_units_qty: 3,
      promo_sales: 13.0,
      gp: 38.83,
      sales: 1,
      total_promo: -2.36,
      profit: 38.83,
      cost_of_goods_sold: 0.0,
      amount_refunded: 0,
      PPC_conversions_7d_same_sku_count: 0,
      fba: 47,
      fbm: 0,
      children: [
        {
          marketplace: 3,
          product__brand: 'Activene',
          product: 53134,
          smallImage: 'http://ecx.images-amazon.com/images/I/513djQP9sjL._SL75_.jpg',
          name:
            'Activene Dry Skin Repair Cream - All-Purpose Moisturizing Formula with 12 Powerful Natural Ingredients to Soothe and Hydrate Very Dry, Chapped Skin (3 oz). Paraben Free (Naturally Scented)',
          sku: 'UA-91K9-KICJ',
          asin: 'B078RNS6BJ',
          fba: 47,
          fbm: 0,
          parent_asin: 'B078RNVH89',
          variation_value_one: 'Naturally Scented',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Scent',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          p: 38.83,
          units: 4,
          gp: 38.83,
          order_item_count: 4,
          orders: 4,
          promo_units_qty: 1,
          full_units_qty: 3,
          promo_sales: 13,
          other_fees: -5.99,
          fba_fees: -12.76,
          commission_fees: -9.09,
          tax: 2.35,
          other_revenue: -9.77,
          total_promo: -2.36,
          cost_of_goods_sold: 0.0,
          margin: 61.59581218274111,
          sales: 123222263.03,
          est_tax_unpaid: 0.0,
          fbm_shipping_cost: 0.0,
          cin7_cogs: -10.1,
          recordId: 53134,
          sessions_sum: 16,
          profit: 38.83,
          acos: 75.324,
          tacos: 23.43252,
          return_on_investment: 0,
          type: 'child'
        }
      ],
      sessions_sum: 16,
      return_on_investment: 0
    },
    {
      marketplace: 3,
      product__brand: 'Activene',
      product: 141,
      smallImage: 'http://ecx.images-amazon.com/images/I/51rAED0i9cL._SL75_.jpg',
      name:
        'Activene Arnica Gel Cream - with Menthol and MSM. Pain Relief for Joint, Tendon, Muscle Ache. Chosen by Sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Pain 3 Ounce',
      sku:
        'Activene Arnica Gel Cream - with Menthol and MSM. Pain Relief for Joint, Tendon, Muscle Ache. Chosen by Sufferers',
      asin: 'B012A5Z89K1',
      fba: 1556,
      fbm: 0,
      parent_asin: '',
      variation_value_one: 'black',
      variation_value_two: 'big',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: 'color',
      variation_type_two: 'size',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 2945.59,
      units: 490,
      gp: 4895.79,
      order_item_count: 381,
      orders: 380,
      promo_units_qty: 74,
      full_units_qty: 416,
      promo_sales: 1133,
      other_fees: -43.16,
      fba_fees: -1556.72,
      acos: 75.324,
      tacos: 23.43252,
      commission_fees: -1137.03,
      tax: 274.43,
      other_revenue: 34.91,
      total_promo: -176.94,
      cost_of_goods_sold: 1950.2,
      margin: 38.33432023893961,
      product_sales: 7863.97,
      shipping: -10,
      est_tax_unpaid: 0.0,
      fbm_shipping_cost: 0.0,
      recordId: 141,
      sessions_sum: 1251,
      qty_refunded: 9,
      refunds: -117.91,
      refunded_cogs: 19.9,
      fbm_return_cost: null,
      reimbursement: 18.33,
      reimbursement_return: null,
      reimbursed_inventory: 0.0,
      profit: 2865.91,
      return_on_investment: 146.95467131576248
    },
    {
      marketplace: 3,
      product__brand: 'InstaShaker',
      product: 134,
      smallImage: 'http://ecx.images-amazon.com/images/I/51j9zN-WyFL._SL75_.jpg',
      sku: 'LP-PDOY-8FHZ',
      asin: 'B01LVWG3WW',
      fba: 1311,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 2022.49,
      units: 111,
      gp: 3132.49,
      order_item_count: 103,
      orders: 103,
      promo_units_qty: 30,
      full_units_qty: 81,
      promo_sales: 1143,
      other_fees: -54.4,
      fba_fees: -539.58,
      acos: 75.324,
      tacos: 23.43252,
      commission_fees: -648.6,
      tax: 262.26,
      other_revenue: -101.22,
      total_promo: -160.23,
      cost_of_goods_sold: 1110.0,
      margin: 45.585765901002326,
      sales: 4582.93,
      est_tax_unpaid: 0.0,
      fbm_shipping_cost: 0.0,
      recordId: 134,
      sessions_sum: 1155,
      qty_refunded: 8,
      refunds: -251.83,
      refunded_cogs: 30.0,
      fbm_return_cost: null,
      reimbursement: 51.63,
      reimbursement_return: null,
      reimbursed_inventory: 0.0,
      profit: 1852.29,
      return_on_investment: 166.87297297297297
    },
    {
      marketplace: 3,
      product__brand: 'Cedergren',
      product: 132649,
      smallImage: 'http://ecx.images-amazon.com/images/I/41jsXv3xlsL._SL75_.jpg',
      name:
        'Cedergren Hot Towel Warmer Cabinet - Stainless Steel Wet Towel Steamer (7 Liters). Mini Cabbie for Barber Shop, Massage, SPA, Nails and Beauty Parlor, Hair Salon. UL Certified Cabi',
      sku: 'ML-4SAW-DNYH',
      asin: 'B07F87FDG7',
      fba: 92,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 482.93,
      units: 6,
      gp: 842.93,
      order_item_count: 6,
      orders: 6,
      promo_units_qty: 5,
      full_units_qty: 1,
      promo_sales: 955,
      other_fees: 0.0,
      fba_fees: -99.72,
      commission_fees: -166.35,
      tax: 72.4,
      other_revenue: -418.0,
      total_promo: -145.0,
      acos: 75.324,
      tacos: 23.43252,
      cost_of_goods_sold: 360.0,
      margin: 38.51116427432217,
      sales: 1181.4,
      est_tax_unpaid: 0.0,
      fbm_shipping_cost: 0.0,
      recordId: 132649,
      sessions_sum: 222,
      qty_refunded: 2,
      refunds: -340.65,
      refunded_cogs: 0.0,
      fbm_return_cost: null,
      reimbursement: null,
      reimbursement_return: null,
      reimbursed_inventory: null,
      profit: 142.28,
      return_on_investment: 39.522222222222226
    },
    {
      marketplace: 3,
      product__brand: 'nachteule',
      product: 1569,
      smallImage: 'http://ecx.images-amazon.com/images/I/41UQJTpiOoL._SL75_.jpg',
      name:
        'Rechargeable Book Light for Glasses. Led Reading Light. Clip on Design Made in Germany. Complimentary Protective Case Included',
      sku: 'PI-6HD2-PV2T',
      asin: 'B014K05KAA',
      fba: 0,
      fbm: 0,
      parent_asin: '',
      variation_value_one: 'BBB',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: 'Color',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      p: 386.88,
      units: 31,
      gp: 689.96,
      order_item_count: 29,
      orders: 29,
      promo_units_qty: null,
      full_units_qty: 31,
      promo_sales: null,
      other_fees: -12.98,
      fba_fees: -102.3,
      commission_fees: -139.19,
      tax: 44.05,
      other_revenue: 12.98,
      total_promo: 0.0,
      cost_of_goods_sold: 300.08,
      margin: 41.669449081803,
      acos: 75.324,
      tacos: 23.43252,
      sales: 972.5,
      est_tax_unpaid: 3.0,
      fbm_shipping_cost: 0.0,
      recordId: 1569,
      sessions_sum: 231,
      qty_refunded: 1,
      refunds: -26.36,
      refunded_cogs: 0.0,
      fbm_return_cost: null,
      reimbursement: null,
      reimbursement_return: null,
      reimbursed_inventory: null,
      profit: 360.52,
      return_on_investment: 120.14129565449214
    },
    {
      recordId: 'B078RNVH87',
      product: 'B078RNVH87',
      marketplace: 3,
      is_parent: true,
      refunds: 0.0,
      ppc_orders: 0,
      p: 38.83,
      type: 'parent',
      product__brand: 'Activene',
      units: 4,
      brand: '',
      qty_refunded: 0,
      variation_type_five: '',
      asin: 'B078RNVH87',
      name:
        'Activene Dry Skin Repair Cream - All-Purpose Moisturizing Formula with 12 Powerful Natural Ingredients to Soothe and Hydrate Very Dry, Chapped Skin (3 oz). Paraben Free (Naturally Scented)',
      smallImage: 'http://ecx.images-amazon.com/images/I/513djQP9sjL._SL75_.jpg',
      orders: 4,
      package_quantity: 0,
      promo_units_qty: 1,
      margin: 61.59581218274111,
      full_units_qty: 3,
      promo_sales: 13.0,
      gp: 38.83,
      sales: 63.03,
      total_promo: -2.36,
      acos: 75.324,
      tacos: 23.43252,
      profit: 38.83,
      cost_of_goods_sold: 0.0,
      amount_refunded: 0,
      PPC_conversions_7d_same_sku_count: 0,
      fba: 47,
      fbm: 0,
      children: [
        {
          marketplace: 3,
          product__brand: 'Activene',
          product: 53134,
          smallImage: 'http://ecx.images-amazon.com/images/I/513djQP9sjL._SL75_.jpg',
          name:
            'Activene Dry Skin Repair Cream - All-Purpose Moisturizing Formula with 12 Powerful Natural Ingredients to Soothe and Hydrate Very Dry, Chapped Skin (3 oz). Paraben Free (Naturally Scented)',
          sku: 'UA-91K9-KICJ',
          asin: 'B078RNS6BJ',
          fba: 47,
          fbm: 0,
          parent_asin: 'B078RNVH87',
          variation_value_one: 'Naturally Scented',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Scent',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          p: 38.83,
          units: 4,
          gp: 38.83,
          order_item_count: 4,
          orders: 4,
          promo_units_qty: 1,
          full_units_qty: 3,
          promo_sales: 13,
          other_fees: -5.99,
          fba_fees: -12.76,
          commission_fees: -9.09,
          tax: 2.35,
          other_revenue: -9.77,
          total_promo: -2.36,
          cost_of_goods_sold: 0.0,
          margin: 61.59581218274111,
          acos: 75.324,
          tacos: 23.43252,
          sales: 63.03,
          est_tax_unpaid: 0.0,
          fbm_shipping_cost: 0.0,
          recordId: 53134,
          sessions_sum: 16,
          profit: 38.83,
          return_on_investment: 0,
          type: 'child'
        }
      ],
      sessions_sum: 16,
      return_on_investment: 0
    },
    {
      recordId: 'B07JHYD2PL',
      product: 'B07JHYD2PL',
      marketplace: 3,
      is_parent: true,
      refunds: -41.46,
      ppc_orders: 0,
      p: 15.74,
      type: 'parent',
      product__brand: 'Zegaro',
      units: 4,
      brand: '',
      qty_refunded: 4,
      variation_type_five: '',
      asin: 'B07JHYD2PL',
      name:
        'Zegaro Digital Alarm Clock for Bedroom - Large 4.2 Inch LCD Display with Blue LED Back Light. Big Numbers, Date and Temperature. Easy to Use, Battery Operated. Stainless Steel Metal Case (Black)',
      smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
      orders: 4,
      package_quantity: 0,
      promo_units_qty: 2,
      margin: 24.670846394984327,
      full_units_qty: 2,
      promo_sales: 24.0,
      gp: 34.34,
      sales: 59.41,
      total_promo: -7.98,
      profit: -11.77,
      acos: 75.324,
      tacos: 23.43252,
      cost_of_goods_sold: 18.6,
      amount_refunded: 0,
      PPC_conversions_7d_same_sku_count: 0,
      fba: 1194,
      fbm: 0,
      children: [
        {
          marketplace: 3,
          product__brand: 'Zegaro',
          product: 385834,
          smallImage: 'http://ecx.images-amazon.com/images/I/41l-Oii3XlL._SL75_.jpg',
          name:
            'Zegaro Digital Alarm Clock for Bedroom - Large 4.2 Inch LCD Display with Blue LED Back Light. Big Numbers, Date and Temperature. Easy to Use, Battery Operated. Stainless Steel Metal Case (Black)',
          sku: 'EBC02-MB',
          asin: 'B07JHYT5VH',
          fba: 753,
          fbm: 0,
          parent_asin: 'B07JHYD2PL',
          variation_value_one: 'Midnight Black',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Color',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          p: 15.74,
          units: 4,
          gp: 34.34,
          order_item_count: 4,
          orders: 4,
          promo_units_qty: 2,
          full_units_qty: 2,
          promo_sales: 24,
          other_fees: 0.0,
          fba_fees: -13.12,
          commission_fees: -8.36,
          tax: 3.59,
          other_revenue: -15.95,
          total_promo: -7.98,
          cost_of_goods_sold: 18.6,
          margin: 24.670846394984327,
          sales: 59.41,
          est_tax_unpaid: 0.0,
          fbm_shipping_cost: 0.0,
          acos: 75.324,
          tacos: 23.43252,
          recordId: 385834,
          sessions_sum: 32,
          qty_refunded: 3,
          refunds: -27.42,
          refunded_cogs: 13.95,
          fbm_return_cost: null,
          reimbursement: null,
          reimbursement_return: null,
          reimbursed_inventory: null,
          profit: 2.27,
          return_on_investment: 48.81720430107527,
          type: 'child'
        },
        {
          product: 385835,
          marketplace: 3,
          qty_refunded: 1,
          refunds: -14.04,
          est_tax_unpaid: 0.0,
          refunded_cogs: 0.0,
          fbm_return_cost: null,
          recordId: 385835,
          reimbursement: null,
          acos: 75.324,
          tacos: 23.43252,
          reimbursement_return: null,
          reimbursed_inventory: null,
          smallImage: 'http://ecx.images-amazon.com/images/I/41VFRoQTARL._SL75_.jpg',
          name:
            'Zegaro Digital Alarm Clock for Bedroom - Large 4.2 Inch LCD Display with Blue LED Back Light. Big Numbers, Date and Temperature. Easy to Use, Battery Operated. Stainless Steel Metal Case (White)',
          sku: 'EBC02-CW',
          asin: 'B07JJGXVN5',
          fba: 441,
          fbm: 0,
          parent_asin: 'B07JHYD2PL',
          variation_value_one: 'Cloud White',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Color',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          profit: -14.04,
          sessions_sum: 6,
          type: 'child'
        }
      ],
      sessions_sum: 38,
      return_on_investment: -63.279569892473106
    },
    {
      recordId: 'B07PVL35S3',
      product: 'B07PVL35S3',
      marketplace: 3,
      is_parent: true,
      refunds: 0.0,
      ppc_orders: 0,
      p: 3.18,
      type: 'parent',
      product__brand: 'Zegaro',
      units: 1,
      brand: '',
      qty_refunded: 0,
      variation_type_five: '',
      asin: 'B07PVL35S3',
      acos: 75.324,
      tacos: 23.43252,
      name:
        'Zegaro Digital Alarm Clock for Bedroom - 2.6 in LCD Display with Blue LED Backlight Light & Small, Big Numbers, Battery Operated. Easy to Use for Kids Girls Boys Teens & Elderly (Midnight Black)',
      smallImage: 'http://ecx.images-amazon.com/images/I/41kw60htgsL._SL75_.jpg',
      orders: 1,
      package_quantity: 0,
      promo_units_qty: 0,
      margin: 31.89568706118355,
      full_units_qty: 1,
      promo_sales: 0.0,
      gp: 5.98,
      sales: 10.59,
      total_promo: 0.0,
      profit: 2.18,
      cost_of_goods_sold: 2.8,
      amount_refunded: 0,
      PPC_conversions_7d_same_sku_count: 0,
      fba: 1509,
      fbm: 0,
      children: [
        {
          marketplace: 3,
          product__brand: 'Zegaro',
          product: 385833,
          smallImage: 'http://ecx.images-amazon.com/images/I/41kw60htgsL._SL75_.jpg',
          name:
            'Zegaro Digital Alarm Clock for Bedroom - 2.6 in LCD Display with Blue LED Backlight Light & Small, Big Numbers, Battery Operated. Easy to Use for Kids Girls Boys Teens & Elderly (Midnight Black)',
          sku: 'EBC01-MB',
          asin: 'B07JJKYZK3',
          fba: 1186,
          fbm: 0,
          parent_asin: 'B07PVL35S3',
          variation_value_one: 'Midnight Black',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Color',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          p: 3.18,
          units: 1,
          gp: 5.98,
          order_item_count: 1,
          orders: 1,
          promo_units_qty: null,
          full_units_qty: 1,
          promo_sales: null,
          acos: 75.324,
          tacos: 23.43252,
          other_fees: 0.0,
          fba_fees: -3.19,
          commission_fees: -0.8,
          tax: 0.62,
          other_revenue: -9.97,
          total_promo: 0.0,
          cost_of_goods_sold: 2.8,
          margin: 31.89568706118355,
          sales: 10.59,
          est_tax_unpaid: 0.0,
          fbm_shipping_cost: 1.0,
          recordId: 385833,
          sessions_sum: 22,
          profit: 2.18,
          return_on_investment: 77.85714285714286,
          type: 'child'
        },
        {
          product: 385832,
          marketplace: 3,
          smallImage: 'http://ecx.images-amazon.com/images/I/41iALlWDxnL._SL75_.jpg',
          name:
            'Zegaro Digital Alarm Clock for Bedroom - 2.6 in LCD Display with Blue LED Backlight Light & Small, Big Numbers, Battery Operated. Easy to Use for Kids Girls Boys Teens & Elderly (Cloud White)',
          sku: 'EBC01-CW',
          asin: 'B07JH2FNKR',
          acos: 75.324,
          tacos: 23.43252,
          fba: 323,
          fbm: 0,
          parent_asin: 'B07PVL35S3',
          variation_value_one: 'Cloud White',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Color',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          sessions_sum: 14,
          type: 'child'
        }
      ],
      sessions_sum: 36,
      return_on_investment: 77.85714285714288
    },
    {
      recordId: 'B01MDQ5TCW',
      product: 'B01MDQ5TCW',
      marketplace: 3,
      is_parent: true,
      refunds: 0.0,
      ppc_orders: 0,
      p: 0.0,
      type: 'parent',
      product__brand: null,
      units: 0,
      brand: '',
      qty_refunded: 0,
      variation_type_five: '',
      asin: 'B01MDQ5TCW',
      name:
        'Activene Plantar Fasciitis Socks - Foot Sleeves. Extra Compression for Heels and Arch Support. Made for Heel Spur, Achilles Tendon, Pain and Swelling Relief. 1 Pair (2 Socks) with Gift Box (Small)',
      smallImage: 'http://ecx.images-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg',
      orders: 0,
      package_quantity: 0,
      promo_units_qty: 0,
      margin: 0.0,
      full_units_qty: 0,
      promo_sales: 0.0,
      gp: 0.0,
      sales: 0.0,
      total_promo: 0.0,
      profit: 0.0,
      cost_of_goods_sold: 0.0,
      amount_refunded: 0,
      PPC_conversions_7d_same_sku_count: 0,
      fba: 6,
      fbm: 0,
      children: [
        {
          product: 135,
          marketplace: 3,
          smallImage: 'http://ecx.images-amazon.com/images/I/51GZqcjLwOL._SL75_.jpg',
          name:
            'Activene Plantar Fasciitis Socks - Foot Sleeves. Extra Compression for Heels and Arch Support. Made for Heel Spur, Achilles Tendon, Pain and Swelling Relief. 1 Pair (2 Socks) with Gift Box (Small)',
          sku: 'OQ-AXJY-88OQ',
          asin: 'B01MFBKZLD',
          fba: 6,
          fbm: 0,
          parent_asin: 'B01MDQ5TCW',
          variation_value_one: 'Small',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Size',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          sessions_sum: 8,
          type: 'child'
        }
      ],
      sessions_sum: 8,
      return_on_investment: 0
    }
  ]
};

const fakeProduct = {
  marketplace: 3,
  units: 239,
  product: 1415554,
  brand: 'Activene',
  asin: 'B012A5Z89K',
  name:
    'ACTIVENE Arnica Gel Cream - with Menthol and MSM | Pain Relief for Joint, Tendon & Muscle Pains | Chosen by sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Ache 3oz',
  variation_value_one: '',
  marketplace__flagimage: 'profiles/flags/us.svg',
  smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
  id: 141,
  margin: 31.79713032862719,
  sku: 'UF-KPKM-TMSZ',
  inventory_available: 358,
  profit: 1038.55040505,
  orders: 207,
  ppc_orders: 23,
  organic_orders: 2,
  refunds: 24.00430275,
  roi: 122.12421942347721,
  promo_units_qty: 35,
  sales: 3231.7569432
};

const parentProduct = {
  marketplace: 3,
  units: 239,
  product: 1416662,
  brand: 'Activene',
  type: 'parent',
  asin: 'B012A5Z89K',
  name:
    'ACTIVENE Arnica Gel Cream - with Menthol and MSM | Pain Relief for Joint, Tendon & Muscle Pains | Chosen by sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Ache 3oz',
  variation_value_one: '',
  marketplace__flagimage: 'profiles/flags/us.svg',
  smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
  id: 141,
  margin: 31.79713032862719,
  sku: 'UF-KPKM-TMSZ',
  inventory_available: 358,
  net_profit: 1038.55040505,
  orders: 207,
  ppc_orders: 23,
  organic_orders: 2,
  refunds: 24.00430275,
  roi: 122.12421942347721,
  promo_units: 35,
  sales: 3231.7569432
};

const response = {
  products: [
    {
      product: 14490716,
      marketplace: 3,
      product_sales: 3635.45,
      discounts: 0.0,
      refunds: -279.65,
      fba_fees: -370.11,
      referral_fees: -545.09,
      referral_fee_reimbursement: 41.93,
      refund_commission: -8.4,
      other_refunds: 0.0,
      reimbursement: 0.0,
      reimbursement_return: 0.0,
      reimbursed_inventory: 0.0,
      refunded_cogs: 43.4,
      production_cost: -911.4,
      shipping_cost: -97.65,
      cin7_cogs: 0.0,
      shipping: 0.0,
      employees_expenses: 0.0,
      warehouse_expenses: 0.0,
      branding_expenses: 0.0,
      other_expenses: 0.0,
      fbm_shipping: 0.0,
      units: 91,
      replacement_units: 2,
      promo_sales: 0.0,
      promo_units: 0,
      orders: 83,
      shipped_orders: 80,
      unshipped_orders: 0,
      pending_orders: 3,
      canceled_orders: 0,
      replacement_orders: 1,
      quantity_refunded: 7,
      organic_orders: 83,
      full_price_units: 91,
      new_tax: 0.0,
      sessions: 1039,
      browser_sessions: 450,
      mobile_sessions: 589,
      conversion_rate: 211.1225,
      ads_sales: 0.0,
      ads_products: 0.0,
      ads_custom: 0.0,
      ads_brands: 0.0,
      ads_video: 0.0,
      ads_display: 0.0,
      ppc_orders: 0,
      clicks: 0,
      impressions: 0,
      amazon_expenses: {
        fba_sf: -8.5282,
        fba_ltsf: 0.0
      },
      coupon_payment_event: 0.0,
      subscription: 0.0,
      fba_inbound_convenience_fee: 0.0,
      fba_inbound_transportation_fee: 0.0,
      fba_inbound_transportation_program_fee: 0.0,
      fba_removal_fee: 0.0,
      fba_disposal_fee: 0.0,
      seller_deal_payment: 0.0,
      seller_review_enrollment_payment_event: 0.0,
      fba_overage_fee: 0.0,
      fba_international_inbound_freight_fee: 0.0,
      fba_international_inbound_freight_tax_and_duty: 0.0,
      storage_fees: -8.5282,
      long_term_storage_fees: 0.0,
      storage_fees_fin: -8.1936,
      long_term_storage_fees_fin: 0.0,
      storage_fees_fin_spread: -8.5282,
      long_term_storage_fees_fin_spread: 0.0,
      ads_products_spread: 0.0,
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      fba: 148,
      fbm: 0,
      parent_asin: '',
      variation_value_one: 'BBB',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: 'Color',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      sku: 'BU-4HE3-PV122',
      asin: 'A554K46TJL',
      custom_expenses: []
    },
    {
      product: 14490709,
      marketplace: 3,
      product_sales: 12122.36,
      discounts: -127.45,
      refunds: -99.88,
      fba_fees: -1937.26,
      referral_fees: -1801.17,
      referral_fee_reimbursement: 14.24,
      refund_commission: -2.84,
      other_refunds: 5.0,
      reimbursement: 0.0,
      reimbursement_return: 0.0,
      reimbursed_inventory: 0.0,
      refunded_cogs: 7.96,
      production_cost: -1903.2,
      shipping_cost: -39.04,
      cin7_cogs: 0.0,
      shipping: 0.0,
      employees_expenses: 0.0,
      warehouse_expenses: 0.0,
      branding_expenses: 0.0,
      other_expenses: 0.0,
      fbm_shipping: 0.0,
      units: 488,
      replacement_units: 0,
      promo_sales: 1273.47,
      promo_units: 51,
      orders: 421,
      shipped_orders: 361,
      unshipped_orders: 0,
      pending_orders: 60,
      canceled_orders: 0,
      replacement_orders: 0,
      quantity_refunded: 7,
      organic_orders: 416,
      full_price_units: 437,
      new_tax: 0.0,
      sessions: 1406,
      browser_sessions: 591,
      mobile_sessions: 815,
      conversion_rate: 753.9227,
      ads_sales: 124.85,
      ads_products: -81.19,
      ads_custom: 0.0,
      ads_brands: 0.0,
      ads_video: 0.0,
      ads_display: 0.0,
      ppc_orders: 5,
      clicks: 46,
      impressions: 23642,
      amazon_expenses: {
        fba_sf: -20.028,
        fba_ltsf: -0.536
      },
      coupon_payment_event: 0.0,
      subscription: 0.0,
      fba_inbound_convenience_fee: 0.0,
      fba_inbound_transportation_fee: 0.0,
      fba_inbound_transportation_program_fee: 0.0,
      fba_removal_fee: 0.0,
      fba_disposal_fee: 0.0,
      seller_deal_payment: 0.0,
      seller_review_enrollment_payment_event: 0.0,
      fba_overage_fee: 0.0,
      fba_international_inbound_freight_fee: 0.0,
      fba_international_inbound_freight_tax_and_duty: 0.0,
      storage_fees: -20.028,
      long_term_storage_fees: -0.536,
      storage_fees_fin: -23.4364,
      long_term_storage_fees_fin: 0.0,
      storage_fees_fin_spread: -20.028,
      long_term_storage_fees_fin_spread: -0.536,
      ads_products_spread: 0.0,
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      fba: 2138,
      fbm: 0,
      parent_asin: '',
      variation_value_one: '',
      variation_value_two: '',
      variation_value_three: '',
      variation_value_four: '',
      variation_value_five: '',
      variation_type_one: '',
      variation_type_two: '',
      variation_type_three: '',
      variation_type_four: '',
      variation_type_five: '',
      sku: 'GG-51B4-DTG2',
      asin: 'H515DFT59R',
      custom_expenses: []
    }
  ],
  is_limited: false,
  overview: [
    {
      recordId: '',
      product: '',
      marketplace: 3,
      is_parent: true,
      ppc_orders: 5,
      type: 'parent',
      product__brand: null,
      units: 579,
      brand: '',
      quantity_refunded: 14,
      variation_type_five: '',
      asin: '',
      parent_asin: '',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      orders: 504,
      promo_units: 51,
      full_units_qty: 0,
      promo_sales: 1273.47,
      discounts: -127.45,
      new_tax: 0.0,
      ads_display: 0.0,
      ads_products: -81.19,
      ads_products_spread: 0.0,
      ads_brands: 0.0,
      ads_video: 0.0,
      ads_sales: 124.85,
      product_sales: 15757.810000000001,
      amazon_expenses: {
        fba_sf: -28.5562,
        fba_ltsf: -0.536
      },
      custom_expenses: [],
      production_cost: -2814.6,
      shipping_cost: -136.69,
      shipping: 0.0,
      fbm_shipping: 0.0,
      fba_fees: -2307.37,
      fba_sf: 0.0,
      storage_fees_fin: -31.63,
      storage_fees_fin_spread: -28.556199999999997,
      long_term_storage_fees_fin: 0.0,
      long_term_storage_fees_fin_spread: -0.536,
      fba_ltsf: 0.0,
      referral_fees: -2346.26,
      fba: 2286,
      fbm: 0,
      children: [
        {
          product: 14490709,
          marketplace: 3,
          product_sales: 12122.36,
          discounts: -127.45,
          refunds: -99.88,
          fba_fees: -1937.26,
          referral_fees: -1801.17,
          referral_fee_reimbursement: 14.24,
          refund_commission: -2.84,
          other_refunds: 5.0,
          reimbursement: 0.0,
          reimbursement_return: 0.0,
          reimbursed_inventory: 0.0,
          refunded_cogs: 7.96,
          production_cost: -1903.2,
          shipping_cost: -39.04,
          cin7_cogs: 0.0,
          shipping: 0.0,
          employees_expenses: 0.0,
          warehouse_expenses: 0.0,
          branding_expenses: 0.0,
          other_expenses: 0.0,
          fbm_shipping: 0.0,
          units: 488,
          replacement_units: 0,
          promo_sales: 1273.47,
          promo_units: 51,
          orders: 421,
          shipped_orders: 361,
          unshipped_orders: 0,
          pending_orders: 60,
          canceled_orders: 0,
          replacement_orders: 0,
          quantity_refunded: 7,
          organic_orders: 416,
          full_price_units: 437,
          new_tax: 0.0,
          sessions: 1406,
          browser_sessions: 591,
          mobile_sessions: 815,
          conversion_rate: 753.9227,
          ads_sales: 124.85,
          ads_products: -81.19,
          ads_custom: 0.0,
          ads_brands: 0.0,
          ads_video: 0.0,
          ads_display: 0.0,
          ppc_orders: 5,
          clicks: 46,
          impressions: 23642,
          amazon_expenses: {
            fba_sf: -20.028,
            fba_ltsf: -0.536
          },
          coupon_payment_event: 0.0,
          subscription: 0.0,
          fba_inbound_convenience_fee: 0.0,
          fba_inbound_transportation_fee: 0.0,
          fba_inbound_transportation_program_fee: 0.0,
          fba_removal_fee: 0.0,
          fba_disposal_fee: 0.0,
          seller_deal_payment: 0.0,
          seller_review_enrollment_payment_event: 0.0,
          fba_overage_fee: 0.0,
          fba_international_inbound_freight_fee: 0.0,
          fba_international_inbound_freight_tax_and_duty: 0.0,
          storage_fees: -20.028,
          long_term_storage_fees: -0.536,
          storage_fees_fin: -23.4364,
          long_term_storage_fees_fin: 0.0,
          storage_fees_fin_spread: -20.028,
          long_term_storage_fees_fin_spread: -0.536,
          ads_products_spread: 0.0,
          smallImage:
            'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
          name:
            'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
          fba: 2138,
          fbm: 0,
          parent_asin: '',
          variation_value_one: '',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: '',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          sku: 'GG-51B4-DTG2',
          asin: 'H515DFT59R',
          custom_expenses: []
        },
        {
          product: 14490716,
          marketplace: 3,
          product_sales: 3635.45,
          discounts: 0.0,
          refunds: -279.65,
          fba_fees: -370.11,
          referral_fees: -545.09,
          referral_fee_reimbursement: 41.93,
          refund_commission: -8.4,
          other_refunds: 0.0,
          reimbursement: 0.0,
          reimbursement_return: 0.0,
          reimbursed_inventory: 0.0,
          refunded_cogs: 43.4,
          production_cost: -911.4,
          shipping_cost: -97.65,
          cin7_cogs: 0.0,
          shipping: 0.0,
          employees_expenses: 0.0,
          warehouse_expenses: 0.0,
          branding_expenses: 0.0,
          other_expenses: 0.0,
          fbm_shipping: 0.0,
          units: 91,
          replacement_units: 2,
          promo_sales: 0.0,
          promo_units: 0,
          orders: 83,
          shipped_orders: 80,
          unshipped_orders: 0,
          pending_orders: 3,
          canceled_orders: 0,
          replacement_orders: 1,
          quantity_refunded: 7,
          organic_orders: 83,
          full_price_units: 91,
          new_tax: 0.0,
          sessions: 1039,
          browser_sessions: 450,
          mobile_sessions: 589,
          conversion_rate: 211.1225,
          ads_sales: 0.0,
          ads_products: 0.0,
          ads_custom: 0.0,
          ads_brands: 0.0,
          ads_video: 0.0,
          ads_display: 0.0,
          ppc_orders: 0,
          clicks: 0,
          impressions: 0,
          amazon_expenses: {
            fba_sf: -8.5282,
            fba_ltsf: 0.0
          },
          coupon_payment_event: 0.0,
          subscription: 0.0,
          fba_inbound_convenience_fee: 0.0,
          fba_inbound_transportation_fee: 0.0,
          fba_inbound_transportation_program_fee: 0.0,
          fba_removal_fee: 0.0,
          fba_disposal_fee: 0.0,
          seller_deal_payment: 0.0,
          seller_review_enrollment_payment_event: 0.0,
          fba_overage_fee: 0.0,
          fba_international_inbound_freight_fee: 0.0,
          fba_international_inbound_freight_tax_and_duty: 0.0,
          storage_fees: -8.5282,
          long_term_storage_fees: 0.0,
          storage_fees_fin: -8.1936,
          long_term_storage_fees_fin: 0.0,
          storage_fees_fin_spread: -8.5282,
          long_term_storage_fees_fin_spread: 0.0,
          ads_products_spread: 0.0,
          smallImage:
            'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
          name:
            'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
          fba: 148,
          fbm: 0,
          parent_asin: '',
          variation_value_one: 'BBB',
          variation_value_two: '',
          variation_value_three: '',
          variation_value_four: '',
          variation_value_five: '',
          variation_type_one: 'Color',
          variation_type_two: '',
          variation_type_three: '',
          variation_type_four: '',
          variation_type_five: '',
          sku: 'BU-4HE3-PV122',
          asin: 'A554K46TJL',
          custom_expenses: []
        }
      ],
      sessions: 2445,
      browser_sessions: 1041,
      mobile_sessions: 1404,
      impressions: 23642,
      clicks: 46,
      refund_commission: -11.24,
      referral_fee_reimbursement: 56.17,
      refunds: -379.53,
      other_refunds: 5.0,
      reimbursement: 0.0,
      reimbursement_return: 0.0,
      reimbursed_inventory: 0.0,
      refunded_cogs: 51.36
    }
  ]
};

mock.onGet(API_V2_1.products.sales).reply(() => {
  const result = { ...salesDb };
  for (let i = 0; i < 15; i += 1) {
    const newProduct = i % 5 === 0 ? { ...parentProduct } : { ...fakeProduct };
    newProduct.units += i;
    newProduct.sales += i * 2 + i;
    newProduct.product = i;
    result.products.push(newProduct);
  }

  return [200, response];
});
