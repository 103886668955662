import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import Colors from 'styles/colors';
import { EllipsisIcon } from '../icons';

const DotsButton = ({ onClick, className, disabled }) => {
  return (
    <ButtonBase
      disabled={disabled}
      variant="text"
      className={classNames(
        'px-3 py-2 normal-case text-sm rounded hover:bg-gray-lighter transition duration-200',
        className
      )}
      size="small"
      onClick={onClick}
    >
      <EllipsisIcon height="14px" width="5px" fill={Colors.gray.dark} />
    </ButtonBase>
  );
};

DotsButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

DotsButton.defaultProps = {
  onClick: () => {},
  className: '',
  disabled: false
};

export default DotsButton;
