import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FlatButton, GradButton } from '@components';
import ModernDialog from '@components/dialogs/ModernDialog';
import FileInput from '@components/inputs/FileInput';
import DateInput from '@components/inputs/DateInput';
import BulkUploadIcon from './ic_cloud-upload-alt-solid.svg';

const HistoricalCogsBulkUpdate = ({ className, lifetime, onDownloadTemplate, onFileUploaded }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selectedFiles, setSelectedFiles] = useState([]);
  const onClose = () => setOpen(false);
  const onFileLoaded = fileData => {
    onFileUploaded(fileData, date);
    onClose();
  };
  const onConfirm = () => {
    const [file] = selectedFiles;
    const reader = new FileReader();
    reader.onload = () => onFileLoaded(reader.result);
    reader.readAsText(file);
  };
  const content = (
    <div>
      <DateInput
        date={date}
        setDate={setDate}
        title="Start Date"
        className="mb-8"
        lifeTime={lifetime}
      />
      <FileInput
        selectedFiles={selectedFiles}
        addSelectedFiles={setSelectedFiles}
        mask=".csv"
        loadFileFunc={(file, reader) => reader.readAsText(file)}
      />
      <div className="w-full flex justify-center">
        <FlatButton onClick={onDownloadTemplate} className="mt-4 mb-24 text-yellow">
          Get Template
        </FlatButton>
      </div>
    </div>
  );
  return (
    <>
      <GradButton
        className={className}
        onClick={() => {
          setSelectedFiles([]);
          setOpen(true);
        }}
      >
        <div className="flex flex-row items-center">
          <img src={BulkUploadIcon} alt="arrow-left" className="h-4 w-4 mr-2" />
          RF Bulk Upload
        </div>
      </GradButton>
      <ModernDialog
        title="Bulk Upload Product Costs"
        onConfirm={onConfirm}
        onCancel={onClose}
        open={open}
        content={content}
        confirmActive={selectedFiles.length}
      />
    </>
  );
};

HistoricalCogsBulkUpdate.propTypes = {
  className: PropTypes.string,
  onDownloadTemplate: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func.isRequired
};

HistoricalCogsBulkUpdate.defaultProps = {
  className: ''
};

export default HistoricalCogsBulkUpdate;
