import { APPLY_CONFIG, SET_APP, SET_APP_VERSION, SET_ONBOARDING_PAGE_SCREEN } from '../actions';

const initialState = {
  app: 'Dashboard',
  appMenu: [],
  userMenu: [],
  showGetStartedCheckList: true,
  showTrialInfo: true,
  profileTabs: null,
  onboardingPageScreen: 'onboardingPanel',
  version: null
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP: {
      const { app } = action;

      return {
        ...state,
        app
      };
    }
    case APPLY_CONFIG: {
      const { appMenu, userMenu, showGetStartedCheckList, showTrialInfo } = action.config;
      return { ...state, appMenu, userMenu, showGetStartedCheckList, showTrialInfo };
    }
    case SET_ONBOARDING_PAGE_SCREEN: {
      const { onboardingPageScreen } = action;
      return {
        ...state,
        onboardingPageScreen
      };
    }
    case SET_APP_VERSION: {
      return { ...state, version: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default dashboard;
