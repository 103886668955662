/* eslint-disable import/prefer-default-export */
import { takeLeading, spawn, takeEvery, put, call, select } from '@redux-saga/core/effects';
import { WARNING } from '@components/notifications/NotificationView';
import {
  HTTP_SERVER_UNAVAILABLE,
  HTTP_UNAUTHORIZED,
  TOKEN_EXPIRED_ERROR_TEXT,
  isCommonError,
  configSentry
} from '@utils';
import { closeIntercomSession } from '@utils/intercom';
import {
  CLEANUP_SYSTEM_NOTIFICATIONS,
  HANDLE_SYSTEM_ERRORS,
  PING_SERVER,
  pingServerAction
} from './action';
import { refreshTokenSaga } from '../login';
import {
  setPrimaryNotificationMsgAction,
  setServerUnavailableAction,
  setSuccessMsgAction,
  setTokenAction,
  USER_LOGOUT
} from '../../actions';
import { LOGIN_REDUCER_KEY, REHYDRATE_ACTION_TYPE, networkSelector } from '../../reducers';

const PING_DELAY = 20000;

function* pingServerSaga() {
  yield new Promise(resolve => setTimeout(resolve, PING_DELAY));
  yield spawn(refreshTokenSaga, { forceRefresh: true });
}

function* serverUnavailableSaga() {
  yield put(setServerUnavailableAction(true));
  yield put(
    setPrimaryNotificationMsgAction(
      'The server is temporarily unavailable. Please wait, we make it better for you.',
      WARNING
    )
  );
  yield put(pingServerAction());
}

const SESSION_EXPIRED_NOTIFICATION_TEXT = 'Session expired! Please login again.';

function* userUnauthorizedSaga(errorDetail) {
  yield put(setTokenAction(null));
  const notificationText =
    errorDetail === TOKEN_EXPIRED_ERROR_TEXT
      ? SESSION_EXPIRED_NOTIFICATION_TEXT
      : "You've been logged out. Please contact support@sellermetrix.com if problem persists.";

  yield put(setPrimaryNotificationMsgAction(notificationText));
}
export const getIsCorsError = error => !error?.response && error?.message === 'Network Error';

function* cleanupSystemNotificationSaga({ payload }) {
  const { primaryNotification, serverUnavailable } = yield select(networkSelector);
  if (
    (!payload || !isCommonError(payload?.response?.status)) &&
    (serverUnavailable || primaryNotification === SESSION_EXPIRED_NOTIFICATION_TEXT) &&
    !getIsCorsError(payload)
  ) {
    yield put(setServerUnavailableAction(false));
    yield put(setPrimaryNotificationMsgAction(''));
    if (serverUnavailable) {
      yield put(
        setSuccessMsgAction('The server is available again, thank you for waiting!', true, true)
      );
    }
  }
}

function* handleSystemErrorsSaga({ payload }) {
  yield call(cleanupSystemNotificationSaga, { payload });
  const errorDetail = payload?.response?.data?.detail;

  if (getIsCorsError(payload)) {
    yield call(serverUnavailableSaga);
    return;
  }

  switch (payload?.response?.status) {
    case HTTP_UNAUTHORIZED: {
      yield call(userUnauthorizedSaga, errorDetail);
      break;
    }
    case HTTP_SERVER_UNAVAILABLE: {
      yield call(serverUnavailableSaga);
      break;
    }
    default:
      break;
  }
}

function* closeInterComeSaga() {
  yield closeIntercomSession();
}

function* initSentryUserIdSaga({ payload, key }) {
  if (key === LOGIN_REDUCER_KEY) yield configSentry({ id: payload?.id });
}

export function* systemSagaWatcher() {
  yield takeLeading([PING_SERVER], pingServerSaga);
  yield takeEvery([CLEANUP_SYSTEM_NOTIFICATIONS], cleanupSystemNotificationSaga);
  yield takeEvery([HANDLE_SYSTEM_ERRORS], handleSystemErrorsSaga);
  yield takeLeading([USER_LOGOUT], closeInterComeSaga);
  yield takeLeading([REHYDRATE_ACTION_TYPE], initSentryUserIdSaga);
}
