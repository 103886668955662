import { put, select, takeLatest } from '@redux-saga/core/effects';
import { isFullAccessForFree } from '@sm/api';
import {
  SET_USER_PROFILE,
  setNotFilledCompanyCountryWarningShowAction,
  setPpcNeedsReconnectWarningShowAction
} from 'seller/store/actions';
import { userPreferencesSelector, userSelector } from 'seller/store/reducers';
import {
  setAccessDeniedWarningShowAction,
  setSkippedDisconnectedAccountsAction,
  setSpApiExpiredWarningShowAction,
  setSpApiExpiredWarningSkippedAction,
  setSkippedPpcNeedsReconnectAction,
  SKIP_ACCESS_DENIED_WARNING,
  SKIP_SP_API_EXPIRED_WARNING,
  SKIP_PPC_NEEDS_RECONNECT_WARNING
} from './action';

export function* userPreferencesSaga(action) {
  const { type } = action;
  const {
    accessDeniedSkipped,
    ppcNeedsReconnectSkipped,
    spApiExpiredWarningSkipped
  } = yield select(userPreferencesSelector);
  const { profile } = yield select(userSelector);
  const {
    notConnectedSPA,
    pcc_needs_reconnect: pccNeedsReconnect,
    company_residence_country: companyResidenceCountry,
    sp_api_connection_expired: spApiConnectionExpired,
    billing
  } = profile;

  if (action.type === SET_USER_PROFILE) {
    if (billing && !isFullAccessForFree(profile)) {
      yield put(setNotFilledCompanyCountryWarningShowAction(!companyResidenceCountry));
    }
    yield put(setAccessDeniedWarningShowAction(notConnectedSPA && !accessDeniedSkipped));
    if (!notConnectedSPA && accessDeniedSkipped) {
      yield put(setSkippedDisconnectedAccountsAction(false));
    }
    yield put(
      setPpcNeedsReconnectWarningShowAction(pccNeedsReconnect && !ppcNeedsReconnectSkipped)
    );
    if (!pccNeedsReconnect && ppcNeedsReconnectSkipped) {
      yield put(setSkippedPpcNeedsReconnectAction(false));
    }
    yield put(
      setSpApiExpiredWarningShowAction(spApiConnectionExpired && !spApiExpiredWarningSkipped)
    );
    if (!spApiConnectionExpired && spApiExpiredWarningSkipped) {
      yield put(setSpApiExpiredWarningSkippedAction(false));
    }
  }

  if (type === SKIP_ACCESS_DENIED_WARNING) {
    yield put(setSkippedDisconnectedAccountsAction(true));
    yield put(setAccessDeniedWarningShowAction(false));
  }

  if (type === SKIP_PPC_NEEDS_RECONNECT_WARNING) {
    yield put(setSkippedPpcNeedsReconnectAction(true));
    yield put(setPpcNeedsReconnectWarningShowAction(false));
  }

  if (action.type === SKIP_SP_API_EXPIRED_WARNING) {
    yield put(setSpApiExpiredWarningSkippedAction(true));
    yield put(setSpApiExpiredWarningShowAction(false));
  }
}

export function* userPreferencesSagaWatcher() {
  yield takeLatest(
    [
      SET_USER_PROFILE,
      SKIP_ACCESS_DENIED_WARNING,
      SKIP_PPC_NEEDS_RECONNECT_WARNING,
      SKIP_SP_API_EXPIRED_WARNING
    ],
    userPreferencesSaga
  );
}
