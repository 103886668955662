import { put, takeLatest } from '@redux-saga/core/effects';
import { axiosCall } from '@utils';
import API from '@sm/api';
import { setNotificationMsgAction } from '../../actions';
import { REDIRECT_TO_SHOPIFY_APP_INSTALL } from './action';

function* redirectToShopifyAppInstall({ payload }) {
  try {
    const params = { shop: payload.shopUrl };
    const { data } = yield axiosCall.get(API.shopify.sign_up, { params });
    const { install_link: installLink, error } = data;
    if (error) {
      yield put(setNotificationMsgAction(error));
      return;
    }
    window.location = installLink;
  } catch (e) {
    console.log('redirectToShopifyAppInstall', e);
    yield put(setNotificationMsgAction('Failed to generate installation link'));
  } finally {
    if (payload.callback) payload.callback();
  }
}

export function* shopifySagaWatcher() {
  yield takeLatest(REDIRECT_TO_SHOPIFY_APP_INSTALL, redirectToShopifyAppInstall);
}
