import axios from 'axios/index';
import API, { HOST } from './api';
import { EU_REGION, FE_REGION, INDIA_REGION, NA_REGION } from './Flags';

const REGION_ENDPOINTS = {
  [NA_REGION]: 'https://www.amazon.com/ap/oa',
  [INDIA_REGION]: 'https://www.amazon.com/ap/oa',
  [EU_REGION]: 'https://eu.account.amazon.com/ap/oa',
  [FE_REGION]: 'https://apac.account.amazon.com/ap/oa'
};

export const startAdvertisingSignup = (sellerId, redirectApi, errorHandler, region) => {
  const endpoint = REGION_ENDPOINTS[region || NA_REGION];
  if (!endpoint) {
    console.error('unexpected region: ', region);
    return;
  }

  const redirectUrl = `${HOST}/${redirectApi}`;
  const params = sellerId ? { sellerId } : null;
  axios
    .post(API.get_lwa_state, params)
    .then(res => {
      console.log(res);
      if (!res.data || !res.data.state) {
        console.error(`not found state: ${res.data}`);
        return;
      }

      const baseUrl = `${endpoint}?client_id=amzn1.application-oa2-client.0d0c7ea82748494d83c4a732283447ed&scope=advertising::campaign_management&response_type=code&state=${res.data.state}&redirect_uri=${redirectUrl}`;
      console.log(baseUrl);
      window.location = baseUrl;
    })
    .catch(err => {
      console.log(err.response.data);
      if (errorHandler) {
        errorHandler(err.response && err.response.data ? err.response.data : err.message);
      }
    });
};

export const mock = () => {};
