/* eslint-disable import/prefer-default-export */
import { put, select, takeLeading, takeLatest } from 'redux-saga/effects';
import { differenceInDays } from 'date-fns';
import API, { TOKEN_EXPIRATION_TIMEOUT } from '@sm/api';
import { axiosCall, sendTextError, TOKEN_REFRESH_PERIOD_EXPIRED } from '@utils';
import { REFRESH_TOKEN, RESET_PASSWORD, SETUP_NEW_PASSWORD } from './action';
import { loginSelector } from '../../reducers';
import {
  setNetworkActivityAction,
  setNotificationMsgAction,
  setTokenAction,
  setTokenRefreshExpiredAction
} from '../../actions';

export function* refreshTokenSaga({ forceRefresh }) {
  const { tokenDate, token, tokenRefreshExpired } = yield select(loginSelector);
  if (!forceRefresh && (!tokenDate || tokenRefreshExpired)) return;
  try {
    console.log(`Last refresh date: ${new Date(+tokenDate)}`);
    const difference = differenceInDays(new Date(), new Date(+tokenDate));
    if (difference > TOKEN_EXPIRATION_TIMEOUT || forceRefresh) {
      const { data } = yield axiosCall.post(API.refreshToken, { token });
      yield put(setTokenAction(data.token));
      yield put(setTokenRefreshExpiredAction(false));
    }
  } catch (error) {
    if (error?.response?.data?.non_field_errors?.[0] === TOKEN_REFRESH_PERIOD_EXPIRED) {
      yield put(setTokenRefreshExpiredAction(true));
    }
    console.log('refreshSaga error', error);
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    yield put(setNetworkActivityAction(true));
    const { callback, email } = payload;
    yield axiosCall.post(API.password.reset, { email });
    if (callback) callback();
  } catch (e) {
    yield put(setNotificationMsgAction('Sending email for password recovery failed'));
  } finally {
    yield put(setNetworkActivityAction(false));
  }
}

function* setupNewPasswordSaga({ payload }) {
  yield put(setNetworkActivityAction(true));
  const { password, userId, token, callback } = payload;
  try {
    if (!userId || !token) {
      if (callback) callback(false);
      return;
    }
    yield axiosCall.post(API.password.resetDone, { uid: userId, token, new_password: password });
    if (callback) callback(true);
  } catch (error) {
    if (callback) callback(false);
    sendTextError('setupNewPasswordSaga error', error);
  } finally {
    yield put(setNetworkActivityAction(false));
  }
}

export function* loginSagaWatcher() {
  yield takeLeading([REFRESH_TOKEN], refreshTokenSaga);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(SETUP_NEW_PASSWORD, setupNewPasswordSaga);
}
