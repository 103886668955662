/* eslint-disable global-require */
import { getWebpImg } from '@utils';

const images = {
  'il_main-block-mobile.png': require('./il_main-block-mobile.png'),
  'il_main-block-mobile.webp': require('./il_main-block-mobile.webp'),
  'il_main-block.png': require('./il_main-block.png'),
  'il_main-block.webp': require('./il_main-block.webp')
};
const mainBlockWebImg = getWebpImg(images, 'il_main-block');

export default mainBlockWebImg;
