import { sortStrAsc } from '@components/data-table/sortFuncs';
import {
  // acosView,
  conversionRateView,
  inventoryView,
  getNewProfitView,
  ordersView,
  getRefundsView,
  getSalesCell,
  getTotalAdvertisingView,
  unitsView,
  roiView,
  getSessionsView
} from './views';

// eslint-disable-next-line import/prefer-default-export
export const getColumns = isUnitEconomicsView => {
  const result = {
    smallImage: {
      title: 'Product',
      width: 349,
      sort: {
        field: 'name',
        type: 'string',
        func: sortStrAsc
      }
    },
    sales: {
      title: 'Sales',
      currency: true,
      customView: getSalesCell(isUnitEconomicsView),
      width: 140
    },
    orders: {
      title: 'Order Items',
      customView: ordersView
    },
    units: {
      title: 'Units',
      customView: unitsView
    },
    total_ads: {
      title: 'Ad Spend',
      currency: true,
      customView: getTotalAdvertisingView(isUnitEconomicsView)
    },
    // acos: {
    //   title: 'ACoS',
    //   customView: acosView,
    //   percentage: true
    // },
    total_refunds: {
      title: 'Refunds',
      currency: true,
      customView: getRefundsView('quantity_refunded', isUnitEconomicsView)
    },
    profit: {
      title: 'Net Profit',
      currency: true,
      customView: getNewProfitView(isUnitEconomicsView)
    },
    roi: {
      title: 'ROI (%)',
      customView: roiView
    },
    inventory: {
      title: 'Inventory',
      customView: inventoryView
    },
    sessions: {
      title: 'Sessions',
      formatted: true,
      customView: getSessionsView(isUnitEconomicsView)
    },
    convRate: {
      title: 'Conv. Rate (%)',
      customView: conversionRateView
    }
  };
  if (isUnitEconomicsView) {
    delete result.orders;
    delete result.units;
  }
  return result;
};
