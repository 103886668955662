import React from 'react';
import PropTypes from 'prop-types';
import NoImage from '@components/icons/no-image-available.png';
import { ProductsPicBox } from '@components';
import Colors from 'styles/colors';
import { ChevronRightIcon } from '@components/icons';
import FLAGS from '@sm/Flags';
import { defaultRowHeight } from '@components/products/products-search-results/ProductsList';

const BookmarkRow = ({ pictures, name, marketplace, productCount, onClick }) => {
  if (!name) return null;
  const flag = FLAGS.find(flagInfo => flagInfo.id === marketplace);
  return (
    <div
      className="w-full pl-2 flex items-center justify-between hover:shadow"
      style={{ height: defaultRowHeight }}
      onClick={onClick}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
    >
      <ProductsPicBox
        pictures={pictures?.length ? pictures : [NoImage]}
        fixedSize={{ small: '2rem', large: '2rem' }}
      />
      <div className="ml-4 normal-case leading-none truncate">
        <p className="text-xs font-medium text-gray-darkest max-w-full truncate">{name}</p>
        <div className="flex items-center mt-1">
          <img src={flag.src} alt="flag" width="22" height="12" className="rounded-sm mr-2" />
          <p className="text-gray-dark text-xs">
            {productCount} Product{productCount > 1 ? 's' : ''}
          </p>
        </div>
      </div>
      <ChevronRightIcon
        height="10px"
        fill={Colors.gray.dark}
        className="flex-shrink-0 ml-auto mr-3"
      />
    </div>
  );
};

BookmarkRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  pictures: PropTypes.instanceOf(Array).isRequired,
  marketplace: PropTypes.number.isRequired,
  productCount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

export default BookmarkRow;
