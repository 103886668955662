export const prepareSelectedProducts = bookmark => {
  const data = bookmark.products;
  let selectedProducts = [];
  if (data?.brands) {
    const brands = data.brands.map(object => ({
      ...object,
      type: 'brand',
      selected: true
    }));
    selectedProducts = [...selectedProducts, ...brands];
  }
  if (data?.parent_products) {
    const parents = data.parent_products.map(object => ({
      ...object,
      type: 'parent',
      selected: true
    }));
    selectedProducts = [...selectedProducts, ...parents];
  }
  if (data?.products) {
    const products = data.products.map(object => ({
      ...object,
      type: object.variation_type_one ? 'variation' : 'default',
      selected: true
    }));
    selectedProducts = [...selectedProducts, ...products];
  }
  return selectedProducts;
};

const compareTypeId = (a, b) => a.id === b.id && a.type === b.type;

export const getSelectionDiff = (bookmarkProducts, selectedProducts) => {
  if (!bookmarkProducts)
    return {
      newProducts: selectedProducts.map(item => ({ ...item })),
      deletedProducts: [],
      stillExistProducts: [],
      changed: false
    };
  const { deletedProducts, stillExistProducts } = bookmarkProducts.reduce(
    (acc, bookmarkProduct) =>
      !selectedProducts.find(selectedProduct => compareTypeId(bookmarkProduct, selectedProduct))
        ? { ...acc, deletedProducts: [...acc.deletedProducts, { ...bookmarkProduct }] }
        : { ...acc, stillExistProducts: [...acc.stillExistProducts, { ...bookmarkProduct }] },
    { deletedProducts: [], stillExistProducts: [] }
  );
  const newProducts = selectedProducts.reduce(
    (acc, selectedProduct) =>
      !bookmarkProducts.find(bookmarkProduct => compareTypeId(bookmarkProduct, selectedProduct))
        ? [...acc, { ...selectedProduct }]
        : acc,
    []
  );
  return {
    deletedProducts,
    newProducts,
    stillExistProducts,
    changed: !!deletedProducts.length || !!newProducts.length
  };
};

export const getBookmarkItems = items => {
  if (!items) return [];
  return items
    .map(item => item?.sku || item?.asin || item?.name)
    .filter((value, index, array) => value && array.indexOf(value) === index);
};
