import {
  SET_MBR_PATCH_LOADING,
  SET_MBR_REPORT,
  SET_MBR_REPORT_LOADING,
  SET_PRIMARY_MBR_CHART_DATA,
  SET_PRIMARY_MBR_CHART_LOADING,
  SET_SECONDARY_MBR_CHART_DATA,
  SET_SECONDARY_MBR_CHART_LOADING
} from 'seller/store/saga/monthlyBrandReview';

const initialState = {
  reportData: null,
  reportLoading: false,
  patchLoading: false,
  chartPrimaryData: [],
  chartSecondaryData: [],
  chartPrimaryLoading: false,
  chartSecondaryLoading: false
};
export const monthlyBrandReviewSelector = state => state.monthlyBrandReview;

const monthlyBrandReview = (state = initialState, action) => {
  switch (action.type) {
    case SET_MBR_REPORT: {
      return { ...state, reportData: action.payload };
    }
    case SET_MBR_REPORT_LOADING:
      return { ...state, reportLoading: action.payload };

    case SET_MBR_PATCH_LOADING:
      return { ...state, patchLoading: action.payload };

    case SET_PRIMARY_MBR_CHART_DATA:
      return { ...state, chartPrimaryData: action.payload };

    case SET_PRIMARY_MBR_CHART_LOADING:
      return { ...state, chartPrimaryLoading: action.payload };

    case SET_SECONDARY_MBR_CHART_DATA:
      return { ...state, chartSecondaryData: action.payload };

    case SET_SECONDARY_MBR_CHART_LOADING:
      return { ...state, chartSecondaryLoading: action.payload };

    default: {
      return state;
    }
  }
};

export default monthlyBrandReview;
