/* eslint-disable import/prefer-default-export */
import { all, call, fork, spawn } from '@redux-saga/core/effects';

// https://redux-saga.js.org/docs/advanced/RootSaga.html
const getSpawnSaga = sendError =>
  function* spawnSaga(saga) {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        if (sendError) fork(sendError, [e]);
      }
    }
  };

export const getSagaWatcher = (watchers, sendError) => {
  const spawnSaga = getSpawnSaga(sendError);
  return function* sagaWatcher() {
    yield all(watchers.map(saga => spawn(spawnSaga, saga)));
  };
};
