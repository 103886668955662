import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Popover } from '@material-ui/core';
import { ConfirmDialog } from '@components/index';
import ContextMenu from '@components/common/ContextMenu';
import { usePopover } from '@utils';

const CommonToolsContext = React.createContext();
const useCommonToolsContext = () => useContext(CommonToolsContext);
const defaultConfirmDialogData = {
  message: '',
  description: '',
  onConfirm: () => {},
  onCancel: () => {}
};

const CommonToolsProvider = ({ children }) => {
  const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(defaultConfirmDialogData);
  const hideConfirmDialog = () => setIsConfirmDialogOpened(false);
  const showConfirmDialog = data => {
    setConfirmDialogData(data);
    setIsConfirmDialogOpened(true);
  };

  const contextMenuPopover = usePopover();

  return (
    <CommonToolsContext.Provider
      value={{
        isConfirmDialogOpened,
        confirmDialogData,
        hideConfirmDialog,
        showConfirmDialog,
        contextMenuPopover
      }}
    >
      {children}
      <Dialog open={isConfirmDialogOpened} onClose={hideConfirmDialog} maxWidth={false}>
        <ConfirmDialog
          onCancel={() => {
            if (confirmDialogData.onCancel) confirmDialogData.onCancel();
            hideConfirmDialog();
          }}
          onConfirm={() => {
            confirmDialogData.onConfirm();
            hideConfirmDialog();
          }}
          message={confirmDialogData.message}
          description={confirmDialogData.description}
          confirmText={confirmDialogData.confirmText}
        />
      </Dialog>
      <Popover
        className="ml-2"
        open={!!contextMenuPopover.target}
        anchorEl={contextMenuPopover.target}
        onClose={contextMenuPopover.clearTarget}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <ContextMenu
          options={contextMenuPopover.data?.options || []}
          callback={contextMenuPopover.clearTarget}
        />
      </Popover>
    </CommonToolsContext.Provider>
  );
};
CommonToolsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { CommonToolsProvider, useCommonToolsContext };
