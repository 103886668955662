import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { POSITIVE_DECREASE_FIELDS } from '@sm/api';
import { getAgencyCompareKey } from 'agency/dashboard/utils';
import Card from './Card';
import getCardStyle from './CardSizeCalculator';

const selected = 0;

const Totals = props => {
  const [hovered, toggleHover] = useState(false);
  const { values, totalsLoading, currency, metrics, isCompareMode } = props;

  const content = useMemo(() => {
    const metrixInfoList = Object.keys(metrics).filter(key => metrics[key].totals);
    const cardStyle = getCardStyle(values);

    return metrixInfoList.map((key, index) => {
      const data = values[key];
      const compareData = isCompareMode ? values[getAgencyCompareKey(key)] : null;
      const dataInfo = metrics[key];
      if (!dataInfo) {
        console.error(`data info not found for key: ${key}`);
        return null;
      }
      const { cardButton, breakdown, noDecimalScale } = dataInfo;
      let infoLoader = breakdown || null;
      if (cardButton) infoLoader = cardButton(values);
      let value = data && data.value ? data.value : data;
      if (key === 'ads_products') {
        const adsBrand = values.ads_brand || 0;
        const display = values.ads_display || 0;
        const video = values.ads_video || 0;
        value += adsBrand + display + video;
      }
      let compareValue;
      if (isCompareMode) {
        compareValue = compareData && compareData.value ? compareData.value : compareData;
        if (key === 'ads_products') {
          const adsBrand = values[getAgencyCompareKey('ads_brand')] || 0;
          const display = values[getAgencyCompareKey('ads_display')] || 0;
          const video = values[getAgencyCompareKey('ads_video')] || 0;
          compareValue += adsBrand + display + video;
        }
      }
      const subvalues = dataInfo.subvalues ? dataInfo.subvalues(data, values) : null;
      return (
        <Card
          isCompareMode={isCompareMode && index !== 0}
          className={index === metrixInfoList.length - 1 ? '' : 'mr-2'}
          key={key}
          title={dataInfo.title}
          value={value}
          noDecimalScale={!!noDecimalScale}
          compareValue={compareValue}
          subvalues={subvalues}
          isSelected={index === selected}
          currency={dataInfo.currency ? currency : null}
          infoIcon={infoLoader}
          minimumWidth={isCompareMode ? `calc(${cardStyle.style} + 2rem)` : cardStyle.style}
          totalsLoading={totalsLoading}
          tooltip={dataInfo.totalCardTooltip}
          positiveDecrease={POSITIVE_DECREASE_FIELDS.includes(key)}
        />
      );
    });
  }, [currency, isCompareMode, metrics, totalsLoading, values]);
  return (
    <>
      <div
        className={`xxs:invisible-scroll-container lg:scroll-container scrolling-touch overflow-auto pb-1 ${
          hovered ? 'lg:visible' : 'lg:invisible'
        }`}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
        data-index="2"
      >
        <div className="flex w-full visible">{content}</div>
      </div>
      <div className="lg:sm-h-14px xxs:h-2 w-full" />
    </>
  );
};

Totals.propTypes = {
  values: PropTypes.instanceOf(Object),
  totalsLoading: PropTypes.bool.isRequired,
  currency: PropTypes.string,
  isCompareMode: PropTypes.bool,
  breakdownApiParams: PropTypes.instanceOf(Object)
};

Totals.defaultProps = {
  values: null,
  currency: '',
  isCompareMode: false,
  breakdownApiParams: {}
};

export default Totals;
