import API from '@sm/api';
import mock from '../mock';

const ALL_ORDERS = 'All Orders';
const PENDING_ONLY = 'Pending only';

const vatDB = [
  {
    marketplace: 5,
    vat: 20,
    apply: ALL_ORDERS
  },
  {
    marketplace: 6,
    vat: 20,
    apply: ALL_ORDERS
  },
  {
    marketplace: 7,
    vat: 20,
    apply: PENDING_ONLY
  },
  {
    marketplace: 8,
    vat: 20,
    apply: ALL_ORDERS
  }
];

mock.onGet(API.vat).reply(() => {
  return [200, vatDB];
});
