import { API_V2_1 } from '@sm/api';
import mock from '../mock';

const sqpProducts = [
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 3794,
    qp_asin_count_clicks: 113,
    qp_asin_count_purchases: 18,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream',
    qp_asin_query_volume: 4701,
    qp_asin_impressions: 112097,
    qp_asin_clicks: 1799,
    qp_asin_purchases: 318,
    qp_asin_median_query_price_purchases: 17.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 270,
    qp_asin_count_clicks: 68,
    qp_asin_count_purchases: 24,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene',
    qp_asin_query_volume: 141,
    qp_asin_impressions: 1627,
    qp_asin_clicks: 76,
    qp_asin_purchases: 26,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 200,
    qp_asin_count_clicks: 54,
    qp_asin_count_purchases: 23,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene anti-inflammatory and pain relief formulation cream',
    qp_asin_query_volume: 106,
    qp_asin_impressions: 2655,
    qp_asin_clicks: 68,
    qp_asin_purchases: 25,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2193,
    qp_asin_count_clicks: 22,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pain relief cream',
    qp_asin_query_volume: 26843,
    qp_asin_impressions: 645933,
    qp_asin_clicks: 9647,
    qp_asin_purchases: 1573,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1343,
    qp_asin_count_clicks: 17,
    qp_asin_count_purchases: 5,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendonitis cream',
    qp_asin_query_volume: 1700,
    qp_asin_impressions: 40103,
    qp_asin_clicks: 683,
    qp_asin_purchases: 94,
    qp_asin_median_query_price_purchases: 13.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 606,
    qp_asin_count_clicks: 20,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'best anti inflammatory cream',
    qp_asin_query_volume: 614,
    qp_asin_impressions: 14558,
    qp_asin_clicks: 150,
    qp_asin_purchases: 15,
    qp_asin_median_query_price_purchases: 24.68,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 594,
    qp_asin_count_clicks: 17,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream tendonitis',
    qp_asin_query_volume: 607,
    qp_asin_impressions: 13296,
    qp_asin_clicks: 208,
    qp_asin_purchases: 31,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 626,
    qp_asin_count_clicks: 24,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation cream',
    qp_asin_query_volume: 728,
    qp_asin_impressions: 15867,
    qp_asin_clicks: 202,
    qp_asin_purchases: 26,
    qp_asin_median_query_price_purchases: 15.39,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 40,
    qp_asin_count_clicks: 12,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene pain relief cream',
    qp_asin_query_volume: 21,
    qp_asin_impressions: 562,
    qp_asin_clicks: 13,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 735,
    qp_asin_count_clicks: 10,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'topical anti inflammatory',
    qp_asin_query_volume: 912,
    qp_asin_impressions: 24474,
    qp_asin_clicks: 384,
    qp_asin_purchases: 61,
    qp_asin_median_query_price_purchases: 21.25,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 537,
    qp_asin_count_clicks: 12,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory creams',
    qp_asin_query_volume: 572,
    qp_asin_impressions: 12374,
    qp_asin_clicks: 156,
    qp_asin_purchases: 24,
    qp_asin_median_query_price_purchases: 20.85,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 193,
    qp_asin_count_clicks: 13,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammation cream',
    qp_asin_query_volume: 236,
    qp_asin_impressions: 5844,
    qp_asin_clicks: 91,
    qp_asin_purchases: 14,
    qp_asin_median_query_price_purchases: 22.93,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1875,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation relief',
    qp_asin_query_volume: 3169,
    qp_asin_impressions: 79096,
    qp_asin_clicks: 903,
    qp_asin_purchases: 67,
    qp_asin_median_query_price_purchases: 17.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 282,
    qp_asin_count_clicks: 12,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation relief cream',
    qp_asin_query_volume: 371,
    qp_asin_impressions: 8925,
    qp_asin_clicks: 157,
    qp_asin_purchases: 22,
    qp_asin_median_query_price_purchases: 10.79,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1061,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pain cream',
    qp_asin_query_volume: 7014,
    qp_asin_impressions: 174937,
    qp_asin_clicks: 2528,
    qp_asin_purchases: 451,
    qp_asin_median_query_price_purchases: 11.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 90,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pomada para desinflamar tendones',
    qp_asin_query_volume: 101,
    qp_asin_impressions: 1781,
    qp_asin_clicks: 34,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1726,
    qp_asin_count_clicks: 10,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'arthritis cream',
    qp_asin_query_volume: 15739,
    qp_asin_impressions: 375416,
    qp_asin_clicks: 5971,
    qp_asin_purchases: 1385,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 164,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory lotion',
    qp_asin_query_volume: 171,
    qp_asin_impressions: 3973,
    qp_asin_clicks: 58,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 13.47,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 906,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory',
    qp_asin_query_volume: 12520,
    qp_asin_impressions: 299297,
    qp_asin_clicks: 3869,
    qp_asin_purchases: 427,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 315,
    qp_asin_count_clicks: 12,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for feet',
    qp_asin_query_volume: 357,
    qp_asin_impressions: 7147,
    qp_asin_clicks: 109,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 12.98,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 482,
    qp_asin_count_clicks: 10,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory cream',
    qp_asin_query_volume: 574,
    qp_asin_impressions: 15350,
    qp_asin_clicks: 153,
    qp_asin_purchases: 22,
    qp_asin_median_query_price_purchases: 20.01,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 843,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'msm cream',
    qp_asin_query_volume: 2281,
    qp_asin_impressions: 65922,
    qp_asin_clicks: 1234,
    qp_asin_purchases: 200,
    qp_asin_median_query_price_purchases: 13.0,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 28,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'elbow tendonitis relief cream',
    qp_asin_query_volume: 42,
    qp_asin_impressions: 987,
    qp_asin_clicks: 19,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 716,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory for knee pain',
    qp_asin_query_volume: 901,
    qp_asin_impressions: 19184,
    qp_asin_clicks: 198,
    qp_asin_purchases: 21,
    qp_asin_median_query_price_purchases: 22.78,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 574,
    qp_asin_count_clicks: 9,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory creams',
    qp_asin_query_volume: 557,
    qp_asin_impressions: 18818,
    qp_asin_clicks: 144,
    qp_asin_purchases: 9,
    qp_asin_median_query_price_purchases: 22.35,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 340,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory gel',
    qp_asin_query_volume: 364,
    qp_asin_impressions: 8175,
    qp_asin_clicks: 147,
    qp_asin_purchases: 21,
    qp_asin_median_query_price_purchases: 14.21,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 443,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti swelling cream',
    qp_asin_query_volume: 557,
    qp_asin_impressions: 13133,
    qp_asin_clicks: 230,
    qp_asin_purchases: 28,
    qp_asin_median_query_price_purchases: 8.32,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 187,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'reduce knee inflammation',
    qp_asin_query_volume: 235,
    qp_asin_impressions: 12033,
    qp_asin_clicks: 65,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 179,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendonitis pain relief cream',
    qp_asin_query_volume: 238,
    qp_asin_impressions: 7701,
    qp_asin_clicks: 102,
    qp_asin_purchases: 11,
    qp_asin_median_query_price_purchases: 21.0,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 380,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'best anti inflammatory',
    qp_asin_query_volume: 432,
    qp_asin_impressions: 18246,
    qp_asin_clicks: 119,
    qp_asin_purchases: 11,
    qp_asin_median_query_price_purchases: 21.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1369,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory pain relief',
    qp_asin_query_volume: 1595,
    qp_asin_impressions: 32969,
    qp_asin_clicks: 331,
    qp_asin_purchases: 18,
    qp_asin_median_query_price_purchases: 12.47,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 12,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activine',
    qp_asin_query_volume: 21,
    qp_asin_impressions: 107,
    qp_asin_clicks: 6,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 175,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation cream extra strength',
    qp_asin_query_volume: 228,
    qp_asin_impressions: 5711,
    qp_asin_clicks: 75,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 27,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arthritis anti inflammatory cream',
    qp_asin_query_volume: 37,
    qp_asin_impressions: 821,
    qp_asin_clicks: 11,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 23,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'hand inflammation relief',
    qp_asin_query_volume: 45,
    qp_asin_impressions: 945,
    qp_asin_clicks: 23,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 29.5,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 218,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for pain',
    qp_asin_query_volume: 294,
    qp_asin_impressions: 6433,
    qp_asin_clicks: 78,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 22.71,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 218,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory cream for pain',
    qp_asin_query_volume: 271,
    qp_asin_impressions: 6036,
    qp_asin_clicks: 40,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 272,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'foot tendonitis relief',
    qp_asin_query_volume: 1924,
    qp_asin_impressions: 42085,
    qp_asin_clicks: 529,
    qp_asin_purchases: 64,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 199,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: "dupuytren's contracture cream",
    qp_asin_query_volume: 303,
    qp_asin_impressions: 6176,
    qp_asin_clicks: 165,
    qp_asin_purchases: 30,
    qp_asin_median_query_price_purchases: 32.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 124,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory topical',
    qp_asin_query_volume: 153,
    qp_asin_impressions: 3315,
    qp_asin_clicks: 35,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 22.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 64,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'steroid cream for pain',
    qp_asin_query_volume: 85,
    qp_asin_impressions: 1798,
    qp_asin_clicks: 37,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 14.21,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 126,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tennis elbow pain relief cream',
    qp_asin_query_volume: 165,
    qp_asin_impressions: 4287,
    qp_asin_clicks: 75,
    qp_asin_purchases: 9,
    qp_asin_median_query_price_purchases: 19.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 83,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'active 10 pain relief and healing cream',
    qp_asin_query_volume: 94,
    qp_asin_impressions: 2343,
    qp_asin_clicks: 39,
    qp_asin_purchases: 8,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para sobar tendones',
    qp_asin_query_volume: 10,
    qp_asin_impressions: 60,
    qp_asin_clicks: 5,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 52,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory for skin',
    qp_asin_query_volume: 68,
    qp_asin_impressions: 1357,
    qp_asin_clicks: 23,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 119,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pomada para desinflamar',
    qp_asin_query_volume: 180,
    qp_asin_impressions: 3384,
    qp_asin_clicks: 38,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 790,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'penetrex',
    qp_asin_query_volume: 8546,
    qp_asin_impressions: 244230,
    qp_asin_clicks: 5073,
    qp_asin_purchases: 1186,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 47,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'msm gel for pain',
    qp_asin_query_volume: 80,
    qp_asin_impressions: 1974,
    qp_asin_clicks: 42,
    qp_asin_purchases: 8,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 45,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for tennis elbow',
    qp_asin_query_volume: 38,
    qp_asin_impressions: 1202,
    qp_asin_clicks: 25,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 22.64,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream with tumeric',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 180,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 77,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arthritis cream for knees',
    qp_asin_query_volume: 137,
    qp_asin_impressions: 3799,
    qp_asin_clicks: 54,
    qp_asin_purchases: 10,
    qp_asin_median_query_price_purchases: 19.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 75,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'swollen feet and ankles relief cream',
    qp_asin_query_volume: 136,
    qp_asin_impressions: 2834,
    qp_asin_clicks: 51,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 15.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 32,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for trigger finger',
    qp_asin_query_volume: 33,
    qp_asin_impressions: 759,
    qp_asin_clicks: 16,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for ankle',
    qp_asin_query_volume: 20,
    qp_asin_impressions: 420,
    qp_asin_clicks: 6,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 8.79,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 52,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pain relief ointment',
    qp_asin_query_volume: 763,
    qp_asin_impressions: 17636,
    qp_asin_clicks: 229,
    qp_asin_purchases: 26,
    qp_asin_median_query_price_purchases: 11.98,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 14,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene cream',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 133,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 6,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'natural anti-inflammatory cream',
    qp_asin_query_volume: 11,
    qp_asin_impressions: 209,
    qp_asin_clicks: 4,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activeene crema',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 37,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory cream for knee',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 33,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arthritis ointment',
    qp_asin_query_volume: 233,
    qp_asin_impressions: 6028,
    qp_asin_clicks: 81,
    qp_asin_purchases: 17,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 28,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema antiinflamatoria para tendones',
    qp_asin_query_volume: 26,
    qp_asin_impressions: 569,
    qp_asin_clicks: 18,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 18.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation cream for skin',
    qp_asin_query_volume: 22,
    qp_asin_impressions: 502,
    qp_asin_clicks: 8,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 18,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory muscle',
    qp_asin_query_volume: 29,
    qp_asin_impressions: 671,
    qp_asin_clicks: 9,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 22.89,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 7,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pain reliever for tendonitis',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 133,
    qp_asin_clicks: 7,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 6,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'best cream for inflammation',
    qp_asin_query_volume: 11,
    qp_asin_impressions: 205,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 6,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation back burn',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 95,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 5,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'marijuana antiflwmatory',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 81,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arnica cream tendons',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 95,
    qp_asin_clicks: 4,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cream for elbow fracture',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'parches en tiras para el dolor de hombros, desinflamatorios',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 73,
    qp_asin_clicks: 4,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 16.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'acupuncture menthol rub',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 120,
    qp_asin_clicks: 3,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory supplement cream',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 40,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cream hot tendinitis',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema analgesica para dolor de inflamacion',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 36,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation massage tool',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 120,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'lotion to put on painful scars',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'turmeric cream for anti inflammation',
    qp_asin_query_volume: 6,
    qp_asin_impressions: 116,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'actavine',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 24,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'after c section care for inflamed tummy',
    qp_asin_query_volume: 10,
    qp_asin_impressions: 176,
    qp_asin_clicks: 5,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflamation arthritis cream for hands',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 60,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory cream and itch relief',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 20,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'antiinflammatory topical cream',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arthriris cream',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 78,
    qp_asin_clicks: 3,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 9.75,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para desinflamar los tendones',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 39,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para la inflamación en los tendones',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cycling inflammation formulation',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 60,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 17.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'knee swelling reducer cream',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 17.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'revo after care',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 37,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendinitis relief jbee',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendon release cream',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 10,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 677,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'penetrex inflammation formulation',
    qp_asin_query_volume: 778,
    qp_asin_impressions: 19845,
    qp_asin_clicks: 412,
    qp_asin_purchases: 80,
    qp_asin_median_query_price_purchases: 22.45,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 152,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'cream for inflammation',
    qp_asin_query_volume: 203,
    qp_asin_impressions: 4699,
    qp_asin_clicks: 48,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 8.32,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 338,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'knee swelling relief',
    qp_asin_query_volume: 1023,
    qp_asin_impressions: 22866,
    qp_asin_clicks: 336,
    qp_asin_purchases: 25,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 687,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'penetrex joint & muscle therapy',
    qp_asin_query_volume: 13638,
    qp_asin_impressions: 340628,
    qp_asin_clicks: 8255,
    qp_asin_purchases: 2180,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 679,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'muscle pain relief cream',
    qp_asin_query_volume: 4711,
    qp_asin_impressions: 107711,
    qp_asin_clicks: 1845,
    qp_asin_purchases: 364,
    qp_asin_median_query_price_purchases: 8.32,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 599,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'arthritis in fingers treatment',
    qp_asin_query_volume: 5793,
    qp_asin_impressions: 128217,
    qp_asin_clicks: 1501,
    qp_asin_purchases: 115,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 369,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema arnica antiinflamatoria',
    qp_asin_query_volume: 500,
    qp_asin_impressions: 9763,
    qp_asin_clicks: 226,
    qp_asin_purchases: 15,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 362,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendonitis',
    qp_asin_query_volume: 2800,
    qp_asin_impressions: 61701,
    qp_asin_clicks: 753,
    qp_asin_purchases: 80,
    qp_asin_median_query_price_purchases: 14.3,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'pomada para tendones inflamados',
    qp_asin_query_volume: 19,
    qp_asin_impressions: 346,
    qp_asin_clicks: 9,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 644,
    qp_asin_count_clicks: 0,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'te de arnica desinflamante',
    qp_asin_query_volume: 3931,
    qp_asin_impressions: 82681,
    qp_asin_clicks: 2143,
    qp_asin_purchases: 429,
    qp_asin_median_query_price_purchases: 9.89,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 445
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 388,
    qp_asin_count_clicks: 21,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light',
    qp_asin_query_volume: 582,
    qp_asin_impressions: 12857,
    qp_asin_clicks: 176,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 16.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 158,
    qp_asin_count_clicks: 14,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light clip',
    qp_asin_query_volume: 214,
    qp_asin_impressions: 4382,
    qp_asin_clicks: 96,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 8.03,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 572,
    qp_asin_count_clicks: 9,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading lights for books in bed',
    qp_asin_query_volume: 59571,
    qp_asin_impressions: 1299150,
    qp_asin_clicks: 28730,
    qp_asin_purchases: 5453,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 437,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable book light',
    qp_asin_query_volume: 5033,
    qp_asin_impressions: 109114,
    qp_asin_clicks: 2647,
    qp_asin_purchases: 326,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 127,
    qp_asin_count_clicks: 18,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses clip on light',
    qp_asin_query_volume: 161,
    qp_asin_impressions: 4826,
    qp_asin_clicks: 78,
    qp_asin_purchases: 6,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 123,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight for glasses',
    qp_asin_query_volume: 169,
    qp_asin_impressions: 3543,
    qp_asin_clicks: 80,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 11.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 265,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glasses with light',
    qp_asin_query_volume: 682,
    qp_asin_impressions: 18589,
    qp_asin_clicks: 324,
    qp_asin_purchases: 14,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 73,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glasses light',
    qp_asin_query_volume: 115,
    qp_asin_impressions: 3330,
    qp_asin_clicks: 38,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 9,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight glasses attachment',
    qp_asin_query_volume: 12,
    qp_asin_impressions: 261,
    qp_asin_clicks: 7,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 7,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable book light for glasses',
    qp_asin_query_volume: 10,
    qp_asin_impressions: 260,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 90,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on lights for glasses',
    qp_asin_query_volume: 118,
    qp_asin_impressions: 2575,
    qp_asin_clicks: 65,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 27.22,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 106,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on glasses light',
    qp_asin_query_volume: 148,
    qp_asin_impressions: 3133,
    qp_asin_clicks: 54,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'book light for glasses',
    qp_asin_query_volume: 42,
    qp_asin_impressions: 967,
    qp_asin_clicks: 17,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 7.66,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 19,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on led light for glasses',
    qp_asin_query_volume: 23,
    qp_asin_impressions: 606,
    qp_asin_clicks: 17,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 29,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight attachment for glasses',
    qp_asin_query_volume: 47,
    qp_asin_impressions: 1069,
    qp_asin_clicks: 15,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 15.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 26,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eye glasses light',
    qp_asin_query_volume: 39,
    qp_asin_impressions: 1024,
    qp_asin_clicks: 16,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 10,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip light glasses',
    qp_asin_query_volume: 15,
    qp_asin_impressions: 408,
    qp_asin_clicks: 6,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 8,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'ear reading light',
    qp_asin_query_volume: 11,
    qp_asin_impressions: 250,
    qp_asin_clicks: 5,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 305,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses with light',
    qp_asin_query_volume: 495,
    qp_asin_impressions: 11675,
    qp_asin_clicks: 186,
    qp_asin_purchases: 9,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 30,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses with reading light',
    qp_asin_query_volume: 33,
    qp_asin_impressions: 849,
    qp_asin_clicks: 18,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 17,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on eyeglass light',
    qp_asin_query_volume: 22,
    qp_asin_impressions: 583,
    qp_asin_clicks: 11,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 8,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on led light glasses',
    qp_asin_query_volume: 9,
    qp_asin_impressions: 216,
    qp_asin_clicks: 5,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 5,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for eyeglass',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 107,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses clip light scope',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glasses clip on light',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 40,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses lifht',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light rechargable clip',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 60,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 295,
    qp_asin_count_clicks: 12,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for glasses',
    qp_asin_query_volume: 382,
    qp_asin_impressions: 8530,
    qp_asin_clicks: 188,
    qp_asin_purchases: 19,
    qp_asin_median_query_price_purchases: 15.19,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 11,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eye glass light attachment',
    qp_asin_query_volume: 18,
    qp_asin_impressions: 341,
    qp_asin_clicks: 7,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 494,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses with lights',
    qp_asin_query_volume: 1896,
    qp_asin_impressions: 42376,
    qp_asin_clicks: 724,
    qp_asin_purchases: 30,
    qp_asin_median_query_price_purchases: 18.49,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 477,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses with lights',
    qp_asin_query_volume: 1244,
    qp_asin_impressions: 27037,
    qp_asin_clicks: 515,
    qp_asin_purchases: 28,
    qp_asin_median_query_price_purchases: 16.49,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 273,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight glasses',
    qp_asin_query_volume: 404,
    qp_asin_impressions: 8978,
    qp_asin_clicks: 151,
    qp_asin_purchases: 8,
    qp_asin_median_query_price_purchases: 16.49,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 40,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass lights clip on',
    qp_asin_query_volume: 58,
    qp_asin_impressions: 1331,
    qp_asin_clicks: 26,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 5.48,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 200,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light glasses',
    qp_asin_query_volume: 274,
    qp_asin_impressions: 6489,
    qp_asin_clicks: 106,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 314,
    qp_asin_count_clicks: 9,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glasses with lights',
    qp_asin_query_volume: 1877,
    qp_asin_impressions: 48908,
    qp_asin_clicks: 951,
    qp_asin_purchases: 47,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 9,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses mounted light',
    qp_asin_query_volume: 13,
    qp_asin_impressions: 268,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule rechargeable book light for glasses',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 139,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 278,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light glasses',
    qp_asin_query_volume: 1207,
    qp_asin_impressions: 27493,
    qp_asin_clicks: 369,
    qp_asin_purchases: 22,
    qp_asin_median_query_price_purchases: 16.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 196,
    qp_asin_count_clicks: 11,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light for glasses',
    qp_asin_query_volume: 259,
    qp_asin_impressions: 5749,
    qp_asin_clicks: 98,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 18.68,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 15,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule',
    qp_asin_query_volume: 22,
    qp_asin_impressions: 676,
    qp_asin_clicks: 9,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 82,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses flashlight attachment',
    qp_asin_query_volume: 115,
    qp_asin_impressions: 2375,
    qp_asin_clicks: 47,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 7.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 76,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses flashlight',
    qp_asin_query_volume: 108,
    qp_asin_impressions: 2335,
    qp_asin_clicks: 40,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 13.89,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 94,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glass with light',
    qp_asin_query_volume: 120,
    qp_asin_impressions: 2752,
    qp_asin_clicks: 26,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 19.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 54,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'book light glasses',
    qp_asin_query_volume: 72,
    qp_asin_impressions: 1578,
    qp_asin_clicks: 32,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 198,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'lights for glasses',
    qp_asin_query_volume: 281,
    qp_asin_impressions: 6958,
    qp_asin_clicks: 103,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 96,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light for glasses',
    qp_asin_query_volume: 131,
    qp_asin_impressions: 2943,
    qp_asin_clicks: 60,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 22,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses flashlight',
    qp_asin_query_volume: 30,
    qp_asin_impressions: 570,
    qp_asin_clicks: 13,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 17,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass clip light',
    qp_asin_query_volume: 24,
    qp_asin_impressions: 580,
    qp_asin_clicks: 10,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 49,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass light',
    qp_asin_query_volume: 71,
    qp_asin_impressions: 1745,
    qp_asin_clicks: 27,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 44,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for glasses dental',
    qp_asin_query_volume: 114,
    qp_asin_impressions: 2884,
    qp_asin_clicks: 55,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 29.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 24,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'neck reading light',
    qp_asin_query_volume: 4798,
    qp_asin_impressions: 121947,
    qp_asin_clicks: 2556,
    qp_asin_purchases: 425,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 165,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses with light',
    qp_asin_query_volume: 382,
    qp_asin_impressions: 8400,
    qp_asin_clicks: 165,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 16.33,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 19,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eye glass light',
    qp_asin_query_volume: 23,
    qp_asin_impressions: 694,
    qp_asin_clicks: 7,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 237,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'book lights for reading at night in bed',
    qp_asin_query_volume: 3768,
    qp_asin_impressions: 88273,
    qp_asin_clicks: 1827,
    qp_asin_purchases: 308,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 15,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light attachment for eye glasses',
    qp_asin_query_volume: 21,
    qp_asin_impressions: 399,
    qp_asin_clicks: 11,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 14,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glass light',
    qp_asin_query_volume: 30,
    qp_asin_impressions: 577,
    qp_asin_clicks: 7,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 10,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses book light',
    qp_asin_query_volume: 19,
    qp_asin_impressions: 361,
    qp_asin_clicks: 4,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 10,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'led spotlight clip on glasses',
    qp_asin_query_volume: 12,
    qp_asin_impressions: 228,
    qp_asin_clicks: 5,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 10,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading lights for glasses',
    qp_asin_query_volume: 17,
    qp_asin_impressions: 398,
    qp_asin_clicks: 6,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 9,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on led glasses',
    qp_asin_query_volume: 13,
    qp_asin_impressions: 370,
    qp_asin_clicks: 6,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 8,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight clip on glasses',
    qp_asin_query_volume: 10,
    qp_asin_impressions: 230,
    qp_asin_clicks: 10,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 189,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading headlamp',
    qp_asin_query_volume: 949,
    qp_asin_impressions: 21094,
    qp_asin_clicks: 450,
    qp_asin_purchases: 67,
    qp_asin_median_query_price_purchases: 12.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 5,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'linterna para anteojos usb',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 134,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 22,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable book light for glasses. led reading light. clip on',
    qp_asin_query_volume: 22,
    qp_asin_impressions: 416,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 18,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'book reading headlamp',
    qp_asin_query_volume: 39,
    qp_asin_impressions: 983,
    qp_asin_clicks: 20,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 12,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light that clips to glasses',
    qp_asin_query_volume: 16,
    qp_asin_impressions: 304,
    qp_asin_clicks: 11,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 191,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'bed reading light',
    qp_asin_query_volume: 5331,
    qp_asin_impressions: 125199,
    qp_asin_clicks: 2389,
    qp_asin_purchases: 320,
    qp_asin_median_query_price_purchases: 14.39,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 8,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses with reading lights',
    qp_asin_query_volume: 24,
    qp_asin_impressions: 1179,
    qp_asin_clicks: 13,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 8,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'headlamp for glasses',
    qp_asin_query_volume: 17,
    qp_asin_impressions: 359,
    qp_asin_clicks: 12,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 7,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses lighy',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 133,
    qp_asin_clicks: 4,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 7,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'head lamp rechargeable light wirh glasses',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 140,
    qp_asin_clicks: 9,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 5,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'led flash light glasses',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 95,
    qp_asin_clicks: 4,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on glasses light eye',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 68,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for glasses for reading at night',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 95,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'wearable light for reading',
    qp_asin_query_volume: 14,
    qp_asin_impressions: 389,
    qp_asin_clicks: 5,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses clip for shirt',
    qp_asin_query_volume: 471,
    qp_asin_impressions: 13694,
    qp_asin_clicks: 233,
    qp_asin_purchases: 29,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light glasses attachment',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass clip on flashlight rechargeable',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 120,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses attachment light',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'mini light attachment for glasses',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'over ears light for reading rechargeable',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 4,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eye glasses led attachment',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 20,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight to attach to eyeglasses usb rechargeable',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 97,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'lights for eyes glasses clip on',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'loghts that go on eye glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading lights made in germany',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 33,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading lights on glasses for books',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'small light that straps on geo reading glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 94,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light attachment',
    qp_asin_query_volume: 138,
    qp_asin_impressions: 2700,
    qp_asin_clicks: 53,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 8.37,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 45,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glass with light',
    qp_asin_query_volume: 54,
    qp_asin_impressions: 1771,
    qp_asin_clicks: 33,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 41,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light glasses',
    qp_asin_query_volume: 48,
    qp_asin_impressions: 1704,
    qp_asin_clicks: 27,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 5.17,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 187,
    qp_asin_count_clicks: 0,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glass light',
    qp_asin_query_volume: 350,
    qp_asin_impressions: 7965,
    qp_asin_clicks: 23,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.9,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 26,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses clip light',
    qp_asin_query_volume: 36,
    qp_asin_impressions: 814,
    qp_asin_clicks: 20,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 7.66,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 59,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'headlamps for reading',
    qp_asin_query_volume: 387,
    qp_asin_impressions: 10004,
    qp_asin_clicks: 134,
    qp_asin_purchases: 15,
    qp_asin_median_query_price_purchases: 12.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 126,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glasses with lights rechargeable',
    qp_asin_query_volume: 168,
    qp_asin_impressions: 4894,
    qp_asin_clicks: 85,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 18.94,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 84,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses with lights rechargeable',
    qp_asin_query_volume: 166,
    qp_asin_impressions: 3784,
    qp_asin_clicks: 66,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 16.49,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 34,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light to clip on glasses',
    qp_asin_query_volume: 63,
    qp_asin_impressions: 1190,
    qp_asin_clicks: 33,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 31,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass flashlight',
    qp_asin_query_volume: 45,
    qp_asin_impressions: 1174,
    qp_asin_clicks: 18,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 8.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light that attaches to glasses',
    qp_asin_query_volume: 39,
    qp_asin_impressions: 861,
    qp_asin_clicks: 18,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  },
  {
    date: '2024-05-31',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'luz para lentes',
    qp_asin_query_volume: 36,
    qp_asin_impressions: 727,
    qp_asin_clicks: 18,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 82
  }
];

const sqpProductsPrev = [
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 82,
    qp_asin_count_clicks: 48,
    qp_asin_count_purchases: 4,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule',
    qp_asin_query_volume: 98,
    qp_asin_impressions: 2737,
    qp_asin_clicks: 48,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 59,
    qp_asin_count_clicks: 34,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule rechargeable book light for glasses',
    qp_asin_query_volume: 68,
    qp_asin_impressions: 2002,
    qp_asin_clicks: 40,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 355,
    qp_asin_count_clicks: 26,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light',
    qp_asin_query_volume: 499,
    qp_asin_impressions: 12108,
    qp_asin_clicks: 162,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 16.45,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 354,
    qp_asin_count_clicks: 14,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for glasses',
    qp_asin_query_volume: 501,
    qp_asin_impressions: 12090,
    qp_asin_clicks: 261,
    qp_asin_purchases: 18,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 124,
    qp_asin_count_clicks: 10,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses clip on light',
    qp_asin_query_volume: 168,
    qp_asin_impressions: 4638,
    qp_asin_clicks: 82,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 11.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 843,
    qp_asin_count_clicks: 23,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses with lights',
    qp_asin_query_volume: 1270,
    qp_asin_impressions: 28402,
    qp_asin_clicks: 518,
    qp_asin_purchases: 36,
    qp_asin_median_query_price_purchases: 16.49,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 53,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses reading light',
    qp_asin_query_volume: 78,
    qp_asin_impressions: 1785,
    qp_asin_clicks: 27,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 15.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 547,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'bed reading light',
    qp_asin_query_volume: 5357,
    qp_asin_impressions: 126312,
    qp_asin_clicks: 2287,
    qp_asin_purchases: 293,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1036,
    qp_asin_count_clicks: 15,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses with lights',
    qp_asin_query_volume: 2184,
    qp_asin_impressions: 49185,
    qp_asin_clicks: 781,
    qp_asin_purchases: 61,
    qp_asin_median_query_price_purchases: 15.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 61,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass light',
    qp_asin_query_volume: 103,
    qp_asin_impressions: 2656,
    qp_asin_clicks: 33,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 21.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 149,
    qp_asin_count_clicks: 11,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light attachment',
    qp_asin_query_volume: 228,
    qp_asin_impressions: 5734,
    qp_asin_clicks: 83,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 13.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 236,
    qp_asin_count_clicks: 13,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light glasses',
    qp_asin_query_volume: 344,
    qp_asin_impressions: 8097,
    qp_asin_clicks: 146,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 599,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light glasses',
    qp_asin_query_volume: 1358,
    qp_asin_impressions: 31760,
    qp_asin_clicks: 379,
    qp_asin_purchases: 14,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses flashlight',
    qp_asin_query_volume: 31,
    qp_asin_impressions: 756,
    qp_asin_clicks: 9,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 357,
    qp_asin_count_clicks: 11,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glasses with lights',
    qp_asin_query_volume: 1938,
    qp_asin_impressions: 49342,
    qp_asin_clicks: 978,
    qp_asin_purchases: 68,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 135,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on glasses light',
    qp_asin_query_volume: 186,
    qp_asin_impressions: 4247,
    qp_asin_clicks: 103,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 25.98,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 70,
    qp_asin_count_clicks: 9,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light clip',
    qp_asin_query_volume: 100,
    qp_asin_impressions: 2422,
    qp_asin_clicks: 42,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 11.39,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass clip light',
    qp_asin_query_volume: 47,
    qp_asin_impressions: 1063,
    qp_asin_clicks: 17,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 11.39,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 8,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip light glasses',
    qp_asin_query_volume: 10,
    qp_asin_impressions: 192,
    qp_asin_clicks: 4,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 33,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'book light for glasses',
    qp_asin_query_volume: 47,
    qp_asin_impressions: 1098,
    qp_asin_clicks: 18,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'eye glasses light',
    qp_asin_query_volume: 26,
    qp_asin_impressions: 711,
    qp_asin_clicks: 11,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 24,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on lights for glasses',
    qp_asin_query_volume: 47,
    qp_asin_impressions: 935,
    qp_asin_clicks: 21,
    qp_asin_purchases: 6,
    qp_asin_median_query_price_purchases: 18.77,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 17,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on lights for reading glasses',
    qp_asin_query_volume: 30,
    qp_asin_impressions: 684,
    qp_asin_clicks: 12,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 11.79,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 11,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading headlamp',
    qp_asin_query_volume: 1087,
    qp_asin_impressions: 25197,
    qp_asin_clicks: 450,
    qp_asin_purchases: 54,
    qp_asin_median_query_price_purchases: 11.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass clip on light',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 131,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'tiny clip on light eyeglasses',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light clip glasses',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'brand: nachteule rechargeable book light for glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 20,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on spotlight for glasses',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglasses reading light',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'head lamps connect to glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 22,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light bed side long arm clip on',
    qp_asin_query_volume: 14,
    qp_asin_impressions: 256,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeabel glasses with light',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 33,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 39.95,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable lights that clip to glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 39.95,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 180,
    qp_asin_count_clicks: 10,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light for glasses',
    qp_asin_query_volume: 305,
    qp_asin_impressions: 6467,
    qp_asin_clicks: 113,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 21.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 92,
    qp_asin_count_clicks: 9,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'book light glasses',
    qp_asin_query_volume: 121,
    qp_asin_impressions: 2593,
    qp_asin_clicks: 52,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 21.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 18,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on lights for eyeglasses',
    qp_asin_query_volume: 23,
    qp_asin_impressions: 611,
    qp_asin_clicks: 14,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 10.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 183,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'lights for glasses',
    qp_asin_query_volume: 262,
    qp_asin_impressions: 6376,
    qp_asin_clicks: 97,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 11.79,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 19,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light for safety glasses',
    qp_asin_query_volume: 71,
    qp_asin_impressions: 1534,
    qp_asin_clicks: 13,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 119,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses flashlight',
    qp_asin_query_volume: 193,
    qp_asin_impressions: 4093,
    qp_asin_clicks: 68,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 11.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 353,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses with light',
    qp_asin_query_volume: 555,
    qp_asin_impressions: 12327,
    qp_asin_clicks: 215,
    qp_asin_purchases: 10,
    qp_asin_median_query_price_purchases: 15.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 435,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'book lights for reading at night in bed',
    qp_asin_query_volume: 3162,
    qp_asin_impressions: 76570,
    qp_asin_clicks: 1432,
    qp_asin_purchases: 187,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 63,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass light attachment',
    qp_asin_query_volume: 81,
    qp_asin_impressions: 2147,
    qp_asin_clicks: 38,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 8.02,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 313,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses with light',
    qp_asin_query_volume: 535,
    qp_asin_impressions: 12330,
    qp_asin_clicks: 180,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 79,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight for glasses',
    qp_asin_query_volume: 126,
    qp_asin_impressions: 2787,
    qp_asin_clicks: 60,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 11.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 66,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable reading glasses',
    qp_asin_query_volume: 86,
    qp_asin_impressions: 1774,
    qp_asin_clicks: 54,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 305,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'flashlight glasses',
    qp_asin_query_volume: 510,
    qp_asin_impressions: 12286,
    qp_asin_clicks: 203,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 11.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglasses light',
    qp_asin_query_volume: 41,
    qp_asin_impressions: 970,
    qp_asin_clicks: 15,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 11.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 29,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light glasses clip',
    qp_asin_query_volume: 40,
    qp_asin_impressions: 837,
    qp_asin_clicks: 18,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on headlamp for glasses',
    qp_asin_query_volume: 37,
    qp_asin_impressions: 879,
    qp_asin_clicks: 17,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 34.66,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 21,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses light clip on',
    qp_asin_query_volume: 24,
    qp_asin_impressions: 502,
    qp_asin_clicks: 14,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 12.29,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 55,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light attachment for glasses',
    qp_asin_query_volume: 79,
    qp_asin_impressions: 1581,
    qp_asin_clicks: 26,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 11.39,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 17,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light with glasses',
    qp_asin_query_volume: 34,
    qp_asin_impressions: 683,
    qp_asin_clicks: 12,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 235,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'book light',
    qp_asin_query_volume: 230647,
    qp_asin_impressions: 4986953,
    qp_asin_clicks: 115449,
    qp_asin_purchases: 17073,
    qp_asin_median_query_price_purchases: 8.79,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 14,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'lights on glasses',
    qp_asin_query_volume: 20,
    qp_asin_impressions: 424,
    qp_asin_clicks: 10,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 46,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light for glasses attachment',
    qp_asin_query_volume: 80,
    qp_asin_impressions: 1644,
    qp_asin_clicks: 30,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 11.39,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 44,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'safety glasses light',
    qp_asin_query_volume: 99,
    qp_asin_impressions: 2313,
    qp_asin_clicks: 33,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 6,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for eye glasses rechargeable',
    qp_asin_query_volume: 6,
    qp_asin_impressions: 150,
    qp_asin_clicks: 7,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 6,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule light',
    qp_asin_query_volume: 6,
    qp_asin_impressions: 60,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'led safety glasses',
    qp_asin_query_volume: 296,
    qp_asin_impressions: 7392,
    qp_asin_clicks: 134,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 16.49,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for reading glasses',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass light led clip',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 180,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 39,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass flashlight',
    qp_asin_query_volume: 46,
    qp_asin_impressions: 1036,
    qp_asin_clicks: 22,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 8.02,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses attachible light',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 107,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses flashlight attachment',
    qp_asin_query_volume: 170,
    qp_asin_impressions: 3481,
    qp_asin_clicks: 74,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 12.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 25,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light that attaches to glasses',
    qp_asin_query_volume: 32,
    qp_asin_impressions: 692,
    qp_asin_clicks: 15,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 2.79,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 14,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses clip light',
    qp_asin_query_volume: 19,
    qp_asin_impressions: 405,
    qp_asin_clicks: 12,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 11.59,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 14,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable light glasses stylish',
    qp_asin_query_volume: 25,
    qp_asin_impressions: 475,
    qp_asin_clicks: 17,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 12,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light that clips on glasses',
    qp_asin_query_volume: 14,
    qp_asin_impressions: 261,
    qp_asin_clicks: 6,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 10,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glass with lights',
    qp_asin_query_volume: 17,
    qp_asin_impressions: 433,
    qp_asin_clicks: 4,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 5,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'craft light clip onto eyeglasses',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 134,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 5,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light attachment to glasses',
    qp_asin_query_volume: 16,
    qp_asin_impressions: 292,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 187,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light',
    qp_asin_query_volume: 120570,
    qp_asin_impressions: 2718102,
    qp_asin_clicks: 52975,
    qp_asin_purchases: 8865,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 5,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading light ear',
    qp_asin_query_volume: 13,
    qp_asin_impressions: 247,
    qp_asin_clicks: 5,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on reading light for glasses',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 134,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 11.39,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses ligjt',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light clip glasses',
    qp_asin_query_volume: 5,
    qp_asin_impressions: 95,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light glasses reading',
    qp_asin_query_volume: 8,
    qp_asin_impressions: 195,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'lights for eye glasses',
    qp_asin_query_volume: 14,
    qp_asin_impressions: 269,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'mini light glasses attachment',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 159,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on light for glases',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 75,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass litht',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 75,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'luces para lentes',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable book light for glasses. led reading light. clip on',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 75,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on reading light gladses',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'led clip on light glasses',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'led flashlights tjat attach to glasses',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 40,
    qp_asin_clicks: 3,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'light for reading glasses with attachment',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule book light',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading glasses clip on lights',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'tiny glasses light',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'clip on glasses light usb',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'eyeglass clip reading light rechargable',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 20,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'glasses with loght',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'led reading light glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule - rechargeable reading light for glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 11,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'nachteule clip on light for glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 15,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'pro-idee clip on light for glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 16,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'reading nightlight for glasses',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'A554K46TJL',
    qp_asin_query: 'rechargeable led light clip on glasses for close up work',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 2,
    qp_asin_purchases: 0,
    qp_asin_median_query_price_purchases: 0.0,
    marketplace: 3,
    parent_asin: '',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    deleted: false,
    variation_value_one: 'BBB',
    variation_value_two: '',
    variation_type_one: 'Color',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/61AmTnVdznL._SL75_.jpg',
    orders_count: 90
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 270,
    qp_asin_count_clicks: 68,
    qp_asin_count_purchases: 27,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene anti-inflammatory and pain relief formulation cream',
    qp_asin_query_volume: 151,
    qp_asin_impressions: 3587,
    qp_asin_clicks: 96,
    qp_asin_purchases: 31,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3435,
    qp_asin_count_clicks: 120,
    qp_asin_count_purchases: 15,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream',
    qp_asin_query_volume: 4355,
    qp_asin_impressions: 98661,
    qp_asin_clicks: 1667,
    qp_asin_purchases: 300,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 154,
    qp_asin_count_clicks: 50,
    qp_asin_count_purchases: 15,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene',
    qp_asin_query_volume: 80,
    qp_asin_impressions: 816,
    qp_asin_clicks: 60,
    qp_asin_purchases: 17,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 38,
    qp_asin_count_clicks: 16,
    qp_asin_count_purchases: 10,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activine',
    qp_asin_query_volume: 34,
    qp_asin_impressions: 301,
    qp_asin_clicks: 19,
    qp_asin_purchases: 10,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1093,
    qp_asin_count_clicks: 24,
    qp_asin_count_purchases: 5,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation cream',
    qp_asin_query_volume: 1269,
    qp_asin_impressions: 26238,
    qp_asin_clicks: 289,
    qp_asin_purchases: 32,
    qp_asin_median_query_price_purchases: 15.38,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 413,
    qp_asin_count_clicks: 14,
    qp_asin_count_purchases: 6,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory cream',
    qp_asin_query_volume: 496,
    qp_asin_impressions: 14416,
    qp_asin_clicks: 142,
    qp_asin_purchases: 29,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1306,
    qp_asin_count_clicks: 20,
    qp_asin_count_purchases: 4,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendonitis cream',
    qp_asin_query_volume: 1645,
    qp_asin_impressions: 37975,
    qp_asin_clicks: 739,
    qp_asin_purchases: 90,
    qp_asin_median_query_price_purchases: 13.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2236,
    qp_asin_count_clicks: 11,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation relief',
    qp_asin_query_volume: 3475,
    qp_asin_impressions: 79472,
    qp_asin_clicks: 1069,
    qp_asin_purchases: 95,
    qp_asin_median_query_price_purchases: 18.79,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 530,
    qp_asin_count_clicks: 21,
    qp_asin_count_purchases: 4,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream tendonitis',
    qp_asin_query_volume: 555,
    qp_asin_impressions: 13693,
    qp_asin_clicks: 192,
    qp_asin_purchases: 27,
    qp_asin_median_query_price_purchases: 20.58,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 968,
    qp_asin_count_clicks: 14,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'topical anti inflammatory',
    qp_asin_query_volume: 1126,
    qp_asin_impressions: 33899,
    qp_asin_clicks: 447,
    qp_asin_purchases: 83,
    qp_asin_median_query_price_purchases: 10.98,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 36,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 4,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene arnica cream',
    qp_asin_query_volume: 18,
    qp_asin_impressions: 619,
    qp_asin_clicks: 12,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 581,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'penetrex inflammation formulation',
    qp_asin_query_volume: 688,
    qp_asin_impressions: 17945,
    qp_asin_clicks: 337,
    qp_asin_purchases: 82,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 188,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 3,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation cream extra strength',
    qp_asin_query_volume: 252,
    qp_asin_impressions: 5818,
    qp_asin_clicks: 111,
    qp_asin_purchases: 17,
    qp_asin_median_query_price_purchases: 14.28,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1403,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory',
    qp_asin_query_volume: 12192,
    qp_asin_impressions: 276950,
    qp_asin_clicks: 3838,
    qp_asin_purchases: 389,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 410,
    qp_asin_count_clicks: 10,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tennis elbow cream',
    qp_asin_query_volume: 506,
    qp_asin_impressions: 14211,
    qp_asin_clicks: 199,
    qp_asin_purchases: 31,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 289,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'verapamil topical cream',
    qp_asin_query_volume: 418,
    qp_asin_impressions: 5851,
    qp_asin_clicks: 151,
    qp_asin_purchases: 14,
    qp_asin_median_query_price_purchases: 17.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 55,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendon cream',
    qp_asin_query_volume: 58,
    qp_asin_impressions: 1218,
    qp_asin_clicks: 34,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 19.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 46,
    qp_asin_count_clicks: 7,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene pain relief cream',
    qp_asin_query_volume: 23,
    qp_asin_impressions: 559,
    qp_asin_clicks: 7,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 109,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendonitis pain relief cream',
    qp_asin_query_volume: 132,
    qp_asin_impressions: 4340,
    qp_asin_clicks: 62,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 841,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cream for inflammation',
    qp_asin_query_volume: 844,
    qp_asin_impressions: 21521,
    qp_asin_clicks: 72,
    qp_asin_purchases: 13,
    qp_asin_median_query_price_purchases: 18.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 9,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 2,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para el dolor en el codo tendinitis',
    qp_asin_query_volume: 9,
    qp_asin_impressions: 171,
    qp_asin_clicks: 9,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 19.98,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 524,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendonitis pain relief',
    qp_asin_query_volume: 859,
    qp_asin_impressions: 19197,
    qp_asin_clicks: 211,
    qp_asin_purchases: 27,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 895,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'muscle pain relief cream',
    qp_asin_query_volume: 5712,
    qp_asin_impressions: 129039,
    qp_asin_clicks: 2273,
    qp_asin_purchases: 445,
    qp_asin_median_query_price_purchases: 8.66,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 152,
    qp_asin_count_clicks: 6,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammation cream',
    qp_asin_query_volume: 193,
    qp_asin_impressions: 4648,
    qp_asin_clicks: 67,
    qp_asin_purchases: 9,
    qp_asin_median_query_price_purchases: 20.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 321,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory topical',
    qp_asin_query_volume: 398,
    qp_asin_impressions: 8886,
    qp_asin_clicks: 121,
    qp_asin_purchases: 8,
    qp_asin_median_query_price_purchases: 10.31,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 129,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for feet',
    qp_asin_query_volume: 135,
    qp_asin_impressions: 2741,
    qp_asin_clicks: 59,
    qp_asin_purchases: 10,
    qp_asin_median_query_price_purchases: 15.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 171,
    qp_asin_count_clicks: 8,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema antiinflamatoria',
    qp_asin_query_volume: 251,
    qp_asin_impressions: 4915,
    qp_asin_clicks: 90,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 14.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 526,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arnica cream',
    qp_asin_query_volume: 44420,
    qp_asin_impressions: 1084728,
    qp_asin_clicks: 24377,
    qp_asin_purchases: 7059,
    qp_asin_median_query_price_purchases: 14.28,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 131,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendocomplex para tendones',
    qp_asin_query_volume: 108,
    qp_asin_impressions: 1067,
    qp_asin_clicks: 57,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 29.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 101,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'penetrex pain relief cream inflammation formulation',
    qp_asin_query_volume: 109,
    qp_asin_impressions: 3377,
    qp_asin_clicks: 47,
    qp_asin_purchases: 12,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 347,
    qp_asin_count_clicks: 5,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti swelling cream',
    qp_asin_query_volume: 446,
    qp_asin_impressions: 10385,
    qp_asin_clicks: 168,
    qp_asin_purchases: 14,
    qp_asin_median_query_price_purchases: 8.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 397,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 17.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'best anti inflammatory',
    qp_asin_query_volume: 606,
    qp_asin_impressions: 22790,
    qp_asin_clicks: 133,
    qp_asin_purchases: 10,
    qp_asin_median_query_price_purchases: 18.79,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 318,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema arnica antiinflamatoria',
    qp_asin_query_volume: 436,
    qp_asin_impressions: 8616,
    qp_asin_clicks: 168,
    qp_asin_purchases: 18,
    qp_asin_median_query_price_purchases: 8.54,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 10,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation pain cream',
    qp_asin_query_volume: 11,
    qp_asin_impressions: 254,
    qp_asin_clicks: 4,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 241,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'spray para golpes de futbolistas',
    qp_asin_query_volume: 560,
    qp_asin_impressions: 9878,
    qp_asin_clicks: 240,
    qp_asin_purchases: 17,
    qp_asin_median_query_price_purchases: 10.98,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 273,
    qp_asin_count_clicks: 12,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory creams',
    qp_asin_query_volume: 288,
    qp_asin_impressions: 9417,
    qp_asin_clicks: 91,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 20.58,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 214,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'treatment for arthritis in fingers',
    qp_asin_query_volume: 316,
    qp_asin_impressions: 12987,
    qp_asin_clicks: 82,
    qp_asin_purchases: 14,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 200,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'reduce inflammation',
    qp_asin_query_volume: 431,
    qp_asin_impressions: 9677,
    qp_asin_clicks: 273,
    qp_asin_purchases: 6,
    qp_asin_median_query_price_purchases: 18.79,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 149,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for pain',
    qp_asin_query_volume: 192,
    qp_asin_impressions: 4122,
    qp_asin_clicks: 49,
    qp_asin_purchases: 11,
    qp_asin_median_query_price_purchases: 14.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 140,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cream for pain relief and inflammation',
    qp_asin_query_volume: 326,
    qp_asin_impressions: 8284,
    qp_asin_clicks: 124,
    qp_asin_purchases: 20,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 159,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'knee bursitis treatment',
    qp_asin_query_volume: 264,
    qp_asin_impressions: 6204,
    qp_asin_clicks: 83,
    qp_asin_purchases: 8,
    qp_asin_median_query_price_purchases: 18.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 81,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory lotion',
    qp_asin_query_volume: 110,
    qp_asin_impressions: 2519,
    qp_asin_clicks: 31,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 29,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'non steroid anti inflammatory topical cream',
    qp_asin_query_volume: 21,
    qp_asin_impressions: 522,
    qp_asin_clicks: 12,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 10.38,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 63,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema desinflamatorio',
    qp_asin_query_volume: 82,
    qp_asin_impressions: 1674,
    qp_asin_clicks: 24,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 18.49,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 26,
    qp_asin_count_clicks: 4,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'elbow bursitis cream',
    qp_asin_query_volume: 31,
    qp_asin_impressions: 716,
    qp_asin_clicks: 14,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 23.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 69,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendon pain relief',
    qp_asin_query_volume: 96,
    qp_asin_impressions: 2579,
    qp_asin_clicks: 44,
    qp_asin_purchases: 5,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 31,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para desinflamar musculos',
    qp_asin_query_volume: 86,
    qp_asin_impressions: 1668,
    qp_asin_clicks: 32,
    qp_asin_purchases: 4,
    qp_asin_median_query_price_purchases: 7.37,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 91,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'antiinflamatorio natural',
    qp_asin_query_volume: 146,
    qp_asin_impressions: 3029,
    qp_asin_clicks: 53,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 14.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 1009,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'te de arnica desinflamante',
    qp_asin_query_volume: 4105,
    qp_asin_impressions: 81097,
    qp_asin_clicks: 2151,
    qp_asin_purchases: 393,
    qp_asin_median_query_price_purchases: 9.89,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 49,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory skin cream',
    qp_asin_query_volume: 60,
    qp_asin_impressions: 1472,
    qp_asin_clicks: 22,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 23.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 49,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para la inflamación',
    qp_asin_query_volume: 67,
    qp_asin_impressions: 1362,
    qp_asin_clicks: 27,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 22.63,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 9,
    qp_asin_count_clicks: 3,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cbd for pain anti inflammatory',
    qp_asin_query_volume: 6,
    qp_asin_impressions: 114,
    qp_asin_clicks: 5,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 36,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para desinflamar y dolor',
    qp_asin_query_volume: 50,
    qp_asin_impressions: 938,
    qp_asin_clicks: 10,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 31,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammatory cream',
    qp_asin_query_volume: 45,
    qp_asin_impressions: 975,
    qp_asin_clicks: 8,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 8.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 24,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema desinflamatorio muscular',
    qp_asin_query_volume: 38,
    qp_asin_impressions: 708,
    qp_asin_clicks: 5,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 12.55,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 22,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflamation cream',
    qp_asin_query_volume: 22,
    qp_asin_impressions: 676,
    qp_asin_clicks: 7,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'antiinflammatory cream',
    qp_asin_query_volume: 20,
    qp_asin_impressions: 546,
    qp_asin_clicks: 10,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 10.33,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 390,
    qp_asin_count_clicks: 11,
    qp_asin_count_purchases: 0,
    qp_asin_median_price_purchases: 0.0,
    asin: 'H515DFT59R',
    qp_asin_query: 'best anti inflammatory cream',
    qp_asin_query_volume: 393,
    qp_asin_impressions: 9220,
    qp_asin_clicks: 95,
    qp_asin_purchases: 11,
    qp_asin_median_query_price_purchases: 15.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 14,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory hands',
    qp_asin_query_volume: 9,
    qp_asin_impressions: 171,
    qp_asin_clicks: 4,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 11.82,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 7,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'mentol antiflamtorio',
    qp_asin_query_volume: 8,
    qp_asin_impressions: 141,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 6,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'level 3 cream for pain',
    qp_asin_query_volume: 12,
    qp_asin_impressions: 268,
    qp_asin_clicks: 4,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 2,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'rub on relief 3 oz - topical herbal cream',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 138,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 38,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arnica menthol cream',
    qp_asin_query_volume: 45,
    qp_asin_impressions: 1189,
    qp_asin_clicks: 26,
    qp_asin_purchases: 8,
    qp_asin_median_query_price_purchases: 14.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 34,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'best for inflammation',
    qp_asin_query_volume: 63,
    qp_asin_impressions: 2111,
    qp_asin_clicks: 16,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 23.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 30,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema antiinflamatoria para tendones',
    qp_asin_query_volume: 21,
    qp_asin_impressions: 412,
    qp_asin_clicks: 13,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 28,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'zheng gu shui pain relief',
    qp_asin_query_volume: 765,
    qp_asin_impressions: 17631,
    qp_asin_clicks: 441,
    qp_asin_purchases: 128,
    qp_asin_median_query_price_purchases: 22.3,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 23,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'arnica cream for inflammation',
    qp_asin_query_volume: 23,
    qp_asin_impressions: 629,
    qp_asin_clicks: 14,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 14.28,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflamatory cream',
    qp_asin_query_volume: 23,
    qp_asin_impressions: 583,
    qp_asin_clicks: 6,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 26.8,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'steroid cream for pain',
    qp_asin_query_volume: 59,
    qp_asin_impressions: 1413,
    qp_asin_clicks: 26,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 18.95,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 20,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendinitis pain relief',
    qp_asin_query_volume: 22,
    qp_asin_impressions: 495,
    qp_asin_clicks: 7,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 16,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para la tendonitis',
    qp_asin_query_volume: 29,
    qp_asin_impressions: 630,
    qp_asin_clicks: 9,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 23.29,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 13,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory steroid creams',
    qp_asin_query_volume: 10,
    qp_asin_impressions: 346,
    qp_asin_clicks: 4,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 12,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pomada para dolores musculares',
    qp_asin_query_volume: 4209,
    qp_asin_impressions: 81622,
    qp_asin_clicks: 1166,
    qp_asin_purchases: 98,
    qp_asin_median_query_price_purchases: 9.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 12,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'tendonitis elbow cream',
    qp_asin_query_volume: 18,
    qp_asin_impressions: 419,
    qp_asin_clicks: 6,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 12,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'torn meniscus pain relief',
    qp_asin_query_volume: 141,
    qp_asin_impressions: 2848,
    qp_asin_clicks: 40,
    qp_asin_purchases: 7,
    qp_asin_median_query_price_purchases: 17.09,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 8,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'prepak cream',
    qp_asin_query_volume: 12,
    qp_asin_impressions: 222,
    qp_asin_clicks: 5,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 7,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for ankle',
    qp_asin_query_volume: 6,
    qp_asin_impressions: 155,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 7,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflammation topical',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 115,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 6,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory cream for feet',
    qp_asin_query_volume: 7,
    qp_asin_impressions: 239,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammation knee',
    qp_asin_query_volume: 9,
    qp_asin_impressions: 156,
    qp_asin_clicks: 6,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatoryy for elbo',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 120,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory gel ice',
    qp_asin_query_volume: 8,
    qp_asin_impressions: 152,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 4,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'antiinflannatory back cream',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory foot creams',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'antinflamatory cream',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'crema para el dolor en el codo',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 2,
    qp_asin_purchases: 2,
    qp_asin_median_query_price_purchases: 19.98,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'inflamation pain cream',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 112,
    qp_asin_clicks: 5,
    qp_asin_purchases: 3,
    qp_asin_median_query_price_purchases: 18.99,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'joint pain relief anti inflammation',
    qp_asin_query_volume: 4,
    qp_asin_impressions: 76,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 3,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'pain relief anti inflammatory balm',
    qp_asin_query_volume: 3,
    qp_asin_impressions: 57,
    qp_asin_clicks: 3,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'achilles tendonitis anti inflammatory',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 20,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'activene anti-inflammatory & pain relief formulation',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 22,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti in lflammatory crea',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 25,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti inflammatory cream for lower back muscle',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 20,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'anti-inflammatory tendon cream',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'antiflamitory cream',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'best remedy for sniff knee after tkr',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 10,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cbd anti inflammatory pain relief cream',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cream antiflamatori',
    qp_asin_query_volume: 1,
    qp_asin_impressions: 19,
    qp_asin_clicks: 1,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'cremas para desinflamat',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  },
  {
    date: '2024-04-30',
    qp_asin_count_impressions: 2,
    qp_asin_count_clicks: 1,
    qp_asin_count_purchases: 1,
    qp_asin_median_price_purchases: 24.97,
    asin: 'H515DFT59R',
    qp_asin_query: 'help for tennis elbow cream',
    qp_asin_query_volume: 2,
    qp_asin_impressions: 38,
    qp_asin_clicks: 2,
    qp_asin_purchases: 1,
    qp_asin_median_query_price_purchases: 24.97,
    marketplace: 3,
    parent_asin: '',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    deleted: false,
    variation_value_one: '',
    variation_value_two: '',
    variation_type_one: '',
    variation_type_two: '',
    smallImage: 'https://m.media-amazon.com/images/I/71Rtm-hnbfL._SL75_.jpg',
    orders_count: 486
  }
];
let isPrev = false;
mock.onGet(API_V2_1.searchQueryPerformance.products).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 200));
  if (isPrev) {
    isPrev = false;
    return [200, sqpProductsPrev];
  }
  isPrev = true;
  return [200, sqpProducts];
});

const STATUS = {
  onboarding: 'finished', // 'started', 'finished'
  is_pulling_active: true // false
};
mock.onGet(API_V2_1.searchQueryPerformance.status).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200, STATUS];
});

const ACCOUNTS = [
  {
    seller_id: 'A2S4KAOVUFJTUH',
    region: 'NA',
    name: 'easy_baby North America Seller Account',
    marketplaces: [
      {
        total_jobs: 100,
        processed_jobs: 45,
        saved_jobs: 10,
        marketplaceid: 'A2EUQ1WTGCTBG2',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 100,
        saved_jobs: 100,
        marketplaceid: 'ATVPDKIKX0DER',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 0,
        saved_jobs: 25,
        marketplaceid: 'A1AM78C64UM0Y8',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      }
    ]
  },
  {
    seller_id: 'A3Q7PLQT3KXZKN',
    region: 'NA',
    name: 'bbr North America Seller Account',
    marketplaces: [
      {
        total_jobs: 100,
        processed_jobs: 45,
        saved_jobs: 10,
        marketplaceid: 'A2EUQ1WTGCTBG2',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 100,
        saved_jobs: 100,
        marketplaceid: 'ATVPDKIKX0DER',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      },
      {
        total_jobs: 100,
        processed_jobs: 0,
        saved_jobs: 25,
        marketplaceid: 'A1AM78C64UM0Y8',
        error: null,
        is_metadata_outdated: true,
        updated_on: null
      }
    ]
  }
];
mock.onGet(API_V2_1.searchQueryPerformance.accounts).reply(async () => {
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200, ACCOUNTS];
});
