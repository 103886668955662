import React, { useEffect, useRef, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';
import { LoadingSpinner } from '@components/index';
import OutlinedTextField from 'seller/pages/email-tool/components/OutlinedTextField';
import PropTypes from 'prop-types';

export const InlinePopupInput = ({
  open,
  value,
  onChange,
  isLoading,
  isFirstLine,
  isFirstColumn,
  isPercent
}) => {
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef();
  useEffect(() => {
    // Need to focus input when component is mounted
    if (open) inputRef.current.querySelector('input').focus();
  }, [open]);
  if (!open) return null;
  const requestInProcess = isLoading && inputValue !== value;
  const verticalAlignClass = isFirstLine ? 'top-0' : 'bottom-0';
  const horizontalAlignClass = isFirstColumn ? 'left-0' : 'right-0';
  const getInputProps = () => {
    if (isPercent)
      return {
        endAdornment: <InputAdornment position="start">%</InputAdornment>
      };
    return {
      startAdornment: <InputAdornment position="start">$</InputAdornment>
    };
  };
  return (
    <ClickAwayListener onClickAway={() => onChange(inputValue)}>
      <div
        className={classNames(
          'absolute flex items-center z-10',
          verticalAlignClass,
          horizontalAlignClass
        )}
        style={{ top: isFirstLine ? '2rem' : '' }}
        ref={inputRef}
      >
        <LoadingSpinner loading={requestInProcess} size="1.25rem" className="self-end" />
        <div className="bg-white shadow relative">
          <OutlinedTextField
            title=""
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value);
            }}
            disabled={requestInProcess}
            className="w-24"
            InputProps={getInputProps()}
            onKeyPress={e => {
              if (e.key === 'Enter') onChange(inputValue);
            }}
            ref={inputRef}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

InlinePopupInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFirstLine: PropTypes.bool.isRequired,
  isFirstColumn: PropTypes.bool.isRequired,
  isPercent: PropTypes.bool.isRequired
};

export default InlinePopupInput;
