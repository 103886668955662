import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Colors from 'styles/colors';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import arrowsDisableCheck from './disableCheck';
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';

const navbarStyle = {
  width: 'calc(100% - 2rem)',
  left: '1rem',
  top: '-0.25rem'
};

export default class CalendarNavbar extends Component {
  state = {
    prev: 0,
    next: 0
  };

  render() {
    const { prev, next } = this.state;
    const {
      onPreviousClick,
      onNextClick,
      className,
      lifeTime,
      today,
      month,
      twoCalendarsView
    } = this.props;

    const disable = arrowsDisableCheck(lifeTime, today, month, twoCalendarsView);

    if (prev !== 0) {
      onPreviousClick();
      this.setState(prevState => ({
        prev: prevState.prev - 1
      }));
    }
    if (next !== 0) {
      onNextClick();
      this.setState(prevState => ({
        next: prevState.next - 1
      }));
    }

    return (
      <div className={classNames('flex justify-between absolute', className)} style={navbarStyle}>
        <div className="flex">
          <IconButton
            onClick={() => {
              if (!disable.singleLeft) {
                this.setState({ prev: 1 });
              }
            }}
            className="z-10 h-8 w-8 p-0"
            disabled={disable.singleLeft}
          >
            <ChevronLeftIcon
              className="h-3 w-3"
              fill={disable.singleLeft ? Colors.gray.default : Colors.black.default}
            />
          </IconButton>
          <IconButton
            className="flex h-8 w-8 p-0"
            onClick={() => {
              if (!disable.doubleLeft) {
                this.setState({ prev: 2 });
              } else if (!disable.singleLeft) {
                this.setState({ prev: 1 });
              }
            }}
            disabled={disable.singleLeft}
          >
            <ChevronLeftIcon
              className="z-10 h-3 w-3 -mr-2"
              fill={disable.singleLeft ? Colors.gray.default : Colors.black.default}
            />
            <ChevronLeftIcon
              className="z-10 h-3 w-3"
              fill={disable.singleLeft ? Colors.gray.default : Colors.black.default}
            />
          </IconButton>
        </div>
        <div className="flex">
          <IconButton
            onClick={() => {
              if (!disable.doubleRight) {
                this.setState({ next: 2 });
              } else if (!disable.singleRight) {
                this.setState({ next: 1 });
              }
            }}
            disabled={disable.singleRight}
            className="z-10 h-8 w-8 p-0"
          >
            <ChevronRightIcon
              className="h-3 w-3 -mr-2"
              fill={disable.singleRight ? Colors.gray.default : Colors.black.default}
            />
            <ChevronRightIcon
              className="h-3 w-3"
              fill={disable.singleRight ? Colors.gray.default : Colors.black.default}
            />
          </IconButton>
          <IconButton
            className="z-10 h-8 w-8 p-0"
            disabled={disable.singleRight}
            onClick={() => {
              if (!disable.singleRight) {
                this.setState({ next: 1 });
              }
            }}
          >
            <ChevronRightIcon
              className="h-3 w-3"
              fill={disable.singleRight ? Colors.gray.default : Colors.black.default}
            />
          </IconButton>
        </div>
      </div>
    );
  }
}

CalendarNavbar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  className: PropTypes.string,
  lifeTime: PropTypes.instanceOf(Date).isRequired,
  today: PropTypes.instanceOf(Date).isRequired,
  month: PropTypes.instanceOf(Date),
  twoCalendarsView: PropTypes.bool.isRequired
};

CalendarNavbar.defaultProps = {
  className: '',
  onPreviousClick: () => {},
  onNextClick: () => {},
  month: null
};
