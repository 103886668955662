import { useState } from 'react';

/* eslint-disable import/prefer-default-export */
export function useTextInput(initValue) {
  const [value, setValue] = useState(initValue);

  function onChange(event) {
    setValue(event.currentTarget.value);
  }

  return {
    value,
    defaultValue: initValue,
    onChange,
    setValue
  };
}
