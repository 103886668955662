import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from './routing';
import { RouteView, Homepage, LoginWindow, Registration, ResetPasswordPage } from './homepageIndex';

const HomepageRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.root} component={Homepage} />
      <RouteView path={ROUTES.login} component={LoginWindow} />
      <RouteView path={ROUTES.registration} component={Registration} />
      <RouteView path={ROUTES.resetPassword} component={ResetPasswordPage} />
    </Switch>
  );
};

export default HomepageRoutes;
