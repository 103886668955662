import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import { BookmarkIcon } from '@components/icons';
import { GradButton } from '@components/buttons';
import { SVG_GRAD_ONE } from '@components/gradients';
import { useSelector } from 'react-redux';
import { productSearchSelector } from 'seller/store/reducers';
import BookmarkDialog from './BookmarkDialog';
import { getSelectionDiff } from './utils';

const StyledButton = withStyles({
  root: {
    '&:hover': {
      background: 'none'
    }
  }
})(Button);

const hoverBackground = (
  <div
    className="absolute top-0 left-0 h-full w-full hover-bg-grad-8 rounded"
    style={{ opacity: '0.1' }}
  />
);

const BookmarkTabButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { selectedBookmark, selectedProducts } = useSelector(productSearchSelector);
  const selectionDiff = getSelectionDiff(
    selectedBookmark?.preparedBookmarkProducts,
    selectedProducts
  );
  const showUpdate = selectionDiff?.changed;
  const onCLick = () => setShowDialog(true);
  return (
    <>
      <StyledButton className="h-8 px-0 max-w-1/2" variant="text" onClick={onCLick}>
        {hoverBackground}
        <div className="pl-8 flex items-center w-full">
          <BookmarkIcon height="1rem" fill={SVG_GRAD_ONE} className="-ml-6 absolute" />
          <span
            className={classNames('text-sm font-normal text-yellow normal-case mr-2 truncate', {
              'max-w-full': !showUpdate
            })}
          >
            {selectedBookmark?.name || 'Bookmark selection'}
          </span>
        </div>
      </StyledButton>
      {!!selectedBookmark && showUpdate && (
        <GradButton className="h-8" onClick={onCLick}>
          Update
        </GradButton>
      )}
      <BookmarkDialog
        selectedBookmark={selectedBookmark}
        selectionDiff={selectionDiff}
        onClose={() => setShowDialog(false)}
        open={showDialog}
      />
    </>
  );
};

export default BookmarkTabButton;
