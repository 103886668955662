import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from './CustomButton';

const AddFilterButton = ({ onClick }) => {
  return (
    <CustomButton
      className="flex flex-row justify-between flex-1 px-2 py-1 text-xs normal-case leading-normal sm-min-w-100px h-full text-violet-dark bg-white border border-violet-dark rounded"
      onClick={onClick}
      paddingY="0"
    >
      <div className="flex flex-row items-center">Add Filter</div>
      <div className="text-xl leading-none">+</div>
    </CustomButton>
  );
};

AddFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AddFilterButton;
