import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import Colors from 'styles/colors';

const textFieldStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: Colors.gray.dark
    },
    '& label': {
      transform: 'translate(14px, 1.1rem) scale(1)',
      color: Colors.gray.dark,
      fontSize: '0.875rem',
      backgroundColor: Colors.white.default,
      padding: '0 5px 2px 5px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
      color: Colors.gray.black,
      fontSize: '0.75rem',
      backgroundColor: Colors.white.default,
      padding: '0 5px 2px 5px'
    },
    '& input': {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: Colors.gray.darkest,
      fontSize: '0.875rem'
    },
    '& .MuiOutlinedInput-root': {
      minHeight: '3.0625rem',
      '& fieldset': {
        borderColor: Colors.gray.default
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.gray.default
      },
      '&:hover:not(.Mui-disabled) fieldset': {
        borderColor: Colors.yellow.default,
        borderWidth: '1px'
      },
      '&.Mui-focused:not(.Mui-disabled) fieldset': {
        borderColor: Colors.yellow.default,
        borderWidth: '1px'
      }
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: Colors.white.dark
    }
  }
});

const YellowMuiTextField = props => {
  const textFieldClasses = textFieldStyles();
  return (
    <TextField
      type="text"
      variant="outlined"
      {...props}
      classes={textFieldClasses}
      autoComplete="new-password"
    />
  );
};

export default YellowMuiTextField;
