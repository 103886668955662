import React from 'react';
import PropTypes from 'prop-types';

const SvgGradInitializer = ({ id, start, end, gradientProps }) => {
  return (
    <svg style={{ width: 0, height: 0, position: 'absolute' }} aria-hidden="true" focusable="false">
      <linearGradient id={id} {...gradientProps}>
        <stop offset="0%" stopColor={start} />
        <stop offset="100%" stopColor={end} />
      </linearGradient>
    </svg>
  );
};

SvgGradInitializer.propTypes = {
  id: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  gradientProps: PropTypes.instanceOf(Object)
};
SvgGradInitializer.defaultProps = {
  gradientProps: {}
};

export default SvgGradInitializer;
