import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Colors from 'styles/colors';
import { DownloadIcon } from '../icons';
import GradButton from './GradButton';

function DownloadButton(props) {
  const {
    children,
    active,
    data,
    fileName,
    className,
    columns,
    onClick,
    dateRange,
    marketplaceID,
    onHandle,
    ...others
  } = props;

  return (
    <GradButton
      className={classNames('h-8', className, {
        'bg-grad-8': active,
        'bg-gray': !active
      })}
      paddingY="py-0"
      onClick={() => {
        if (!active) {
          return;
        }
        onClick(data, fileName, columns, dateRange, marketplaceID, onHandle);
      }}
      {...others}
      active={active}
    >
      <div className="flex flex-row items-center">
        <DownloadIcon fill={Colors.white.default} className="w-4 h-4 mr-2" />
        {children}
      </div>
    </GradButton>
  );
}

DownloadButton.propTypes = {
  children: PropTypes.node.isRequired,
  fileName: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  columns: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

DownloadButton.defaultProps = {
  className: '',
  onClick: () => {},
  active: true
};

export default DownloadButton;
